<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getPusherInstance } from '@/utils';

export default {
  name: 'ChatDataProvider',
  created() {
    this.loadChatData();
    getPusherInstance().bind('chat-change', () => this.loadChatData());

    getPusherInstance().bind('chat-new-chat', (topic, data) =>
      this.addChat(data.additional_data)
    );

    getPusherInstance().bind('chat-new-message', (topic, data) =>
      this.addMessage(data.additional_data)
    );

    getPusherInstance().bind('chat-new-user', (topic, data) =>
      this.addChatUser(data.additional_data)
    );
  },

  methods: {
    ...mapActions('Chat', ['loadChatData', 'resetChatPage', 'addMessage']),
    ...mapMutations('Chat', [
      'addChat',
      'updateChat',
      'deleteChat',
      'addChatUser',
      'updateChatUser',
      'deleteChatUser',
      'updateChatMessage',
      'deleteChatMessage'
    ])
  },
  computed: {
    ...mapGetters('Chat', ['getMode', 'getChats', 'isLoading'])
  },

  render() {
    if (this.isLoading) {
      return this.$scopedSlots.default({
        isLoading: this.isLoading
      });
    }

    return this.$scopedSlots.default({
      mode: this.getMode,
      chats: this.getChats
    });
  }
};
</script>
