<template>
  <button @click="$emit('click')" :class="className">
    <i class="fa fa-sm" :class="'fa-' + icon" />
    <span class="text-sm">
      {{ custom_caption }}
    </span>
  </button>
</template>
<script>
export default {
  name: 'BookSessionButton',
  props: {
    icon: { type: Array, default: null },
    custom_caption: {},
    className: {
      type: String,
      default: 'flex items-center space-x-2'
    }
  }
};
</script>
