<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <div
      class="flex flex-wrap flex-col w-full h-full overflow-y-auto overflow-x-hidden"
      :class="!isPromotedPage ? ' p-10' : 'pt-4'"
      :style="designStyles"
    >
      <div class="flex-1 space-y-4">
        <!-- Header -->
        <div class="flex flex-col md:flex-row space-y-2">
          <!-- Image -->
          <div
            class="md:mr-4 flex justify-center"
            aria-label="speakers image"
            tabindex="0"
          >
            <Avatar
              :image="speaker.profileImageUrl"
              :circle="true"
              aria-hidden="true"
              :alt="$t('Views.Shared.SpeakerPicture')"
              :size="!isPromotedPage ? 'xlarge' : 'large'"
            />
          </div>
          <!--Details-->
          <div class="flex-1 text-center md:text-left space-y-4">
            <div>
              <h2
                class="font-bold mb-2"
                :class="!isPromotedPage ? 'text-md' : 'text-base '"
              >
                {{ speaker.name + ' ' + speaker.lastName }}
              </h2>
              <h3
                class="break-word font-bold mb-2"
                :class="!isPromotedPage ? ' text-base' : ' text-sm'"
              >
                {{ speaker.company }}
              </h3>
              <h4
                class="break-word text-sm"
                :class="!isPromotedPage ? ' font-bold' : ' '"
              >
                {{ speaker.jobTitle }}
              </h4>
            </div>

            <!-- Social Media -->
            <div class="flex justify-center md:justify-start">
              <ContactDetails
                :links="speaker.links"
                :phoneNumber="speaker.phoneNumber"
                :email="speaker.email"
              ></ContactDetails>
            </div>
          </div>
        </div>
        <div class="text-base html-content" v-html="speaker.description" />
        <div v-if="!isPromotedPage" class="flex justify-between items-baseline">
          <div>
            <button
              @click="onModalClose"
              class="button button-primary text-sm md:text-base"
              aria-label="close"
            >
              {{ $t('Components.Speaker.SpeakerInformation.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>
<script>
import Avatar from '@/components/shared/Avatar';
import ContactDetails from '@/components/shared/ContactDetails';
import { mapActions } from 'vuex';

export default {
  name: 'SpeakerInformation',
  components: { Avatar, ContactDetails },
  props: {
    speaker: {
      type: Object,
      required: true
    },
    isPromotedPage: Boolean
  },
  computed: {
    applicablePlatform() {
      return this.$route.fullPath.includes('webapp') ? ['webApp'] : ['digital'];
    }
  },
  mounted() {
    // this.$root.$on('focusChild', () => this.$refs.childFocus.focus());
  },
  methods: {
    ...mapActions('Info', ['setPromotedPage']),
    onModalClose() {
      this.$emit('close', {
        success: false
      });
      // setTimeout(function () {
      //   document.getElementById('sInfo').focus();
      // }, 0);
    }
  }
};
</script>

<style scoped>
::v-deep .html-content > p:has(iframe) {
  position: relative;
  padding-top: 56.25%;
}
::v-deep .html-content > p > iframe {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
}
</style>
