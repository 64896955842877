<template>
  <CurrentEventDataProvider
    v-slot="{ data, isOutsideEventContext, designStyles }"
  >
    <div id="app" :style="designStyles">
      <!-- Application content -->
      <transition v-if="data || isOutsideEventContext" name="fade">
        <router-view />
      </transition>

      <!-- Loader  -->
      <div v-else>
        <div class="loader">Loading...</div>
      </div>

      <notifications group="default" position="bottom center" />
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import store from './store';
import ApiClient from '@/client';
import Bowser from 'bowser';
import '@khmyznikov/pwa-install';
import { injectPwaInstallPrompt } from '@/helpers/pwa-helpers';

export default {
  name: 'App',
  methods: {
    createUrlBlob(data) {
      const blob = new Blob([JSON.stringify(data)], {
        type: 'application/json'
      });
      return URL.createObjectURL(blob);
    },

    injectManifest(data) {
      var current = document.querySelector('link[rel="manifest"]');

      if (!current) {
        var link = document.createElement('link');
        link.id = 'manifest';
        link.rel = 'manifest';
        link.href = this.createUrlBlob(data);
        document.head.appendChild(link);
      }
    }
  },
  mounted() {
    store.subscribe((mutation) => {
      if (mutation.type.startsWith('Terms/setShown')) {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const isSafari = browser.satisfies({ safari: '>1' });

        // For Safari use user-specific
        if (isSafari) {
          ApiClient.getUserManifest().then((response) => {
            this.injectManifest(response.data);
            import('pwacompat');
            injectPwaInstallPrompt(response.data);
          });
        } else {
          ApiClient.getManifest().then((response) => {
            this.injectManifest(response.data);
          });
        }
      }
    });
  }
};
</script>

<style lang="scss" scoped>
#app {
  background-color: var(--background-color);
  color: var(--color-text);
}

.loader {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #888;
}
</style>
