<template>
  <CurrentEventDataProvider>
    <SponsorsDataProvider>
      <div class="sponsors-container h-full flex flex-col justify-center">
        <div class="flex flex-wrap justify-center">
          <tappin-top-sponsors
            ref="topSponsorsWc"
            class="mx-auto w-large"
            colorScheme="primary"
            :caption="options.label"
            :size="options.size"
          >
          </tappin-top-sponsors>
        </div>
      </div>
    </SponsorsDataProvider>
  </CurrentEventDataProvider>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    options: Object
  },
  data() {
    return {
      translations: {},
      sponsorsElement: null
    };
  },
  mounted() {
    this.sponsorsElement = this.$refs.topSponsorsWc;

    if (!this.sponsorsElement) {
      console.error(`topSponsorsWc element was not found`);
      return;
    }

    this.sponsorsElement.designStyles = this.getDesignStyles;
    this.sponsorsElement.translations = this.translations;
    this.sponsorsElement.sponsors = this.options.elements.map((element) =>
      this.sponsors?.find((sponsor) => sponsor.id === element.sponsorId)
    );

    this.sponsorsElement.addEventListener('sponsorClick', (e) => {
      const sponsorId = e.detail?.sponsor?.id;
      if (!sponsorId) {
        return;
      }

      this.$router.push({
        name: 'sponsor',
        params: { sponsorId }
      });
    });
  },
  watch: {
    '$store.state.Sponsors.items': function (newSponsors) {
      this.sponsorsElement.sponsors = this.options.elements.map((element) =>
        newSponsors.find((sponsor) => sponsor.id === element.sponsorId)
      );
    }
  },
  computed: {
    ...mapGetters('CurrentEvent', ['getDesignStyles']),
    ...mapGetters('Sponsors', { sponsors: 'getItems' })
  }
};
</script>
<style scoped lang="scss">
.sponsors-container {
  background-color: var(--color-primary-bg);
}
.w-large {
  width: 1060px;
}
</style>
