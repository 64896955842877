<template>
  <CurrentEventDataProvider v-slot="{ logoUrl, designStyles }">
    <div
      class="flex flex-wrap overflow-hidden w-full h-full space-y-2"
      :style="designStyles"
    >
      <!-- Navbar -->
      <div
        class="navbar top-0 z-30 flex justify-between items-center text-center sticky font-bold bg-dark px-4 w-full"
      >
        <div class="flex items-center h-16">
          <img
            :src="'https://jolly-coast-0ed26f703-development.westeurope.3.azurestaticapps.net/ec7c06a311e758b602863004841e8d19.svg'"
            :alt="logoUrl && 'Event\'s logo'"
            class="object-scale-down h-6 md:h-12 w-auto"
          />
        </div>
      </div>
      <!-- Content -->
      <div class="flex flex-row w-full h-full px-2 md:px-4 justify-center">
        <div class="space-y-6">
          <div
            class="flex flex-row flex-wrap items-center justify-center space-x-4"
          >
            <div>
              <img
                :src="sponsor.logoUrl"
                :alt="logoUrl && 'Event\'s logo'"
                class="object-scale-down h-6 md:h-16 w-16"
              />
            </div>
            <span class="sponsor-name">{{ sponsor.name }}</span>
          </div>
          <Card v-if="!sent">
            <form
              method="post"
              @submit.prevent="sendContactInfo"
              class="space-y-6 p-2"
            >
              <!-- Header-->
              <div class="flex flex-col text-center space-y-2">
                <h1 class="font-bold text-lg">Contact Us</h1>
                <h2>Lets get in touch</h2>
              </div>
              <!-- Content-->
              <div class="space-y-2">
                <!-- Name -->
                <div class="items-start">
                  <div
                    class="inline-block font-bold text-xxs md:text-xs lg:text-sm"
                  >
                    {{ $t('Views.Sponsor.ContactForm.name') }}
                  </div>
                  <div class="md:flex">
                    <div class="w-full lg:w-1/2 md:mr-2">
                      <input
                        v-model="form.firstName"
                        :placeholder="$t('Views.Sponsor.ContactForm.firstName')"
                        type="text"
                        class="p-1 md:p-2 bg-light text-dark text-base w-full rounded outline-none border-none shadow-md"
                      />
                      <div v-if="$v.form.firstName.$invalid" class="form-error">
                        {{ $t('Views.Sponsor.ContactForm.firstNameRequired') }}
                      </div>
                    </div>
                    <div class="w-full lg:w-1/2">
                      <input
                        v-model="form.lastName"
                        :placeholder="$t('Views.Sponsor.ContactForm.lastName')"
                        type="text"
                        class="p-1 md:p-2 bg-light text-dark text-base w-full rounded outline-none border-none shadow-md"
                      />
                      <div v-if="$v.form.lastName.$invalid" class="form-error">
                        {{ $t('Views.Sponsor.ContactForm.lastNameRequired') }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Email-->
                <div class="w-full">
                  <div
                    class="w-1/2 inline-block font-bold text-xxs md:text-xs lg:text-sm"
                  >
                    {{ $t('Views.Sponsor.ContactForm.email') }}
                  </div>
                  <div>
                    <input
                      v-model="form.email"
                      type="email"
                      class="p-1 md:p-2 bg-light text-base text-gray-500 w-full rounded outline-none border-none shadow-md"
                      :placeholder="$t('Views.Sponsor.ContactForm.email')"
                    />
                    <div v-if="$v.form.email.$invalid" class="form-error">
                      {{ $t('Views.Sponsor.ContactForm.emailInvalid') }}
                    </div>
                  </div>
                </div>
                <!-- Company -->
                <div class="w-full md:mr-2">
                  <div
                    class="inline-block font-bold text-xxs md:text-xs lg:text-sm"
                  >
                    {{ $t('Views.Sponsor.ContactForm.company') }}
                  </div>
                  <input
                    v-model="form.company"
                    type="text"
                    class="p-1 md:p-2 bg-light text-base text-dark w-full rounded outline-none border-none shadow-md"
                    :placeholder="$t('Views.Sponsor.ContactForm.company')"
                  />
                  <div v-if="$v.form.company.$invalid" class="form-error">
                    {{ $t('Views.Sponsor.ContactForm.companyNameRequired') }}
                  </div>
                </div>
                <!-- Description -->
                <div class="items-start">
                  <div>
                    <span
                      class="inline-block font-bold text-xxs md:text-xs lg:text-sm"
                    >
                      {{ $t('Views.Sponsor.ContactForm.description') }}
                    </span>
                  </div>
                  <div>
                    <textarea
                      v-model="form.description"
                      rows="2"
                      type="text"
                      class="p-2 bg-light text-xxs md:text-xs lg:text-sm text-dark w-full rounded"
                      :placeholder="$t('Views.Sponsor.ContactForm.description')"
                    />
                  </div>
                </div>
              </div>
              <!-- Actions -->
              <div
                class="text-base flex justify-center items-baseline relative"
              >
                <!-- Submit -->
                <TappinButton
                  v-focus
                  class="button-primary cursor-pointer hover:opacity-50 transition-all duration-200 text-dark font-bold space-x-2 text-sm lg:text-base py-2 px-4 border rounded"
                  :custom_caption="
                    loading
                      ? $t('Views.Sponsor.ContactForm.saving')
                      : $t('Views.Sponsor.ContactForm.submit')
                  "
                  type="submit"
                  aria-label="Submit the details"
                >
                  <span
                    v-if="loading"
                    class="inline-block"
                    aria-label="loading"
                  >
                    {{ $t('Views.Sponsor.ContactForm.saving') }}
                    <i class="fas ml-2 fa-circle-notch fa-spin" />
                  </span>
                  <span v-else class="inline-block" aria-label="save">
                    {{ $t('Views.Sponsor.ContactForm.submit') }}
                  </span>
                </TappinButton>
              </div>
            </form>
          </Card>
          <div v-else class="flex flex-col items-center justify-center">
            <div>
              <img src="/assets/tick.png" class="h-32 w-auto mt-4 mb-4" />
            </div>
            <div class="flex flex-col items-center justify-center gap-2">
              <h1 class="greets">
                {{ $t('Views.Sponsor.ContactForm.thankYou') }}
              </h1>
              <p>
                {{ $t('Views.Sponsor.ContactForm.submissionMessage') }}
              </p>
              <p class="greets-subtitle">
                {{ $t('Views.Sponsor.ContactForm.submissionSubtitle') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>
<script>
import ApiClient from '@/client';
import { mapGetters } from 'vuex';
import Card from '@/components/shared/Card';
import TappinButton from '@/components/buttons/TappinButton';
import { email, minLength, required } from 'vuelidate/lib/validators';

export default {
  name: 'SponsorContactForm',
  components: {
    Card,
    TappinButton
  },
  validations: {
    form: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        email
      },
      company: {
        required
      },
      description: {
        minLength: minLength(4)
      }
    }
  },
  created() {
    this.fetchItems();
  },
  data() {
    return {
      sponsorId: window.location.pathname.split('/')[2],
      loading: false,
      sponsor: null,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        description: ''
      },
      sent: false
    };
  },
  computed: {
    ...mapGetters('CurrentEvent', ['getDesignStyles'])
  },
  methods: {
    sendContactInfo() {
      this.loading = true;
      const form = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        company: this.form.company,
        description: this.form.description
      };
      ApiClient.sendLeadToSponsor(this.sponsorId, form).then((r) => {
        if (r.data === true) {
          this.sent = true;
        }
      });
    },

    fetchItems() {
      ApiClient.getSponsor(this.sponsorId).then((r) => (this.sponsor = r.data));
    }
  }
};
</script>

<style>
.form-error {
  margin-top: 5px;
  color: red;
  font-weight: bold;
  font-size: 12px;
}
.sponsor-name {
  font-size: 22px;
  font-weight: bold;
}

.greets {
  font-size: 28px;
  font-weight: bold;
}

.greets-subtitle {
  font-style: italic;
  color: lightgray;
}
</style>
