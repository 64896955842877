var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CurrentEventDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ hasFullEventDetails, features }){return [(hasFullEventDetails)?_c('div',{staticClass:"primary-container flex flex-col lg:flex-row w-full overflow-hidden"},[_c('div',{staticClass:"main secondary-container w-full self-start flex flex-auto flex-col lg:h-screen transform transition-all ease-out duration-500 z-20"},[_c('div',[_vm._t("navbar")],2),_c('div',{staticClass:"flex-1"},[_vm._t("mainContent",null,{"features":features})],2),_vm._t("belowMainContent",function(){return [_c('div',{staticClass:"hidden lg:flex h-16 text-sm"},[(_vm.isWebapp)?_c('TappinButton',{staticClass:"button-primary cursor-pointer hover:opacity-50 transition-all duration-200 text-dark font-bold p-2 space-x-2 text-sm lg:text-base",attrs:{"icon":"chevron-left","custom_caption":_vm.$t('Components.shared.ContentWithSidebarLayout.backToLobby')},on:{"click":_vm.backToLobby}}):_vm._e()],1)]})],2),_c('div',{staticClass:"aside sidebar-container flex flex-col-reverse lg:flex-row h-full lg:max-h-screen lg:w-auto"},[_c('div',{staticClass:"aside__expand sidebar-container-expand flex-1 h-full lg:border-l border-grey pt-0 lg:pt-20 flex flex-col justify-between lg:transform lg:transition-all lg:ease-out lg:duration-500",class:{
          'sidebar-collapsed lg:w-0 lg:translate-x-full overflow-hidden':
            _vm.sidebarCollapsed,
          'sidebar-expanded lg:translate-x-0 lg:w-72 xl:w-96':
            !_vm.sidebarCollapsed
        }},[_vm._t("sidebarContent")],2),_c('div',{staticClass:"aside__not-expand border-b-0 border-t-0 flex flex-col w-16 relative z-20 border-grey overflow-x-visible overflow-y-auto"},[_c('div',{ref:"childTab",staticClass:"sidebar bg-navbar hidden lg:flex items-center h-20 p-4",attrs:{"tabindex":"-1"}},[_c('transition',{attrs:{"name":"fade"}},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                content: !_vm.sidebarCollapsed
                  ? _vm.$t('Components.shared.ContentWithSidebarLayout.collapse')
                  : _vm.$t('Components.shared.ContentWithSidebarLayout.expand'),
                placement: 'right',
                theme: 'light-border',
                arrow: false,
                flipBehavior: ['flip']
              }),expression:"{\n                content: !sidebarCollapsed\n                  ? $t('Components.shared.ContentWithSidebarLayout.collapse')\n                  : $t('Components.shared.ContentWithSidebarLayout.expand'),\n                placement: 'right',\n                theme: 'light-border',\n                arrow: false,\n                flipBehavior: ['flip']\n              }"}],staticClass:"button text-black w-16 h-16",attrs:{"aria-expanded":[!_vm.sidebarCollapsed ? 'true' : 'false'],"aria-label":[
                !_vm.sidebarCollapsed
                  ? 'sidebar contents open'
                  : 'sidebar contents closed'
              ]},on:{"click":_vm.toggleOpen}},[_c('i',{staticClass:"fal fa-chevron-left",class:{
                  'fa-chevron-left': _vm.sidebarCollapsed,
                  'fa-chevron-right': !_vm.sidebarCollapsed
                },attrs:{"aria-hidden":"true"}})])])],1),_vm._t("sidebar")],2)]),_vm._t("bottom")],2):_vm._e()]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }