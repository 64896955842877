<template>
  <div class="absolute top-0 bottom-0 w-full h-full">
    <ParticipantList
      :active="active"
      :listHeight="85.4"
      :showTitle="true"
      :showHeader="true"
    />
  </div>
</template>

<script>
import ParticipantList from '@/components/participants/ParticipantList';

export default {
  name: 'Participants',
  components: {
    ParticipantList
  },
  props: {
    active: Boolean
  }
};
</script>
