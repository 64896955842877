<template>
  <div class="overflow-hidden screen-height">
    <div class="h-full">
      <transition name="fade">
        <div class="video-container h-full">
          <video
            class="responsive-iframe"
            id="video-player"
            controls
            autoplay
            style="display: none"
          ></video>
          <div v-if="isReady" class="h-full" id="other-embed">
            <div v-if="!isImage" class="h-full">
              <iframe
                class="responsive-iframe"
                v-if="hasStream && embedType !== 'm3u8'"
                :src="embedUrl"
                frameborder="0"
                allowfullscreen
                :allow="iframeAllowAttributes"
              />
            </div>
            <div
              v-else
              class="flex flex-wrap justify-center content-center h-full image-container"
            >
              <img :src="embedUrl" />
              <div
                v-if="!isImage"
                class="absolute top-0 right-0 left-0 bottom-0 flex flex-col justify-center items-center"
              >
                <h2 class="font-bold text-lg mb-2 text-dark text-shadow-white">
                  {{ $t('Views.Stage.noStream') }}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ApiClient from '@/client';

export default {
  name: 'Section',
  props: {
    isReady: {
      type: Boolean,
      default: false
    },
    isImage: {
      type: Boolean,
      default: false
    },
    hasStream: {
      type: Boolean,
      default: false
    },
    embedType: {
      type: String,
      default: ''
    },
    embedUrl: {
      type: String,
      default: ''
    },
    iframeAllowAttributes: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      streamListener: null,
      player: null
    };
  },
  mounted() {
    this.updateStream(this.embedType);
  },
  watch: {
    embedType: async function (val) {
      this.updateStream(val);
    }
  },
  methods: {
    async updateStream(val) {
      const playerElement = document.getElementById('video-player');
      const iframeElement = document.getElementById('other-embed');
      this.removeStreamListener();
      if (val !== 'm3u8' && val !== 'ivs-embed') {
        playerElement.style.display = 'none';
        iframeElement.style.display = 'block';
        return;
      }

      if (!window.IVSPlayer.isPlayerSupported) {
        return;
      }

      playerElement.style.display = 'block';
      iframeElement.style.display = 'none';

      const player = window.IVSPlayer.create();

      player.attachHTMLVideoElement(playerElement);
      this.player = player;

      if (this.embedUrl?.includes('ivs/v1')) {
        await ApiClient.getSignedCookiesForRecordings();
        // Wait for several seconds for registereing cookies
        setTimeout(() => {
          // Add random hash to query to override browser cache
          const hash = (Math.random() + 1).toString(36).substring(2);
          player.load(`${this.embedUrl}?hash=${hash}`);
        }, 1500);
        return player.setRequestCredentials('include');
      }

      if (val === 'ivs-embed') {
        this.addStreamListener(player, this.embedUrl);
      }

      player.load(this.embedUrl);
      player.setAutoplay(true);
      player.play();
    },
    // Remove listeners and destroy player instance
    removeStreamListener() {
      this.player?.removeEventListener('Ready', this.playerReady);
      this.player?.removeEventListener('Ended', this.streamEnded);
      this.player?.delete();
      this.player = null;
      this.playerUrl = null;

      if (this.streamListener) {
        clearInterval(this.streamListener);
        this.streamListener = null;
      }
    },
    // Stream listener to listen for HLS stream status change
    addStreamListener(player, url) {
      this.playerUrl = url;
      this.streamListener = setInterval(() => {
        player.load(url);
      }, 5000);

      player.addEventListener('Ready', this.playerReady);
      player.addEventListener('Ended', this.streamEnded);
    },
    playerReady() {
      this.player?.play();

      if (this.streamListener) {
        clearInterval(this.streamListener);
        this.streamListener = null;
      }
    },
    // If stream ended add listener if stream will available again
    streamEnded() {
      this.addStreamListener(this.player, this.playerUrl);
    }
  }
};
</script>

<style lang="scss" scoped>
.screen-height {
  height: 100%;
}
@media only screen and (min-width: 751px) and (max-width: 1023px) {
  .screen-height {
    height: 30rem;
  }
}
@media only screen and (max-width: 750px) {
  .screen-height {
    height: 12rem;
  }
}
.video-container {
  position: relative;
  overflow: hidden;
  margin-bottom: -7px;
  transition: padding-bottom 0ms ease 200ms, width, height 200ms ease;
  height: 100%;
  height: 100%;
  width: 100%;
  & iframe,
  & object,
  & embed,
  & video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.image-container {
  max-height: calc(100vh - 14em);
}
</style>
