<template>
  <div class="flex flex-col h-full lg:h-screen">
    <div class="flex overflow-auto py-2 lg:py-0">
      <div class="flex flex-col w-full lg:mb-24">
        <div class="w-full">
          <!--search-->
          <div class="px-0 py-2 md:px-12 lg:px-0 ml-2 mr-2 relative">
            <input
              v-model="query"
              id="autosuggest__input"
              class="bg-light text-dark text-base p-4 w-full rounded-lg border border-black"
              :placeholder="$t('Components.Chat.search')"
            />
          </div>
        </div>

        <!--Chat messages list-->
        <div
          class="chat-list lg:flex-1 flex flex-wrap relative overflow-x-hidden overflow-y-auto lg:h-screen mb-10"
        >
          <div v-for="chat in filteredOptions[0].data" :key="chat.ChatID">
            <button
              type="submit"
              class="button w-full h-12 lg:h-16 hover:opacity-50"
              aria-label="open individual message"
              @click="openChat(chat)"
            >
              <div class="flex flex-wrap items-center">
                <div class="icon space-x-2">
                  <Avatar
                    :image="chatPicture(chat)"
                    :circle="true"
                    :size="'medium'"
                    :online-status="status(chat)"
                    @error="setAltImg"
                  />
                  <div
                    v-if="hasUnreadMessages(chat)"
                    class="message flex flex-col text-left font-bold"
                    aria-label="has unread message"
                  >
                    <div class="name">
                      <span class="name__truncate text-xs md:text-base">
                        {{ chatName(chat) }}
                      </span>
                    </div>
                    <div>
                      <span class="message__truncate text-xxs md:text-xs">
                        {{ getChatLastMessage(chat.ChatID).Message }}
                      </span>
                    </div>
                    <div class="icon__unread" />
                  </div>
                  <div
                    v-else
                    class="message flex flex-col text-left"
                    aria-label="go to message"
                  >
                    <div class="name">
                      <span class="name__truncate text-xs md:text-base">
                        {{ chatName(chat) }}
                      </span>
                    </div>

                    <span class="message__truncate text-xxs md:text-xs">
                      {{ getChatLastMessage(chat.ChatID).Message }}
                    </span>
                  </div>
                  <div
                    class="text-xxs md:text-xs justify-end whitespace-nowrap flex-1"
                    aria-label="last message time"
                  >
                    <!--
                      <Moment format="HH:mm" />
                      <span class="display-time">{{
                        chat.Messages.length
                      }}</span>-->
                    <span
                      :title="
                        formattedDateTime(
                          getChatLastMessage(chat.ChatID).CreatedAt
                        )
                      "
                      >{{
                        formattedTime(getChatLastMessage(chat.ChatID).CreatedAt)
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Avatar from '@/components/shared/Avatar';
import { formatDate, unknownUser } from '@/utils';

export default {
  name: 'ChatList',
  components: {
    Avatar
  },
  data() {
    return {
      query: '',
      selected: ''
    };
  },
  computed: {
    ...mapGetters('Chat', [
      'getChats',
      'getChatWithUser',
      'getChatLastMessage',
      'getUnreadChatMessages'
    ]),
    ...mapGetters('Users', {
      isUserOnline: 'isUserOnline'
    }),
    ...mapGetters('Authentication', { getOwnUser: 'getUser' }),
    ...mapGetters('Authentication', ['getDefaultImage']),
    filteredOptions() {
      return [
        {
          data: _.orderBy(
            this.getChats.filter((option) => {
              return (
                option.Messages.length >= 1 &&
                this.chatName(option)
                  .toLowerCase()
                  .indexOf(this.query.toLowerCase()) > -1
              );
            }),
            [(item) => item.Messages[item.Messages.length - 1]?.CreatedAt],
            ['desc']
          )
        }
      ];
    }
  },
  methods: {
    ...mapActions('Chat', ['addChatEntry', 'markChatRead']),
    ...mapMutations('Chat', [
      'setCurrentChatID',
      'setIsNewChat',
      'setIsChatList'
    ]),
    formattedTime(time) {
      return formatDate(time, 'HH:mm');
    },
    formattedDateTime(date) {
      return formatDate(date, 'DD-MM-YYYY HH:mm');
    },
    setAltImg(event) {
      event.target.src = this.getDefaultImage;
    },
    // TODO: Fix for Group Chats
    getChatOtherUser(users) {
      return this.getChatOtherUsers(users)[0] ?? unknownUser;
    },
    getChatOtherUsers(users) {
      return users.filter((item) => item.UserID !== this.getOwnUser.UserID);
    },
    getSuggestionValue(suggestion) {
      return this.chatName(suggestion.item);
    },
    status(chat) {
      return this.isUserOnline(this.getChatOtherUser(chat.Users).UserID);
    },
    chatName(chat) {
      const otherParticipant =
        chat.Users.find((x) => x.UserID !== this.getOwnUser.UserID) ??
        unknownUser;
      return `${otherParticipant.Firstname} ${otherParticipant.Lastname}`;
    },
    chatPicture(chat) {
      return this.getChatOtherUser(chat.Users).Picture
        ? this.getChatOtherUser(chat.Users).Picture
        : 'https://ideffector-prod.s3.amazonaws.com/images/avatar.png';
    },
    openChat(chat) {
      this.markChatRead({
        chatid: chat.ChatID
      });
      this.setCurrentChatID(chat.ChatID);
      this.setIsChatList(false);
      this.setIsNewChat(false);
    },
    openNewChat() {
      this.setIsNewChat(true);
      this.setIsChatList(false);
      this.setCurrentChatID(false);
    },
    hasUnreadMessages(chat) {
      return this.getUnreadChatMessages(chat.ChatID).length > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.new-chat {
  position: fixed;
  bottom: 3rem;
  right: 2rem;
}
.icon {
  position: relative;
  display: flex;
  border: none;
  outline: none;
}
.icon__unread {
  position: absolute;
  top: 40%;
  right: 15%;
  width: 10px;
  height: 10px;
  background: var(--color-primary-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .chat-list {
    flex-direction: column;
    padding: 0 4rem;
  }
}
.message {
  width: 20rem;
}
.name {
  width: 22rem;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .message {
    width: 18rem;
  }
  .name {
    width: 18rem;
  }
}
@media only screen and (min-width: 760px) and (max-width: 992px) {
  .message {
    width: 34rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 760px) {
  .message {
    width: 20rem;
  }
  .name {
    width: 20rem;
  }
}
@media only screen and (max-width: 480px) {
  .message {
    width: 14rem;
  }
  .name {
    width: 16rem;
  }
}
@media only screen and (max-width: 360px) {
  .message {
    width: 8rem;
  }
  .name {
    width: 8rem;
  }
}
.message__truncate {
  width: 80%;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.name__truncate {
  width: 80%;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
</style>
