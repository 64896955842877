import ApiClient from '../../client';
import { createNamespacedDataProviderStore, extendStore } from './dataprovider';

const store = createNamespacedDataProviderStore((setItems) => {
  return ApiClient.getDocuments().then((response) =>
    setItems(response.data.documents)
  );
});

export default extendStore(store, {
  state: {
    documents: []
  },
  getters: {
    getItems: (state) => {
      return state.documents;
    }
  },
  mutations: {
    setItems: (state, payload) => {
      state.documents = payload;
    }
  },
  actions: {
    async getDocumentsBySessionId({ commit }, sessionId) {
      let response;
      try {
        if (sessionId) {
          response = await ApiClient.getDocumentsBySessionId(sessionId);
        } else {
          response = ApiClient.getDocuments();
        }
        commit('setItems', response.data?.documents);
      } catch (e) {
        console.error('Get documents by session threw an exception', e);
        return;
      }
    },
    async getDocuments({ commit }) {
      const response = await ApiClient.getDocuments();
      commit('setItems', response.data?.documents);
    }
  }
});
