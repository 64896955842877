<template>
  <div class="h-32 p-8 text-center">
    <i class="fas fa-circle-notch fa-spin text-xl" />
  </div>
</template>

<script>
export default {
  name: 'Spinner'
};
</script>
