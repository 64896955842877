<template>
  <div v-if="hasFullEventDetails">
    <div class="speakers-wrapper lg:px-5 overflow-x-hidden space-y-1">
      <component
        v-for="element in elements"
        :is="element.component"
        :key="element.key"
        :options="element.options"
      />
      <!-- Back -->
      <BackToTop />
    </div>
  </div>
  <div v-else class="full-page-loader">
    <img src="/assets/favicon/apple-touch-icon.png" alt="Tappin logo" />
    Loading...
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PublicSpeakers from '@/views/Iframes/PublicSpeakers';
import BackToTop from '@/components/shared/BackToTop';
import {
  lobbyElementCategory,
  moduleType
} from '@/constants/lobby-page-constants';

const componentList = [
  {
    category: lobbyElementCategory.MODULE,
    type: moduleType.SPEAKER,
    component: PublicSpeakers
  }
];

export default {
  name: 'SpeakersIframe',
  components: {
    BackToTop,
    PublicSpeakers
  },
  computed: {
    ...mapGetters('CurrentEvent', ['lobbyElements', 'hasFullEventDetails']),
    elements() {
      if (!this.lobbyElements) {
        return componentList;
      }

      let websocketElements = this.lobbyElements.filter(
        (x) => x.id !== 'header'
      );

      return websocketElements.map((element) => ({
        ...element,
        key: element.id,
        component:
          element.component ??
          componentList.find(
            (y) => y.category === element.category && y.type === element.type
          )?.component
      }));
    }
  }
};
</script>

<style scoped lang="scss">
.speakers-wrapper {
  position: relative;
  background-color: var(--background-color);
}
.full-page-loader {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% - 96px);
  text-align: center;
}

.full-page-loader > img {
  height: 192px;
  width: 192px;
  animation: 1.8s infinite heartbeat;
  display: block;
  margin-bottom: 10px;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>
