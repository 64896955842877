<template>
  <CurrentEventDataProvider v-slot="{ hasFullEventDetails }">
    <div class="lobby" v-if="hasFullEventDetails">
      <!-- Navbar -->
      <Navbar :show-nav="true" :full-width="true" />

      <div class="lobby-wrapper lg:px-5 overflow-x-hidden space-y-1">
        <component
          v-for="element in elements"
          :is="element.component"
          :key="element.key"
          :options="element.options"
        />
        <!-- Back -->
        <BackToTop />
      </div>

      <LobbyFooter class="flex lg:hidden" />
    </div>
    <div v-else class="full-page-loader">
      <img src="/assets/favicon/apple-touch-icon.png" alt="Tappin logo" />
      Loading...
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import { mapGetters } from 'vuex';
import Navbar from '@/components/shared/Navbar';
import Banner from '@/components/Banner';
import LobbySponsorTopList from '@/views/Lobby/LobbySponsorTopList';
import LobbySponsors from '@/views/Lobby/LobbySponsors';
import LobbyAgenda from '@/views/Lobby/LobbyAgenda';
import LobbyFaq from '@/views/Lobby/LobbyFaq';
import LobbySpeakers from '@/views/Lobby/LobbySpeakers';
import BackToTop from '@/components/shared/BackToTop';
import LobbyFooter from '@/views/Lobby/LobbyFooter';
import MainStageButton from '@/components/shared/MainStageButton';
import {
  lobbyElementCategory,
  moduleType
} from '@/constants/lobby-page-constants';

const componentList = [
  {
    category: lobbyElementCategory.MODULE,
    type: moduleType.BANNER,
    component: Banner
  },
  {
    category: lobbyElementCategory.MODULE,
    type: moduleType.INFO_PAGE,
    component: LobbyFaq
  },
  {
    category: lobbyElementCategory.MODULE,
    type: moduleType.AGENDA,
    component: LobbyAgenda
  },
  {
    category: lobbyElementCategory.MODULE,
    type: moduleType.SPEAKER,
    component: LobbySpeakers
  },
  {
    category: lobbyElementCategory.MODULE,
    type: moduleType.SPONSOR,
    component: LobbySponsors
  },
  {
    category: lobbyElementCategory.MODULE,
    type: moduleType.TOP_SPONSORS,
    component: LobbySponsorTopList
  }
];

export default {
  name: 'Lobby',
  components: {
    BackToTop,
    LobbySponsorTopList,
    LobbySponsors,
    LobbyAgenda,
    LobbyFaq,
    LobbySpeakers,
    Navbar,
    Banner,
    LobbyFooter,
    MainStageButton
  },
  computed: {
    ...mapGetters('CurrentEvent', [
      'isLobbyEnabled',
      'lobbyElements',
      'hasFullEventDetails'
    ]),
    elements() {
      if (!this.lobbyElements) {
        return componentList;
      }

      let lobbyElements = this.lobbyElements.filter((x) => x.id !== 'header');
      const headerOptions = this.lobbyElements.find(
        (x) => x.id === 'header'
      )?.options;

      if (headerOptions?.mainStageEnabled) {
        const mainStageCaption = headerOptions?.mainStageLabel;

        const mainButtonElement = {
          id: 'mainStageButton',
          options: { mainStageCaption },
          component: MainStageButton
        };

        lobbyElements.splice(
          lobbyElements.findIndex((x) => x.id === 'banner') + 1,
          0,
          mainButtonElement
        );
      }

      return lobbyElements.map((element) => ({
        ...element,
        key: element.id,
        component:
          element.component ??
          componentList.find(
            (y) => y.category === element.category && y.type === element.type
          )?.component
      }));
    }
  },
  watch: {
    '$store.state.CurrentEvent': function (value) {
      if (value && !this.isLobbyEnabled) {
        this.$router.push({
          name: 'stage'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.lobby-wrapper {
  position: relative;
  background-color: var(--background-color);
}
.avatar {
  object-fit: cover;
  text-align: center;
}
.full-page-loader {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% - 96px);
  text-align: center;
}

.full-page-loader > img {
  height: 192px;
  width: 192px;
  animation: 1.8s infinite heartbeat;
  display: block;
  margin-bottom: 10px;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>
