<template>
  <div
    class="flex flex-col flex-wrap justify-between h-full w-full bg-white text-dark py-2"
  >
    <!--search-->
    <div class="flex justify-between py-4">
      <div class="w-full md:w-1/2 relative">
        <input
          v-model="searchText"
          type="text"
          :placeholder="'Search quiz'"
          class="bg-light text-base w-full rounded-3xl"
          ref="childFocus"
        />
        <i class="fas fa-search absolute right-0 m-3" />
      </div>
    </div>
    <div class="flex w-full">
      <h1 class="font-bold text-md md:text-lg mb-2">Quiz List</h1>
    </div>
    <div class="table-container shadow-lg">
      <table class="mx-auto table-auto w-full">
        <thead class="sticky top-0 bg-white">
          <tr class="text-left shadow-md">
            <th class="w-1/4 p-4 md:p-8 text-xs md:text-base">Title</th>
            <th
              class="w-1/4 p-4 md:p-8 text-xs md:text-base cursor-pointer select-none"
              @click="toggleSortOrder"
            >
              Average
              <span class="ml-2">
                <i :class="sortOrderIcon"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="quiz in filteredQuizList" :key="quiz.id" class="border">
            <td class="w-1/4 p-4 md:p-8 text-xs md:text-base">
              {{ quiz.title }}
            </td>
            <td class="w-1/4 p-4 md:p-8 text-xs md:text-base">
              Average: {{ quiz.average }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'QuizList',
  props: {
    polls: Array
  },
  data() {
    return {
      searchText: '',
      sortOrder: 'asc'
    };
  },
  computed: {
    filteredQuizList() {
      let filtered = this.polls;

      if (this.searchText) {
        filtered = filtered.filter((quiz) =>
          quiz.title.toLowerCase().includes(this.searchText.toLowerCase())
        );
      }

      return filtered.sort((a, b) => {
        if (this.sortOrder === 'asc') {
          return a.average - b.average;
        } else {
          return b.average - a.average;
        }
      });
    },
    sortOrderIcon() {
      return this.sortOrder === 'asc' ? 'fas fa-arrow-up' : 'fas fa-arrow-down';
    }
  },
  methods: {
    toggleSortOrder() {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }
  }
};
</script>
<style scoped lang="scss">
:root {
  --sidebar-height: 100%;
}

.table-container {
  overflow-y: auto;
  height: calc(100vh - 17em);
}
</style>
