<template>
  <div class="p-4 w-full lg:w-3/5 xl:w-full">
    <!-- Quiz Header -->
    <h3 class="font-bold text-md">
      {{ quiz.question }}
    </h3>
    <div class="text-sm html-content" v-html="quiz.description" />
    <SinglePoll
      v-if="currentPoll"
      :key="currentPoll && currentPoll.id"
      :id="currentPoll && currentPoll.id"
      :poll="currentPoll"
      :answer="currentPollAnswer"
      :next="next"
      :previous="previous"
      :hasNext="hasNext"
      :hasPrevious="hasPrevious"
      :submit="handleSubmit"
      :close="close"
    />
  </div>
</template>

<script>
import SinglePoll from './SinglePoll.vue';
import { pollType } from '@/constants/poll-type-constants';

export default {
  name: 'QuizPoll',
  components: {
    SinglePoll
  },
  props: {
    quiz: {
      type: Object,
      required: true
    },
    submit: {
      type: Function,
      required: false
    },
    close: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      answers: [],
      // Store current question index
      pollIndex: 0,
      inputCode: '',
      errorInputCode: ''
    };
  },
  mounted() {
    this.populateAnswers();
    if (this.quiz?.questionType === pollType.TREASURE_HUNT) {
      this.pollIndex =
        this.quiz?.items.findIndex((x) => !x.poll.selectedAnswer) ?? 0;
    }
  },
  computed: {
    hasNext() {
      return this.pollIndex < this.quiz?.items?.length - 1;
    },
    hasPrevious() {
      if (this.quiz?.questionType === pollType.TREASURE_HUNT) {
        return false;
      }

      return this.pollIndex > 0;
    },
    currentPoll() {
      if (this.quiz?.items?.[this.pollIndex]) {
        return this.quiz.items[this.pollIndex].poll;
      }
      return null;
    },
    currentPollAnswer() {
      if (this.currentPoll) {
        return this.answers[this.pollIndex];
      }
      return null;
    }
  },
  methods: {
    handleSubmit(data) {
      this.updateAnswer(data);
      if (this.quiz?.questionType === pollType.QUIZ && !this.quiz.templateId) {
        this.submitQuiz();
        return;
      }

      if (
        this.quiz?.questionType === pollType.TREASURE_HUNT &&
        !this.quiz.templateId
      ) {
        this.submitTreasureHunt();
        return;
      }

      if (this.quiz?.questionType === pollType.QUIZ && !!this.quiz.templateId) {
        this.submitTemplateQuiz();
        return;
      }
      if (
        this.quiz?.questionType === pollType.TREASURE_HUNT &&
        !!this.quiz.templateId
      ) {
        this.submitTemplateTreasureHunt();
        return;
      }
    },
    updateAnswer(data) {
      const answer = {
        freeTextAnswer: data.freeTextAnswer,
        selectedAlternative: data.selectedAlternative,
        questionType: data.questionType
      };
      this.answers[this.pollIndex] = answer;
    },
    populateAnswers() {
      this.answers = this.quiz.items.map(() => null);
    },
    // Go to next question
    next(data) {
      if (this.pollIndex < this.quiz?.items?.length - 1) {
        this.updateAnswer(data);
        if (
          this.quiz?.questionType === pollType.TREASURE_HUNT &&
          !this.quiz.templateId
        ) {
          this.submitTreasureHunt();
        }

        if (
          this.quiz?.questionType === pollType.TREASURE_HUNT &&
          !!this.quiz.templateId
        ) {
          this.submitTemplateTreasureHunt();
        }

        this.pollIndex++;
      }
    },
    // Go to previous question
    previous(data) {
      if (this.pollIndex > 0) {
        this.updateAnswer(data);
        this.pollIndex--;
      }
    },
    submitQuiz() {
      this.submit({
        quizId: this.quiz.id,
        items: this.quiz.items.map((x, i) => ({
          id: x.id,
          pollId: x.pollId,
          answers: [
            this.answers[i]?.selectedAlternative?.text ||
              this.answers[i]?.freeTextAnswer
          ]
        })),
        templateId: this.quiz.templateId
      });
    },
    submitTreasureHunt() {
      const item = this.quiz.items[this.pollIndex];
      this.submit({
        quizId: this.quiz.id,
        items: [
          {
            id: item.id,
            pollId: item.pollId,
            answers: [
              this.answers[this.pollIndex]?.selectedAlternative?.text ||
                this.answers[this.pollIndex]?.freeTextAnswer
            ]
          }
        ]
      });
    },
    submitTemplateTreasureHunt() {
      const item = this.quiz.items[this.pollIndex];
      this.submit({
        quizId: this.quiz.id,
        items: [
          {
            id: item.id,
            pollId: item.pollId,
            answers: [
              this.answers[this.pollIndex]?.selectedAlternative?.text ||
                this.answers[this.pollIndex]?.freeTextAnswer
            ]
          }
        ],
        templateId: this.quiz.templateId
      });
    },
    submitTemplateQuiz() {
      this.submit({
        quizId: this.quiz.id,
        items: this.quiz.items.map((x, i) => ({
          id: x.id,
          pollId: x.pollId,
          answers: [
            // this.answers[i]?.selectedAlternative?.id --> In case of the use of answer ids
            this.answers[i]?.selectedAlternative?.text ||
              this.answers[i]?.freeTextAnswer
          ]
        })),
        templateId: this.quiz.templateId
      });
    }
  }
};
</script>
