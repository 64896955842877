import { createNamespacedDataProviderStore, extendStore } from './dataprovider';
import ApiClient from '../../client';
import Vue from 'vue';
import _ from 'lodash';
const store = createNamespacedDataProviderStore((setItems) => {
  return ApiClient.getBookSessions().then((response) => {
    setItems(response.data);
  });
});
export default extendStore(store, {
  getters: {
    getBookSessions: (state) => state.items,
    myAgendasWithSessionsGroupedByDay: (state) => {
      return {
        sessions: _.groupBy(state.items, (session) =>
          Vue.moment(session.startDate).format('YYYY-MM-DD')
        )
      };
    },
    singleMyAgendaGroupedByDay: (state, getters) => {
      return getters.myAgendasWithSessionsGroupedByDay ?? { sessions: {} };
    },
    getLoadingState: (state) => state.loading
  },
  mutations: {
    addOrDeleteBooksSesions(state, payload) {
      const index = state.items.findIndex((x) => x.id === payload.id);
      if (index > -1) {
        state.items.splice(index, 1);
      } else {
        state.items.push(payload);
      }
    },
    setSortedBooksSesions(state, payload) {
      state.items.sort((a, b) => {
        return new Date(a.startDate) - new Date(b.startDate);
      });
    },
    setLoadingSession(state, payload) {
      state.loading = payload;
    }
  },
  actions: {
    async addOrDeleteBooksSesions({ commit, getters }, payload) {
      await commit('setLoadingSession', true);

      const checkIfExist = getters.getBookSessions.some(
        (session) => payload.id === session.id
      );
      if (checkIfExist) {
        await ApiClient.deleteBookSession(payload).then((res) => {
          if (res.data) {
            commit('addOrDeleteBooksSesions', payload);
            commit('setLoadingSession', false);
          }
        });
      } else {
        await ApiClient.addBookSession(payload).then((res) => {
          if (res.data) {
            commit('addOrDeleteBooksSesions', payload);
            commit('setLoadingSession', false);
          }
        });
      }
    },
    loadSortedBooksSesions({ commit }) {
      commit('setSortedBooksSesions');
    }
  }
});
