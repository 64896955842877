var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PublicCategoriesDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ agendaSessionCategories }){return [_c('PublicAgendasDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({
      groupItemIndex,
      groupItemIsFirst,
      groupItemIsLast,
      initiallyActiveDate,
      searchAgendasForDateAndTime,
      singleAgendaGroupedByDay,
      lastDateInSingleAgenda,
      isLoading,
      trackById,
      tracks,
      goToStreamFromSession,
      filterSessions,
      data,
      features,
      locale
    }){return [_c('AgendaSection',{attrs:{"locale":locale,"caption":data.captions.programCaption,"initiallyActiveDate":initiallyActiveDate,"groupItemIndex":groupItemIndex,"groupItemIsFirst":groupItemIsFirst,"groupItemIsLast":groupItemIsLast,"searchAgendasForDateAndTime":searchAgendasForDateAndTime,"singleAgendaGroupedByDay":singleAgendaGroupedByDay,"lastDateInSingleAgenda":lastDateInSingleAgenda,"isLoading":isLoading,"tracks":tracks,"trackById":trackById,"goToStreamFromSession":goToStreamFromSession,"showGoToMainStage":features.agendaSessionGoToMainStage,"checkMainStage":data.lobbyElements.find((label) => label.options.mainStageLabel),"filterSessions":filterSessions,"agendaSessionCategories":agendaSessionCategories,"isExternalAgenda":true}})]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }