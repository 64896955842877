<template>
  <transition name="fade">
    <div
      v-show="visible"
      v-tippy="{ placement: 'left', arrow: true }"
      class="vue-back-to-top"
      :content="text"
      @click="backToTop"
    >
      <button class="button button-primary" aria-label="go to top of lobby">
        <i class="far fa-angle-up" aria-hidden="true" />
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BackToTop',
  props: {
    text: {
      type: String,
      default: 'Back to top'
    },
    visibleoffset: {
      type: [String, Number],
      default: 600
    },
    visibleoffsetbottom: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      visible: false
    };
  },
  mounted() {
    window.smoothscroll = () => {
      const currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.scrollTo(0, 0);
      }
    };
    window.addEventListener('scroll', this.watchScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.watchScroll);
  },
  methods: {
    watchScroll() {
      const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset);
      const pastBottomOffset =
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - parseInt(this.visibleoffsetbottom);
      this.visible =
        parseInt(this.visibleoffsetbottom) > 0
          ? pastTopOffset && !pastBottomOffset
          : pastTopOffset;
    },
    backToTop() {
      window.smoothscroll();
    }
  }
};
</script>

<style lang="scss" scoped>
.vue-back-to-top {
  position: fixed;
  right: 1.5rem;
  bottom: 4.5rem;
  z-index: 1000;
  cursor: pointer;
  outline: none;
}
</style>
