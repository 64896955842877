<template>
  <CurrentEventDataProvider v-slot="{ hasFullEventDetails, features }">
    <div
      v-if="hasFullEventDetails"
      class="primary-container flex flex-col lg:flex-row w-full overflow-hidden"
    >
      <div
        class="main secondary-container w-full self-start flex flex-auto flex-col lg:h-screen transform transition-all ease-out duration-500 z-20"
      >
        <!-- Navbar -->
        <div><slot name="navbar" /></div>

        <!-- Main Content -->
        <div class="flex-1">
          <slot name="mainContent" :features="features" />
        </div>

        <!-- Below main stream -->
        <slot name="belowMainContent">
          <div class="hidden lg:flex h-16 text-sm">
            <TappinButton
              v-if="isWebapp"
              icon="chevron-left"
              class="button-primary cursor-pointer hover:opacity-50 transition-all duration-200 text-dark font-bold p-2 space-x-2 text-sm lg:text-base"
              @click="backToLobby"
              :custom_caption="
                $t('Components.shared.ContentWithSidebarLayout.backToLobby')
              "
            />
          </div>
        </slot>
      </div>

      <!-- Sidebar with contents -->
      <div
        class="aside sidebar-container flex flex-col-reverse lg:flex-row h-full lg:max-h-screen lg:w-auto"
      >
        <!-- Sidebar expand -->
        <div
          :class="{
            'sidebar-collapsed lg:w-0 lg:translate-x-full overflow-hidden':
              sidebarCollapsed,
            'sidebar-expanded lg:translate-x-0 lg:w-72 xl:w-96':
              !sidebarCollapsed
          }"
          class="aside__expand sidebar-container-expand flex-1 h-full lg:border-l border-grey pt-0 lg:pt-20 flex flex-col justify-between lg:transform lg:transition-all lg:ease-out lg:duration-500"
        >
          <!-- Sidebar content -->
          <slot name="sidebarContent" />
        </div>
        <!-- Sidebar collapse -->
        <div
          class="aside__not-expand border-b-0 border-t-0 flex flex-col w-16 relative z-20 border-grey overflow-x-visible overflow-y-auto"
        >
          <!-- Sidebar collapse -->
          <div
            ref="childTab"
            tabindex="-1"
            class="sidebar bg-navbar hidden lg:flex items-center h-20 p-4"
          >
            <transition name="fade">
              <button
                v-tippy="{
                  content: !sidebarCollapsed
                    ? $t('Components.shared.ContentWithSidebarLayout.collapse')
                    : $t('Components.shared.ContentWithSidebarLayout.expand'),
                  placement: 'right',
                  theme: 'light-border',
                  arrow: false,
                  flipBehavior: ['flip']
                }"
                class="button text-black w-16 h-16"
                :aria-expanded="[!sidebarCollapsed ? 'true' : 'false']"
                :aria-label="[
                  !sidebarCollapsed
                    ? 'sidebar contents open'
                    : 'sidebar contents closed'
                ]"
                @click="toggleOpen"
              >
                <i
                  class="fal fa-chevron-left"
                  aria-hidden="true"
                  :class="{
                    'fa-chevron-left': sidebarCollapsed,
                    'fa-chevron-right': !sidebarCollapsed
                  }"
                />
              </button>
            </transition>
          </div>
          <!-- Sidebar -->
          <slot name="sidebar"></slot>
        </div>
      </div>
      <slot name="bottom" />
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import { getApplicablePlatforms } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import TappinButton from '@/components/buttons/TappinButton';

export default {
  name: 'ContentWithSidebarLayout',
  components: {
    TappinButton
  },
  props: {
    sidebarCollapsed: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    ...mapGetters('CurrentEvent', ['features']),
    isWebapp() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? false
        : this.features.lobbyEnabled;
    }
  },
  methods: {
    ...mapActions('Authentication', ['doLogout']),
    toggleOpen() {
      if (this.sidebarCollapsed) {
        this.$emit('closed');
      } else {
        this.$emit('opened');
      }
    },
    backToLobby() {
      this.$router.push({
        name: 'lobby'
      });
    }
  },
  mounted() {
    this.$root.$on('focusTab', () => this.$refs.childTab.focus());
  }
};
</script>

<style lang="scss" scoped>
.primary-container {
  height: 100%;
}
@supports (height: 100dvh) {
  .primary-container {
    height: 100dvh;
  }
}
</style>
