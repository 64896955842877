<template>
  <SponsorDataProvider v-slot="{ sponsor, users }">
    <div
      class="absolute top-0 bottom-0 w-full overflow-y-auto flex flex-col z-10l"
      :style="designStyles"
    >
      <div class="p-4 pt-0">
        <h2 class="text-md font-bold pb-6">
          {{ $t('Views.Sponsor.labelContact') }}
        </h2>
        <CollapsibleList>
          <CollapsibleItem
            v-for="user in users"
            :key="user.UserID"
            v-slot="{ active, toggle }"
            :item-id="user.UserID"
          >
            <div class="pb-6">
              <div class="flex pb-4">
                <div class="pr-4 w-1/4">
                  <Avatar
                    :image="user.Picture"
                    :alt="$t('Views.Shared.UserPicture')"
                  />
                </div>
                <div class="flex flex-col justify-between">
                  <h3 class="text-base font-bold">
                    {{ user.Firstname }} {{ user.Lastname }}
                  </h3>
                  <p class="text-xs">
                    {{ user.Company }}
                  </p>
                  <div class="flex pt-2">
                    <button
                      class="button button-secondary mr-4"
                      aria-label="sponsor info"
                      @click="toggle"
                    >
                      <span v-if="active">
                        {{ $t('Views.Sponsor.showLess') }}
                      </span>
                      <span v-else>{{ $t('Views.Sponsor.moreInfo') }}</span>
                    </button>
                    <button
                      :disabled="hasAlreadySentRequestToUser(user.UserID)"
                      class="button button-primary mr-4"
                      aria-label="Ask for a meeting"
                      @click="requestMeeting(user.UserID)"
                    >
                      {{ $t('Views.Sponsor.askForMeeting') }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-show="active" class="pb-8">
                <p class="font-bold text-xs mb-0">
                  {{ user.Jobtitle }}
                </p>
                <div v-if="user.Cell">
                  <span class="text-xs">{{ user.Cell }}</span>
                </div>
                <div v-if="user.Email">
                  <span class="text-xs">{{ user.Email }}</span>
                </div>
                <div class="pt-2 text-xs html-content" v-html="user.About2" />
              </div>
            </div>
          </CollapsibleItem>
        </CollapsibleList>
      </div>
    </div>
  </SponsorDataProvider>
</template>
<script>
import CollapsibleItem from '@/components/shared/CollapsibleItem';
import CollapsibleList from '@/components/shared/CollapsibleList';
import Avatar from '@/components/shared/Avatar';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Contacts',
  components: {
    CollapsibleItem,
    CollapsibleList,
    Avatar
  },
  data() {
    return {
      contactedUsers: []
    };
  },
  methods: {
    ...mapActions('Sponsor', ['requestSponsorUserMeeting']),
    ...mapGetters('CurrentEvent', {
      designStyles: 'getDesignStyles'
    }),
    requestMeeting(userId) {
      this.contactedUsers.push(userId);
      this.requestSponsorUserMeeting(userId)
        .then(() =>
          this.$notify({
            group: 'default',
            title: this.$t('Views.Sponsor.requestSent')
          })
        )
        .catch(() => {
          this.contactedUsers = this.contactedUsers.filter(
            (id) => id !== userId
          );
        });
    },
    hasAlreadySentRequestToUser(userId) {
      return this.contactedUsers.includes(userId);
    }
  }
};
</script>

<style lang="scss" scoped>
.h-84 {
  img {
    height: 84px;
    width: 84px;
  }
}
</style>
