<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <div class="p-10 flex flex-col h-full overflow-show" :style="designStyles">
      <div class="h-full flex flex-col">
        <!-- Heading -->
        <div class="mb-8">
          <h3 class="text-md font-bold">
            {{ $t('Components.TwoFactorModal.TwoFactorRequired') }}
          </h3>
        </div>

        <!-- Form-->
        <div class="flex-1">
          <!-- Email -->
          <div class="mb-8">
            <input
              v-model="pin"
              type="text"
              name="pin"
              minlength="6"
              class="w-full p-4 bg-light text-dark w-full rounded"
              @keyup.enter="onSubmit"
            />
            <p class="mt-4 text-base">
              {{ $t('Components.TwoFactorModal.Help') }}
            </p>
          </div>

          <div v-if="error" class="mt-4 form-error">
            <span class="form-error text-sm font-bold">
              <i class="fas fa-exclamation-circle mr-1" />
              {{ $t('Components.RecoveryPasswordModal.somethingWentWrong') }}
            </span>
          </div>
        </div>

        <!-- Close -->
        <div class="text-base flex justify-between items-center mt-8">
          <button
            class="button button-secondary"
            aria-label="Close"
            @click="onCancel"
          >
            {{ $t('Components.RecoveryPasswordModal.close') }}
          </button>

          <button
            :disabled="!canSubmit"
            class="button button-primary"
            aria-label="send pin"
            @click="onSubmit"
          >
            <span v-if="loading" class="inline-block" aria-label="sending">
              {{ $t('Components.TwoFactorModal.sendingPin') }}
              <i class="fas ml-2 fa-circle-notch fa-spin" aria-hidden="true" />
            </span>
            <span v-else class="inline-block" aria-label="Pin sent">
              {{ $t('Components.TwoFactorModal.PinSent') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<style lang="scss">
.vue-country-select {
  border: none !important;
  background: var(--color-light);
  padding: 5px;
  .current {
    .country-code {
      margin: 0 10px;
      font-size: 16px !important;
    }
  }
}
</style>

<script>
import { mapGetters } from 'vuex';
import numeric from 'vuelidate/lib/validators/numeric';

export default {
  name: 'TwoFactorModal',
  data() {
    return {
      loading: false,
      error: null,
      pin: null
    };
  },
  computed: {
    ...mapGetters('CurrentEvent', ['getId']),
    canSubmit() {
      if (this.pin) {
        return this.pin.length > 5 && numeric(this.pin);
      } else {
        return false;
      }
    }
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$emit('close', {
        pin: this.pin,
        pinId: this.pinId,
        success: true
      });
    },
    onCancel() {
      this.$emit('close', {
        success: false
      });
    }
  }
};
</script>
