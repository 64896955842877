<template>
  <CurrentEventDataProvider v-slot="{ title, data, features }">
    <div class="h-full w-full flex flex-col bg-white text-dark px-4">
      <!-- Navbar -->
      <div
        class="navbar pt-4 flex justify-between items-center font-bold bg-white sticky top-0 z-30"
      >
        <!-- Logo & Event name -->
        <div class="logo flex items-center">
          <img
            :src="data.logoUrl"
            :alt="data.logoUrl && 'Event\'s logo'"
            class="object-scale-down h-10 md:h-16 w-auto"
          />
        </div>

        <!-- Support & Logout -->
        <div>
          <button
            v-show="chatWidgetLoaded"
            v-if="features.stageSupport"
            class="button button-primary button-xs text-xxs md:text-xs sm:inline-block"
            aria-label="Support"
            @click="toggleLiveChatWindow"
          >
            {{ $t('Views.Login.support') }}
          </button>
        </div>
      </div>

      <!-- Content -->
      <div
        class="flex flex-col flex-1 bg-light mt-4 md:mb-8 md:ml-8 md:mr-8 items-center justify-center"
        style="
          color: var(--login-color-text);
          background-color: var(--login-background-color);
        "
      >
        <div class="text-center mb-2 md:mb-8">
          <h1 class="font-bold text-md md:text-lg mb-4">
            {{ title }}
          </h1>
          <div
            class="text-xs md:text-base px-6 text-center html-content"
            v-html="data.loginCaption"
          />
        </div>

        <Card v-if="data.active === '1'" class="p-8 md:p-10 md:w-2/5">
          <form
            method="post"
            autocapitalize="none"
            @submit.prevent="submitForm"
          >
            <div class="space-y-4">
              <!-- Email -->
              <div class="relative">
                <input
                  v-model="form.email"
                  :placeholder="$t('Views.Login.email')"
                  type="text"
                  name="email"
                  :readonly="isLoggingIn"
                  class="p-4 border-none text-dark w-full rounded transition-all duration-200 ease-in-out"
                  :class="{ 'bg-grey': isLoggingIn, 'bg-light': !isLoggingIn }"
                  :aria-label="$t('Views.Login.email')"
                />
                <i class="fal fa-user text-dark absolute top-0 right-0 m-5" />
              </div>
              <!-- Password -->
              <div class="relative">
                <input
                  v-model="form.password"
                  :placeholder="$t('Views.Login.password')"
                  type="password"
                  name="password"
                  :readonly="isLoggingIn"
                  class="p-4 border-none text-dark w-full rounded transition-all duration-200 ease-in-out"
                  :class="{ 'bg-grey': isLoggingIn, 'bg-light': !isLoggingIn }"
                  :aria-label="$t('Views.Login.password')"
                />
                <i class="fal fa-lock text-dark absolute top-0 right-0 m-5" />
              </div>
              <!--  -->
              <div
                class="text-dark text-xs md:text-sm lg:text-base select-none space-y-4"
              >
                <!-- Platform visibility -->
                <div
                  class="hidden md:flex flex-col space-y-2"
                  v-if="isWebappEnabled && (isLobbyEnabled || isStageEnabled)"
                >
                  <div v-if="shouldRenderError" class="flex-1">
                    <transition name="fade">
                      <span
                        class="form-error flex text-sm font-bold items-center"
                      >
                        <i class="fas fa-exclamation-circle mr-1" />
                        <p>Please select an option</p>
                      </span>
                    </transition>
                  </div>
                  <div>
                    <span>{{ $t('Views.Login.platformToggleLabel') }}</span>
                  </div>
                  <div class="flex space-x-8">
                    <div class="space-x-2">
                      <input
                        type="radio"
                        v-model="form.platform"
                        :value="false"
                        id="physicalRadio"
                        :aria-label="$t('Views.Login.physical')"
                      />
                      <label for="physicalRadio">{{
                        $t('Views.Login.physical')
                      }}</label>
                    </div>
                    <div class="space-x-2">
                      <input
                        type="radio"
                        v-model="form.platform"
                        :value="true"
                        id="onlineRadio "
                        :aria-label="$t('Views.Login.online')"
                      />
                      <label for="onlineRadio">{{
                        $t('Views.Login.online')
                      }}</label>
                    </div>
                  </div>
                </div>
                <!-- Profile visibility -->
                <div
                  class="space-x-2"
                  v-if="
                    features.stageTabParticipants ||
                    features.webAppNavigationParticipants
                  "
                >
                  <ToggleButton
                    :color="{ checked: '#22aaa2', unchecked: '#A6a6a6' }"
                    :width="75"
                    :value="form.visible"
                    :labels="{
                      checked: $t('Views.Login.visible'),
                      unchecked: $t('Views.Login.hidden')
                    }"
                    :aria-label="$t('Views.Login.visibilityToggleLabel')"
                    @input="form.visible = !form.visible"
                  />
                  <span>{{ $t('Views.Login.visibilityToggleLabel') }}</span>
                </div>
              </div>

              <div class="flex items-baseline justify-between flex-col">
                <div v-if="loginFailed" class="flex-1 mb-4">
                  <transition name="fade">
                    <span class="form-error text-sm font-bold">
                      <i class="fas fa-exclamation-circle mr-1" />
                      {{ $t('Views.Login.usernameOrPasswordWrong') }}
                    </span>
                  </transition>
                </div>

                <div
                  class="w-full flex flex-col-reverse md:flex-row md:justify-end"
                >
                  <TappinButton
                    class="whitespace-nowrap button button-secondary mt-2 md:mt-0 md:mr-2"
                    aria-label="Forget Password"
                    @click="showPasswordRecoveryModal"
                    :custom_caption="$t('Views.Login.forgotPassword')"
                  />
                  <TappinButton
                    v-if="data.registrationOpen === '1'"
                    class="whitespace-nowrap button button-secondary mt-2 md:mt-0 md:mr-2"
                    aria-label="Register"
                    @click="showRegisterModal"
                    :custom_caption="$t('Views.Login.register')"
                  />

                  <TappinButton
                    type="submit"
                    :disabled="isLoggingIn || loading"
                    class="whitespace-nowrap button button-primary mt-2 md:mt-0"
                    :aria-label="isLoggingIn ? 'loading' : 'login'"
                    :icon="isLoggingIn ? 'circle-notch' : ''"
                    :custom_caption="
                      isLoggingIn
                        ? $t('Views.Login.loggingIn')
                        : $t('Views.Login.login')
                    "
                  />
                </div>
              </div>
            </div>
          </form>
        </Card>
        <Card v-else class="p-10 lg:w-2/5">
          <span class="text-center" aria-label="This event has closed">
            <i class="fas fa-exclamation-circle mr-1" aria-label="hidden" />
            {{ $t('Views.Login.eventClosed') }}
          </span>
        </Card>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';

import { mapActions, mapGetters } from 'vuex';
import Card from '@/components/shared/Card';
import ApiClient from '@/client';
import TermsOfServiceModal from '@/components/TermsOfServiceModal';
import RecoverPasswordModal from '@/components/RecoverPasswordModal';
import { togglesSupportChat } from '@/mixins';
import RegistrationModal from '@/components/RegistrationModal';
import TwoFactorModal from '@/components/TwoFactorModal';
import TappinButton from '@/components/buttons/TappinButton';
import { redirectAuthenticatedUser } from '@/helpers/redirection-helpers';

export default {
  name: 'Login',
  components: {
    Card,
    ToggleButton,
    TappinButton
  },
  mixins: [togglesSupportChat],
  data() {
    return {
      loading: false,
      loginFailed: false,
      form: {
        email: '',
        password: '',
        remember: false,
        visible: true,
        platform: null
      },
      tosText: null,
      twoFact: null,
      twoFactPinId: null
    };
  },
  computed: {
    ...mapGetters('CurrentEvent', [
      'features',
      'isLobbyEnabled',
      'isStageEnabled',
      'isWebappEnabled',
      'getId',
      'getTwoFact',
      'features'
    ]),
    ...mapGetters('Authentication', ['isLoggedIn', 'isLoggingIn']),
    ...mapGetters('Terms', ['termsShown']),

    shouldRenderError() {
      return window.innerWidth > 768 && this.form.platform === null;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    ...mapActions('Authentication', ['doLogin', 'doLogout']),
    ...mapActions('Terms', ['setTermsAsShown']),
    handleResize() {
      this.$forceUpdate();
    },
    async submitForm(e) {
      this.loginFailed = false;
      this.loading = true;

      if (this.features.twoFactorAuth) {
        // Show two-factor modal screen
        this.showTwoFactorModal();
        return;
      }
      await this.doLogin({
        email: this.form.email,
        password: this.form.password,
        eventId: this.getId,
        visible: this.form.visible,
        platform: this.form.platform
      });

      return this.isLoggedIn ? this.onLoginSuccess() : this.onLoginFailure();
    },

    showTwoFactorModal() {
      ApiClient.getTwoFactorPin(this.form.email, this.getId).then(
        (response) => {
          if (response.data.status === 'success') {
            this.twoFactPinId = response.data.data.pinId;
            this.$modal.show(
              TwoFactorModal,
              {},
              {
                focusTrap: true,
                clickToClose: false,
                adaptive: true,
                width: '700',
                height: '80%'
              },
              {
                'before-close': (event) => {
                  return event.params.success
                    ? this.onTwoFactorSuccess(event.params.pin)
                    : this.onTwoFactorFailure();
                }
              }
            );
          } else {
            console.log('Login failed to verify two factor.');
          }
        }
      );
    },
    showPasswordRecoveryModal() {
      this.$modal.show(
        RecoverPasswordModal,
        null,
        {
          adaptive: true,
          width: '90%',
          height: 'auto',
          minWidth: 300,
          maxWidth: 640,
          classes: ['overflow-visible'] // vue-country-codes dropdown needs to be able to overflow outside modal
        },
        {
          opened: () => this.$root.$emit('focusChild')
        }
      );
    },
    showTermsOfServiceModal() {
      ApiClient.getTermsOfServiceText(this.form.email, this.getId).then(
        (response) => {
          this.tosText = response.data;
          this.$modal.show(
            TermsOfServiceModal,
            {
              tos: response.data
            },
            {
              focusTrap: true,
              clickToClose: false,
              adaptive: true,
              width: '700',
              height: '80%'
            },
            {
              'before-close': (event) => {
                return event.params.agreed
                  ? this.onTosAgreed()
                  : this.onTosDeclined();
              }
            }
          );
        }
      );
    },
    showRegisterModal() {
      this.$modal.show(RegistrationModal, null, {
        adaptive: true,
        height: 'auto',
        width: '100%'
      });
    },
    onLoginSuccess() {
      if (this.termsShown) {
        this.onTosAgreed();
      } else {
        this.showTermsOfServiceModal();
      }

      if (window.innerWidth <= 768) {
        // Redirect to the web app route
        this.$router.push({ name: 'webapp' });
      }
    },
    onTosAgreed() {
      ApiClient.storeTermsOfServiceAcceptance(
        this.form.email,
        this.tosText,
        this.getId
      ).then(() => {
        this.setTermsAsShown();
        this.loading = false;
        this.loginFailed = false;
        redirectAuthenticatedUser(
          this.$router,
          this.isWebappEnabled,
          this.isLobbyEnabled,
          this.isStageEnabled,
          this.form.platform
        );
      });
    },
    onTosDeclined() {
      this.$notify({
        group: 'default',
        // TODO: Translate
        title: 'For å bruke tappin online må du godkjenne brukerbetingelsene'
      });
    },
    onLoginFailure() {
      console.log('Login failed after two factor');
      this.loading = false;
      this.loginFailed = true;
    },
    onTwoFactorFailure() {
      this.loading = false;
      this.loginFailed = true;
    },
    async onTwoFactorSuccess(pin) {
      await this.doLogin({
        email: this.form.email,
        password: this.form.password,
        eventId: this.getId,
        pinId: this.twoFactPinId,
        pin: pin,
        visible: this.form.visible,
        platform: this.form.platform
      });

      return this.isLoggedIn ? this.onLoginSuccess() : this.onLoginFailure();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
