<template>
  <div class="p-4 w-full lg:w-3/5 xl:w-full space-y-6">
    <!-- Free Text Poll -->
    <div v-if="isFreeTextPoll">
      <!-- Poll Header -->
      <h3 class="font-bold text-md">
        {{ poll.question }}
      </h3>
      <div class="text-sm html-content" v-html="poll.description" />
      <textarea
        v-model="freeTextAnswer"
        rows="8"
        :placeholder="
          $t('Components.Timeline.Promoted.Poll.freeTextPlaceholder')
        "
        class="w-full p-4 placeholder-dark bg-light text-sm"
      />
    </div>
    <!-- Multiple choice poll -->
    <div v-else>
      <div v-if="alreadySubmitted">
        <div v-if="shouldDisplayResults">
          <!-- Poll Header -->
          <h3 class="font-bold text-md">
            {{ poll.question }}
          </h3>
          <div class="text-sm html-content" v-html="poll.description" />
          <!-- Multiple choice options -->
          <div
            v-for="alternative in pollAnswers"
            :key="alternative.index"
            :class="{
              'bg-grey text-dark button flex items-center justify-between w-full my-2':
                !isSelectedAlternative(alternative),
              'bg-primary-bg text-primary-text button flex items-center justify-between w-full my-2':
                isSelectedAlternative(alternative),
              'cursor-auto': poll.selectedAnswer
            }"
            aria-label="alternative poll"
            @click="setSelectedAlternative(alternative)"
          >
            <span aria-label="alternative poll texts">{{
              alternative.text
            }}</span>
            <span class="font-bold">{{ alternative.count }} </span>
          </div>
        </div>
        <div v-else>
          <h1>
            {{ $t('Components.Timeline.Promoted.Poll.notPublic') }}
          </h1>
        </div>
      </div>
      <div v-else>
        <!-- Poll Header -->
        <h3 class="font-bold text-md">
          {{ poll.question }}
        </h3>
        <div class="text-sm html-content" v-html="poll.description" />
        <!-- Multiple choice options -->
        <div
          v-for="alternative in pollAnswers"
          :key="alternative.index"
          :class="{
            'bg-grey text-dark button flex items-center justify-between w-full my-2':
              !isSelectedAlternative(alternative),
            'bg-primary-bg text-primary-text button flex items-center justify-between w-full my-2':
              isSelectedAlternative(alternative),
            'cursor-auto': poll.selectedAnswer
          }"
          aria-label="alternative poll"
          @click="setSelectedAlternative(alternative)"
        >
          <span aria-label="alternative poll texts">{{
            alternative.text
          }}</span>
        </div>
      </div>
    </div>
    <!--Poll action-->
    <PollActions
      hasClose
      :hasPrevious="hasPrevious"
      :hasNext="hasNext"
      :previous="handlePrevious"
      :next="handleNext"
      :submit="handleSubmit"
      :disabled="alreadySubmitted"
      @close="close"
    />
  </div>
</template>

<script>
import PollActions from '@/components/shared/PollActions';
import { pollType } from '@/constants/poll-type-constants';

export default {
  name: 'SinglePoll',
  components: {
    PollActions
  },
  data() {
    return {
      // Free text
      freeTextAnswer: this.answer?.freeTextAnswer || '',
      // Multi choice
      selectedAlternative: this.answer?.selectedAlternative || null,
      inputCode: '',
      errorInputCode: '',
      showCodedPoll: false
    };
  },
  props: {
    id: {
      type: String,
      required: true
    },
    poll: {
      type: Object
    },
    hasPrevious: {
      type: Boolean,
      required: false
    },
    hasNext: {
      type: Boolean,
      required: false
    },
    previous: {
      type: Function,
      required: false
    },
    next: {
      type: Function,
      required: false
    },
    submit: {
      type: Function,
      required: false
    },
    answer: {
      type: Object,
      required: false
    },
    close: {
      type: Function,
      required: false
    }
  },
  computed: {
    isFreeTextPoll() {
      return this.poll.questionType === pollType.OPEN_TEXT;
    },
    shouldDisplayResults() {
      return this.poll?.publicResults ?? false;
    },
    pollAnswers() {
      if (this.isFreeTextPoll) {
        return [];
      }

      if (!this.poll?.alternatives) {
        return [];
      }
      return Object.keys(this.poll.alternatives).map((i) => {
        return {
          index: i,
          text: this.poll.alternatives[i].text,
          count: this.poll.alternatives[i].count,
          id: this.poll.alternatives[i].id
        };
      });
    },
    getPollData() {
      return {
        freeTextAnswer: this.freeTextAnswer,
        selectedAlternative: this.selectedAlternative,
        poll: this.poll
      };
    },
    alreadySubmitted() {
      return this.poll.selectedAnswer !== null;
    }
  },
  methods: {
    setSelectedAlternative(alternative) {
      if (this.poll.selectedAnswer) {
        return;
      }
      this.selectedAlternative = alternative;

      /*ApiClient.getPoll(this.id).then((response) => {
        this.poll = response.data;
      });*/
    },
    isSelectedAlternative(alternative) {
      const selectedAlternative =
        this.selectedAlternative || this.poll.selectedAnswer;
      if (selectedAlternative) {
        return selectedAlternative?.text === alternative.text;
      }

      return false;
    },
    handleSubmit() {
      this.submit(this.getPollData);
    },
    handleNext() {
      this.next(this.getPollData);
    },
    handlePrevious() {
      this.previous(this.getPollData);
    },
    setShowCodedPoll(e) {
      this.showCodedPoll = e;
    }
  }
};
</script>
