<template>
  <div class="flex flex-col flex-wrap h-full">
    <div class="flex justify-end">
      <button
        class="button button-primary hidden md:block toggle-fullscreen"
        @click="requestFullScreen"
      >
        Full screen
      </button>
    </div>
    <div class="mb-8 shrink-0 grow-0 flex-auto">
      <h2 class="font-bold text-md">
        {{ poll.title }}
      </h2>
    </div>
    <div
      id="fullscreen"
      ref="container"
      class="flex items-center shrink-1 flex-grow-1 flex-auto max-h-screen overflow-hidden bg-white text-dark justify-center"
    >
      <h1 v-if="poll.votes.some((x) => x.isSelected)" ref="text" :style="style">
        {{ poll.votes.find((x) => x.isSelected).label }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    poll: Object
  },
  data() {
    return {
      style: {
        fontSize: '50px'
      }
    };
  },
  mounted() {
    requestAnimationFrame(this.render);
    this.$nextTick(() => {
      const self = this;
      requestAnimationFrame(self.render);
    });
  },
  methods: {
    requestFullScreen() {
      const elem = document.getElementById('fullscreen');
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    render() {
      this.adjustText();
      requestAnimationFrame(this.render);
    },
    adjustText() {
      const textWidth = this.$refs.text?.offsetWidth;
      const textHeight = this.$refs.text?.offsetHeight;

      const containerWidth = this.$refs.container?.scrollWidth;
      const containerHeight = this.$refs.container?.scrollHeight;

      if (
        textWidth < containerWidth &&
        textWidth / containerWidth < 0.9 &&
        textHeight < containerHeight &&
        textHeight / containerHeight < 0.9
      ) {
        if (parseInt(this.$refs.text.style['font-size']) < 900) {
          this.style.fontSize =
            Math.round(parseInt(this.$refs.text.style['font-size']) + 10) +
            'px';
        }
      } else if (textWidth >= containerWidth || textHeight >= containerHeight) {
        if (parseInt(this.$refs.text.style['font-size']) > 20) {
          this.style.fontSize =
            Math.round(parseInt(this.$refs.text.style['font-size']) - 10) +
            'px';
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
h1 {
  font-size: 5vw;
  font-weight: bold;
  line-height: 1.15;
  margin: 0;
  text-align: center;
}
</style>
