<template>
  <div>
    <div v-if="isChatList">
      <!--Header-->
      <div class="w-full">
        <div class="px-2 my-3 md:px-12 lg:px-3 lg:my-2">
          <div class="lg:h-18">
            <div class="flex flex-wrap items-center">
              <h2 class="text-md md:text-lg text-center">
                {{ $t('Components.Chat.chat') }}
              </h2>
              <div class="text-right flex-1">
                <div>
                  <button
                    class="button button-primary text-xs md:text-sm"
                    aria-label="open new chat"
                    @click="openNewChat()"
                  >
                    <i class="far fa-plus" aria-hidden="true" />
                    {{ $t('Components.Chat.ChatMessages.newChatButton') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChatList v-if="isChatList" />
    <NewChat v-if="isNewChat" />
    <OutgoingCall v-if="isOutGoingCall" />
    <VideoCall v-if="isInCall" />
    <CallEnded v-if="isCallEnded" />
    <NotAvailableModal v-if="isNotAvailable" />
    <ChatMain v-if="isChatMain" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import NewChat from '@/components/chat/NewChat';
import ChatMain from '@/components/chat/ChatMain';
import ChatList from '@/components/chat/ChatList';
import OutgoingCall from '@/components/chat/OutgoingCall';
import VideoCall from '@/components/chat/VideoCall';
import CallEnded from '@/components/chat/CallEnded';
import NotAvailableModal from '@/components/chat/NotAvailableModal';
export default {
  name: 'Chat',
  components: {
    NotAvailableModal,
    NewChat,
    CallEnded,
    VideoCall,
    OutgoingCall,
    ChatList,
    ChatMain
  },
  computed: {
    ...mapGetters('Chat', [
      'getCurrentChatID',
      'getIsOutGoingCall',
      'getInCall',
      'getCallEnded',
      'getCallNotAvailable',
      'getIsNewChat',
      'getIsChatList'
    ]),
    isChatList() {
      return this.getIsChatList;
    },
    isOutGoingCall() {
      return this.getIsOutGoingCall;
    },
    isInCall() {
      return this.getInCall;
    },
    isCallEnded() {
      return this.getCallEnded;
    },
    isNotAvailable() {
      return this.getCallNotAvailable;
    },
    isChatMain() {
      return !!this.getCurrentChatID;
    },
    isNewChat() {
      return this.getIsNewChat;
    }
  },
  methods: {
    ...mapMutations('Chat', [
      'setCurrentChatID',
      'setIsNewChat',
      'setIsChatList'
    ]),
    openNewChat() {
      this.setIsNewChat(true);
      this.setIsChatList(false);
      this.setCurrentChatID(false);
    }
  }
};
</script>

<style scoped></style>
