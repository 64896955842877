<template>
  <div
    ref="avatar"
    class="avatar"
    :class="{
      [`avatar--medium`]: typeof size !== 'number' && size === 'medium',
      [`avatar--small`]: typeof size !== 'number' && size === 'small',
      [`avatar--large`]: typeof size !== 'number' && size === 'large',
      [`avatar--xlarge`]: typeof size !== 'number' && size === 'xlarge'
    }"
    :style="{
      height: typeof size === 'number' && size + 'rem',
      width: typeof size === 'number' && size + 'rem'
    }"
    :aria-label="ariaLabel"
  >
    <div class="avatar__holder" :class="{ 'avatar--circle': circle }">
      <img loading="lazy" :src="source" class="avatar__image" :alt="alt" />
    </div>
    <div v-if="onlineStatus" class="avatar__status">
      <span
        class="status"
        :class="{
          'status--online': onlineStatus === true,
          'status--offline': onlineStatus === false
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    alt: String,
    ariaLabel: String,
    circle: { type: Boolean, default: false },
    image: String,
    size: [Number, String], // use 'small', 'medium', 'large', 'full' for predefined values or number (for rem)
    onlineStatus: Boolean
  },
  computed: {
    source() {
      return this.image || '/assets/user-solid.svg';
    }
  }
};
</script>

<style scoped lang="scss">
.avatar {
  position: relative;
  &__holder {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
  }
  &__status {
    background: var(--background-color);
    border-radius: 50%;
    bottom: 0;
    height: 33%;
    position: absolute;
    right: 0;
    width: 33%;
  }
  &__status-name {
    align-items: center;
    background: #1a202c;
    border-radius: 50%;
    bottom: 0;
    color: #fff;
    display: flex;
    font-size: 13px;
    font-weight: bold;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 700ms;
    z-index: 0;
    &:hover {
      opacity: 0.75;
    }
  }
  .status {
    border-radius: 50%;
    height: 75%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    &--online {
      background: var(--color-status-online);
    }
    &--offline {
      background: var(--color-status-offline);
    }
  }
  &--circle {
    border-radius: 50%;
    overflow: hidden;
  }
  &--small {
    height: 1rem;
    width: 1rem;
  }
  &--medium {
    height: 2rem;
    width: 2rem;
  }
  &--large {
    height: 6rem;
    width: 6rem;
  }
  &--xlarge {
    height: 8rem;
    width: 8rem;
  }
  &--full {
    width: 100%;
    &:after {
      position: absolute;
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
  &__image {
    border-radius: 50%;
    border: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    text-align: center;
    text-indent: -10000px;
    top: 0;
    width: 100%;
  }
}
</style>
