<template>
  <div :class="className">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Tabs',
  props: {
    className: {}
  }
};
</script>

<style lang="scss" scoped></style>
