<template>
  <div class="p-10 md:p-4 flex flex-col h-full">
    <div class="h-full flex flex-col">
      <!-- Heading -->
      <div v-if="title" class="mb-4">
        <h3 class="text-lg font-bold">
          {{ title }}
        </h3>
      </div>

      <!-- Embed -->
      <div class="relative">
        <div class="iframe-container">
          <iframe class="iframe" :src="embeddableUrl" frameborder="0" />
        </div>
      </div>

      <!-- Close -->
      <div class="text-base mt-6">
        <button
          class="bg-dark text-white py-1 px-3 rounded text-base"
          aria-label="close"
          @click="$emit('close')"
        >
          {{ $t('Components.shared.iframe.close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
// Taken from https://www.w3schools.com/howto/howto_css_responsive_iframes.asp
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>

<script>
import { toEmbeddableUrl } from '@/utils';

export default {
  name: 'IframeModal',
  props: {
    url: {
      required: true,
      type: String
    },
    title: {
      required: false,
      type: String,
      default: null
    }
  },
  computed: {
    embeddableUrl() {
      return toEmbeddableUrl(this.url);
    }
  }
};
</script>
