<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getPusherInstance } from '@/utils';

export default {
  name: 'CurrentEventDataProvider',
  created() {
    const interval = setInterval(() => {
      if (!this.hasFullEventDetails) {
        return;
      }
      getPusherInstance().bind('event-change', (topic, data) => {
        const previousEvent = this.getEvent;

        if (data?.additional_data) {
          const newEvent = data.additional_data;

          if (newEvent.streamUrl !== this.getStreamUrl) {
            this.setStreamFromEvent(newEvent);
          }
          if (
            newEvent?.features?.StageTabInfoPages === true &&
            newEvent?.features?.StageTabInfoPages !==
              previousEvent?.features?.StageTabInfoPages
          ) {
            this.fetchInfoPages();
          }
          this.updateEventInfo(newEvent);
          return;
        }

        this.loadFullEventInfo().then(() => {
          const newEvent = this.getEvent;

          if (newEvent.streamUrl !== this.getStreamUrl) {
            this.setStreamFromEvent(newEvent);
          }
        });
      });
      clearInterval(interval);
    }, 100);
  },
  methods: {
    ...mapActions('CurrentEvent', ['loadFullEventInfo', 'updateEventInfo']),
    ...mapActions('Stream', ['setStreamFromEvent']),
    ...mapActions('Timeline', ['resetAllTimelineItems']),
    ...mapActions('Pages', { fetchInfoPages: 'fetchItems' })
  },
  computed: {
    ...mapState('CurrentEvent', ['eventId', 'eventDetails']),
    ...mapGetters('CurrentEvent', [
      'hasFullEventDetails',
      'features',
      'isSingleDayEvent',
      'isSameMonthEvent',
      'isSameYearEvent',
      'isWithoutEndDate',
      'getLocale',
      'getDesignStyles',
      'getEvent'
    ]),
    ...mapGetters('Stream', ['getStreamUrl'])
  },
  render() {
    return this.$scopedSlots.default({
      locale: this.getLocale,
      eventId: this.eventId,
      title: this.eventDetails?.eventName,
      loginText: this.eventDetails?.loginCaption,
      lobbyBannerText: this.eventDetails?.lobbyBannerText,
      logoUrl: this.eventDetails?.logoUrl,
      designStyles: this.eventDetails ? this.getDesignStyles : null,
      hasFullEventDetails: this.hasFullEventDetails,
      data: this.eventDetails,
      features: this.eventDetails ? this.features : null,
      isOutsideEventContext: this.eventId === null,
      isSingleDayEvent: this.isSingleDayEvent,
      isWithoutEndDate: this.isWithoutEndDate,
      isSameMonthEvent: this.isSameMonthEvent,
      isSameYearEvent: this.isSameYearEvent
    });
  }
};
</script>

<style scoped></style>
