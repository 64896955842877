import ApiClient from '@/client';
import router from '@/router';
import { getPusherInstance } from '@/utils';

export const state = () => {
  return {
    loggingIn: false,
    loginError: null,
    loggedIn: false,
    loginSuccessful: false,
    userId: null,
    eventId: null,
    cookieInfo: null,
    user: null,
    defaultImage: 'https://ideffector-prod.s3.amazonaws.com/images/avatar.png'
  };
};

export const getters = {
  isLoggedIn: (state) => {
    return state.loggedIn;
  },
  isLoggingIn: (state) => {
    return state.loggingIn;
  },
  getUser: (state) => {
    return state.user;
  },
  getDefaultImage: (state) => {
    return state.defaultImage;
  }
};

export const mutations = {
  setDefaultImage: (state, payload) => {
    state.defaultImage = payload;
  },
  setUser: (state, payload) => {
    state.user = payload;
  },
  clearUser: (state) => {
    state.user = null;
  },
  setCookieInfo(state, payload) {
    state.cookieInfo = payload;
  },
  setLoggedIn: (state, payload) => {
    state.loggedIn = payload;
  },
  loginStart: (state) => {
    state.loggingIn = true;
  },
  loginStop: (state) => {
    state.loggingIn = false;
  },
  loginSuccess: (state, response) => {
    state.eventId = response.eventID;
    state.loggedIn = response.loginSuccessful;
    state.loginSuccessful = response.loginSuccessful;
    state.userId = response.userID;
    state.loginError = null;
  },
  loginFailure: (state, response) => {
    state.loggedIn = false;
    state.loginSuccessful = false;
    state.loginError = response.message;
  },
  Logout: (state) => {
    state.loggedIn = false;
    state.loggingIn = false;
    state.loginError = null;
    state.userId = false;
    state.loginSuccessful = null;
    state.eventId = null;
    state.user = null;
    state.cookieInfo = null;
  }
};

export const actions = {
  async doLogin({ dispatch, commit, rootGetters, rootState }, loginData) {
    await commit('loginStart');
    const email = loginData.email && loginData.email.trim();

    const response = await ApiClient.login(
      email,
      loginData.password,
      loginData.eventId,
      loginData.visible,
      loginData.pin,
      loginData.pinId
    ).catch(async () => {
      return { data: { loginSuccessful: false } };
    });

    if (response.data.loginSuccessful === false) {
      commit('loginFailure', response.data);
    } else {
      response.data.eventID = loginData.eventId;
      commit('loginSuccess', response.data);
      await dispatch('getUser');
    }

    await commit('loginStop');
  },

  async doPasswordlessLogin(
    { dispatch, commit, rootGetters, rootState },
    data
  ) {
    await commit('loginStart');

    const response = await ApiClient.passwordlessLogin(
      data.payload,
      data.eventId,
      data.visible
    ).catch(async () => {
      return { data: { loginSuccessful: false } };
    });

    if (response.data.loginSuccessful === false) {
      commit('loginFailure', response.data);
    } else {
      response.data.eventID = data.eventId;
      commit('loginSuccess', response.data);
      await dispatch('getUser');
    }

    await commit('loginStop');
  },

  doLogout({ commit, rootGetters }) {
    const eventId = rootGetters['CurrentEvent/getId'];
    localStorage.setItem(`tokens_${eventId}`, null);

    const websocketInstance = getPusherInstance();
    if (websocketInstance && websocketInstance.socket) {
      websocketInstance.socket.close(1000);
      clearInterval(websocketInstance.heartbeat);
      websocketInstance.heartbeat = null;
    }

    commit('Logout');

    router.push({ name: 'login' });
    // refresh page to reset vuex state
    router.go(0);
  },

  changeAvatar({ dispatch }, file) {
    ApiClient.uploadProfileAvatar(file).then((response) => {
      dispatch('getUser');
    });
  },

  async toggleProfileVisibility({ dispatch }, file) {
    ApiClient.toggleProfileVisibility(file).then((response) => {
      dispatch('getUser');
    });
  },

  async updateUser({ dispatch }, userData) {
    await ApiClient.updateProfile(userData);
    await dispatch('getUser');
  },

  async getUser({ commit, dispatch }) {
    let response;
    try {
      response = await ApiClient.getCurrentInfoUserFromSession();
    } catch (err) {
      commit('setLoggedIn', false);
      commit('clearUser');
      await dispatch('doLogout');
      return;
    }

    if (response.data.userModuleResult) {
      commit('setUser', response.data.userModuleResult);
      const { Firstname, Lastname, Email } = response.data.userModuleResult;

      try {
        window.LiveChatWidget.call(
          'set_customer_name',
          `${Firstname} ${Lastname}`
        );
        window.LiveChatWidget.call('set_customer_email', Email);
      } catch (e) {
        console.log('LiveChat widget threw exception', e);
      }

      commit('setLoggedIn', true);
    } else {
      commit('clearUser');
      commit('setLoggedIn', false);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
