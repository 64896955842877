<template>
  <CurrentEventDataProvider v-slot="{ data, title }">
    <div class="h-full w-full flex flex-col bg-white text-dark px-4">
      <!-- Content -->
      <div
        class="flex flex-col flex-1 bg-light mt-4 md:mb-8 md:ml-8 md:mr-8 items-center justify-center"
      >
        <div class="text-center mb-2 md:mb-8">
          <h1 class="font-bold text-md md:text-lg mb-4">
            {{ title }}
          </h1>
          <h2 class="font-bold text-base md:text-md">
            {{
              checkLoginType === 0
                ? $t('Views.PollResults.loginIntoResults')
                : $t('Views.ParticipantsAdmitted.loginIntoResults')
            }}
          </h2>
        </div>

        <Card v-if="data.active === '1'" class="p-8 md:p-10 md:w-2/5">
          <form
            method="post"
            autocapitalize="none"
            @submit.prevent="submitForm"
          >
            <div class="space-y-4">
              <div class="relative">
                <input
                  v-model="form.password"
                  :placeholder="$t('Views.Login.password')"
                  type="password"
                  name="password"
                  :readonly="resultsIsLoggedIn"
                  class="p-4 border-none text-dark w-full rounded transition-all duration-200 ease-in-out"
                  :class="{
                    'bg-grey': resultsIslogingIn,
                    'bg-light': !resultsIslogingIn
                  }"
                  :aria-label="$t('Views.Login.password')"
                />
                <i class="fal fa-lock text-dark absolute top-0 right-0 m-5" />
              </div>

              <div class="flex items-baseline justify-between flex-col">
                <div v-if="loginFailed" class="flex-1 mb-4">
                  <transition name="fade">
                    <span class="form-error text-sm font-bold">
                      <i class="fas fa-exclamation-circle mr-1" />
                      {{ $t('Views.PollResults.passwordWrong') }}
                    </span>
                  </transition>
                </div>
                <div
                  class="w-full flex flex-col-reverse md:flex-row md:justify-end"
                >
                  <TappinButton
                    type="submit"
                    :disabled="resultsIslogingIn || loading"
                    class="whitespace-nowrap button button-primary mt-2 md:mt-0"
                    :aria-label="resultsIslogingIn ? 'loading' : 'login'"
                    :icon="resultsIslogingIn ? 'circle-notch' : ''"
                    :custom_caption="
                      resultsIslogingIn
                        ? $t('Views.Login.loggingIn')
                        : $t('Views.Login.login')
                    "
                  />
                </div>
              </div>
            </div>
          </form>
        </Card>
        <Card v-else class="p-10 lg:w-2/5">
          <span class="text-center" aria-label="This event has closed">
            <i class="fas fa-exclamation-circle mr-1" aria-label="hidden" />
            {{ $t('Views.Login.eventClosed') }}
          </span>
        </Card>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import TappinButton from '@/components/buttons/TappinButton.vue';
import Card from '@/components/shared/Card.vue';
import { redirectAuthenticatedUserToResults } from '@/helpers/redirection-helpers';
import { mapActions, mapGetters } from 'vuex';
import { loginType } from '@/constants/login-type-constants';
export default {
  components: {
    TappinButton,
    Card
  },
  props: {
    pollAccessKey: String
  },
  data() {
    return {
      loading: false,
      loginFailed: false,
      form: {
        password: '',
        platform: false
      }
    };
  },
  computed: {
    checkLoginType() {
      const path = window.location.pathname;
      const matchingRoute = Object.keys(loginType).find((route) =>
        path.startsWith(route)
      );
      if (matchingRoute) {
        return loginType[matchingRoute];
      }
      return 'unknown';
    },
    ...mapGetters('ResultsAuthentication', [
      'resultsIsLoggedIn',
      'resultsIslogingIn',
      'resultsloginFailure'
    ])
  },
  methods: {
    ...mapActions('ResultsAuthentication', [
      'doLoginToPollResults',
      'doLoginToParticipantResults'
    ]),
    async submitForm(e) {
      this.loginFailed = false;
      this.loading = true;
      if (this.checkLoginType === 0) {
        await this.doLoginToPollResults({
          password: this.form.password,
          pollAccessKey: this.pollAccessKey
        });
      }

      if (this.checkLoginType === 1) {
        await this.doLoginToParticipantResults({
          password: this.form.password
        });
      }

      return this.resultsIsLoggedIn
        ? this.onLoginSuccess()
        : this.onLoginFailure();
    },
    onLoginSuccess() {
      if (this.resultsIsLoggedIn) {
        redirectAuthenticatedUserToResults(
          this.$router,
          this.pollAccessKey,
          this.checkLoginType
        );
      } else {
        this.loading = false;
      }
    },
    onLoginFailure() {
      console.log('Login failed');
      this.loading = false;
      this.loginFailed = true;
    }
  }
};
</script>
