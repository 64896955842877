function injectPwaInstallPrompt() {
  var recent = document.getElementById('pwa-install-safari');
  if (recent) {
    return;
  }

  const manifest = document.querySelector('link[rel="manifest"]');

  console.log('manifest', manifest);

  const pwaInstall = document.createElement('pwa-install');
  pwaInstall.setAttribute('id', 'pwa-install-safari');
  pwaInstall.setAttribute('manifest-url', manifest.href);
  document.body.append(pwaInstall);
}

export { injectPwaInstallPrompt };
