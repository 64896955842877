<template>
  <div class="flex items-center justify-center w-full">
    <!-- Date filter -->
    <div v-if="showDates" class="flex flex-col items-center w-full space-y-2">
      <select
        v-model="selectedOption"
        @change="handleTodayOption"
        class="flex flex-col border border-black rounded-lg p-2 gap-2 w-full"
      >
        <option value="default">Select</option>
        <option value="today">Today</option>
        <option value="dateRange">custom date</option>
      </select>
      <div
        class="flex flex-col border border-black rounded-lg p-4 gap-2 w-full"
        v-if="selectedOption === 'dateRange'"
      >
        <h2 class="text-xs">Choose date</h2>
        <div class="flex space-x-2">
          <input
            v-model="fromDate"
            type="date"
            id="from-date"
            @change="handleDates({ date: fromDate, type: 'from' })"
            class="block rounded text-xs w-full"
            :max="toDate"
          />
          <input
            v-model="toDate"
            type="date"
            id="to-date"
            :min="fromDate"
            @change="handleDates({ date: toDate, type: 'to' })"
            :disabled="fromDate === ''"
            class="block rounded text-xs w-full"
          />
        </div>
      </div>
    </div>

    <!-- Groups filter -->
    <div class="w-full space-y-2" v-if="showCheckbox">
      <div class="flex justify-between items-center w-full">
        <div class="relative w-2/5">
          <input
            type="text"
            :placeholder="$t('Components.Filters.searchPlaceholder')"
            class="bg-light text-base w-full rounded-3xl"
            ref="childFocus"
            @input="handleTextSearch"
          />
          <i class="fas fa-search fa-sm absolute right-0 m-5" />
        </div>
        <div class="flex space-x-2 w-3/5 justify-end">
          <TappinButton
            :icon="debounceLoading ? 'circle-notch' : 'repeat'"
            @click="handleResetFilter"
            class="whitespace-nowrap button button-secondary mt-2 md:mt-0"
            aria-label="Reset"
          />
          <TappinButton
            @click="handleSaveFilter"
            class="whitespace-nowrap button button-primary mt-2 md:mt-0"
            aria-label="Save"
            custom_caption="Save"
          />
        </div>
      </div>
      <div class="flex flex-col w-full space-y-2 px-4">
        <div
          class="text-base flex justify-between"
          role="menuitem"
          v-for="group in displayedItems"
          :key="group.id"
        >
          <div>
            <input
              :disabled="debounceLoading"
              type="checkbox"
              @change="handleCheckBox($event, group.id)"
              :checked="checkBoxFilter.includes(group.id)"
              :value="group.id"
            /><span class="ml-2">{{ group.name }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Input search filter -->
    <div v-if="showSearchFilter || showCategories" class="flex w-full">
      <div v-if="showSearchFilter" class="w-2/3 relative">
        <input
          type="text"
          :placeholder="$t('Components.Filters.searchPlaceholder')"
          class="bg-light text-base w-full rounded-3xl"
          ref="childFocus"
          @input="handleTextSearch"
        />
        <i class="fas fa-search absolute right-0 m-3" />
      </div>
      <!-- Categories filter -->
      <div v-if="showCategories" class="w-1/3 flex px-1 md:px-4">
        <select
          v-model="categoryFilter"
          class="custom-select border-none w-full"
          @change="$emit('category-filter', categoryFilter)"
        >
          <option value="">
            {{ $t('Components.Filters.categories') }}
          </option>
          <option
            v-for="category in categories"
            :value="category.value"
            :key="category.value"
          >
            {{ category.label }}
          </option>
        </select>
        <span class="custom-select-icon"
          ><i class="fa fa-bars-filter" aria-hidden="true"
        /></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TappinButton from '../buttons/TappinButton.vue';
export default {
  name: 'Filters',
  components: { TappinButton },
  props: {
    showDates: Boolean,
    showSearchFilter: Boolean,
    showCategories: Boolean,
    showCheckbox: Boolean,
    categories: Array,
    tableCheckBoxName: String,
    isDebouncedFilter: Boolean,
    debounceLoading: Boolean,
    groups: Array
  },
  data() {
    return {
      fromDate: '',
      toDate: '',
      selectedOption: 'default',
      searchFilter: '',
      categoryFilter: '',
      checkBoxFilter: [],
      showCheckBoxTable: false,
      debouncedSearchTimeout: null
    };
  },
  computed: {
    displayedItems() {
      return this.filterListGroup();
    }
  },
  methods: {
    ...mapActions('Groups', ['filterGroups']),
    filterListGroup() {
      if (this.searchFilter) {
        this.filterGroups(this.searchFilter);
        return this.$store.getters['Groups/getFilteredGroups'];
      } else {
        return this.$store.getters['Groups/getItems'];
      }
    },
    handleSaveFilter() {
      this.$emit('checkbox-filter', this.checkBoxFilter);
    },
    handleResetFilter() {
      this.checkBoxFilter = [];
      this.$emit('checkbox-filter', this.checkBoxFilter);
    },
    handleShowCheckBoxTable() {
      this.showCheckBoxTable = !this.showCheckBoxTable;
    },
    handleTextSearch(e) {
      if (this.debouncedSearchTimeout) {
        clearTimeout(this.debouncedSearchTimeout);
      }
      const value = e.target.value.trim();
      this.searchFilter = value;

      if (this.isDebouncedFilter) {
        this.debouncedSearchTimeout = setTimeout(() => {
          this.$emit('search-filter', this.searchFilter);
        }, 500);
      } else {
        this.$emit('search-filter', this.searchFilter);
      }
    },
    handleCheckBox(e) {
      if (this.debouncedSearchTimeout) {
        clearTimeout(this.debouncedSearchTimeout);
      }
      const value = e.target.value.trim();
      const isChecked = this.checkBoxFilter.find((v) => v === value);
      if (!isChecked) {
        this.checkBoxFilter.push(value);
      } else {
        this.checkBoxFilter = this.checkBoxFilter.filter(
          (v) => v.trim() !== value
        );
      }
    },
    handleDates(e) {
      if (e.type === 'from') {
        this.fromDate = e.date;
      } else if (e.type === 'to') {
        this.toDate = e.date;
      }
      if (this.selectedOption === 'today') {
        const today = new Date().toISOString().slice(0, 10);
        this.fromDate = today;
        this.toDate = today;
      }
      const datesRange = {
        from: this.fromDate,
        to: this.toDate
      };
      this.$emit('date-filter', datesRange);
    },
    handleTodayOption() {
      if (this.selectedOption === 'today') {
        const today = new Date().toISOString().slice(0, 10);
        this.fromDate = today;
        this.toDate = today;
      } else {
        this.fromDate = '';
        this.toDate = '';
      }
      const datesRange = {
        from: this.fromDate,
        to: this.toDate
      };
      this.$emit('date-filter', datesRange);
    }
  }
};
</script>

<style>
.button-group {
  background-color: rgb(222, 222, 222);
}
.menu {
  width: 25%;
}

.dropdown-button {
  text-align: right;
}

.form-checkbox {
  background-color: lightgray !important;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: none;
  cursor: pointer;
}

.custom-select-icon {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 480px) {
  .dropdown-button {
    text-align: center;
  }

  .menu {
    width: 45%;
  }
}
</style>
