var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CurrentEventDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ locale, designStyles }){return [_c('AgendasDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ trackById, tracks, setStreamFromSession }){return [_c('BookSessionsDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({
        bookSessions,
        singleMyAgendaGroupedByDay,
        addOrDeleteBooksSesions,
        isBookSessionLoading
      }){return [_c('div',{style:(designStyles)},[(!_vm.isAgendaStage)?_c('div',{staticClass:"text-base md:text-md font-bold"},[_vm._v(" "+_vm._s(_vm.$t('Views.Stage.Agenda.myAgenda'))+" ")]):_vm._e(),(bookSessions.length === 0)?_c('div',{staticClass:"flex justify-center items-center"},[_c('p',{staticClass:"p-5 font-bold text-sm md:text-base"},[_vm._v(" "+_vm._s(_vm.$t('Views.Lobby.Sections.Program.noBookSessions'))+" ")])]):_c('div',[(_vm.successMessage)?_c('div',{staticClass:"flex"},[_c('div',{staticClass:"border-2 border-green"}),_c('div',{staticClass:"success-message p-2 w-full"},[_vm._v(_vm._s(_vm.successMessage))])]):_vm._e(),_c('CollapsibleList',{class:_vm.isAgendaStage
                ? ''
                : 'flex flex-col overflow-x-hidden overflow-y-auto w-full'},_vm._l((singleMyAgendaGroupedByDay.sessions),function(day,date){return _c('CollapsibleItem',{key:date,attrs:{"item-id":date,"agenda":singleMyAgendaGroupedByDay},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('div',{staticClass:"py-4 border-b border-grey"},[_c('div',{staticClass:"button w-full flex justify-between items-baseline",staticStyle:{"text-transform":"capitalize"},attrs:{"aria-labelledby":"agenda date","aria-expanded":[active ? 'true' : 'false'],"aria-controls":"expanded-panel"},on:{"click":toggle}},[_c('h2',{staticClass:"text-base xl:text-md",class:_vm.isAgendaStage ? '' : 'text-sm xl:text-base'},[(locale === 'en')?_c('Moment',{attrs:{"value":date,"format":"dddd DD MMMM"}}):_vm._e(),(locale === 'nb')?_c('Moment',{attrs:{"value":date,"format":"dddd DD. MMMM"}}):_vm._e()],1),_c('div',{attrs:{"aria-hidden":"true"}},[(active)?_c('i',{staticClass:"fas fa-chevron-up"}):_c('i',{staticClass:"fas fa-chevron-down"})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(active),expression:"active"}],attrs:{"id":"expanded-panel"}},[_c('CollapsibleList',_vm._l((day),function(session){return _c('CollapsibleItem',{key:session.id,attrs:{"item-id":session.id,"agenda":singleMyAgendaGroupedByDay},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('div',{staticClass:"my-2",attrs:{"id":session.id}},[_c('Card',{attrs:{"containerClass":[
                            'flex p-2 rounded-2xl bg-white space-x-2 w-full'
                          ]}},[(
                              tracks &&
                              tracks.length > 1 &&
                              session.trackId != '0' &&
                              tracks[0].id != session.trackId
                            )?_c('div',{staticClass:"border-4 border-solid",style:({
                              'border-color': trackById(session.trackId)
                                .headerBackgroundColor
                            })}):_vm._e(),_c('div',{staticClass:"w-full py-1 space-y-2"},[_c('div',{staticClass:"flex justify-between items-center w-full text-xs"},[_c('div',{staticClass:"flex space-x-2"},[_c('div',[_c('Moment',{attrs:{"value":session.startDate,"format":"HH:mm"}}),_vm._v(" - "),_c('Moment',{attrs:{"value":session.endDate,"format":"HH:mm"}})],1),(
                                    tracks &&
                                    tracks.length > 1 &&
                                    session.trackId != '0'
                                  )?_c('div',{staticClass:"inline-block font-bold px-2 text-white",style:({
                                    'background-color': trackById(
                                      session.trackId
                                    ).headerBackgroundColor
                                  })},[_vm._v(" "+_vm._s(trackById(session.trackId).title)+" ")]):_vm._e()]),(isBookSessionLoading)?_c('div',[_c('i',{staticClass:"fas fa-circle-notch fa-spin"})]):_vm._e(),_c('BookSessionButton',{staticClass:"button border",class:[
                                  bookSessions.some(
                                    (ses) => ses.id === session.id
                                  )
                                    ? 'text-red border border-red	'
                                    : 'border'
                                ],attrs:{"icon":[
                                  bookSessions.some(
                                    (ses) => ses.id === session.id
                                  )
                                    ? 'minus-square'
                                    : 'calendar-plus-o'
                                ],"custom_caption":bookSessions.some(
                                    (ses) => ses.id === session.id
                                  )
                                    ? _vm.$t('Views.Stage.Agenda.cancel')
                                    : _vm.$t('Views.Stage.Agenda.attend')},on:{"click":function($event){return _vm.handleSessionClick(
                                    session,
                                    bookSessions,
                                    addOrDeleteBooksSesions,
                                    isBookSessionLoading
                                  )}}})],1),_c('div',[_c('h3',{staticClass:"font-bold text-base"},[_vm._v(" "+_vm._s(session.title)+" ")]),_c('h4',[_vm._v(" "+_vm._s(session.subtitle)+" ")])]),(
                                session.description ||
                                session.speakers.length ||
                                (session.docIds &&
                                  session.docIds.length > 0) ||
                                session.liveUrl
                              )?_c('div',[_c('div',{staticClass:"flex justify-start text-sm space-x-2"},[(
                                    session.description ||
                                    session.speakers.length
                                  )?_c('div',{staticClass:"button button-secondary",attrs:{"aria-label":"Learn more about session","aria-expanded":[active ? 'true' : 'false'],"aria-controls":"expanded-panel"},on:{"click":toggle}},[_vm._v(" "+_vm._s(active ? _vm.$t('Views.Stage.Agenda.showLess') : _vm.$t('Views.Stage.Agenda.moreInfo'))+" ")]):_vm._e(),(session.liveUrl)?_c('div',{staticClass:"button button-primary",attrs:{"aria-label":_vm.$t('Views.Stage.Agenda.goToScene')},on:{"click":function($event){return setStreamFromSession(session)}}},[_vm._v(" "+_vm._s(_vm.$t('Views.Stage.Agenda.goToScene'))+" ")]):_vm._e(),_c('AgendaDocuments',{attrs:{"session":session}})],1),(active)?_c('div',{attrs:{"id":"expanded-panel"}},[(session.speakers.length)?_c('div',{ref:"childElement",refInFor:true,staticClass:"flex flex-row flex-wrap p-2"},_vm._l((session.speakers),function(speaker){return _c('div',{key:speaker.id,staticClass:"flex items-center space-x-2 space-y-2 w-1/2"},[_c('Avatar',{attrs:{"image":speaker.profileImageUrl,"circle":true,"size":'small'}}),_c('div',{staticClass:"flex flex-col text-xs"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(speaker.name + ' ' + speaker.lastName))]),(speaker.jobTitle)?_c('span',[_vm._v(" "+_vm._s(speaker.jobTitle))]):_vm._e(),(speaker.company)?_c('span',[_vm._v(" "+_vm._s(speaker.company))]):_vm._e()])],1)}),0):_vm._e(),_c('div',{staticClass:"text-sm html-content",domProps:{"innerHTML":_vm._s(session.description)}})]):_vm._e()]):_vm._e()])])],1)]}}],null,true)})}),1)],1)])]}}],null,true)})}),1)],1)])]}}],null,true)})]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }