var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CurrentEventDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ data, features, locale }){return [_c('BookSessionsDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ bookSessions, addOrDeleteBooksSesions, isBookSessionLoading }){return [_c('CategoriesDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ agendaSessionCategories }){return [(features.lobbyAgenda)?_c('AgendasDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({
          groupItemIndex,
          groupItemIsFirst,
          groupItemIsLast,
          initiallyActiveDate,
          searchAgendasForDateAndTime,
          singleAgendaGroupedByDay,
          lastDateInSingleAgenda,
          isLoading,
          trackById,
          tracks,
          goToStreamFromSession,
          filterSessions
        }){return [_c('AgendaSection',{attrs:{"locale":locale,"bookSessions":bookSessions,"caption":_vm.options.label || data.captions.programCaption,"initiallyActiveDate":initiallyActiveDate,"groupItemIndex":groupItemIndex,"groupItemIsFirst":groupItemIsFirst,"groupItemIsLast":groupItemIsLast,"searchAgendasForDateAndTime":searchAgendasForDateAndTime,"singleAgendaGroupedByDay":singleAgendaGroupedByDay,"lastDateInSingleAgenda":lastDateInSingleAgenda,"isLoading":isLoading,"tracks":tracks,"trackById":trackById,"goToStreamFromSession":goToStreamFromSession,"showGoToMainStage":features.agendaSessionGoToMainStage,"checkMainStage":data.lobbyElements.find((label) => label.options.mainStageLabel),"addOrDeleteBooksSesions":addOrDeleteBooksSesions,"filterSessions":filterSessions,"agendaSessionCategories":agendaSessionCategories,"isBookSessionLoading":isBookSessionLoading}})]}}],null,true)}):_vm._e()]}}],null,true)})]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }