var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"avatar",staticClass:"avatar",class:{
    [`avatar--medium`]: typeof _vm.size !== 'number' && _vm.size === 'medium',
    [`avatar--small`]: typeof _vm.size !== 'number' && _vm.size === 'small',
    [`avatar--large`]: typeof _vm.size !== 'number' && _vm.size === 'large',
    [`avatar--xlarge`]: typeof _vm.size !== 'number' && _vm.size === 'xlarge'
  },style:({
    height: typeof _vm.size === 'number' && _vm.size + 'rem',
    width: typeof _vm.size === 'number' && _vm.size + 'rem'
  }),attrs:{"aria-label":_vm.ariaLabel}},[_c('div',{staticClass:"avatar__holder",class:{ 'avatar--circle': _vm.circle }},[_c('img',{staticClass:"avatar__image",attrs:{"loading":"lazy","src":_vm.source,"alt":_vm.alt}})]),(_vm.onlineStatus)?_c('div',{staticClass:"avatar__status"},[_c('span',{staticClass:"status",class:{
        'status--online': _vm.onlineStatus === true,
        'status--offline': _vm.onlineStatus === false
      }})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }