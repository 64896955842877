export const state = () => {
  return {
    shown: false
  };
};

export const getters = {
  termsShown: (state) => {
    return state.shown;
  }
};

export const mutations = {
  setShown: (state, shown) => {
    state.shown = shown;
  }
};

export const actions = {
  setTermsAsShown({ state, commit }) {
    commit('setShown', true);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
