<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'PublicAgendasDataProvider',
  methods: {
    ...mapActions('Agendas', ['filterSessions'])
  },
  computed: {
    ...mapState('CurrentEvent', ['eventDetails']),

    ...mapGetters('Agendas', [
      'getItems',
      'isLoading',
      'failed',
      'getAgendaCount',
      'getTracks',
      'groupItemIndex',
      'groupItemIsFirst',
      'groupItemIsLast',
      'searchAgendasForDateAndTime',
      'singleAgendaBySpeaker',
      'singleAgendaGroupedByDay',
      'singleAgendaGroupedByTime',
      'agendasWithSessionsGroupedByDay',
      'agendasWithSessionsGroupedByTime'
    ]),
    ...mapGetters('CurrentEvent', [
      'hasFullEventDetails',
      'features',
      'getLocale',
      'getEvent'
    ]),
    getLastDateInSingleAgenda() {
      return this.isLoading
        ? null
        : _.last(Object.keys(this.singleAgendaGroupedByDay.sessions));
    },
    getFirstDateInSingleAgenda() {
      return this.isLoading
        ? null
        : Object.keys(this.singleAgendaGroupedByDay.sessions)[0];
    },
    initiallyActiveDate() {
      const currentDate = Vue.moment().format('YYYY-MM-DD');
      const dates = Object.keys(this.singleAgendaGroupedByDay.sessions);
      const activeDate = dates.includes(currentDate) ? currentDate : dates[0];
      return this.isLoading ? null : activeDate;
    }
  },

  render() {
    // TODO(23 jul. 2020) ~ Helge: We should not render until we have our data, make this a bit more clever and abstract it away
    if (this.isLoading) {
      return this.$scopedSlots.default({
        isLoading: this.isLoading
      });
    }

    return this.$scopedSlots.default({
      agendas: this.getItems,
      tracks: this.getTracks,
      trackById: (trackId) => {
        return this.getTracks.find((t) => t.id === trackId);
      },
      hasSingleAgenda: this.getItems.length === 1,
      agendaCount: this.getAgendaCount,
      loading: this.isLoading,
      failed: this.failed,
      refresh: this.refresh,
      groupItemIndex: this.groupItemIndex,
      groupItemIsFirst: this.groupItemIsFirst,
      groupItemIsLast: this.groupItemIsLast,
      searchAgendasForDateAndTime: this.searchAgendasForDateAndTime,
      singleAgendaBySpeakerId: (id) => {
        return this.singleAgendaBySpeaker(id);
      },
      singleAgendaGroupedByDay: this.singleAgendaGroupedByDay,
      singleAgendaGroupedByTime: this.singleAgendaGroupedByTime,
      agendasWithSessionsGroupedByDay: this.agendasWithSessionsGroupedByDay,
      initiallyActiveDate: this.initiallyActiveDate,
      firstDateInSingleAgenda: this.getFirstDateInSingleAgenda,
      lastDateInSingleAgenda: this.getLastDateInSingleAgenda,
      setStreamFromSession: this.setStreamFromSession,
      goToStreamFromSession: this.goToStreamFromSession,
      filterSessions: this.filterSessions,
      data: this.eventDetails,
      features: this.features,
      locale: this.getLocale,
      hasFullEventDetails: this.hasFullEventDetails
    });
  }
};
</script>
