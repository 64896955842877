function redirectAuthenticatedUser(
  $router,
  isWebappEnabled,
  isLobbyEnabled,
  isStageEnabled,
  platform,
  pollAccessKey,
  type
) {
  if (isWebappEnabled && (isLobbyEnabled || isStageEnabled)) {
    if (platform === true) {
      $router.push({
        name: isLobbyEnabled ? 'lobby' : 'stage'
      });
    } else if (platform === false) {
      $router.push({
        name: 'webapp'
      });
    }
  } else if (isWebappEnabled && !(isLobbyEnabled || isStageEnabled)) {
    $router.push({
      name: 'webapp'
    });
  } else if ((isLobbyEnabled || isStageEnabled) && !isWebappEnabled) {
    $router.push({
      name: isLobbyEnabled ? 'lobby' : 'stage'
    });
  }
  if (type === 0) {
    if (pollAccessKey) {
      $router.push({
        name: 'poll-results',
        params: { pollAccessKey: pollAccessKey }
      });
    } else {
      $router.push({
        name: 'generic-poll-results'
      });
    }
  }
  if (type === 1) {
    $router.push({
      name: 'participants-status'
    });
  }

  return $router;
}

function redirectAuthenticatedUserToResults($router, pollAccessKey, type) {
  if (type === 0) {
    if (pollAccessKey) {
      $router.push({
        name: 'poll-results',
        params: { pollAccessKey: pollAccessKey }
      });
    } else {
      $router.push({
        name: 'generic-poll-results'
      });
    }
  }
  if (type === 1) {
    $router.push({
      name: 'participants-admitted'
    });
  }

  return $router;
}

export { redirectAuthenticatedUser, redirectAuthenticatedUserToResults };
