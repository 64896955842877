<template>
  <Card class="space-y-4">
    <div class="flex">
      <div class="pr-4 w-1/4">
        <Avatar
          :image="speaker.profileImageUrl"
          :circle="true"
          :size="'full'"
          :aria-label="speaker.name + ' ' + speaker.lastName"
        />
      </div>
      <div class="w-3/4 flex flex-col items-start">
        <h3 class="text-sm md:text-base font-bold" ref="speakerName">
          {{ speaker.name + ' ' + speaker.lastName }}
        </h3>
        <span class="text-xs">
          {{ speaker.company }}
        </span>
        <span class="font-bold text-xs pb-2" aria-label="speakers title">
          {{ speaker.jobTitle }}
        </span>
        <div>
          <button
            v-if="
              speaker.description?.length ||
              speaker.phoneNumber ||
              speaker.email
            "
            class="button button-secondary mr-1 xl:mr-4 text-sm xl:text-base"
            @click="handleSpeakerInfoClick"
          >
            <span :aria-label="$t('Views.Stage.Speakers.moreInfo')">{{
              $t('Views.Stage.Speakers.moreInfo')
            }}</span>
          </button>
          <button
            v-if="speaker.sessions?.length"
            class="button button-primary text-sm xl:text-base"
            aria-label="read details about speaker sessions"
            @click="showSpeakerSessionsModal"
          >
            {{ $t('Views.Lobby.Sections.Speakers.sessions') }}
          </button>
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
import Avatar from '@/components/shared/Avatar';
import { createAnalyticsEvent } from '@/helpers/ga-helper';
import SpeakerSessions from '@/components/speaker/SpeakerSessions';
import SpeakerInformation from '@/components/speaker/SpeakerInformation';
import Card from '@/components/shared/Card.vue';

export default {
  name: 'item-component',
  components: {
    Avatar,
    Card
  },
  props: {
    index: {
      // index of current item
      type: Number
    },
    source: {
      // here is: {uid: 'unique_1', text: 'abc'}
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    speaker() {
      return this.source;
    }
  },
  methods: {
    handleSpeakerInfoClick(e) {
      this.onMoreInfoClick(e);
      this.showSpeakerInfoModal();
    },
    onMoreInfoClick() {
      createAnalyticsEvent(
        this.$gtag,
        'ClickOnSpeakerInfo',
        'Speakers',
        this.speaker?.name && this.speaker?.name.length > 0
          ? this.speaker.name
          : this.$store.getters['CurrentEvent/getId']
      );
    },
    showSpeakerInfoModal() {
      document.body.classList.add('overflow-hidden');
      this.$modal.show(
        SpeakerInformation,
        {
          speaker: this.speaker
        },
        {
          width: '95%',
          height: 'auto',
          adaptive: true,
          minWidth: 300,
          maxWidth: 640
        },
        { closed: () => document.body.classList.remove('overflow-hidden') }
      );
    },
    showSpeakerSessionsModal() {
      document.body.classList.add('overflow-hidden');
      this.$modal.show(
        SpeakerSessions,
        {
          speaker: this.speaker
        },
        {
          width: '95%',
          height: 'auto',
          adaptive: true,
          minWidth: 300,
          maxWidth: 640
        },
        { closed: () => document.body.classList.remove('overflow-hidden') }
      );
    }
  }
};
</script>
