var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CurrentEventDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ features }){return [_c('Card',{attrs:{"id":"card"}},[_c('div',[_c('div',{staticClass:"flex items-center w-full"},[_c('a',{staticClass:"no-underline hover:underline flex flex-wrap",attrs:{"href":"javascript:void(0)"},on:{"mouseover":_vm.mouseEnter,"mouseleave":_vm.mouseLeave}},[_c('div',{staticClass:"flex items-center"},[_c('Avatar',{staticClass:"w-6 h-6 md:w-8 md:h-8",attrs:{"image":_vm.user.Picture,"circle":true,"online-status":_vm.isUserOnline(_vm.user.UserID)},on:{"error":_vm.setAltImg}}),_c('div',{class:[
                (_vm.user.Firstname + _vm.user.Lastname).length < 20
                  ? 'font-bold px-2 text-xs md:text-sm'
                  : 'font-bold px-2 text-xxs md:text-xs'
              ]},[_vm._v(" "+_vm._s(_vm.user.Firstname)+" "+_vm._s(_vm.user.Lastname)+" ")])],1)]),_c('div',{staticClass:"text-xxs md:text-xs text-right whitespace-nowrap flex-1"},[_c('from-now',{staticClass:"red",attrs:{"value":_vm.item.CreatedOn,"tag":"span"}})],1)]),_c('div',{staticClass:"my-4 relative"},[(_vm.item.Message)?_c('div',{staticClass:"pr-8"},[_c('div',{staticClass:"text-xs md:text-sm html-content",domProps:{"innerHTML":_vm._s(_vm.item.Message)}})]):_vm._e(),_c('div',{class:[
            _vm.item.imageUrls.length > 1 ? 'grid grid-cols-2 gap-2' : 'block '
          ]},_vm._l((_vm.item.imageUrls),function(imageUrl){return _c('div',{key:imageUrl},[_c('TimelineImage',{attrs:{"image":_vm.baseApiAssetUrl + imageUrl},on:{"click":function($event){return _vm.showImageModal(_vm.baseApiAssetUrl + imageUrl)}}})],1)}),0)]),(_vm.isItemPostedByCurrentUser(_vm.item))?_c('div',{staticClass:"flex justify-center"},[_c('DeleteButton',{attrs:{"custom_caption":_vm.$t('Components.Timeline.TimelineUserItem.removePost')},on:{"click":function($event){return _vm.deleteItem(_vm.item.TimelineID)}}})],1):_vm._e()]),_c('div',{staticClass:"flex justify-end items-center"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"button",attrs:{"id":"TimelineLikes","disabled":_vm.islikeDisabled,"aria-label":"like this post"},on:{"click":_vm.toggleLike}},[_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.item.Likes))]),_c('i',{staticClass:"fa-thumbs-up ml-2",class:{
              fal: !_vm.isLikedByCurrentUser,
              fas: _vm.isLikedByCurrentUser
            },attrs:{"aria-hidden":"true"}})]),(_vm.isTimelineCommentingEnabled)?_c('div',{staticClass:"button",attrs:{"id":"TimelineComments","aria-label":"comment on this post"},on:{"click":function($event){_vm.showComments = !_vm.showComments}}},[_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.item.Comments.length))]),_c('i',{staticClass:"fal fa-comment-alt ml-2",attrs:{"aria-hidden":"true"}})]):_vm._e()])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showComments && _vm.isTimelineCommentingEnabled),expression:"showComments && isTimelineCommentingEnabled"}]},[_c('TimelineCommentPost',{attrs:{"item":_vm.item}}),_vm._l((_vm.item.Comments),function(comment,index){return _c('div',{key:comment.TimelineID,staticClass:"text-xs flex flex-col justify-between m-2 p-4 bg-light rounded",class:{
          'border-b':
            index !== _vm.item.Comments.length - 1 && _vm.item.Comments.length > 1
        }},[_c('div',{staticClass:"flex w-full items-center"},[_c('Avatar',{staticClass:"w-6 h-6 md:w-8 md:h-8",attrs:{"image":_vm.getAuthor(comment).Picture,"circle":true,"online-status":_vm.isUserOnline(_vm.getAuthor(comment).UserID),"alt":_vm.$t('Views.Shared.UserPicture')},on:{"error":_vm.setAltImg}}),_c('div',{staticClass:"font-bold px-1 md:px-2 text-xs md:text-sm"},[_vm._v(" "+_vm._s(_vm.getAuthor(comment).Firstname)+" "+_vm._s(_vm.getAuthor(comment).Lastname)+" ")]),_c('div',{staticClass:"text-xxs md:text-xs text-right h-8 flex flex-1 items-center justify-end"},[_c('from-now',{attrs:{"value":comment.CreatedOn,"tag":"span"}})],1)],1),_c('div',{staticClass:"my-4 relative"},[_c('div',{staticClass:"m-2 text-xs md:text-sm pr-8 html-content",domProps:{"innerHTML":_vm._s(comment.Message)}}),_c('div',{class:[
              comment.imageUrls.length > 1
                ? 'grid grid-cols-2 gap-2'
                : 'block'
            ]},_vm._l((comment.imageUrls),function(imageUrl){return _c('div',{key:imageUrl},[_c('TimelineImage',{attrs:{"image":_vm.baseApiAssetUrl + imageUrl},on:{"click":function($event){return _vm.showImageModal(_vm.baseApiAssetUrl + imageUrl)}}})],1)}),0)]),(_vm.isItemPostedByCurrentUser(comment))?_c('div',{staticClass:"flex justify-center"},[_c('DeleteButton',{attrs:{"custom_caption":_vm.$t('Components.Timeline.TimelineUserItem.removeComment')},on:{"click":function($event){return _vm.deleteComment(comment.TimelineID)}}})],1):_vm._e()])})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.toShowOnHover),expression:"toShowOnHover"}],on:{"mouseover":_vm.cardOver,"mouseleave":_vm.cardLeave}},[_c('Card',{attrs:{"id":"hovercard"}},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"w-1/4"},[_c('Avatar',{staticClass:"h-10 w-10 md:h-16 md:w-16",attrs:{"image":_vm.user.Picture,"circle":true,"online-status":_vm.isUserOnline(_vm.user.UserID),"alt":_vm.$t('Views.Shared.UserPicture')},on:{"error":_vm.setAltImg}})],1),_c('div',{staticClass:"flex flex-col space-y-1 md:space-y-2 sm:text-left"},[_c('h3',{staticClass:"text-xs md:text-sm font-bold"},[_vm._v(" "+_vm._s(_vm.user.Firstname)+" "+_vm._s(_vm.user.Lastname)+" ")]),_c('h2',{staticClass:"text-xs md:text-sm"},[_vm._v(" "+_vm._s(_vm.user.Company)+" ")]),_c('div',{staticClass:"flex flex-row"},[_c('button',{staticClass:"button button-primary py-1 px-2 rounded text-xxxs md:text-xs mr-2",attrs:{"aria-label":"View profile"},on:{"click":function($event){return _vm.showDetails(_vm.user)}}},[_vm._v(" "+_vm._s(_vm.$t('Views.Stage.ParticipantsModal.viewProfile'))+" ")]),(
                  !_vm.isItemPostedByCurrentUser(_vm.item) &&
                  features.stageChatEnabled
                )?_c('button',{staticClass:"button button-secondary py-1 px-2 rounded text-xxxs md:text-xs",attrs:{"aria-label":"send message"},on:{"click":function($event){return _vm.createChat(_vm.user)}}},[_vm._v(" "+_vm._s(_vm.$t('Views.Stage.ParticipantsModal.sendMessage'))+" ")]):_vm._e()])])])])],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }