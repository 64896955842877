<template>
  <CurrentEventDataProvider v-slot="{ locale, designStyles }">
    <BookSessionsDataProvider v-slot="{ addOrDeleteBooksSesions }">
      <div
        class="flex flex-col items-center p-6 space-y-2"
        :style="designStyles"
      >
        <div class="flex flex-col w-full h-full space-y-2">
          <div class="flex justify-center md:text-md">
            {{ $t('Views.Stage.Agenda.confirmationToAttend') }}
          </div>
          <div
            class="grid gap-4 grid-cols-2 px-2 md:px-16 items-center text-sm"
          >
            <div class="flex justify-center">
              <div class="font-bold">
                {{ $t('Views.Stage.Agenda.sessionDetails') }} :
              </div>
            </div>
            <div class="flex flex-col">
              <div>
                <Moment
                  v-if="locale === 'en'"
                  :value="session.startDate"
                  format="dddd DD MMMM"
                />
                <Moment
                  v-if="locale === 'nb'"
                  :value="session.startDate"
                  format="dddd DD. MMMM"
                />
              </div>
              <div>
                <Moment :value="session.startDate" format="HH:mm" />
                -
                <Moment :value="session.endDate" format="HH:mm" />
              </div>
              <div>{{ session.title }}</div>
            </div>
          </div>
        </div>
        <div class="flex space-x-8">
          <div
            class="button button-secondary"
            aria-label="Close"
            @click="onClose"
          >
            {{ $t('Views.Stage.Agenda.close') }}
          </div>
          <div
            class="button button-primary"
            aria-label="attend"
            @click="handleSessionClick(session, addOrDeleteBooksSesions)"
          >
            {{ $t('Views.Stage.Agenda.confirmAttend') }}
          </div>
        </div>
      </div>
    </BookSessionsDataProvider>
  </CurrentEventDataProvider>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AgendaConfirmationModal',
  props: {
    session: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters('CurrentEvent', ['getDesignStyles'])
  },
  methods: {
    handleSessionClick(session, addOrDeleteBooksSesions) {
      addOrDeleteBooksSesions(session);
      this.onClose();
    },
    onClose() {
      this.$emit('close', {
        success: false
      });
    }
  }
};
</script>
