<template>
  <div class="card" :class="containerClass">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    containerClass: {
      type: Array,
      default: () => [' py-4 px-8 rounded-3xl bg-white']
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  transition: all 200ms;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
</style>
