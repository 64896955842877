<template>
  <div>
    <!-- Image preview-->
    <div class="flex flex-row flex-wrap">
      <div
        v-for="commentImage in commentImages.filter(
          (i) => i.timelineID == item.TimelineID
        )"
        :key="commentImage.previewUrl"
      >
        <ImagePreview
          :image="commentImage.previewUrl"
          @click="onImageUnselect(commentImage.uniqueIndex)"
        />
      </div>
    </div>
    <!-- Comment input -->
    <form
      class="flex flex-col items-center my-2 max-h-max relative border-2 border-grey rounded"
      aria-label="form"
      @submit.prevent="onSubmitComment"
      @drop="onDropCommentImage($event, item.TimelineID)"
      @dragover="dragover"
      @dragleave="dragleave"
    >
      <!-- Form image and caption upload-->
      <div class="flex flex-row w-full h-full items-center justify-center">
        <div class="flex mx-4 pr-4 border-r-4 border-grey">
          <label
            :for="'image-upload-' + item.TimelineID"
            class="custom-file-upload hover:opacity-50"
          >
            <i class="far fa-images"></i>
          </label>
        </div>
        <div class="w-full">
          <input
            v-model="comment"
            type="text"
            :placeholder="
              $t('Components.Timeline.TimelineUserItem.addCommentPlaceholder')
            "
            class="px-4 py-3 w-full text-black text-base placeholder-dark rounded-sm border-none focus:ring-transparent focus:shadow-none"
            aria-label="place a comment"
          />
          <input
            :id="'image-upload-' + item.TimelineID"
            type="file"
            accept="image/*"
            @change="onCommentImageChange($event, item.TimelineID)"
            multiple
          />
        </div>
        <button
          v-tippy="{ placement: 'right', arrow: true }"
          type="submit"
          class="button h-12 w-12 hover:opacity-50 flex justify-center items-center"
          :content="$t('Components.Timeline.TimelineUserItem.send')"
          :disabled="isSendDisabled"
        >
          <i class="fal fa-paper-plane ml-2" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ImagePreview from '@/components/shared/ImagePreview';
import { createAnalyticsEvent } from '@/helpers/ga-helper';

export default {
  components: {
    ImagePreview
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      comment: null,
      commentImages: [],
      uniqueIndex: 0,
      isSendDisabled: false,
      likeDisabled: false
    };
  },
  methods: {
    ...mapActions('Timeline', ['addComment']),
    // Deselect image from preview before posting to comments
    onImageUnselect(e) {
      var index = this.commentImages
        .map((file) => {
          return file.uniqueIndex;
        })
        .indexOf(e);
      this.commentImages.splice(index, 1);
    },
    onSubmitComment() {
      createAnalyticsEvent(
        this.$gtag,
        this.commentImages && this.commentImages.length > 0
          ? 'CommentImage'
          : 'Comment',
        'Timeline',
        this.item.TimelineID
      );
      this.isSendDisabled = true;
      this.addComment({
        message: this.comment,
        timelineId: this.item.TimelineID,
        files: this.commentImages.map((x) => x.file)
      }).then(() => {
        this.comment = null;
        this.commentImages = [];
        setTimeout(() => {
          this.isSendDisabled = false;
        }, 1500);
      });
    },
    onCommentImageChange(e, timelineID) {
      if (e.target.files.length > 8) {
        e.preventDefault();
        alert('Cannot upload more than 8 images at once');
        return;
      } else {
        this.commentImages = [
          ...this.commentImages,
          ...[...e.target.files].map((file) => ({
            file,
            previewUrl: URL.createObjectURL(file),
            uniqueIndex: this.uniqueIndex++,
            timelineID: timelineID
          }))
        ];
      }
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('dropzone-area')) {
        event.currentTarget.classList.add('dropzone-area');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove('dropzone-area');
    },
    onDropCommentImage(e, timelineID) {
      if (e.dataTransfer.files.length > 8) {
        e.preventDefault();
        alert('Cannot upload more than 8 images at once');
        return;
      } else {
        var imageTypes = [
          'image/png',
          'image/gif',
          'image/bmp',
          'image/jpeg',
          'image/webp'
        ];
        if (e.dataTransfer && e.dataTransfer.files) {
          var fileType = e.dataTransfer.files[0].type;
          if (imageTypes.includes(fileType)) {
            this.commentImages = [
              ...this.commentImages,
              ...[...e.dataTransfer.files].map((file) => ({
                file,
                previewUrl: URL.createObjectURL(file),
                uniqueIndex: this.uniqueIndex++,
                timelineID: timelineID
              }))
            ];
            e.currentTarget.classList.remove('dropzone-area');
          } else {
            e.preventDefault();
            alert(
              'Dropped file is not an image, please upload image with formats .png, .jpeg, .webp, .gif or .bmp'
            );
            return;
          }
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.dropzone-area {
  position: relative;
  border: 2px dashed #2e94c4;
}
input[type='file'] {
  display: none;
}
</style>
