import { Base64 } from 'js-base64';
import Vue from 'vue';
import _ from 'lodash';
import queryString from 'querystring';
import store from '@/store';
import i18n from '@/i18n';

export const SearchFilterSort = (
  collection,
  searchComposite,
  searchValue,
  filter,
  sort
) => {
  if (searchComposite && searchValue) {
    collection = collection
      .map((p) => {
        return {
          ...p,
          // Create a composite key that we can perform a fulltext search on.
          internalSearchCompositeKey: searchComposite(p)
        };
      })
      .filter((p) =>
        p.internalSearchCompositeKey.toLocaleLowerCase().includes(searchValue)
      );

    collection.forEach((v) => delete v.internalSearchCompositeKey);
  }

  if (filter) {
    collection = collection.filter(filter);
  }
  if (sort) {
    collection = _.orderBy(collection, sort);
  }

  return collection;
};

export const parsePromoLink = (link) => {
  const parsed = queryString.parse(link);
  if (parsed.pg === 'quiz' || parsed.pg === 'quiz_x') {
    return {
      page: parsed.pg,
      id: parsed.qid
    };
  }
  return {
    page: parsed.pg,
    id: parsed.id
  };
};

let pusherInstance = null;

export const getPusherInstance = (metadata) => {
  if (pusherInstance) {
    return pusherInstance;
  }
  const isReady = store.getters['CurrentEvent/hasFullEventDetails'];
  const currentEvent = store.getters['CurrentEvent/getEvent'];
  // Null object that will allow the consumers of this
  // function to act as if it worked, event if pusher is not available here.
  const pusherFake = {
    bind: (event) => {
      console.log(`Pusher: Fake called with bind with event: ${event}`);
    },
    subscribe: (channel) => {
      console.log(`Pusher: Fake called with subscribe on channel: ${channel}`);
    }
  };

  if (!metadata?.isReady && !isReady) {
    console.log(
      'Pusher: Used getPusher in utils.js before Vuex store has loaded full event information'
    );

    return pusherFake;
  }
  pusherInstance = createWebsocket(currentEvent, null);
  return pusherInstance;
};

export const isRoomAvailableAtTime = (room, time) => {
  time = time || Vue.moment();
  const { wherebyStartDate, wherebyEndDate } = room;
  const start = Vue.moment(wherebyStartDate);
  const end = Vue.moment(wherebyEndDate);

  return time.isBetween(start, end, 'days', '[]');
};

export const formatDate = (time, format) => Vue.moment(time).format(format);

export const formatCurrentDate = (format) => Vue.moment().format(format);

export const embeddableType = (url) => {
  if (!url) {
    return 'none';
  }

  if (url.includes('whereby')) {
    return 'whereby';
  }

  if (url.includes('whereby') && url.includes('embed')) {
    return 'whereby-embed';
  }

  if (url.includes('live-video.net')) {
    return 'ivs-embed';
  }

  if (url.includes('youtube') && url.includes('/embed')) {
    return 'youtube-embed';
  }

  if (url.includes('youtube') && !url.includes('/embed')) {
    return 'youtube';
  }

  if (url.includes('flowplayer')) {
    return 'flowvideo';
  }

  if (url.includes('vimeo') && !url.includes('player.vimeo')) {
    return 'vimeo';
  }
  if (url.includes('vimeo') && url.includes('player.vimeo')) {
    return 'vimeo-embed';
  }
  if (url.includes('.m3u8')) {
    return 'm3u8';
  }

  return 'uknown';
};

export const urlType = (url) => {
  // check url, if it is an image, return true, else return false.
  const isImage = {
    'Content-Type':
      'image/png,image/svg+xml,image/avif,image/webp,image/apng,image/*,*/*'
  };
  if (url === isImage) {
    return true;
  }
  return false;
};

export const toEmbeddableUrl = (url) => {
  if (!url) {
    return '';
  }

  const embedType = embeddableType(url);
  try {
    const parsed = new URL(url);
    // Whereby
    //= ===================================================================
    if (embedType === 'whereby') {
      const boolToString = (value) => (value ? 'on' : 'off');

      const getUser = store.getters['Authentication/getUser'];
      const features = store.getters['CurrentEvent/features'];
      const displayname = getUser.Firstname + ' ' + getUser.Lastname;

      const isPrebuiltWherebyUrl = url?.includes('screenshare=');
      const isHost = url.includes('roomKey=');

      const params = new URLSearchParams();
      if (!isPrebuiltWherebyUrl) {
        params.set('screenshare', boolToString(features.whereByScreenShare));
        params.set('chat', boolToString(features.whereByChat));
        params.set('audio', boolToString(features.whereByAudio));
        params.set('video', boolToString(features.whereByVideo));
        params.set('background', boolToString(features.whereByBackground));
      }

      params.set('displayName', displayname);
      params.set('embed', 'true');
      params.set('leaveButton', 'off');
      params.set('skipMediaPermissionPrompt', 'on');
      params.set('iframeSource', 'tappin-api');
      params.set('recording', 'on');
      if (isHost) {
        params.set('topToolbar', 'on');
      }

      if (isHost || isPrebuiltWherebyUrl) {
        return url + '&' + params.toString();
      }

      return url + '?' + params.toString();
    }

    // Youtube
    //= ===================================================================
    if (embedType === 'youtube') {
      const qs = new URLSearchParams(parsed.search.substring(1));
      return `https://www.youtube.com/embed/${qs.get('v')}`;
    }

    // Vimeo
    //= ===================================================================
    if (embedType === 'vimeo') {
      if (url.includes('/showcase/')) {
        return url;
      }

      if (!parsed.pathname.includes('event')) {
        return 'https://player.vimeo.com/video' + parsed.pathname;
      }
      return 'https://vimeo.com' + parsed.pathname + '/embed';
    }

    // FlowVideo
    //= ===================================================================
    if (embedType === 'flowvideo') {
      return url;
    }

    // Return as is.
    return url;
  } catch (e) {
    return '';
  }
};

function createWebsocket(currentEvent, prevInstance) {
  const topicId = `online-event-${currentEvent.eventId}`;
  const socket = new WebSocket(
    currentEvent.notificationEndpoint + '?topicId=' + topicId
  );
  const topics = prevInstance ? prevInstance.topics : {};
  let subUid = prevInstance ? prevInstance.subUid : -1;

  if (prevInstance) {
    clearInterval(prevInstance.heartbeat);
  }

  const heartbeat = setInterval(() => {
    if (pusherInstance.socket.readyState === WebSocket.OPEN) {
      pusherInstance.socket.send(JSON.stringify({ message: 'ping' }));
    }
  }, 540000);

  pusherInstance = {
    socket: socket,
    topics: topics,
    subUid: subUid,
    heartbeat: heartbeat,
    currentEvent: currentEvent,
    checkConnection: undefined,
    retryCount: 0,
    bind: (topic, callback) => {
      if (topics[topic]?.length > 0) {
        return null;
      }
      if (!topics[topic]) {
        topics[topic] = [];
      }
      const token = (++subUid).toString();
      topics[topic].push({
        token: token,
        func: callback
      });
      return token;
    },
    unbind: (tokens) => {
      tokens.forEach(function (item, index) {
        topics[item.topic] = topics[item.topic].filter(
          (x) => x.token !== item.token
        );
      });

      return null;
    }
  };

  socket.onopen = function (e) {
    console.log('[open] connected to wss');
    const channelName = `online-event-${currentEvent.eventId}`;
    const user = store.getters['Authentication/getUser'];
    socket.send(
      JSON.stringify({
        action: 'SUBSCRIBE',
        topicId: channelName,
        userId: user?.UserID
      })
    );
  };

  socket.onclose = function (event) {
    if (event.wasClean) {
      console.log(
        `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
      );
      console.log('[close] Trying to reconnect...');
    } else {
      // e.g. server process killed or network down
      // event.code is usually 1006 in this case
      console.log('[close] Connection died');
    }

    // AWS 2 hour termination sends code 1001 - Going away
    if (event.code !== 1001) {
      pusherInstance.retryCount += 1;
    }

    if (pusherInstance.retryCount < 5 && event.code !== 1000) {
      const newInstance = createWebsocket(currentEvent, pusherInstance);
      pusherInstance.socket = newInstance.socket;
    }

    if (event.code === 1000) {
      pusherInstance = null;
    }
  };

  socket.onerror = function (error) {
    console.log(`[error] ${error}`);
  };

  socket.onmessage = (message) => {
    const data = JSON.parse(message.data);
    const topic = data.action;

    if (!pusherInstance.topics[topic]) {
      return false;
    }
    setTimeout(function () {
      const subscribers = pusherInstance.topics[topic];
      let len = subscribers ? subscribers.length : 0;

      while (len--) {
        subscribers[len].func(topic, data);
      }
    }, 0);

    pusherInstance.retryCount = 0;
    return true;
  };

  document.addEventListener('visibilitychange', function () {
    if (!document.hidden) {
      if (pusherInstance.socket.readyState === WebSocket.CLOSED) {
        const newInstance = createWebsocket(currentEvent, pusherInstance);
        pusherInstance.socket = newInstance.socket;
      }
    }
  });

  return pusherInstance;
}

export function replaceTokens(obj, profile, path = 'Message') {
  if (Array.isArray(obj)) {
    for (const item of obj) {
      item[path] = replaceItemTokens(item[path], profile);
    }
    return obj;
  }
  if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) {
    obj[path] = replaceItemTokens(obj[path], profile);
  }

  if (typeof obj === 'string' && obj !== null) {
    return replaceItemTokens(obj, profile);
  }

  return obj;
}

export function getApplicablePlatforms(router) {
  return router.fullPath.includes('webapp') ? ['webApp'] : ['digital'];
}

function replaceItemTokens(item, profile) {
  const codedProfile = codeObjectKeys(profile);
  const propMap = {
    qrTicket: 'TicketQrCode'
  };
  return item.replaceAll(
    /{{{?\[?\s*([a-zA-Z0-9_ -?.¿#,!@%^&*()<>{}|+=:;]+)\s*\]?}?}}/g,
    (match, m1) =>
      (Object.keys(propMap).some((x) => x === m1)
        ? codedProfile[propMap[m1]]
        : codedProfile.CalculatedFields[m1]) ?? ''
  );
}

export const unknownUser = {
  Firstname: i18n.t('Components.Timeline.unknown'),
  Lastname: i18n.t('Components.Timeline.user')
};

function codeObjectKeys(obj) {
  const codedObj = {};
  Object.keys(obj).forEach((key) => {
    if (key === 'CalculatedFields') {
      codedObj[key] = {};
      Object.keys(obj[key]).forEach((calculatedKey) => {
        const hashedKey = Base64.encode(calculatedKey);
        codedObj[key][hashedKey] = obj[key][calculatedKey];
      });
    } else {
      codedObj[key] = obj[key];
    }
  });
  return codedObj;
}
