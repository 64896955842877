export const state = () => {
  return {
    items: [],
    mode: 'info',
    promotedPage: {
      page: null,
      id: null
    }
  };
};

export const getters = {
  getMode: (state) => state.mode,
  getPromotedPage: (state) => state.promotedPage
};

export const mutations = {
  setMode: (state, mode) => {
    state.mode = mode;
  },
  setPromotedPage: (state, payload) => {
    state.promotedPage = payload;
  },
  resetMode: (state) => {
    state.mode = 'info';
  }
};

export const actions = {
  async setPromotedPage({ commit }, payload) {
    commit('setMode', 'promo');
    commit('setPromotedPage', payload);
  },

  async resetPromotedPage({ commit }) {
    commit('setMode', 'info');
    commit('setPromotedPage', {
      page: null,
      id: null
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
