import { createAnalyticsEvent } from './ga-helper';

function listenClickInsideContainer(
  containerEl,
  setPromotedPage,
  gtag = null,
  setInternalLink = null
) {
  if (!containerEl) {
    return;
  }

  const listener = function (evt) {
    var element = evt.target || evt.srcElement;
    var parentElement = element.parentNode;

    // Google Analytics Event
    const gaEvent = {
      action: undefined,
      category: undefined,
      label: undefined
    };

    // LINKS
    if (element.getAttribute('href') || parentElement.getAttribute('href')) {
      const href =
        element.getAttribute('href') || parentElement.getAttribute('href');
      const parts = href.split('/');

      if (parts[0] === 'internal') {
        evt.stopImmediatePropagation();
        evt.preventDefault();
        if (parts[1] === 'infoPage') {
          setPromotedPage({
            page: 'info',
            id: parts[2]
          });
          return;
        }

        if (parts[1] === 'speaker') {
          setPromotedPage({
            page: 'speaker',
            id: parts[2]
          });
          return;
        }

        if (parts[1] === 'session') {
          let liveUrl;
          if (parts.length > 3) {
            const protocolIndex = parts.findIndex((part) =>
              part.toLowerCase().startsWith('http')
            );
            liveUrl = parts.slice(protocolIndex).join('/');
          }

          if (setInternalLink) {
            setInternalLink({
              type: parts[1],
              id: parts[2],
              date: parts[3],
              liveUrl: liveUrl
            });
            return;
          }
        }

        if (setInternalLink) {
          setInternalLink({ type: parts[1], id: parts[2] });
        }
      }
      return;
    }

    if (element.hasAttribute('data-tappin-infopage-id')) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      const elInfoPageId = element.getAttribute('data-tappin-infopage-id');
      const parts = elInfoPageId.split('/');
      if (parts[0] === 'internal') {
        if (setInternalLink) {
          setInternalLink({ type: parts[1], id: parts[2] });
        }
      } else {
        setPromotedPage({
          page: 'info',
          id: elInfoPageId
        });
      }

      gaEvent.action = 'ClickOnInfoPage';
      gaEvent.category = 'PromotedMessage';
      gaEvent.label = elInfoPageId;
    }

    if (element.hasAttribute('data-tappin-session-id')) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      const elSessionId = element.getAttribute('data-tappin-session-id');
      setPromotedPage({
        page: 'session',
        id: elSessionId,
        liveUrl: element.getAttribute('data-tappin-session-liveurl')
      });

      gaEvent.action = 'ClickOnSession';
      gaEvent.category = 'PromotedMessage';
      gaEvent.label = elSessionId;
    }

    if (element.hasAttribute('data-tappin-agenda-session')) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      const elInfoPageId = element.getAttribute('data-tappin-agenda-session');
      const parts = elInfoPageId.split('/');

      let liveUrl;
      if (parts.length > 3) {
        const protocolIndex = parts.findIndex((part) =>
          part.toLowerCase().startsWith('http')
        );
        liveUrl = parts.slice(protocolIndex).join('/');
      }
      if (setInternalLink) {
        setInternalLink({
          type: parts[1],
          id: parts[2],
          date: parts[3],
          liveUrl: liveUrl
        });
        return;
      }
    }

    if (element.hasAttribute('data-tappin-userprofile')) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      const value = element.getAttribute('data-tappin-userprofile');
      const parts = value.split('/');
      if (setInternalLink) {
        setInternalLink({ type: parts[1] });
      }
    }

    if (element.hasAttribute('data-tappin-participants')) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      const value = element.getAttribute('data-tappin-participants');
      const parts = value.split('/');
      if (setInternalLink) {
        setInternalLink({ type: parts[1] });
      }
    }

    if (element.hasAttribute('data-tappin-sponsor-id')) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      const elSponsorId = element.getAttribute('data-tappin-sponsor-id');
      const parts = elSponsorId.split('/');
      if (parts[0] === 'internal') {
        if (setInternalLink) {
          setInternalLink({ type: parts[1], id: parts[2] });
        }
      } else {
        setPromotedPage({
          page: 'sponsor',
          id: elSponsorId
        });
      }

      gaEvent.action = 'ClickOnSponsor';
      gaEvent.category = 'PromotedMessage';
      gaEvent.label = elSponsorId;
    }

    if (element.hasAttribute('data-tappin-documents')) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      const value = element.getAttribute('data-tappin-documents');
      const parts = value.split('/');
      if (setInternalLink) {
        setInternalLink({ type: parts[1] });
      }
    }

    if (element.hasAttribute('data-tappin-speaker-id')) {
      evt.preventDefault();
      evt.stopPropagation();
      const elSpeakerId = element.getAttribute('data-tappin-speaker-id');
      const parts = elSpeakerId.split('/');
      if (parts[0] === 'internal') {
        if (setInternalLink) {
          setInternalLink({ type: parts[1], id: parts[2] });
        }
      } else {
        setPromotedPage({
          page: 'speaker',
          id: elSpeakerId
        });
      }

      gaEvent.action = 'ClickOnSpeaker';
      gaEvent.category = 'PromotedMessage';
      gaEvent.label = elSpeakerId;
    }

    if (!element.hasAttribute('data-tappin-poll-id')) {
      if (gtag && gaEvent.action) {
        createAnalyticsEvent(
          gtag,
          gaEvent.action,
          gaEvent.category,
          gaEvent.label
        );
      }
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    setPromotedPage({
      page: element.getAttribute('data-tappin-poll-type') ?? 'poll',
      id: element.getAttribute('data-tappin-poll-id')
    });

    if (gtag) {
      createAnalyticsEvent(
        gtag,
        'ClickOnPoll',
        'PromotedMessage',
        element.getAttribute('data-tappin-poll-id')
      );
    }
  };

  containerEl.addEventListener('click', listener);

  return listener;
}

function removeClickInsideContainerListener(containerEl, listener) {
  if (!containerEl) {
    return;
  }

  containerEl.removeEventListener('click', listener);
}

export { listenClickInsideContainer, removeClickInsideContainerListener };
