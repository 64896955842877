import router from '../router';

export default async function checkParticipantResultsAuthentication({
  next,
  to
}) {
  const eventIdFromRoute = window.location.host.split('.')[0];
  //   const resultsFromRoute = window.location.pathname.split('/')[2];
  const results_access = JSON.parse(
    localStorage.getItem(`participant_results`)
  );

  if (eventIdFromRoute) {
    if (to.name.startsWith('participants-admitted')) {
      if (!results_access) {
        return await router.replace({ name: 'login-participants-results' });
      }
    }
  }

  return next();
}
