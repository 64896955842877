import router from '@/router';
import { embeddableType, toEmbeddableUrl } from '@/utils';
import i18n from '@/i18n';
import { getApplicablePlatforms } from '@/utils';
import store from '../../store';

export const state = () => {
  return {
    streamUrl: null,
    SessionId: null,
    streamTitle: null,
    previousStreamUrl: null,
    previousStreamTitle: null,
    beingRedirectedFromOtherPage: false
  };
};

export const getters = {
  getEmbedType: (state) => embeddableType(state.streamUrl),
  getStreamUrl: (state) => state.streamUrl,
  getSessionId: (state) => state.sessionId,
  getHasStream: (state, getter) => !!getter.getEmbedUrl,
  getStreamTitle: (state) => state.streamTitle,
  getIframeAllowAttributes: (state, getter) => {
    switch (getter.getEmbedType) {
      case 'youtube':
      case 'youtube-embed':
        return 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture';

      case 'whereby':
        return 'camera; microphone; fullscreen; speaker; display-capture';

      case 'vimeo-embed':
      case 'vimeo':
        return 'autoplay; fullscreen';

      case 'flowvideo':
        return 'autoplay; fullscreen';
      default:
        return 'fullscreen'; // TODO: Or leave it blank...
    }
  },
  getEmbedUrl: (state) => {
    const streamUrl = state.streamUrl;
    return toEmbeddableUrl(streamUrl);
  },
  isWhereby: (state) => {
    return state.streamUrl ? state.streamUrl.includes('whereby') : false;
  },
  isBeingRedirectedFromOtherPage: (state) => state.beingRedirectedFromOtherPage
};

export const mutations = {
  setStreamTitle: (state, payload) => {
    state.previousStreamTitle = state.streamTitle;
    state.streamTitle = payload;
  },
  setStreamUrl: (state, payload) => {
    state.previousStreamUrl = state.streamUrl;
    state.streamUrl = payload;
  },
  setSessionId: (state, payload) => {
    state.sessionId = payload;
  },
  clearSessionId: (state) => {
    state.sessionId = null;
  },
  setBeingRedirectedFromOtherPage: (state, payload) => {
    state.beingRedirectedFromOtherPage = payload;
  }
};

export const actions = {
  async changeStreamUrl({ state, commit }, url) {
    commit('setStreamUrl', url);
  },

  async setStreamFromCurrentEvent({ dispatch, rootGetters, commit }) {
    // If this loads right onload, we have to "wait" for the data
    // TODO(28 jul. 2020) ~ Helge: Add a timeout of 5 seconds here
    const interval = setInterval(() => {
      const fullInfo = rootGetters['CurrentEvent/hasFullEventDetails'];

      if (fullInfo) {
        clearInterval(interval);
        router.replace({ session: null }).catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
        dispatch('setStreamFromEvent', rootGetters['CurrentEvent/getEvent']);
        commit('clearSessionId');
        dispatch('Timeline/loadTimelineData', null, { root: true });
      }

      const isLoadingDocuments = store.getters['Documents/isLoading'];
      if (!isLoadingDocuments) {
        dispatch('Documents/getDocuments', null, {
          root: true
        });
      }
    }, 100);
  },

  async setStreamFromEvent({ state, commit }, event) {
    commit('setStreamUrl', event.streamUrl);
    commit('setStreamTitle', i18n.t('Components.shared.Navbar.mainstage'));
  },

  async setStreamFromSession({ state, commit, dispatch }, session) {
    commit('setStreamUrl', session.liveUrl);
    commit('setSessionId', session.id);
    // dispatch('Timeline/agendaSessionloadTimelineData', session.SessionID, {
    //   root: true
    // });
    commit('setStreamTitle', session.title);

    await dispatch('Documents/getDocumentsBySessionId', session.id, {
      root: true
    });

    router
      .push({
        name: 'stage',
        query: {
          session: session.id
        }
      })
      .then(() => {
        if (session.liveUrl.includes('whereby')) {
          window.location.reload();
        }
      });
  },

  async setStreamFromMeetingRoom({ state, commit }, meetingRoom) {
    commit('setStreamUrl', meetingRoom.wherebyRoomURL);
    commit('setStreamTitle', meetingRoom.wherebyMeetingName);
  },

  async setStreamFromSponsor({ state, commit }, sponsor) {
    commit('setStreamUrl', sponsor.streamUrl);
    commit('setStreamTitle', sponsor.name);
  },

  async setStreamFromSessionId(
    { state, commit, rootGetters },
    { sessionId, fallback }
  ) {
    let session = rootGetters['Agendas/getSessionById'](sessionId);
    const interval = setInterval(() => {
      if (session) {
        clearInterval(interval);
        commit('setStreamUrl', session.liveUrl);
        commit('setStreamTitle', session.title);
      }

      session = rootGetters['Agendas/getSessionById'](sessionId);
      if (!session && rootGetters['Agendas/isSuccessfullyLoaded']) {
        clearInterval(interval);

        const loadedEventId = rootGetters['CurrentEvent/getId'];
        const loadedEventSlug = rootGetters['CurrentEvent/getEventSlug'];

        router.push({
          name: 'stage',
          params: { eventId: loadedEventSlug ?? loadedEventId }
        });
        if (fallback) {
          fallback();
        }
      }
    }, 100);
  },

  async goToStreamFromSession({ state, commit, dispatch }, session) {
    commit('setStreamUrl', session.liveUrl);
    commit('setSessionId', session.id);
    dispatch('Timeline/agendaSessionloadTimelineData', session.id, {
      root: true
    });
    commit('setStreamTitle', session.title);
    commit('setBeingRedirectedFromOtherPage', true);

    router.push({
      name: 'stage',
      query: {
        session: session.id
      }
    });
  },

  async setToPreviousStream({ state, commit }) {
    commit('setStreamUrl', state.previousStreamUrl);
    commit('setStreamTitle', state.previousStreamTitle);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
