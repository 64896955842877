<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <div class="p-10 flex flex-col h-full" :style="designStyles">
      <div class="h-full flex flex-col">
        <!-- Heading -->
        <div class="mb-4">
          <h3 class="text-md font-bold">
            {{ $t('Components.TermsOfServiceModal.title') }}
          </h3>
        </div>

        <!-- TOS text-->
        <div
          class="flex-1 overflow-y-scroll py-2 html-content"
          v-html="tos.termsText"
        />

        <!-- Close -->
        <div class="text-base flex justify-between items-center mt-8">
          <button
            class="button button-secondary"
            :aria-label="$t('Components.TermsOfServiceModal.disagree')"
            @click="onDeclined"
          >
            {{ $t('Components.TermsOfServiceModal.disagree') }}
          </button>

          <button
            class="button button-primary"
            :aria-label="$t('Components.TermsOfServiceModal.agree')"
            @click="onAgreed"
          >
            {{ $t('Components.TermsOfServiceModal.agree') }}
          </button>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
export default {
  name: 'TermsOfServiceModal',
  props: {
    tos: {
      type: Object,
      required: true
    }
  },
  methods: {
    onAgreed() {
      this.$emit('close', {
        agreed: true
      });
    },
    onDeclined() {
      this.$emit('close', {
        agreed: false
      });
    }
  }
};
</script>
