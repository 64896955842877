<script>
import { mapGetters, mapMutations } from 'vuex';
import { getPusherInstance } from '@/utils';
import _ from 'lodash';

export default {
  name: 'UsersDataProvider',
  created() {
    getPusherInstance().bind('online-users-change', (topic, data) => {
      this.updateOnlineUsers(data.additional_data);
    });
  },
  methods: {
    ...mapMutations('Users', ['updateOnlineUsers'])
  },
  computed: {
    ...mapGetters('Users', ['getUsers', 'isLoading', 'getFailed'])
  },
  render() {
    return this.$scopedSlots.default({
      users: this.getUsers,
      loading: this.isLoading,
      failed: this.failed
    });
  }
};
</script>
