var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex w-full items-center gap-x-4"},[_c('div',{staticClass:"min-w-1/5 w-1/5"},[_c('Avatar',{staticClass:"medium",attrs:{"image":_vm.user.Picture,"circle":true,"online-status":_vm.isUserOnline(_vm.user.UserID),"alt":_vm.$t('Views.Shared.UserPicture')},on:{"error":_vm.setAltImg}})],1),_c('div',{staticClass:"w-4/5 flex flex-col justify-center space-y-1"},[_c('div',{staticClass:"text-xs md:text-sm lg:text-base font-bold"},[_vm._v(" "+_vm._s(_vm.user.Firstname)+" "+_vm._s(_vm.user.Lastname)+" ")]),_c('div',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.user.Company))]),_c('div',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.user.Jobtitle))]),_c('div',{staticClass:"flex flex-row w-full space-x-2"},[_c('div',[(
                _vm.features.profileEmailVisible ||
                _vm.features.profilePhoneVisible ||
                _vm.user.About2 !== null
              )?_c('TappinButton',{staticClass:"button button-primary",attrs:{"aria-expanded":"true","aria-haspopup":"true","aria-label":"view more info","custom_caption":_vm.moreDetailsExpanded
                  ? _vm.$t('Views.Stage.Speakers.showLess')
                  : _vm.$t('Views.Stage.Participants.moreInfo')},on:{"click":_vm.toggleMoreDetails}}):_vm._e()],1),(_vm.isChatVisible)?_c('div',[(!_vm.isCurrentUser(_vm.user))?_c('TappinButton',{staticClass:"button button-secondary",attrs:{"icon":"comments","aria-label":"send message to this participant"},on:{"click":function($event){return _vm.createChat(_vm.user)}}}):_vm._e()],1):_vm._e()])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.moreDetailsExpanded),expression:"moreDetailsExpanded"}],ref:"childElement",attrs:{"id":"expanded-pane ","tabindex":"-1"}},[_c('ParticipantsModal',{attrs:{"participant":_vm.user}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"pb-8"},[_c('span',{staticClass:"font-bold text-xs",attrs:{"aria-label":"user's title"}},[_vm._v(" "+_vm._s(_vm.user.Title)+" ")]),_c('div',{staticClass:"pt-2 text-xs html-content",attrs:{"aria-label":"read more about participant"},domProps:{"innerHTML":_vm._s(_vm.user.About)}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }