<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Poll from '@/components/timeline/promoted/Poll';
import Info from '@/components/timeline/promoted/Info';
import { getPusherInstance } from '@/utils';
import _ from 'lodash';
import Speaker from '../timeline/promoted/Speaker.vue';
export default {
  name: 'TimelineDataProvider',
  props: {
    applicablePlatforms: {
      type: Array,
      default: () => ['digital']
    }
  },
  created() {
    this.loadTimelineData();
    getPusherInstance().bind('timeline-change', () => this.loadTimelineData());

    getPusherInstance().bind('timeline-modified', (topic, data) =>
      this.updateTimelineItem(data.additional_data)
    );

    getPusherInstance().bind('timeline-remove', (topic, data) =>
      this.removeTimelineItem(data.additional_data)
    );

    getPusherInstance().bind('timeline-add', (topic, data) =>
      this.addTimelineItem(data.additional_data)
    );

    getPusherInstance().bind('timeline-add-comment', (topic, data) =>
      this.addTimelineComment(data.additional_data)
    );

    getPusherInstance().bind('timeline-modified-comment', (topic, data) =>
      this.updateTimelineComment(data.additional_data)
    );

    getPusherInstance().bind('timeline-remove-comment', (topic, data) =>
      this.removeTimelineComment(data.additional_data)
    );

    getPusherInstance().bind('promoted-message-modified', (topic, data) =>
      this.updatePromotedMessage(data.additional_data)
    );

    getPusherInstance().bind('promoted-message-remove', (topic, data) =>
      this.removePromotedMessage(data.additional_data)
    );

    getPusherInstance().bind('promoted-message-add', (topic, data) =>
      this.addPromotedMessage(data.additional_data)
    );
  },
  methods: {
    ...mapActions('Timeline', [
      'loadTimelineData',
      'resetPromotedPage',
      'addTimelineItem',
      'updateTimelineItem',
      'addPromotedMessage',
      'updatePromotedMessage',
      'removePromotedMessage'
    ]),
    ...mapActions('Stream', ['setStreamFromSession']),
    ...mapMutations('Timeline', [
      'setMode',
      'resetMode',
      'addTimelineComment',
      'updateTimelineComment',
      'removeTimelineItem',
      'removeTimelineComment'
    ])
  },
  computed: {
    ...mapGetters('Timeline', [
      'getTimelineItems',
      'getPromotedTimelineItems',
      'getMode',
      'getPromotedPage'
    ]),
    getPromotedPageComponent() {
      if (!this.getPromotedPage.page) {
        return;
      }
      switch (this.getPromotedPage.page) {
        case 'poll':
        case 'quiz':
        case 'treasure_hunt':
        case 'coded_quizzes':
        case 'coded_polls':
          return Poll;
        case 'speaker':
          return Speaker;
        case 'info':
          return Info;
        case 'session':
          this.setStreamFromSession(this.getPromotedPage);
      }

      return null;
    }
  },
  render() {
    return this.$scopedSlots.default({
      timelineItems: this.getTimelineItems.filter(
        (i) =>
          _.intersection(this.applicablePlatforms, i.PlatformDisplayOptions)
            .length > 0
      ),
      promotedTimelineItems: this.getPromotedTimelineItems.filter(
        (i) =>
          _.intersection(this.applicablePlatforms, i.PlatformDisplayOptions)
            .length > 0
      ),
      mode: this.getMode,
      promotedPage: this.getPromotedPage,
      resetPromotedPage: this.resetPromotedPage,
      promotedPageComponent: this.getPromotedPageComponent
    });
  }
};
</script>
