<template>
  <CurrentEventDataProvider>
    <div
      v-if="setFootItems"
      class="footer sticky bottom-0 flex justify-evenly items-center text-center z-30 h-16 border-t border-grey"
    >
      <div
        v-for="footItem in lobbyElements"
        :key="footItem.type"
        class="caption flex flex-col items-center"
      >
        <i :class="footItem.iconClass" />

        <a :href="'#' + footItem.id" class="text-xxs caption__truncate">{{
          footItem.options.label
        }}</a>
      </div>

      <div
        v-if="isParticipantsShown"
        class="caption flex flex-col items-center"
      >
        <i class="fas fa-users fa-lg" />
        <a
          aria-haspopup="true"
          @click="showParticipantsList"
          class="text-xxs caption__truncate"
        >
          {{ participantsCaption }}
        </a>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>
<script>
import { mapGetters } from 'vuex';
import LobbyParticipants from '@/views/Stage/Participant/LobbyParticipants';

export default {
  name: 'LobbyFooter',
  data() {
    return {
      lobbyElements: []
    };
  },
  computed: {
    ...mapGetters('CurrentEvent', [
      'getLocale',
      'getEvent',
      'isWebappEnabled',
      'features'
    ]),
    mainStageCaption() {
      const event = this.getEvent;

      const defaultCaption = this.$t('Components.shared.Navbar.mainstage');

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'header'
      )?.options?.mainStageLabel;

      return mainStageCaption || defaultCaption;
    },
    informationCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.informationCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'information'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },
    speakersCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.speakersCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'speakers'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },
    agendaCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.programCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'agenda'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },
    participantsCaption() {
      const event = this.getEvent;

      const defaultCaption = this.$t(
        'Components.shared.Navbar.participantsList'
      );

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const participantsCaption = event.lobbyElements.find(
        (x) => x.id === 'header'
      )?.options?.participantsLabel;

      return participantsCaption || defaultCaption;
    },
    sponsorCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.sponsorsCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'sponsors'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },
    isInformationShown() {
      const event = this.getEvent;

      if (!event.lobbyElements) {
        return true;
      }

      return event.lobbyElements.some((x) => x.id === 'information');
    },
    isSpeakersShown() {
      const event = this.getEvent;

      if (!event.lobbyElements) {
        return event.features.lobbySpeakers;
      }

      return event.lobbyElements.some((x) => x.id === 'speakers');
    },
    isParticipantsShown() {
      const event = this.getEvent;

      if (!event.lobbyElements) {
        return event.features.stageTabParticipants;
      }

      return event.lobbyElements.find((x) => x.id === 'header')?.options
        ?.participantsEnabled;
    },
    isAgendaShown() {
      const event = this.getEvent;

      if (!event.lobbyElements) {
        return event.features.lobbyAgenda;
      }

      return event.lobbyElements.some((x) => x.id === 'agenda');
    }
  },
  methods: {
    setFootItems() {
      const iconMap = {
        information: 'fas fa-info-circle fa-lg',
        sponsors: 'fas fa-star fa-lg',
        agenda: 'fas fa-calendar-alt fa-lg',
        speakers: 'fas fa-microphone fa-lg'
      };
      this.lobbyElements = this.getEvent.lobbyElements
        .filter((a) => iconMap[a.id])
        .map((a) => {
          a.iconClass = iconMap[a.id];
          return a;
        });
    },
    showParticipantsList() {
      document.body.classList.add('overflow-hidden');
      this.$modal.show(
        LobbyParticipants,
        {},
        {
          clickToClose: true,
          adaptive: true,
          minWidth: 320,
          maxWidth: 800,
          width: '80%',
          height: 'auto'
        },
        {
          opened: () => this.$root.$emit('focusChild'),
          closed: () => document.body.classList.remove('overflow-hidden')
        }
      );
      this.closeMobileMenu();
    }
  },
  mounted() {
    this.setFootItems();
  }
};
</script>
<style lang="scss" scoped>
.footer {
  background-color: var(--navbar-background-color);
  position: fixed;
  min-width: 100vw;
}
.caption {
  width: 4.5rem;
}
.caption__truncate {
  width: 90%;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
</style>
