<template>
  <div class="table-container shadow-lg">
    <table class="mx-auto table-auto w-full">
      <thead class="sticky top-0 bg-white">
        <tr class="text-left shadow-md">
          <th class="w-1/4 p-4 md:p-8 text-xs md:text-base">
            {{ $t('Views.PollResults.name') }}
          </th>
          <th class="w-1/4 p-4 md:p-8 text-xs md:text-base">
            {{ $t('Views.PollResults.lastName') }}
          </th>
          <th class="w-1/4 p-4 md:p-8 text-xs md:text-base">
            {{ $t('Views.PollResults.email') }}
          </th>
          <th class="w-1/4 p-4 md:p-8 text-xs md:text-base">
            {{ $t('Views.PollResults.answers') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, i) in filteredUsers" :key="i" class="border">
          <td class="p-4 md:p-8 text-xs md:text-base">{{ user.name }}</td>
          <td class="p-4 md:p-8 text-xs md:text-base">{{ user.lastName }}</td>
          <td class="p-4 md:p-8 text-xs md:text-base">{{ user.email }}</td>
          <td class="p-4 md:p-8 text-xs md:text-base">{{ user.label }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ResultsTable',
  props: {
    data: []
  },
  computed: {
    filteredUsers() {
      return this.data.filter((user) => user.label);
    }
  }
};
</script>

<style scoped lang="scss">
:root {
  --sidebar-height: 100%;
}

.table-container {
  overflow-y: auto;
  height: calc(100vh - 17em);
}
</style>
