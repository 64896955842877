const pollType = {
  MULTIPLE_ANSWERS: 0,
  OPEN_TEXT: 1,
  QUIZ: 2,
  TREASURE_HUNT: 3,
  CODED_POLL: 4,
  CODED_QUIZ: 5,
  RATING: 10
};

const resultsPageType = {
  OPEN_TEXT: 1,
  QUIZ_LIST: 2,
  RESULTS: 3,
  MULTIPLE_ANSWERS: 4
};

export { pollType, resultsPageType };
