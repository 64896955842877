<template>
  <div class="p-10 flex flex-col h-full">
    <div class="h-full flex flex-col">
      <!-- Heading -->
      <div class="mb-4">
        <h3 class="text-lg font-bold">
          {{ document.Name }}
        </h3>
      </div>

      <!-- Embed -->
      <iframe
        class="w-full h-full flex-1 my-4"
        :src="document.DocumentUrlPreview || document.Documentfile"
        frameborder="0"
      />

      <!-- Close -->
      <div class="text-base">
        <button
          class="bg-dark text-white py-1 px-3 rounded text-base"
          aria-label="close"
          @click="$emit('close')"
        >
          {{ $t('Components.shared.document.close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentPreview',
  props: {
    document: {
      required: true,
      type: Object
    }
  }
};
</script>
