<template>
  <div>
    <!-- Image preview-->
    <div class="flex flex-row flex-wrap">
      <div v-for="image in images" :key="image.previewUrl">
        <ImagePreview
          :image="image.previewUrl"
          @click="onImageUnselect(image.uniqueIndex)"
        />
      </div>
    </div>
    <!-- Form image and caption upload-->
    <form
      class="flex flex-col max-h-max pl-2"
      style="box-shadow: 0 -5px 20px -10px rgba(0, 0, 0, 0.3)"
      @submit.prevent="onSubmitTimelineEntry"
      @drop.prevent="onDropImage"
      @dragover="dragover"
      @dragleave="dragleave"
    >
      <!-- Form image and caption upload-->
      <div
        class="flex flex-row w-full h-full items-center justify-center"
        @click="onClickMessageContainer"
      >
        <div class="mx-4 pr-4 border-r-4 border-grey">
          <label for="image-upload" class="custom-file-upload hover:opacity-50">
            <i class="far fa-images fa-lg"></i>
          </label>
        </div>
        <div class="flex flex-col w-full">
          <input
            ref="message"
            v-model="message"
            type="text"
            :placeholder="$t('Views.Stage.Timeline.writeAMessage')"
            class="bg-white text-black text-base placeholder-dark border-none focus:ring-transparent focus:shadow-none"
          />
          <input
            id="image-upload"
            ref="inputImage"
            type="file"
            accept="image/*"
            @change="onImageChange"
            multiple
          />
        </div>
        <button
          v-tippy="{ placement: 'right', arrow: true }"
          type="submit"
          class="button w-16 h-20 hover:opacity-50 flex items-center justify-center"
          :content="$t('Views.Stage.Timeline.send')"
          aria-label="send post"
          :disabled="isSendDisabled"
        >
          <i class="fal fa-paper-plane" aria-hidden="true" />
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import ImagePreview from '@/components/shared/ImagePreview';
import { createAnalyticsEvent } from '@/helpers/ga-helper';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TimelinePost',
  components: {
    ImagePreview
  },
  data() {
    return {
      message: null,
      isSendDisabled: false,
      images: [],
      uniqueIndex: 0
    };
  },
  computed: {
    ...mapGetters('CurrentEvent', ['getId'])
  },
  methods: {
    ...mapActions('Timeline', [
      'addTimelineEntry',
      'addAgendaSessionTimelineEntry',
      'setPromotedPage'
    ]),
    onSubmitTimelineEntry() {
      createAnalyticsEvent(
        this.$gtag,
        this.images && this.images.length > 0 ? 'PostImage' : 'Post',
        'Timeline',
        this.getId
      );
      this.isSendDisabled = true;
      this.addTimelineEntry({
        message: this.message,
        files: this.images.map((x) => x.file)
      }).then(() => {
        this.message = null;
        this.images = [];
        setTimeout(() => {
          this.isSendDisabled = false;
        }, 1500);
      });
    },
    onSubmitAgendaSessionTimelineEntry() {
      this.addAgendaSessionTimelineEntry({
        SessionId: this.SessionId,
        message: this.message
      }).then(() => {
        this.message = null;
      });
    },
    // Deselect/remove image from preview before posting to timeline
    onImageUnselect(e) {
      var index = this.images
        .map((file) => {
          return file.uniqueIndex;
        })
        .indexOf(e);
      this.images.splice(index, 1);
    },
    dragover(event) {
      event.preventDefault();
      // To show user can drop its files
      if (!event.currentTarget.classList.contains('dropzone-area')) {
        event.currentTarget.classList.add('dropzone-area');
      }
    },
    dragleave(event) {
      // Clean drop zone css
      event.currentTarget.classList.remove('dropzone-area');
    },
    onDropImage(e) {
      if (e.dataTransfer.files.length > 8) {
        e.preventDefault();
        alert('Cannot upload more than 8 images at once');
        e.currentTarget.classList.remove('dropzone-area');
        return;
      } else {
        var imageTypes = [
          'image/png',
          'image/gif',
          'image/bmp',
          'image/jpeg',
          'image/webp'
        ];
        if (e.dataTransfer && e.dataTransfer.files) {
          var fileType = e.dataTransfer.files[0].type;
          if (imageTypes.includes(fileType)) {
            this.images = [
              ...this.images,
              ...[...e.dataTransfer.files].map((file) => ({
                file,
                previewUrl: URL.createObjectURL(file),
                uniqueIndex: this.uniqueIndex++
              }))
            ];
            e.currentTarget.classList.remove('dropzone-area');
          } else {
            e.preventDefault();
            alert(
              'Dropped file is not an image, please upload image with formats .png, .jpeg, .webp, .gif or .bmp'
            );
            e.currentTarget.classList.remove('dropzone-area');
            return;
          }
        }
      }
    },
    onImageChange(e) {
      if (e.target.files.length > 8) {
        e.preventDefault();
        alert('Cannot upload more than 8 images at once');
        return;
      } else {
        this.images = [
          ...this.images,
          ...[...e.target.files].map((file) => ({
            file,
            previewUrl: URL.createObjectURL(file),
            uniqueIndex: this.uniqueIndex++
          }))
        ];
      }
    },
    onClickMessageContainer() {
      this.$refs.message.focus();
    }
  }
};
</script>
<style lang="scss" scoped>
.dropzone-area {
  position: relative;
  border: 2px dashed #2e94c4;
}

input[type='file'] {
  display: none;
}
</style>
