<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <div class="flex flex-wrap flex-col h-screen w-full" :style="designStyles">
      <!-- Close -->
      <div class="absolute top-0 right-0">
        <button class="button" @click="endCall()">
          <i class="far fa-times fa-lg" />
        </button>
      </div>
      <!-- content-->
      <div class="flex flex-col">
        <div>
          <img
            class="object-contain"
            src="https://media.istockphoto.com/videos/happy-man-looking-at-camera-talking-video-calling-webcam-view-video-id1170845338?s=640x640"
          />
        </div>
        <div>
          <img
            class="object-contain"
            src="https://img.freepik.com/free-photo/portrait-cheerful-attractive-young-woman-longsleeve-standing-with-arms-crossed-smiling_295783-39.jpg?size=626&ext=jpg&ga=GA1.1.1623233742.1618963200"
          />
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'VideoCallModal',
  methods: {
    ...mapMutations('Chat', ['setCallEnded', 'setInCall']),
    endCall() {
      this.setInCall(false);
      this.setCallEnded(true);
    }
  }
};
</script>

<style scoped></style>
