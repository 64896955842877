<template>
  <div class="mx-4 md:mx-20 lg:mx-0 px-4 mb-2">
    <component :is="component" v-if="component" :item="item" />
    <div v-else>
      <div><!-- This item cannot be viewed --></div>
    </div>
  </div>
</template>
<script>
import TimelineUserItem from './TimelineUserItem';
import TimelinePromotedItem from './TimelinePromotedItem';

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    component() {
      // TODO(28 jul. 2020) ~ Helge: Double check this, might be enough with an isPromo check
      if (this.item.LinkMode === 'CONTAINER' && this.item.isPromo === '0') {
        return TimelineUserItem;
      }

      if (this.item.isPromo === '1') {
        return TimelinePromotedItem;
      }

      return null;
    }
  }
};
</script>
