import router from '@/router';

export const state = () => {
  return {
    currentTab: null
  };
};

export const getters = {
  getCurrentTab: (state) => {
    return state.currentTab;
  }
};

export const mutations = {
  setCurrentTab: (state, payload) => {
    state.currentTab = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
