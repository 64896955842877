<template>
  <div v-if="!loading" class="p-4 space-y-4">
    <Button
      class="button-primary cursor-pointer hover:opacity-50 transition-all duration-200 text-dark font-bold space-x-2 text-sm lg:text-base py-2 px-4 border rounded"
      aria-label="close"
      @click="handleClose"
    >
      <i class="fa fa-chevron-left mr-2" aria-hidden="true" />
      {{ $t('Components.Timeline.Promoted.Info.back') }}
    </Button>
    <h2 class="text-md font-bold">{{ page.Heading }}</h2>
    <div class="mt-5 text-base page html-content" v-html="page.Tekst" />
    <Button
      class="button-primary cursor-pointer hover:opacity-50 transition-all duration-200 text-dark font-bold space-x-2 text-sm lg:text-base py-2 px-4 border rounded"
      aria-label="close"
      @click="handleClose"
    >
      {{ $t('Components.Timeline.Promoted.Info.close') }}
    </Button>
  </div>
  <Spinner v-else />
</template>

<script>
import ApiClient from '@/client';
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/shared/Spinner.vue';
import { replaceTokens } from '@/utils';

export default {
  name: 'Info',
  components: {
    Spinner
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      page: {},
      loading: true
    };
  },
  created() {
    ApiClient.getPage(this.id).then((response) => {
      response.data = replaceTokens(response.data, this.getUser, 'Tekst');
      this.page = response.data;
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters('Authentication', ['getUser'])
  },
  methods: {
    ...mapActions('Timeline', { resetTimeline: 'resetPromotedPage' }),
    ...mapActions('Info', { resetInfoPage: 'resetPromotedPage' }),
    handleClose() {
      this.resetInfoPage(), this.resetTimeline();
    }
  }
};
</script>
