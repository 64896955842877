<script>
import { mapActions } from 'vuex';
import Vue from 'vue';

export default {
  name: 'CollapsibleItem',
  inject: ['collapsibleListState'],
  props: ['itemId', 'agenda'],
  data() {
    return {
      showAgenda:
        this.agenda &&
        this.agenda.sessions &&
        Object.keys(this.agenda.sessions).length === 1
    };
  },
  computed: {
    active() {
      return this.showAgenda
        ? this.showAgenda
        : this.collapsibleListState.activeItem === this.itemId;
    }
  },
  methods: {
    ...mapActions('Timeline', {
      timelineResetPromotedPage: 'resetPromotedPage'
    }),
    ...mapActions('Info', {
      pagesResetPromotedPage: 'resetPromotedPage'
    }),
    resetPromotedPage() {
      this.timelineResetPromotedPage();
      this.pagesResetPromotedPage();
    },
    toggle() {
      this.showAgenda
        ? (this.showAgenda = !this.showAgenda)
        : (this.collapsibleListState.activeItem = this.active
            ? null
            : this.itemId);
      this.$root.$emit('focusInsideElement');
    }
  },
  render() {
    return this.$scopedSlots.default({
      active: this.active,
      toggle: this.toggle,
      toggleInfoPage: this.resetPromotedPage
    });
  }
};
</script>
