import ApiClient from '../../client';
import { createNamespacedDataProviderStore, extendStore } from './dataprovider';

const store = createNamespacedDataProviderStore((setItems) => {
  return ApiClient.getGroups().then((response) => {
    setItems(response.data);
  });
});

export default extendStore(store, {
  state: {
    filteredItems: []
  },
  getters: {
    getFilteredGroups: (state) => {
      return state.filteredItems;
    }
  },
  mutations: {
    setFilteredGroups: (state, payload) => {
      const filteredGroups = state.items.filter((g) =>
        g.name.toLowerCase().includes(payload.toLowerCase())
      );
      state.filteredItems = filteredGroups;
    }
  },
  actions: {
    filterGroups({ commit }, payload) {
      commit('setFilteredGroups', payload);
    }
  }
});
