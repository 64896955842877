<template>
  <CurrentEventDataProvider>
    <div
      class="absolute top-0 bottom-0 w-full overflow-y-auto over flex flex-col z-10l"
      :class="[isTimelinePostEnabled ? 'timeline-content ' : '']"
      @dragover.prevent
      @drop.prevent
    >
      <TimelineDataProvider
        v-slot="{
          timelineItems,
          promotedTimelineItems,
          mode,
          promotedPage,
          promotedPageComponent
        }"
        :applicablePlatforms="applicablePlatform"
      >
        <div>
          <transition name="fade">
            <!-- Timeline -->
            <div v-if="mode === 'timeline'">
              <!-- Promoted Timeline Items -->
              <TimelineItem
                v-for="item in promotedTimelineItems"
                :key="item.TimelineID"
                :item="item"
                tabindex="0"
              />
              <!-- Other Timeline Items -->
              <TimelineItem
                v-for="item in timelineItems"
                :key="item.TimelineID"
                :item="item"
              />
              <!-- Add timeline post -->
              <TimelinePost
                v-if="isTimelinePostEnabled"
                :class="'bottom-0 fixed max-h-max w-full bg-white'"
              />
            </div>

            <!-- Promo page -->
            <div v-if="mode === 'promo'">
              <component
                :is="promotedPageComponent"
                :id="promotedPage.id"
                container="Timeline"
              />
            </div>
          </transition>
        </div>
      </TimelineDataProvider>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import { getApplicablePlatforms } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import TimelineItem from '@/components/timeline/TimelineItem';
import TimelinePost from '@/components/timeline/TimelinePost';
import {
  listenClickInsideContainer,
  removeClickInsideContainerListener
} from '@/helpers/content-event-helpers';

export default {
  name: 'Timeline',
  components: {
    TimelineItem,
    TimelinePost
  },
  props: {
    active: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return { containerClickEvent: null };
  },
  mounted() {
    this.addListener();
  },
  beforeDestroy() {
    this.removeListener();
  },
  computed: {
    ...mapGetters('CurrentEvent', ['features']),
    ...mapGetters({
      SessionId: 'Stream/getSessionId'
    }),
    applicablePlatform() {
      return getApplicablePlatforms(this.$route);
    },
    isTimelinePostEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppTimelinePosting
        : this.features.stageFuncTimelinePosting;
    }
  },
  methods: {
    ...mapActions('Timeline', ['setPromotedPage']),
    addListener() {
      this.containerClickEvent = listenClickInsideContainer(
        document.getElementsByClassName('sidebar-content')[0],
        this.setPromotedPage,
        this.$gtag,
        this.handleLinks
      );
    },
    removeListener() {
      removeClickInsideContainerListener(
        document.getElementsByClassName('sidebar-content')[0],
        this.containerClickEvent
      );
    },
    handleLinks(e) {
      this.$root.$emit('focus-tab', e);
    }
  },
  watch: {
    active(newActive, oldActive) {
      if (newActive && !oldActive) {
        this.addListener();
      }

      if (oldActive && !newActive) {
        this.removeListener();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.timeline-content {
  padding-bottom: 5rem;
}

::v-deep .html-content > p:has(iframe) {
  position: relative;
  padding-top: 56.25%;
}
::v-deep .html-content > p > iframe {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
}
</style>
