<script>
export default {
  props: {
    value: {
      required: true,
      type: String
    },
    format: {
      required: true,
      type: String
    },
    isOffset: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  created() {
    // This monstrosity forces this component to rerender if the locale changes,
    // this is a workaround for the fact that the moment filter is not reactive and does not update when the locale changes
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'CurrentEvent/setLocale') {
        this.$forceUpdate();
      }
    });
  },
  render() {
    const date = this.$moment(this.value);
    const localOffset = -(new Date().getTimezoneOffset() / 60);

    // This is a workaround for a bug in the API where some dates
    // are returned without taking timezone into account or without UTZ format,
    // meaning they will be shifted by the local timezone offset.
    // So this will re-shift the date back to the correct one
    if (this.isOffset && localOffset !== 0) {
      date.add(localOffset, 'hours');
    }

    // This filter is defined in /moment.js
    return this._v(this.$options.filters.moment(date, this.format));
  }
};
</script>
