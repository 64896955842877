<template>
  <apexchart :options="chartOptions" :series="series" height="100%" />
</template>

<script>
export default {
  props: {
    poll: Array,
    allVoteCount: Number
  },
  data: function () {
    return {
      noData: {
        text: 'Loading...'
      }
    };
  },
  computed: {
    series: function () {
      return [{ data: this.$props.poll.map((vote) => vote.count) }];
    },
    chartOptions: function () {
      return {
        labels: this.$props.poll.map((vote) => vote.label),
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        grid: {
          show: false
        },
        tooltip: {
          enabled: false
        },
        colors: this.$props.poll.map((vote, i) => vote.color ?? '#008FFB'),
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            barHeight: '80%',
            borderRadius: 4,
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'top'
            }
          }
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        dataLabels: {
          textAnchor: 'start',
          enabled: true,
          offsetX: 36,
          style: {
            fontSize: '20px',
            fontFamily: 'Arial',
            fontWeight: 'normal',
            colors: this.$props.poll.map((vote, i) => vote.color ?? '#707070')
          }
        },
        xaxis: {
          categories: this.$props.poll.map((vote) => vote.label),
          labels: {
            show: false,
            maxHeight: 40
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          max: this.$props.allVoteCount + 1,
          labels: {
            maxWidth: 560,
            style: {
              fontSize: '14px',
              fontFamily: 'Arial'
            },
            formatter: (value, index) => {
              const valuePercentage = (
                (this.series[0].data[index?.dataPointIndex] /
                  this.$props.allVoteCount) *
                100
              ).toFixed(1);
              return isNaN(valuePercentage)
                ? `${value} (0.00%)`
                : `${value} (${valuePercentage}%)`;
            }
          }
        }
      };
    }
  }
};
</script>
