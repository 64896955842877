var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CurrentEventDataProvider',[_c('div',{staticClass:"absolute top-0 bottom-0 w-full overflow-y-auto over flex flex-col z-10l",class:[_vm.isTimelinePostEnabled ? 'timeline-content ' : ''],on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();}}},[_c('TimelineDataProvider',{attrs:{"applicablePlatforms":_vm.applicablePlatform},scopedSlots:_vm._u([{key:"default",fn:function({
        timelineItems,
        promotedTimelineItems,
        mode,
        promotedPage,
        promotedPageComponent
      }){return [_c('div',[_c('transition',{attrs:{"name":"fade"}},[(mode === 'timeline')?_c('div',[_vm._l((promotedTimelineItems),function(item){return _c('TimelineItem',{key:item.TimelineID,attrs:{"item":item,"tabindex":"0"}})}),_vm._l((timelineItems),function(item){return _c('TimelineItem',{key:item.TimelineID,attrs:{"item":item}})}),(_vm.isTimelinePostEnabled)?_c('TimelinePost',{class:'bottom-0 fixed max-h-max w-full bg-white'}):_vm._e()],2):_vm._e(),(mode === 'promo')?_c('div',[_c(promotedPageComponent,{tag:"component",attrs:{"id":promotedPage.id,"container":"Timeline"}})],1):_vm._e()])],1)]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }