<template>
  <div class="flex justify-between">
    <!-- Close -->
    <div>
      <button
        class="button button-secondary"
        aria-label="close"
        v-if="hasClose"
        @click="resetPromotedPage"
      >
        {{ $t('Components.Timeline.Promoted.Poll.close') }}
      </button>
    </div>
    <div class="flex justify-between space-x-2">
      <button
        class="button button-secondary"
        v-if="hasPrevious"
        @click="previous"
      >
        <i class="fa fa-arrow-left" />
        {{ $t('Components.PollActions.previous') }}
      </button>
      <button class="button button-secondary" v-if="hasNext" @click="next">
        {{ $t('Components.PollActions.next') }}
        <i class="fa fa-arrow-right" />
      </button>
    </div>
    <!-- Save -->
    <div v-if="submit && !hasNext">
      <button
        class="button button-primary"
        aria-label="send in"
        type="submit"
        :disabled="disabled"
        @click="submit"
      >
        {{ $t('Components.Timeline.Promoted.Poll.sendIn') }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'PollActions',
  props: {
    disabled: {
      type: Boolean,
      required: false
    },
    hasPrevious: {
      type: Boolean,
      required: false
    },
    hasClose: {
      type: Boolean,
      required: false
    },
    hasNext: {
      type: Boolean,
      required: false
    },
    previous: {
      type: Function,
      required: false
    },
    next: {
      type: Function,
      required: false
    },
    submit: {
      type: Function,
      required: false
    }
  },

  methods: {
    ...mapActions('Timeline', {
      timelineResetPromotedPage: 'resetPromotedPage'
    }),
    ...mapActions('Info', {
      pagesResetPromotedPage: 'resetPromotedPage'
    }),
    resetPromotedPage() {
      this.timelineResetPromotedPage();
      this.pagesResetPromotedPage();
      this.$emit('close', false);
    }
  }
};
</script>
