<template>
  <CurrentEventDataProvider>
    <div class="flex" :class="activeDocs ? 'flex-col mt-2' : 'flex-wrap'">
      <div
        v-if="isDocumentsEnabledBySession(session)"
        class="button border border-primary-bg text-primary-bg"
        :class="activeDocs ? 'w-1/3' : ''"
        aria-label="Learn more about session"
        :aria-expanded="[activeDocs ? 'true' : 'false']"
        aria-controls="expanded-panel"
        @click="toggleDocs"
      >
        {{
          activeDocs
            ? $t('Views.Stage.Agenda.AgendaDocuments.hideDocs')
            : $t('Views.Stage.Agenda.AgendaDocuments.showDocs')
        }}
      </div>
      <div v-if="activeDocs" id="expanded-panel">
        <div
          ref="childElement"
          v-if="session && isDocumentsEnabledBySession(session)"
          class="flex flex-row flex-wrap p-2"
        >
          <div
            v-for="document in getDocumentsBySessionId(session?.id)"
            :key="document.DocumentID"
            class="flex items-center w-full mb-2"
          >
            <div class="w-3/5">
              <p class="text-xs">
                <Moment :value="document.uTime" format="DD.MM.YYYY" />
                <br />
              </p>
              <h4 class="font-bold text-xs">
                {{ document.Name }}
              </h4>
            </div>
            <div class="flex w-2/5 space-x-2">
              <div
                v-if="canPreview(document) && isDesktop()"
                class="button button-secondary text-xs"
                aria-expanded="true"
                aria-haspopup="true"
                :aria-label="$t('Views.Stage.Agenda.AgendaDocuments.show')"
                @click="onClickPreview(document)"
              >
                {{ $t('Views.Stage.Agenda.AgendaDocuments.show') }}
              </div>
              <div
                class="button button-primary text-xs"
                :aria-label="$t('Views.Stage.Agenda.AgendaDocuments.download')"
              >
                <a
                  :href="document.Documentfile"
                  target="_blank"
                  @click="onClickDownload(document)"
                  download
                >
                  {{ $t('Views.Stage.Agenda.AgendaDocuments.download') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>
<script>
import { getApplicablePlatforms } from '@/utils';
import { documentPreviewMixin } from '@/mixins';
import { mapGetters } from 'vuex';
import { createAnalyticsEvent } from '@/helpers/ga-helper';

export default {
  name: 'AgendaDocuments',
  props: ['session'],
  data() {
    return {
      document: undefined,
      activeDocs: false
    };
  },
  methods: {
    onClickDownload(doc) {
      createAnalyticsEvent(
        this.$gtag,
        'Download',
        'ProgramDocument',
        doc.DocumentID
      );
    },
    onClickPreview(doc) {
      createAnalyticsEvent(
        this.$gtag,
        'Preview',
        'ProgramDocument',
        doc.DocumentID
      );
      this.openPreview(doc);
    },
    isDesktop() {
      return window.screen.width > 1024;
    },
    toggleDocs() {
      this.activeDocs = !this.activeDocs;
      // Emit the current value of activeDocs to the parent
      this.$emit('update:activeDocs', this.activeDocs);
    }
  },
  mixins: [documentPreviewMixin],
  computed: {
    ...mapGetters('CurrentEvent', ['features', 'getDesignStyles']),
    ...mapGetters('Documents', { getRawDocuments: 'getItems' }),
    getDocuments() {
      return this.getRawDocuments?.filter((x) =>
        x.PlatformDisplayOptions?.includes('digital')
      );
    },
    getWebappDocuments() {
      return this.getRawDocuments?.filter((x) =>
        x.PlatformDisplayOptions?.includes('webApp')
      );
    },
    getDocumentsBySessionId() {
      return (sessionId) => {
        if (getApplicablePlatforms(this.$route).includes('webApp')) {
          const documents = this.getWebappDocuments.filter(
            (d) => d.sessions && d.sessions.includes(sessionId)
          );
          return documents;
        } else {
          const documents = this.getDocuments.filter(
            (d) => d.sessions && d.sessions.includes(sessionId)
          );
          return documents;
        }
      };
    },
    isDocumentsEnabledBySession() {
      return (session) => {
        if (session?.docIds) {
          if (getApplicablePlatforms(this.$route).includes('webApp')) {
            return (
              this.features.webAppNavigationDocuments &&
              this.getWebappDocuments.length > 0 &&
              this.getWebappDocuments.some(
                (d) =>
                  d.sessions &&
                  d.sessions.length > 0 &&
                  d.sessions.includes(session.id)
              )
            );
          } else {
            return (
              this.features.stageTabDocuments &&
              this.getDocuments.length > 0 &&
              this.getDocuments.some(
                (d) =>
                  d.sessions &&
                  d.sessions.length > 0 &&
                  d.sessions.includes(session.id)
              )
            );
          }
        }

        return false;
      };
    }
  }
};
</script>
