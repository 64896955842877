<template>
  <PollDataProvider :container="container" v-slot="{ promotedPage }">
    <div class="p-4">
      <div v-if="loading">
        <i class="fal fa-spinner spin fa-2xl" aria-hidden="true" />
      </div>
      <!-- Thanks for submission -->
      <div v-if="!loading && (submitted === true || hasAlreadyAnsweredPoll)">
        <div
          v-if="
            isQuiz || isTreasureHunt || isTemplateQuiz || isTemplateTreasureHunt
          "
        >
          <h3 class="font-bold text-md mb-2">
            {{ $t('Components.Timeline.Promoted.Poll.thanksForAnswer') }}
          </h3>
        </div>
        <div v-if="isFreeTextPoll">
          <h3 class="font-bold text-md mb-2">
            {{ $t('Components.Timeline.Promoted.Poll.thanksForAnswer') }}
          </h3>
          <div class="text-base">
            {{ $t('Components.Timeline.Promoted.Poll.questionSendIn') }}
          </div>
          <div
            class="text-sm font-bold my-2"
            v-text="freeTextAnswer || freeText.Label"
          />
        </div>
        <div v-if="isMultiChoicePoll || isRatingPoll">
          <h3 class="font-bold text-md mb-2">
            {{ $t('Components.Timeline.Promoted.Poll.thanksForAnswer') }}
          </h3>
          <div class="text-sm">
            {{ $t('Components.Timeline.Promoted.Poll.answerSendIn') }}
          </div>
        </div>
        <!--Poll action-->
        <PollActions hasClose :disabled="submitted" @close="close" />
      </div>
      <div v-if="!loading && submitted === false">
        <!-- Polls/Quiz -->
        <SinglePoll
          v-if="promotedPage.page === 'poll'"
          :id="id"
          :poll="poll"
          :submit="submitPollAnswer"
          :close="close"
        />
        <!-- Polls/Quiz -->
        <QuizPoll
          v-if="
            promotedPage.page === 'quiz' ||
            promotedPage.page === 'treasure_hunt'
          "
          :quiz="poll"
          :submit="submitPollAnswer"
          :close="close"
        />
        <CodedPoll
          v-if="
            promotedPage.page === 'coded_quizzes' ||
            promotedPage.page === 'coded_polls'
          "
          :submit="submitPollAnswer"
          @codePoll="sendPollCode"
          :poll="poll"
          :showQuiz="showQuiz"
          :showPoll="showPoll"
          :errorInputCode="errorInputCode"
          :pollType="getPollType(promotedPage)"
          :pollName="pollName"
          :showInputsCodedPoll="showInputsCodedPoll"
        />
      </div>
    </div>
  </PollDataProvider>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiClient from '@/client';
import SinglePoll from './SinglePoll.vue';
import QuizPoll from './QuizPoll.vue';
import PollActions from '@/components/shared/PollActions';
import { pollType } from '@/constants/poll-type-constants';
import { replaceTokens } from '@/utils';
import CodedPoll from '@/components/shared/CodedPoll.vue';
import { createAnalyticsEvent } from '@/helpers/ga-helper';

export default {
  name: 'Poll',
  components: {
    SinglePoll,
    QuizPoll,
    PollActions,
    CodedPoll
  },
  props: {
    id: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: false
    },
    container: {
      type: String,
      required: true
    },
    close: {
      type: Function,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      poll: {},
      loading: true,
      submitted: undefined,
      freeTextAnswer: '',
      showQuiz: false,
      showPoll: false,
      showInputsCodedPoll: true,
      errorInputCode: '',
      pollType: '',
      pollName: ''
    };
  },
  mounted() {
    if (this.id === 'undefined') {
      this.submitted = this.poll && (this.poll.isAnswered ?? false);
      this.loading = false;
      return;
    }
    if (!this.item) {
      ApiClient.getPoll(this.id, false).then((response) => {
        this.assignPoll(response.data);
      });
    } else {
      this.assignPoll(this.item);
    }
  },
  computed: {
    ...mapGetters('Authentication', ['getUser']),
    isMultiChoicePoll() {
      return this.poll.questionType === pollType.MULTIPLE_ANSWERS;
    },
    isRatingPoll() {
      return this.poll.questionType === pollType.RATING;
    },
    isFreeTextPoll() {
      return this.poll.questionType === pollType.OPEN_TEXT;
    },
    isQuiz() {
      return this.poll.questionType === pollType.QUIZ && !this.poll.templateId;
    },
    isTemplateQuiz() {
      return this.poll.questionType === pollType.QUIZ && !!this.poll.templateId;
    },
    isTemplateTreasureHunt() {
      return (
        this.poll.questionType === pollType.TREASURE_HUNT &&
        !!this.poll.templateId
      );
    },
    isTreasureHunt() {
      return (
        this.poll.questionType === pollType.TREASURE_HUNT &&
        !this.poll.templateId
      );
    },
    hasAlreadyAnsweredPoll() {
      return !!this.poll?.selectedAnswer;
    },
    freeText() {
      return this.poll.votes.find((v) => v.AnsweredBy === this.getUser.UserID);
    }
  },
  methods: {
    getPollType(e) {
      switch (e.page) {
        case 'coded_polls':
          this.pollType = pollType.CODED_POLL;
          this.pollName = 'Poll';
          break;
        case 'coded_quizzes':
          this.pollType = pollType.CODED_QUIZ;
          this.pollName = 'Quiz';
          break;
        default:
          return 'Poll';
      }
    },
    sendPollCode(e) {
      ApiClient.getPoll(e, true).then((response) => {
        if (!response.data) {
          this.errorInputCode = 'Incorrect code';
        }
        if (response.data) {
          this.errorInputCode = '';

          if (this.pollType === pollType.CODED_POLL) {
            if (
              response.data.pollType === pollType.OPEN_TEXT ||
              response.data.pollType === pollType.MULTIPLE_ANSWERS
            ) {
              this.poll = response.data;
              this.showPoll = true;
              this.loading = false;
              this.showInputsCodedPoll = false;
              this.submitted = this.poll && (this.poll.isAnswered ?? false);
            } else {
              this.errorInputCode = 'Incorrect poll code';
            }
          }
          if (this.pollType === pollType.CODED_QUIZ) {
            if (
              response.data.pollType === pollType.QUIZ ||
              response.data.pollType === pollType.TREASURE_HUNT
            ) {
              this.poll = response.data;
              this.showQuiz = true;
              this.showInputsCodedPoll = false;
              this.submitted = this.poll && (this.poll.isAnswered ?? false);
              this.loading = false;
            } else {
              this.errorInputCode = 'Incorrect quiz code';
            }
          }
        }
      });
    },
    assignPoll(poll) {
      poll.description = replaceTokens(poll.description, this.getUser);
      if (poll.items) {
        for (let index = 0; index < poll.items.length; index++) {
          const item = poll.items[index];
          item.poll.description = replaceTokens(
            item.poll.description,
            this.getUser
          );
        }
      }
      this.poll = poll;
      this.submitted = this.poll && (this.poll.isAnswered ?? false);
      this.loading = false;
    },
    submitMultiChoiceAnswer(e) {
      const alternatives = [e.selectedAlternative.text];
      ApiClient.submitMultichoicePollAnswer(
        this.poll.id,
        this.poll.questionType,
        alternatives
      ).then((response) => {
        // Refresh vote count
        ApiClient.getPoll(
          this.showQuiz || this.showPoll ? this.poll.id : this.id,
          false
        ).then((response) => {
          this.poll = response.data;
          this.poll.description = replaceTokens(
            this.poll.description,
            this.getUser
          );
          this.submitted = true;
        });
      });
    },
    submitFreeTextAnswer(e) {
      this.freeTextAnswer = e.freeTextAnswer;
      ApiClient.submitFreeTextPollAnswer(
        this.poll.id,
        this.poll.questionType,
        this.freeTextAnswer
      ).then((response) => {
        this.submitted = true;
      });
    },
    submitQuizAnswer(data) {
      ApiClient.submitQuizAnswer(data).then((response) => {
        this.submitted = true;
      });
    },
    submitTemplateQuizAnswer(data) {
      ApiClient.submitTemplateQuizAnswer(data).then((response) => {
        this.submitted = true;
      });
    },
    submitTemplateTreasureHuntAnswer(data) {
      ApiClient.submitTemplateQuizAnswer(data).then((response) => {
        ApiClient.getPoll(
          this.showQuiz || this.showPoll ? this.poll.id : this.id,
          false
        ).then((response) => {
          this.submitted = response.data.isAnswered;
        });
      });
    },
    submitTreasureHuntAnswer(data) {
      ApiClient.submitQuizAnswer(data).then((response) => {
        ApiClient.getPoll(
          this.showQuiz || this.showPoll ? this.poll.id : this.id,
          false
        ).then((response) => {
          this.submitted = response.data.isAnswered;
        });
      });
    },
    submitPollAnswer(data) {
      if (this.isQuiz) {
        createAnalyticsEvent(this.$gtag, 'Send', 'Quiz', data.quizId);
        return this.submitQuizAnswer(data);
      }

      if (this.isTemplateQuiz) {
        createAnalyticsEvent(this.$gtag, 'Send', 'Quiz', data.quizId);
        return this.submitTemplateQuizAnswer(data);
      }

      if (this.isTemplateTreasureHunt) {
        createAnalyticsEvent(this.$gtag, 'Send', 'Quiz', data.quizId);
        return this.submitTemplateTreasureHuntAnswer(data);
      }
      createAnalyticsEvent(this.$gtag, 'Send', 'Poll', data.poll?.id);

      if (this.isTreasureHunt) {
        return this.submitTreasureHuntAnswer(data);
      }

      if (this.isFreeTextPoll) {
        return this.submitFreeTextAnswer(data);
      }
      if (this.isMultiChoicePoll || this.isRatingPoll) {
        return this.submitMultiChoiceAnswer(data);
      }
    },
    submit() {
      this.submitted = true;
    }
  }
};
</script>

<style scoped>
.spin {
  animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
