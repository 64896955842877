import ApiClient from '../../client';
import { createNamespacedDataProviderStore, extendStore } from './dataprovider';

const store = createNamespacedDataProviderStore((setItems) => {
  return ApiClient.getSponsors().then((response) =>
    setItems(response.data.sponsors)
  );
});

export default extendStore(store, {});
