<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';
import Poll from '@/components/timeline/promoted/Poll';

export default {
  name: 'PagesDataProvider',
  props: {
    applicablePlatforms: {
      type: Array,
      default: () => ['digital']
    }
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('Pages', ['fetchItems', 'refresh']),
    ...mapActions('Info', ['setPromotedPage', 'resetPromotedPage']),
    ...mapMutations('Info', ['setMode', 'resetMode'])
  },
  computed: {
    ...mapGetters('Pages', ['getItems', 'failed', 'isLoading']),
    ...mapGetters('Info', ['getMode', 'getPromotedPage']),
    getPromotedPageComponent() {
      if (!this.getPromotedPage.page) {
        return;
      }
      switch (this.getPromotedPage.page) {
        case 'poll':
        case 'quiz':
        case 'treasure_hunt':
        case 'coded_quizzes':
        case 'coded_polls':
          return Poll;
      }
      return null;
    }
  },
  render() {
    return this.$scopedSlots.default({
      pages: this.getItems?.filter(
        (i) =>
          _.intersection(this.applicablePlatforms, i.PlatformDisplayOptions)
            .length > 0
      ),
      failed: this.failed,
      isLoading: this.isLoading,
      refresh: this.refresh,
      mode: this.getMode,
      promotedPage: this.getPromotedPage,
      promotedPageComponent: this.getPromotedPageComponent
    });
  }
};
</script>
