import store from '../store';
import router from '../router';
import ApiClient from '@/client';
import { redirectAuthenticatedUser } from '@/helpers/redirection-helpers';

export default async function checkAuthentication({ next, to }) {
  const { commit, getters, dispatch } = store;
  const eventId = store.getters['CurrentEvent/getId'];
  const user = JSON.parse(localStorage.getItem(`tokens_${eventId}`));
  const eventIdFromRoute = window.location.host.split('.')[0];

  if (eventIdFromRoute) {
    // Check if we have a "session" cookie in our browser
    if (user) {
      await dispatch('Authentication/getUser');
      const response =
        await ApiClient.getFullEventInfoForEventCurrentlyLoggedInTo();
      const eventIdFromSession = response.data.eventId;
      const eventSlugFromSession = response.data.eventSlug;

      // If the event id from the "full event info", which relies on our
      // session and which event we are actually logged in to, does not
      // match the one in the route params, we logout the user and
      // redirect them to the login page for the new event,
      // then fetch the new event info and set that as the current event
      if (
        eventIdFromRoute !== eventIdFromSession &&
        eventIdFromRoute !== eventSlugFromSession
      ) {
        await dispatch(
          'CurrentEvent/loadAndCacheEventDetails',
          eventIdFromSession
        );
        await dispatch('Authentication/doLogout');
      } else {
        commit('CurrentEvent/setEventId', eventIdFromSession);
        dispatch('CurrentEvent/changeLanguage', response.data.languageId);
        commit('CurrentEvent/setEventDetails', response.data);
        commit('CurrentEvent/setHasFullEventDetails', true);
      }
    }

    const isLoggedIn = store.getters['Authentication/isLoggedIn'];

    if (isLoggedIn && to.name.startsWith('login')) {
      const isWebappEnabled = getters['CurrentEvent/isWebappEnabled'];
      const isLobbyEnabled = getters['CurrentEvent/isLobbyEnabled'];
      const isStageEnabled = getters['CurrentEvent/isStageEnabled'];

      return await redirectAuthenticatedUser(
        router,
        isWebappEnabled,
        isLobbyEnabled,
        isStageEnabled,
        false
      );
    }

    // We are not logged in, nor are we trying to go to the login page
    if (!isLoggedIn && !to.name.startsWith('login')) {
      return await router.replace({ name: 'login' });
    }
  }

  return next();
}
