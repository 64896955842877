<template>
  <CurrentEventDataProvider v-slot="{ locale, designStyles }">
    <AgendasDataProvider v-slot="{ trackById, tracks, setStreamFromSession }">
      <BookSessionsDataProvider
        v-slot="{
          bookSessions,
          singleMyAgendaGroupedByDay,
          addOrDeleteBooksSesions,
          isBookSessionLoading
        }"
      >
        <div :style="designStyles">
          <div v-if="!isAgendaStage" class="text-base md:text-md font-bold">
            {{ $t('Views.Stage.Agenda.myAgenda') }}
          </div>
          <div
            class="flex justify-center items-center"
            v-if="bookSessions.length === 0"
          >
            <p class="p-5 font-bold text-sm md:text-base">
              {{ $t('Views.Lobby.Sections.Program.noBookSessions') }}
            </p>
          </div>
          <div v-else>
            <!-- Success message -->
            <div v-if="successMessage" class="flex">
              <div class="border-2 border-green"></div>
              <div class="success-message p-2 w-full">{{ successMessage }}</div>
            </div>
            <CollapsibleList
              :class="
                isAgendaStage
                  ? ''
                  : 'flex flex-col overflow-x-hidden overflow-y-auto w-full'
              "
            >
              <CollapsibleItem
                v-slot="{ active, toggle }"
                v-for="(day, date) in singleMyAgendaGroupedByDay.sessions"
                :item-id="date"
                :key="date"
                :agenda="singleMyAgendaGroupedByDay"
              >
                <div class="py-4 border-b border-grey">
                  <!-- Agenda day header -->
                  <div
                    style="text-transform: capitalize"
                    class="button w-full flex justify-between items-baseline"
                    aria-labelledby="agenda date"
                    :aria-expanded="[active ? 'true' : 'false']"
                    aria-controls="expanded-panel"
                    @click="toggle"
                  >
                    <h2
                      class="text-base xl:text-md"
                      :class="isAgendaStage ? '' : 'text-sm xl:text-base'"
                    >
                      <Moment
                        v-if="locale === 'en'"
                        :value="date"
                        format="dddd DD MMMM"
                      />
                      <Moment
                        v-if="locale === 'nb'"
                        :value="date"
                        format="dddd DD. MMMM"
                      />
                    </h2>
                    <div aria-hidden="true">
                      <i v-if="active" class="fas fa-chevron-up" />
                      <i v-else class="fas fa-chevron-down" />
                    </div>
                  </div>

                  <!-- Agenda Sessions -->
                  <div v-show="active" id="expanded-panel">
                    <CollapsibleList>
                      <CollapsibleItem
                        v-for="session in day"
                        v-slot="{ active, toggle }"
                        :key="session.id"
                        :item-id="session.id"
                        :agenda="singleMyAgendaGroupedByDay"
                      >
                        <!-- TODO: Add slide transition here -->
                        <div :id="session.id" class="my-2">
                          <!-- Session content -->
                          <Card
                            :containerClass="[
                              'flex p-2 rounded-2xl bg-white space-x-2 w-full'
                            ]"
                          >
                            <div
                              class="border-4 border-solid"
                              v-if="
                                tracks &&
                                tracks.length > 1 &&
                                session.trackId != '0' &&
                                tracks[0].id != session.trackId
                              "
                              :style="{
                                'border-color': trackById(session.trackId)
                                  .headerBackgroundColor
                              }"
                            ></div>
                            <!-- Session content -->
                            <div class="w-full py-1 space-y-2">
                              <div
                                class="flex justify-between items-center w-full text-xs"
                              >
                                <!--session time-->
                                <div class="flex space-x-2">
                                  <div>
                                    <Moment
                                      :value="session.startDate"
                                      format="HH:mm"
                                    />
                                    -
                                    <Moment
                                      :value="session.endDate"
                                      format="HH:mm"
                                    />
                                  </div>
                                  <!--session track-->
                                  <div
                                    class="inline-block font-bold px-2 text-white"
                                    v-if="
                                      tracks &&
                                      tracks.length > 1 &&
                                      session.trackId != '0'
                                    "
                                    :style="{
                                      'background-color': trackById(
                                        session.trackId
                                      ).headerBackgroundColor
                                    }"
                                  >
                                    {{ trackById(session.trackId).title }}
                                  </div>
                                </div>
                                <!--session booking  loading-->
                                <div v-if="isBookSessionLoading">
                                  <i class="fas fa-circle-notch fa-spin" />
                                </div>
                                <!--session booking-->

                                <BookSessionButton
                                  @click="
                                    handleSessionClick(
                                      session,
                                      bookSessions,
                                      addOrDeleteBooksSesions,
                                      isBookSessionLoading
                                    )
                                  "
                                  class="button border"
                                  :class="[
                                    bookSessions.some(
                                      (ses) => ses.id === session.id
                                    )
                                      ? 'text-red border border-red	'
                                      : 'border'
                                  ]"
                                  :icon="[
                                    bookSessions.some(
                                      (ses) => ses.id === session.id
                                    )
                                      ? 'minus-square'
                                      : 'calendar-plus-o'
                                  ]"
                                  :custom_caption="
                                    bookSessions.some(
                                      (ses) => ses.id === session.id
                                    )
                                      ? $t('Views.Stage.Agenda.cancel')
                                      : $t('Views.Stage.Agenda.attend')
                                  "
                                />
                              </div>
                              <!-- Session title and sub-title -->
                              <div>
                                <h3 class="font-bold text-base">
                                  {{ session.title }}
                                </h3>
                                <h4>
                                  {{ session.subtitle }}
                                </h4>
                              </div>
                              <!-- Session Actions -->
                              <div
                                v-if="
                                  session.description ||
                                  session.speakers.length ||
                                  (session.docIds &&
                                    session.docIds.length > 0) ||
                                  session.liveUrl
                                "
                              >
                                <div
                                  class="flex justify-start text-sm space-x-2"
                                >
                                  <!-- Agenda more info -->
                                  <div
                                    v-if="
                                      session.description ||
                                      session.speakers.length
                                    "
                                    class="button button-secondary"
                                    aria-label="Learn more about session"
                                    :aria-expanded="[active ? 'true' : 'false']"
                                    aria-controls="expanded-panel"
                                    @click="toggle"
                                  >
                                    {{
                                      active
                                        ? $t('Views.Stage.Agenda.showLess')
                                        : $t('Views.Stage.Agenda.moreInfo')
                                    }}
                                  </div>
                                  <!-- Agenda scene -->
                                  <div
                                    v-if="session.liveUrl"
                                    class="button button-primary"
                                    :aria-label="
                                      $t('Views.Stage.Agenda.goToScene')
                                    "
                                    @click="setStreamFromSession(session)"
                                  >
                                    {{ $t('Views.Stage.Agenda.goToScene') }}
                                  </div>
                                  <!-- Agenda documents by session -->
                                  <AgendaDocuments
                                    v-bind:session="session"
                                  ></AgendaDocuments>
                                </div>

                                <!-- TODO: Add slide down transition here -->
                                <!-- Expanded Session info -->
                                <div v-if="active" id="expanded-panel">
                                  <!-- Session Description -->
                                  <div
                                    ref="childElement"
                                    v-if="session.speakers.length"
                                    class="flex flex-row flex-wrap p-2"
                                  >
                                    <!-- Session Speaker -->
                                    <div
                                      v-for="speaker in session.speakers"
                                      :key="speaker.id"
                                      class="flex items-center space-x-2 space-y-2 w-1/2"
                                    >
                                      <Avatar
                                        :image="speaker.profileImageUrl"
                                        :circle="true"
                                        :size="'small'"
                                      />
                                      <div class="flex flex-col text-xs">
                                        <span class="font-bold">{{
                                          speaker.name + ' ' + speaker.lastName
                                        }}</span>
                                        <span v-if="speaker.jobTitle">
                                          {{ speaker.jobTitle }}</span
                                        >
                                        <span v-if="speaker.company">
                                          {{ speaker.company }}</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="text-sm html-content"
                                    v-html="session.description"
                                  />
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      </CollapsibleItem>
                    </CollapsibleList>
                  </div>
                </div>
              </CollapsibleItem>
            </CollapsibleList>
          </div>
        </div>
      </BookSessionsDataProvider>
    </AgendasDataProvider>
  </CurrentEventDataProvider>
</template>
<script>
import CollapsibleItem from '../shared/CollapsibleItem.vue';
import CollapsibleList from '../shared/CollapsibleList.vue';
import Card from '@/components/shared/webapp/Card';
import Avatar from '@/components/shared/Avatar.vue';
import BookSessionButton from '../buttons/BookSessionButton.vue';
import AgendaDocuments from '@/views/Stage/Agenda/AgendaDocuments.vue';
import AgendaConfirmationModal from '@/views/Stage/Agenda/AgendaConfirmationModal';
import AgendaDeleteConfirmationModal from '@/views/Stage/Agenda/AgendaDeleteConfirmationModal';
export default {
  name: 'BookSessions',
  components: {
    CollapsibleList,
    CollapsibleItem,
    Card,
    Avatar,
    BookSessionButton,
    AgendaDocuments
  },
  props: {
    isAgendaStage: Boolean
  },
  data() {
    return {
      successMessage: '',
      successTimeout: null
    };
  },
  methods: {
    handleSessionClick(session, bookSessions, addOrDeleteBooksSesions) {
      if (session.myAgendaSize) {
        bookSessions.some((ses) => ses.id === session.id)
          ? this.showDeleteConfirmation(session)
          : this.showConfirmation(session);
        return;
      }

      addOrDeleteBooksSesions(session);
      const isSessionOnMyAgenda = bookSessions.some(
        (ses) => ses.id === session.id
      );
      this.successMessage = isSessionOnMyAgenda
        ? 'Successfully removed agenda'
        : '';

      if (this.successTimeout) {
        clearTimeout(this.successTimeout);
      }

      this.successTimeout = setTimeout(() => {
        this.successMessage = '';
      }, 800);
    },
    showConfirmation(session) {
      document.body.classList.add('overflow-hidden');
      this.$modal.show(
        AgendaConfirmationModal,
        { session },
        {
          adaptive: true,
          minWidth: 320,
          maxWidth: 550,
          width: '45%',
          height: 'auto'
        }
      );
    },
    showDeleteConfirmation(session) {
      document.body.classList.add('overflow-hidden');
      this.$modal.show(
        AgendaDeleteConfirmationModal,
        { session },
        {
          adaptive: true,
          minWidth: 320,
          maxWidth: 550,
          width: '45%',
          height: 'auto'
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .html-content > p:has(iframe) {
  position: relative;
  padding-top: 56.25%;
}
::v-deep .html-content > p > iframe {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
}

.success-message {
  background-color: #c1e1c1;
  color: green;
}
</style>
