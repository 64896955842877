<script>
import Poll from '@/components/timeline/promoted/Poll';

export default {
  name: 'PollDataProvider',
  props: ['container'],
  computed: {
    getPromotedPage() {
      return this.$store.getters[`${this.container}/getPromotedPage`];
    },
    getPromotedPageComponent() {
      if (!this.getPromotedPage?.page) {
        return;
      }
      switch (this.getPromotedPage.page) {
        case 'poll':
        case 'quiz':
        case 'treasure_hunt':
          return Poll;
      }

      return null;
    }
  },
  render() {
    return this.$scopedSlots.default({
      promotedPage: this.getPromotedPage,
      promotedPageComponent: this.getPromotedPageComponent
    });
  }
};
</script>
