<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <PublicGroupsDataProvider>
      <div
        class="flex flex-col overflow-y-auto items-center w-full space-y-4"
        :style="designStyles"
      >
        <div class="text-base md:text-md font-bold">
          {{ $t('Views.Stage.MyProfile.groups') }}
        </div>
        <div
          class="flex flex-col overflow-y-auto overflow-x-hidden w-full h-full p-2 md:p-4"
        >
          <CollapsibleList class="w-full px-2 space-y-4">
            <CollapsibleItem
              v-for="(group, i) in publicGroups"
              v-slot="{ active, toggle }"
              :key="group.id"
              :item-id="i"
            >
              <div
                :class="
                  active ? 'shadow rounded-lg py-1' : 'shadow rounded-2xl p-2'
                "
              >
                <div
                  class="button flex justify-between items-baseline w-full"
                  @click="toggle"
                >
                  <h3 class="capitalize text-sm md:text-base font-bold">
                    {{ group.name }}
                  </h3>
                  <div aria-hidden="true">
                    <i v-if="active" class="fas fa-chevron-up" />
                    <i v-else class="fas fa-chevron-down" />
                  </div>
                </div>
                <!--Participants on group list-->
                <div v-show="active">
                  <ParticipantList
                    :group="group.id"
                    :active="active"
                    :showTitle="false"
                    :showHeader="false"
                    :listHeight="group.userIds.length > 2 ? 60 : 40"
                  />
                </div>
              </div>
            </CollapsibleItem>
          </CollapsibleList>
        </div>
      </div>
    </PublicGroupsDataProvider>
  </CurrentEventDataProvider>
</template>
<script>
import { mapGetters } from 'vuex';
import CollapsibleItem from '@/components/shared/CollapsibleItem';
import CollapsibleList from '@/components/shared/CollapsibleList';
import ParticipantList from '@/components/participants/ParticipantList';

export default {
  name: 'PublicGroups',
  components: {
    CollapsibleItem,
    CollapsibleList,
    ParticipantList
  },
  computed: {
    ...mapGetters('PublicGroups', {
      publicGroups: 'getItems'
    })
  }
};
</script>
<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
}
</style>
