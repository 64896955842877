<script>
import { mapActions, mapGetters } from 'vuex';
import { isRoomAvailableAtTime } from '@/utils';

export default {
  name: 'SponsorDataProvider',
  created() {
    this.loadSponsorData(this.$route.params.sponsorId);
  },
  methods: {
    ...mapActions('Sponsor', ['loadSponsorData', 'refresh'])
  },
  watch: {
    '$route.params.sponsorId': function (id) {
      this.loadSponsorData(id);
    }
  },
  computed: {
    ...mapGetters('Sponsor', [
      'getLoading',
      'getFailed',
      'getDocuments',
      'getSponsor',
      'getUsers',
      'getMeetingRooms',
      'getPolls'
    ])
  },
  render() {
    return this.$scopedSlots.default({
      loading: this.getLoading,
      failed: this.getFailed,
      sponsor: this.getSponsor,
      refresh: this.refresh,
      documents: this.getDocuments,
      meetingRooms: this.getMeetingRooms,
      roomIsAvailable: (room, now) => isRoomAvailableAtTime(room, now),
      users: this.getUsers,
      quizzes: this.getQuizzes,
      polls: this.getPolls
    });
  }
};
</script>
