<template>
  <CurrentEventDataProvider v-slot="{ data, features }">
    <SponsorsDataProvider v-if="features.lobbySponsors">
      <CategoriesDataProvider v-slot="{ sponsorsCategories }">
        <div id="sponsors" class="sponsors-container anchor">
          <div class="mx-auto max-w-large">
            <tappin-sponsors
              ref="sponsorsWc"
              colorScheme="primary"
              :perPage="12"
              :caption="options.label || data.captions.sponsorsCaption"
              :filters="options.isSearchEnabled && features.sponsorFilter"
              :categories="JSON.stringify(sponsorsCategories)"
            >
            </tappin-sponsors>
          </div>
        </div>
      </CategoriesDataProvider>
    </SponsorsDataProvider>
  </CurrentEventDataProvider>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    options: Object
  },
  data() {
    return {
      open: false,
      translations: {
        search_companies: this.$t('Components.Filters.searchPlaceholder'),
        categories: this.$t('Components.Filters.categories'),
        sort_by: this.$t('Components.Filters.sortBy'),
        name: this.$t('Components.Filters.name'),
        order: this.$t('Components.Filters.order'),
        out_of: this.$t('Views.Lobby.SponsorSection.outof')
      },
      sponsorsElement: null
    };
  },
  mounted() {
    this.sponsorsElement = this.$refs.sponsorsWc;
    if (!this.sponsorsElement) {
      console.error(`sponsorsElement was not found`);
      return;
    }
    this.sponsorsElement.designStyles = this.getDesignStyles;
    this.sponsorsElement.translations = this.translations;
    this.sponsorsElement.sponsors = this.sponsors;
    this.sponsorsElement.addEventListener('sponsorClick', (e) => {
      const sponsorId = e.detail?.sponsor?.id;
      if (!sponsorId) {
        return;
      }
      this.$router.push({
        name: 'sponsor',
        params: { sponsorId }
      });
    });
  },
  watch: {
    '$store.state.Sponsors.items': function (newSponsors) {
      this.sponsorsElement.sponsors = newSponsors;
    }
  },
  computed: {
    ...mapGetters('CurrentEvent', ['getDesignStyles']),
    ...mapGetters('Sponsors', { sponsors: 'getItems' })
  }
};
</script>
<style scoped lang="scss">
.sponsors-container {
  background-color: var(--color-primary-bg);
}
</style>
