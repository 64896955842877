<template>
  <button @click="$emit('click')" :type="type" class="flex items-center">
    <div>
      <i class="fal" :class="'fa-' + icon" />
      <span class="text-sm md:text-base">
        {{ custom_caption }}
      </span>
    </div>
    <i class="fal" :class="'fa-' + directionIcon" />
  </button>
</template>
<script>
export default {
  name: 'TappinButton',
  props: {
    icon: { type: String, default: null },
    custom_caption: {},
    directionIcon: { type: String, default: null },
    type: { type: String, default: 'button' }
  }
};
</script>
