<template>
  <CurrentEventDataProvider v-slot="{ hasFullEventDetails }">
    <ChatDataProvider>
      <div
        class="absolute top-0 bottom-0 w-full overflow-y-auto lg:overflow-y-hidden over flex flex-col z-10l"
      >
        <div v-if="hasFullEventDetails">
          <div>
            <Chat />
          </div>
        </div>
        <div v-else class="flex items-center justify-center h-full">
          <i class="fas fa-circle-notch fa-spin text-xl" />
        </div>
      </div>
    </ChatDataProvider>
  </CurrentEventDataProvider>
</template>
<script>
import Chat from '@/components/chat/Chat';
import ChatDataProvider from '@/components/providers/ChatDataProvider';

export default {
  name: 'Conversations',
  components: { ChatDataProvider, Chat }
};
</script>
