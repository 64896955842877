<template>
  <div v-if="!loading" class="p-4 space-y-4">
    <TappinButton
      icon="chevron-left"
      class="button-primary cursor-pointer hover:opacity-50 transition-all duration-200 text-dark font-bold space-x-2 text-sm lg:text-base py-2 px-4 border rounded"
      aria-label="close"
      :custom_caption="$t('Components.Timeline.Promoted.Info.back')"
      @click="handleClose"
    />
    <SpeakerInformation :speaker="speaker" :isPromotedPage="true" />
    <SpeakerSessions :speaker="speaker" :isPromotedPage="true" />
    <TappinButton
      class="button-primary cursor-pointer hover:opacity-50 transition-all duration-200 text-dark font-bold space-x-2 text-sm lg:text-base py-2 px-4 border rounded"
      aria-label="close"
      :custom_caption="$t('Components.Timeline.Promoted.Info.close')"
      @click="handleClose"
    />
  </div>
  <Spinner v-else />
</template>

<script>
import ApiClient from '@/client';
import { mapActions, mapGetters } from 'vuex';
import TappinButton from '@/components/buttons/TappinButton';
import Spinner from '@/components/shared/Spinner.vue';
import SpeakerInformation from '@/components/speaker/SpeakerInformation.vue';
import SpeakerSessions from '@/components/speaker/SpeakerSessions';

export default {
  name: 'Info',
  components: {
    TappinButton,
    Spinner,
    SpeakerInformation,
    SpeakerSessions
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      speaker: {},
      loading: true
    };
  },
  created() {
    ApiClient.getSpeakerById(this.id).then((response) => {
      this.speaker = response.data;
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters('Authentication', ['getUser'])
  },
  methods: {
    ...mapActions('Timeline', { resetTimeline: 'resetPromotedPage' }),
    ...mapActions('Info', { resetInfoPage: 'resetPromotedPage' }),
    handleClose() {
      this.resetInfoPage(), this.resetTimeline();
    }
  }
};
</script>
