<template>
  <div
    v-tippy="tippy"
    :content="label()"
    :class="{
      'h-12 md:h-16 flex justify-center items-center bg-primary-bg text-primary-text hover:opacity-50 tab transition-all duration-200':
        active,
      'tab h-12  md:h-16 flex justify-center items-center lg:bg-navbar hover:opacity-50 transition-all duration-200':
        !active
    }"
    :aria-label="label()"
    @click="$emit('click')"
  >
    <slot>
      <i class="fal" :class="icon" />
      <span>{{ custom_caption }}</span>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    tippy: {
      type: Object,
      required: false,

      default: (windowWidth = window.innerWidth) => {
        if (windowWidth > 1023) {
          return {
            placement: 'right',
            theme: 'light-border',
            arrow: false,
            flipBehavior: ['flip']
          };
        }
        return {
          placement: 'top',
          theme: 'light-border',
          arrow: false,
          flipBehavior: ['flip']
        };
      }
    },
    custom_caption: {},
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: Function,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: true,
      default: null
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  }
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 1023px) {
  .tab {
    background-color: transparent;
    color: white;
  }

  .tab i {
    width: 1em;
  }
}
</style>
