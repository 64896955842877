<script>
export default {
  name: 'FromNow',

  props: {
    tag: { type: String, default: 'span' },
    value: { type: String, default: () => new Date().toISOString() },
    isOffset: { type: Boolean, default: false },
    dropFixes: {
      default: false,
      type: Boolean
    },
    interval: { type: Number, default: 60 * 1000 }
  },
  data() {
    return {
      fromNow: () => {
        if (this.isOffset) {
          return this.$moment(this.value)
            .add(-(new Date().getTimezoneOffset() / 60), 'hours')
            .fromNow(this.dropFixes);
        } else {
          return this.$moment(this.value).fromNow(this.dropFixes);
        }
      },
      title: () => {
        if (this.isOffset) {
          return this.$moment(this.value)
            .add(-(new Date().getTimezoneOffset() / 60), 'hours')
            .format('dddd, MMMM Do YYYY, h:mm:ss a');
        } else {
          return this.$moment(this.value).format(
            'dddd, MMMM Do YYYY, h:mm:ss a'
          );
        }
      }
    };
  },
  mounted() {
    this.intervalId = setInterval(this.updateFromNow, this.interval);
    this.$watch('value', this.updateFromNow);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    updateFromNow() {
      let newFromNow;
      if (this.isOffset) {
        newFromNow = this.$moment(this.value)
          .add(-(new Date().getTimezoneOffset() / 60), 'hours')
          .fromNow(this.dropFixes);
      } else {
        newFromNow = this.$moment(this.value).fromNow(this.dropFixes);
      }
      if (newFromNow !== this.fromNow) {
        this.fromNow = () => newFromNow;
      }
    }
  },

  render(h) {
    return h(
      this.tag,
      {
        attrs: {
          title: this.title()
        }
      },
      this.fromNow()
    );
  }
};
</script>

<style scoped></style>
