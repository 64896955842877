<template>
  <CurrentEventDataProvider v-if="users" v-slot="{ data, designStyles }">
    <div class="flex flex-grow h-full py-2" :style="designStyles">
      <!-- Participants on loading -->
      <div class="flex flex-col w-full h-full">
        <!--header-->
        <div v-if="showHeader" class="space-y-2 p-4">
          <div v-if="showTitle" class="hidden md:flex text-md font-bold">
            {{ data.captions.usersCaption }}
          </div>
          <!-- Filter bar -->
          <div class="flex w-full space-x-2 items-center">
            <div class="w-3/5 relative">
              <input
                v-model="searchText"
                type="text"
                :placeholder="$t('Views.Stage.Participants.participants')"
                class="bg-light text-base w-full rounded-3xl"
                ref="childFocus"
              />
              <i class="fas fa-search absolute right-0 m-3" />
            </div>
            <div class="w-2/5 text-right font-bold">
              {{ userCount }}
            </div>
          </div>
        </div>
        <!-- body -->
        <div class="flex-grow h-auto overflow-x-hidden p-0 md:p-2">
          <VirtualList
            class="w-full px-1 overflow-y-auto"
            :style="`height: ${listHeight}dvh`"
            :estimate-size="70"
            :data-key="'UserID'"
            :key="uniqueKey"
            :data-sources="users"
            :data-component="participantDetailsView"
            :extra-props="{ features, isChatOpen }"
            v-on:tobottom="onScrollToBottom"
          >
            <div slot="footer" class="loading-spinner">
              <Spinner v-if="isLoading" />
            </div>
          </VirtualList>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import VirtualList from 'vue-virtual-scroll-list';
import ParticipantDetails from '@/views/Stage/Participant/ParticipantDetails';
import Spinner from '@/components/shared/Spinner';

export default {
  name: 'Participants',
  components: {
    VirtualList,
    Spinner
  },
  props: {
    active: Boolean,
    listHeight: Number,
    showTitle: Boolean,
    showHeader: Boolean,
    isChatOpen: Boolean,
    group: String,
    uniqueKey: String
  },
  data() {
    return {
      debouncedSearchTimeout: null,
      debouncedSearchText: '',
      page: 1,
      pageSize: 20,
      participantDetailsView: ParticipantDetails
    };
  },
  computed: {
    ...mapGetters('Users', {
      isUserOnline: 'isUserOnline',
      isLoading: 'isLoading',
      users: 'getUsers'
    }),
    ...mapGetters('CurrentEvent', ['features', 'getEvent']),
    ...mapGetters('Authentication', ['getDefaultImage']),
    searchText: {
      get() {
        return this.debouncedSearchText;
      },
      set(val) {
        if (this.debouncedSearchTimeout) {
          clearTimeout(this.debouncedSearchTimeout);
        }
        this.debouncedSearchTimeout = setTimeout(async () => {
          if (this.debouncedSearchText?.trim() !== val?.trim()) {
            this.debouncedSearchText = val;
            this.page = 1;
            await this.fetchUsers({
              page: this.page,
              pageSize: this.pageSize,
              search: this.debouncedSearchText?.trim(),
              groupId: this.group
            });
          }
        }, 500);
      }
    },
    userCount() {
      if (this.debouncedSearchText?.trim()) {
        return `${this.users.length}${
          this.users.length < this.pageSize ? '' : '+'
        } ${this.getEvent.captions.usersCaption}`;
      }

      return '';
    }
  },
  watch: {
    active: {
      async handler(val) {
        if (val) {
          this.page = 1;
          await this.fetchUsers({
            page: this.page,
            pageSize: this.pageSize,
            search: this.debouncedSearchText,
            groupId: this.group
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('Users', ['fetchUsers']),
    async onScrollToBottom() {
      this.page += 1;
      await this.fetchUsers({
        page: this.page,
        pageSize: this.pageSize,
        search: this.debouncedSearchText,
        groupId: this.group,
        append: true
      });
    }
  }
};
</script>

<style scoped>
.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}
button.skip-letters:focus {
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 0 30%;
  padding: 5px;
  font-size: 20px;
  background: white;
  text-align: center;
  color: inherit;
  z-index: 999;
}
</style>
