<template>
  <CurrentEventDataProvider v-slot="{ data, designStyles, features }">
    <div
      class="flex flex-col h-full bg-primary-bg text-primary-text overflow-x-hidden overflow-y-auto p-2 md:px-12 md:py-2"
      :style="designStyles"
    >
      <SponsorsDataProvider :style="designStyles">
        <CategoriesDataProvider v-slot="{ sponsorsCategories }">
          <div>
            <tappin-sponsors
              ref="sponsorsWc"
              colorScheme="primary"
              :perPage="12"
              :caption="options.label || data.captions.sponsorsCaption"
              :filters="features.sponsorFilter"
              :categories="JSON.stringify(sponsorsCategories)"
            >
            </tappin-sponsors>
            <div
              class="text-base flex justify-between items-center mt-2 md:mt-12 lg:mt-6"
            >
              <button
                class="button button-secondary"
                aria-label="Close"
                @click="onClose"
              >
                {{ $t('Components.RecoveryPasswordModal.close') }}
              </button>
            </div>
          </div>
        </CategoriesDataProvider>
      </SponsorsDataProvider>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import { mapGetters } from 'vuex';
import { createAnalyticsEvent } from '@/helpers/ga-helper';
export default {
  props: {
    options: Object
  },
  data() {
    return {
      open: false,
      translations: {
        search_companies: this.$t('Components.Filters.searchPlaceholder'),
        categories: this.$t('Components.Filters.categories'),
        sort_by: this.$t('Components.Filters.sortBy'),
        name: this.$t('Components.Filters.name'),
        order: this.$t('Components.Filters.order'),
        out_of: this.$t('Views.Lobby.SponsorSection.outof')
      },
      sponsorsElement: null
    };
  },
  mounted() {
    this.sponsorsElement = this.$refs.sponsorsWc;

    if (!this.sponsorsElement) {
      console.error(`sponsorsElement was not found`);
      return;
    }

    this.sponsorsElement.designStyles = this.getDesignStyles;
    this.sponsorsElement.translations = this.translations;
    this.sponsorsElement.sponsors = this.sponsors;
    this.sponsorsElement.addEventListener('sponsorClick', (e) => {
      const sponsorId = e.detail?.sponsor?.id;
      if (!sponsorId) {
        return;
      }

      createAnalyticsEvent(this.$gtag, 'Click', 'Sponsors', sponsorId);

      this.$router.push({
        name: 'sponsor',
        params: { sponsorId }
      });

      this.$emit('close', {
        success: false
      });
    });
  },
  computed: {
    ...mapGetters('CurrentEvent', ['getDesignStyles']),
    ...mapGetters('Sponsors', { sponsors: 'getItems' })
  },
  watch: {
    '$store.state.Sponsors.items': function (newSponsors) {
      this.sponsorsElement.sponsors = newSponsors;
    }
  },
  methods: {
    onClose() {
      this.$emit('close', {
        success: false
      });
      setTimeout(function () {
        document.getElementById('allSponsors').focus();
      }, 0);
    }
  }
};
</script>
<style scoped lang="scss">
.leader-start {
  &.indent:not(.indent-parent) {
    left: 50px;
    position: relative;
    width: calc(100% - 50px);
    &:before {
      content: '';
      position: absolute;
      border-left: 3px;
      border-left-color: #cccccc;
      border-left-style: dashed;
      width: auto;
      height: calc(100% + 13px);
      top: -13px;
      left: -25px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: -18px;
      border-bottom: 3px;
      border-bottom-color: #cccccc;
      border-bottom-style: dashed;
      width: 18px;
      height: auto;
    }
    &.indent-first {
      &:before {
        height: 100%;
        top: 0;
      }
    }
    &.indent-last {
      &:before {
        height: calc(50% + 16px);
      }
    }
    .indent-button {
      display: block;
    }
  }
}
button.skip-letters:focus {
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 0 15%;
  padding: 5px;
  font-size: 20px;
  background: white;
  text-align: center;
  color: inherit;
  z-index: 999;
}
</style>
