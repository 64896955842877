<template>
  <div class="flex flex-wrap overflow-hidden w-full h-full md:space-y-2">
    <!-- Navbar -->
    <div
      class="navbar top-0 z-30 flex justify-between items-center text-center sticky font-bold bg-dark px-4 w-full"
    >
      <div class="flex items-center h-16">
        <img
          :src="'https://jolly-coast-0ed26f703-development.westeurope.3.azurestaticapps.net/ec7c06a311e758b602863004841e8d19.svg'"
          :alt="'Event\'s logo'"
          class="object-scale-down h-6 md:h-12 w-auto"
        />
      </div>
    </div>
    <!--  -->
    <div class="flex flex-col w-full h-full px-2 md:px-12 space-y-4">
      <!-- Header -->
      <div class="flex justify-between w-full">
        <!-- Event detail -->
        <div>
          <h1 class="font-bold text-md">
            {{ this.eventName }}
          </h1>
          <h2>{{ this.listName }}</h2>
        </div>
        <!-- Session detail -->
      </div>

      <div
        class="poll-results h-full w-full flex flex-col bg-white text-dark space-y-4"
      >
        <div class="flex flex-row justify-between items-center w-full">
          <div class="w-1/4">
            <h3 class="font-bold">
              {{ $t('Views.ParticipantLink.participantList') }}
            </h3>
            <h4 class="text-sm">
              Showing all {{ totalParticipant }} participants
            </h4>
          </div>
          <!--search-->
          <div class="flex justify-center pt-4 w-full">
            <div class="relative w-1/2">
              <input
                v-model="searchText"
                type="text"
                :placeholder="$t('Views.Stage.Participants.participants')"
                class="bg-light text-base w-full rounded-3xl"
                ref="childFocus"
              />
              <i class="fas fa-search absolute right-0 m-3" />
            </div>
          </div>
          <!--export-->
          <div class="flex justify-end w-1/4">
            <TappinButton
              :icon="loadingExport ? 'circle-notch' : ''"
              class="button button-primary"
              :disabled="loadingExport"
              :custom_caption="loadingExport ? '' : 'Export'"
              @click="handleExport"
            />
          </div>
        </div>
        <!-- Content -->
        <div v-if="isLoading" slot="footer" class="loading-spinner">
          <Spinner />
        </div>
        <div v-else class="table-container shadow-lg">
          <table class="mx-auto table-auto">
            <thead class="sticky top-0 bg-white">
              <tr class="text-left shadow-md">
                <th
                  v-for="(headerColumn, i) in header"
                  :key="i"
                  class="w-1/4 px-2 py-2"
                >
                  {{ headerColumn }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, i) in filteredParticipants"
                :key="i"
                class="border"
              >
                <td
                  v-for="(hd, i) in header"
                  :key="i"
                  class="p-4 md:p-8 text-xs md:text-base"
                >
                  {{ row.hasOwnProperty(i) ? row[i] : '' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from '@/client';
import Spinner from '@/components/shared/Spinner';
import TappinButton from '@/components/buttons/TappinButton.vue';

export default {
  components: {
    Spinner,
    TappinButton
  },
  props: {
    pinCode: String
  },
  data() {
    return {
      isLoading: false,
      eventId: window.location.host.split('.')[0],
      header: [],
      rows: [],
      eventName: '',
      listName: '',
      searchText: '',
      loadingExport: false
    };
  },
  created() {
    this.fetchItems();
  },
  computed: {
    totalParticipant() {
      return this.rows.length;
    },
    filteredParticipants() {
      if (!this.searchText) {
        return this.rows;
      }
      const search = this.searchText.toLowerCase();
      return this.rows.filter((row) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(search)
        )
      );
    }
  },
  methods: {
    fetchItems() {
      this.isLoading = true;
      ApiClient.getParticipantsSharedList(this.eventId, this.pinCode)
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = false;
          }
          this.header = response?.data?.participants?.header;
          this.rows = response?.data?.participants?.values;
          this.eventName = response?.data?.eventName;
          this.listName = response?.data?.listName;
        })
        .catch((e) => console.error(`Critical failure: ${e.message}`));
    },
    async handleExport() {
      this.loadingExport = true;
      await ApiClient.exportParticipantsSharedLink(this.eventId, this.pinCode);
      this.loadingExport = false;
    }
  }
};
</script>

<style scoped lang="scss">
.table {
  width: 100%;
}
.table-container {
  overflow-y: auto;
  height: calc(100vh - 14em);
}
</style>
