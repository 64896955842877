import Vue from 'vue';
import App from './App.vue';
import VueTippy, { TippyComponent } from 'vue-tippy';
import VModal from 'vue-js-modal';
import CustomMomentPluginBecauseVueMomentSucks from '@/moment';
import Vuelidate from 'vuelidate';
import VueCountryCode from 'vue-country-code';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
import '@/components/_global';
import '@/assets/tailwind.css';
import '@/sass/app.scss';
import Moment from '@/components/shared/Moment';
import Notifications from 'vue-notification';
import VueGtag from 'vue-gtag';
import VueGtm from '@gtm-support/vue2-gtm';
import VueApexCharts from 'vue-apexcharts';
import VueChatScroll from 'vue-chat-scroll';
import VueScrollTo from 'vue-scrollto';
import './registerServiceWorker';
import noSleep from 'nosleep.js';
import * as Sentry from '@sentry/vue';

Vue.component('Moment', Moment);
Vue.use(VueCountryCode);
Vue.use(Vuelidate);
Vue.use(CustomMomentPluginBecauseVueMomentSucks);
Vue.use(Notifications);
Vue.use(VueScrollTo);
Vue.use(VModal, {
  dynamicDefault: {
    draggable: false,
    resizable: false,
    focusTrap: true,
    clickToClose: true,
    classes: 'tappin-modal'
  }
});
Vue.use(VueTippy, { theme: 'tappin' });
Vue.component('tippy', TippyComponent);
Vue.use(VueChatScroll);

Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: { id: 'G-YLSK5DHX4C' }
  },
  router
);

Vue.use(VueGtm, {
  id: 'GTM-WK4TRWBB',
  enabled: true,
  debug: true,
  vueRouter: router
});

Vue.directive('focus', {
  inserted(el) {
    el.focus();
  }
});

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Sentry.init({
  Vue,
  dsn: 'https://7d3438fd9d60488f84036032e07ea31e@o4505489584226304.ingest.sentry.io/4505512401764352',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [process.env.VUE_APP_API_BASE_URL],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
  tunnel: `${process.env.VUE_APP_API_BASE_URL}/sentry/tunnel`,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1
});

new Vue({
  render: (h) => h(App),
  router,
  store,
  i18n
}).$mount('#app');

var nosleep = new noSleep();
