<template>
  <div class="section py-8">
    <div class="flex flex-col mx-auto" :class="containerClass">
      <div class="section-header px-4 lg:px-0">
        <h2 class="text-lg md:text-xl">
          <slot name="heading" />
        </h2>
      </div>
      <div class="p-4 lg:p-0">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section',
  props: {
    containerClass: {
      type: String,
      default: 'max-w-base'
    }
  }
};
</script>

<style lang="scss">
.section {
  &-header {
    margin-bottom: 20px;
  }
}
</style>
