<template>
  <div class="flex flex-col items-center justify-center w-full">
    <!-- Poll Header -->
    <h3 class="font-bold text-md" v-if="showInputsCodedPoll">
      {{ $t('Components.CodedPolls.title') }}
    </h3>
    <div
      class="flex flex-col space-y-2 w-4/5 my-4"
      v-if="!showQuiz && !showPoll"
    >
      <div class="flex flex-row" v-if="errorInputCode">
        <div class="error-msg"></div>
        <div class="error-msg flex space-x-4 p-2 items-center w-full">
          <i class="fa fa-exclamation-triangle" aria-hidden="true" />
          <span class="text-xxs md:text-xs xl:text-sm">{{
            errorInputCode
          }}</span>
        </div>
      </div>
      <input
        type="number"
        v-if="showInputsCodedPoll"
        v-model="inputCode"
        onKeyPress="if(this.value.length==4) return false;"
        :placeholder="$t('Components.CodedPolls.enterCodeHere')"
        class="bg-light text-dark h-20 rounded-lg text-center"
        :aria-label="$t('Views.Login.password')"
      />
    </div>
    <div class="flex justify-between w-full pt-2" v-if="!showQuiz && !showPoll">
      <button
        v-if="showInputsCodedPoll"
        type="submit"
        class="button button-secondary"
        @click="resetPromotedPage"
      >
        Cancel
      </button>
      <button
        v-if="showInputsCodedPoll"
        :disabled="!inputCode"
        type="submit"
        class="button button-secondary"
        @click="sendPollCode"
      >
        Ok
      </button>
    </div>
    <QuizPoll v-if="showQuiz" :quiz="poll" :submit="submit" />
    <SinglePoll v-if="showPoll" :id="poll.id" :poll="poll" :submit="submit" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import QuizPoll from '../timeline/promoted/QuizPoll.vue';
import SinglePoll from '../timeline/promoted/SinglePoll.vue';

export default {
  name: 'CodedPoll',
  components: {
    QuizPoll,
    SinglePoll
  },
  props: {
    poll: Object,
    submit: Function,
    showQuiz: Boolean,
    showPoll: Boolean,
    showInputsCodedPoll: Boolean,
    errorInputCode: String,
    pollName: String
  },
  data() {
    return {
      inputCode: ''
    };
  },
  methods: {
    ...mapActions('Timeline', {
      timelineResetPromotedPage: 'resetPromotedPage'
    }),
    ...mapActions('Info', {
      pagesResetPromotedPage: 'resetPromotedPage'
    }),
    resetPromotedPage() {
      this.timelineResetPromotedPage();
      this.pagesResetPromotedPage();
    },
    sendPollCode() {
      this.$emit('codePoll', this.inputCode);
    }
  }
};
</script>
<style scoped lang="scss">
.error-msg {
  color: red;
  border: 2px solid red;
}
</style>
