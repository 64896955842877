import { replaceTokens } from '@/utils';
import ApiClient from '../../client';
import { createNamespacedDataProviderStore, extendStore } from './dataprovider';

const store = createNamespacedDataProviderStore((setItems, user) =>
  ApiClient.getLivePages().then((response) => {
    if (response.data) {
      response.data = replaceTokens(response.data, user, 'Tekst');
    }
    setItems(response.data);
  })
);

export default extendStore(store, {});
