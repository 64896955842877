<template>
  <CurrentEventDataProvider v-slot="{ logoUrl, features }">
    <ChatDataProvider>
      <UsersDataProvider>
        <div
          class="navbar top-0 z-30 flex justify-between items-center text-center sticky font-bold pl-2 md:px-4 h-16"
        >
          <!-- Logo & Event name -->
          <div class="flex items-center" @click="goToHomePage()">
            <img
              :src="logoUrl"
              :alt="logoUrl && 'Event\'s logo'"
              class="object-scale-down h-10 md:h-16 w-auto"
            />
          </div>
          <div v-if="$route.name !== 'lobby' && showNav && mobileMenuOpen">
            <SideBarTabs
              class="flex lg:hidden"
              :class="{ 'navbar-sidebar': mobileMenuOpen }"
              :tabs="tabs"
              :closeMobileMenu="closeMobileMenu"
            />
          </div>
          <!-- Desktop Nav links, shown in lobby -->
          <nav
            v-if="setNavItems && $route.name === 'lobby' && showNav"
            class="navbar-nav items-center justify-between text-center hidden lg:flex"
          >
            <div class="nav-item" v-if="isMainStageShown">
              <router-link
                :to="{ name: 'stage' }"
                class="whitespace-nowrap"
                href="/stage"
              >
                {{ mainStageCaption }}
              </router-link>
            </div>

            <div
              v-for="navItem in lobbyElements"
              :key="navItem.type"
              class="nav-item"
            >
              <a
                :href="'#' + navItem.id"
                @click="closeMobileMenu"
                class="nav-item-child"
              >
                {{ navItem.options.label }}
              </a>
            </div>

            <div class="nav-item cursor-pointer" v-if="isParticipantsShown">
              <a
                id="participants"
                aria-haspopup="true"
                @click="showParticipantsList"
                v-if="$route.name !== 'stage'"
              >
                {{ participantsCaption }}
              </a>
            </div>
          </nav>

          <!-- Support, Chat & Logout -->
          <div class="z-50 flex items-center justify-between">
            <div
              class="rounded-full shadow py-1 px-2 cursor-pointer"
              aria-haspopup="true"
              aria-label="open support"
              @click="toggleLiveChatWindow"
              v-show="chatWidgetLoaded"
              v-if="isSupportEnabled"
            >
              <i class="fal fa-headset" aria-hidden="true"></i>
            </div>
            <!-- lobby chat -->
            <div
              class="icon flex flex-row justify-center items-center"
              v-if="features.lobbyChatEnabled && $route.name === 'lobby'"
            >
              <div
                id="chatModal"
                @click="showChatModal"
                class="chatIcon button button-small lg:inline-block"
                aria-haspopup="true"
                aria-label="open chat"
              >
                <i class="fal fa-comments fa-lg" aria-hidden="true"></i>
              </div>
              <div
                v-if="unreadChatsCount >= 1"
                class="icon__notification-badge"
              >
                <span
                  class="text-xxs md:text-xs"
                  aria-label="unread messages available"
                >
                  {{ unreadChatsCount }}
                </span>
              </div>
            </div>
            <!-- chats on rest of the system-->
            <div
              class="icon flex flex-row justify-center items-center"
              v-if="
                isChatEnabled &&
                $route.name !== 'lobby' &&
                $route.name !== 'sponsor'
              "
            >
              <div class="block lg:hidden">
                <div
                  @click="openChat"
                  class="chatIcon button button-small lg:inline-block"
                  aria-haspopup="true"
                  aria-label="open chat"
                >
                  <i class="fal fa-comments fa-lg" aria-hidden="true"></i>
                </div>
                <div
                  v-if="unreadChatsCount >= 1"
                  class="icon__notification-badge"
                >
                  <span
                    class="text-xxs md:text-xs"
                    aria-label="unread messages available"
                  >
                    {{ unreadChatsCount }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="$route.name === 'lobby'"
              class="button button-small cursor-pointer hover:opacity-50 transition-all duration-200 text-dark font-bold p-2 space-x-2"
              aria-label="View your profile"
              @click="showProfileModal"
            >
              <Avatar
                :image="profile.Picture"
                :circle="true"
                :alt="profile.Picture && $t('Views.Shared.UserPicture')"
                size="medium"
                aria-hidden="true"
                @error="setAltImg"
              />
            </div>
            <div
              v-else
              class="hidden lg:flex button button-small cursor-pointer hover:opacity-50 transition-all duration-200 text-dark font-bold p-2 space-x-2"
              aria-label="View your profile"
              @click="showProfileModal"
            >
              <Avatar
                :image="profile.Picture"
                :circle="true"
                :alt="profile.Picture && $t('Views.Shared.UserPicture')"
                size="medium"
                aria-hidden="true"
                @error="setAltImg"
              />
            </div>
            <div
              v-if="$route.name !== 'lobby' && showMobileMenu"
              class="text-md button lg:hidden inline-block"
              :class="{ 'opacity-25': mobileMenuOpen }"
              aria-label="menu"
              id="clickbox"
              @click="toggleMobileMenu"
            >
              <div v-if="mobileMenuOpen" />
              <div v-else>
                <i class="fas fa-bars" aria-label="hamburger menu" />
              </div>
            </div>
          </div>
        </div>
      </UsersDataProvider>
    </ChatDataProvider>
  </CurrentEventDataProvider>
</template>

<script>
import { getApplicablePlatforms } from '@/utils';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { togglesSupportChat } from '@/mixins';
import UserProfile from '@/views/Stage/UserProfile';
import TicketModal from '@/components/shared/TicketModal';
import SideBarTabs from '@/components/shared/SideBarTabs';
import LobbyParticipants from '@/views/Stage/Participant/LobbyParticipants';
import ChatLobby from '@/components/chat/lobby/ChatLobby';
import Conversations from '@/views/Stage/Conversations';
import ChatDataProvider from '@/components/providers/ChatDataProvider';
import Avatar from '@/components/shared/Avatar';

export default {
  name: 'Navbar',
  components: { ChatDataProvider, Avatar, SideBarTabs },
  mixins: [togglesSupportChat],
  props: {
    showNav: {
      default: false
    },
    showMobileMenu: {
      default: true
    },
    fullWidth: {
      default: true
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      lobbyElements: [],
      listOpen: false,
      mobileMenuOpen: false,
      chatTab: {
        enabled: () => true,
        key: 'conversations',
        icon: 'fa-comments',
        label: () => this.getEvent.captions.profileCaption,
        component: Conversations,
        isModal: false,
        onClick: this.openChat
      }
    };
  },

  computed: {
    ...mapGetters('CurrentEvent', [
      'getLocale',
      'getEvent',
      'isWebappEnabled',
      'isLobbyEnabled',
      'features'
    ]),
    ...mapGetters('Authentication', {
      profile: 'getUser'
    }),
    ...mapGetters('Authentication', ['getDefaultImage']),
    ...mapGetters('Chat', [
      'getChats',
      'getUnreadChatMessages',
      'getUnreadChatsCount'
    ]),
    isSupportEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppSupport
        : this.features.stageSupport;
    },
    isChatEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppChat
        : this.features.stageChatEnabled;
    },
    isQrEnabled() {
      const event = this.getEvent;

      return (
        this.profile &&
        this.profile.TicketQrCode &&
        event.features.stageQrTicketsEnabled
      );
    },
    unreadChatsCount() {
      return this.getUnreadChatsCount;
    },
    mainStageCaption() {
      const event = this.getEvent;

      const defaultCaption = this.$t('Components.shared.Navbar.mainstage');

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'header'
      )?.options?.mainStageLabel;

      return mainStageCaption || defaultCaption;
    },
    informationCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.informationCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'information'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },
    speakersCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.speakersCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'speakers'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },
    agendaCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.programCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'agenda'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },
    participantsCaption() {
      const event = this.getEvent;

      const defaultCaption = this.$t(
        'Components.shared.Navbar.participantsList'
      );

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const participantsCaption = event.lobbyElements.find(
        (x) => x.id === 'header'
      )?.options?.participantsLabel;

      return participantsCaption || defaultCaption;
    },
    sponsorCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.sponsorsCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'sponsors'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },
    isMainStageShown() {
      const event = this.getEvent;

      if (!event.lobbyElements) {
        return true;
      }

      return event.lobbyElements.find((x) => x.id === 'header')?.options
        ?.mainStageEnabled;
    },
    isInformationShown() {
      const event = this.getEvent;

      if (!event.lobbyElements) {
        return true;
      }

      return event.lobbyElements.some((x) => x.id === 'information');
    },
    isSpeakersShown() {
      const event = this.getEvent;

      if (!event.lobbyElements) {
        return event.features.lobbySpeakers;
      }

      return event.lobbyElements.some((x) => x.id === 'speakers');
    },
    isParticipantsShown() {
      const event = this.getEvent;

      if (!event.lobbyElements) {
        return event.features.stageTabParticipants;
      }

      return event.lobbyElements.find((x) => x.id === 'header')?.options
        ?.participantsEnabled;
    },
    isAgendaShown() {
      const event = this.getEvent;

      if (!event.lobbyElements) {
        return event.features.lobbyAgenda;
      }

      return event.lobbyElements.some((x) => x.id === 'agenda');
    }
  },
  methods: {
    ...mapActions('Authentication', ['doLogout']),
    ...mapActions('CurrentEvent', ['changeLanguage']),
    ...mapMutations('Stage', ['setCurrentTab']),
    ...mapMutations('Chat', ['setCurrentChatID', 'setIsChatList']),
    goToHomePage() {
      if (this.$route.fullPath.includes('webapp')) {
        //webapp is redirected to home page
        return window.location.reload();
      }
      if (this.$route.fullPath.includes('sponsor')) {
        return;
      }
      return this.$router.push({
        name: this.isLobbyEnabled ? 'lobby' : 'stage'
      });
    },
    setNavItems() {
      return this.getEvent.lobbyElements.map((a) => {
        if (
          (a.options.label === this.informationCaption &&
            this.isInformationShown) ||
          (a.options.label === this.sponsorCaption &&
            this.features.lobbySponsors) ||
          (a.options.label === this.agendaCaption && this.isAgendaShown) ||
          (a.options.label === this.speakersCaption && this.isSpeakersShown)
        )
          this.lobbyElements.push(a);
      });
    },
    setAltImg(event) {
      event.target.src = this.getDefaultImage;
    },
    openChat() {
      this.sidebarCollapsed = false;
      this.setCurrentTab(this.chatTab);
      this.setCurrentChatID(null);
      this.setIsChatList(true);
    },
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
      return true;
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false;
      return true; // So link click event won't be stopped
    },
    showChatModal(participant) {
      document.body.classList.add('overflow-hidden');
      this.$modal.show(
        ChatLobby,
        { participant },
        {
          clickToClose: false,
          adaptive: true,
          minWidth: 250,
          maxWidth: 1700,
          width: '75%',
          height: '95%'
        },
        { closed: () => document.body.classList.remove('overflow-hidden') }
      );
    },
    showProfileModal(profile) {
      document.body.classList.add('overflow-hidden');

      this.$modal.show(
        UserProfile,
        { profile },
        {
          clickToClose: true,
          adaptive: true,
          minWidth: 250,
          maxWidth: 1000,
          width: '90%',
          height: '80%'
        },
        {
          opened: () => this.$root.$emit('focusChild'),
          closed: () => document.body.classList.remove('overflow-hidden')
        }
      );
    },
    showTicketModal(profile) {
      document.body.classList.add('overflow-hidden');

      this.$modal.show(
        TicketModal,
        {},
        {
          clickToClose: false,
          adaptive: true,
          minWidth: 250,
          maxWidth: 1000,
          width: '50%',
          height: 'auto'
        },
        {
          opened: () => this.$root.$emit('focusChild'),
          closed: () => document.body.classList.remove('overflow-hidden')
        }
      );
    },
    showParticipantsList() {
      document.body.classList.add('overflow-hidden');
      this.$modal.show(
        LobbyParticipants,
        {},
        {
          clickToClose: true,
          adaptive: true,
          minWidth: 250,
          maxWidth: 800,
          width: '80%',
          height: 'auto'
        },
        {
          opened: () => this.$root.$emit('focusChild'),
          closed: () => document.body.classList.remove('overflow-hidden')
        }
      );
      this.closeMobileMenu();
    }
  },
  mounted() {
    this.setNavItems();
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  background-color: var(--navbar-background-color);

  .nav-item:not(:last-child) {
    margin-right: 0px;
  }
  .nav-item {
    width: 10rem;
    display: flex;
    align-items: center;
  }
  .nav-item-child {
    width: 80%;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1336px) {
  .navbar-nav {
    flex-basis: 30%;
    font-size: small;
  }
  .navbar {
    .nav-item {
      width: 6rem;
      display: flex;
      align-items: center;
    }
    .nav-item-child {
      width: 80%;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
  }
}

@media (max-width: 320px) {
  .chatIcon {
    font-size: 0.5rem;
  }
}

@media (max-width: 700px) {
  .chatIcon {
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 1024px) {
  .navbar-sidebar {
    height: 100vh;
    font-size: medium;
    z-index: 29;
    display: flex !important;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
  @supports (height: 100dvh) {
    .navbar-sidebar {
      height: 100dvh;
      font-size: medium;
      z-index: 29;
      display: flex !important;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
    }
  }
}

.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}

.icon__notification-badge {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80%;
}

a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}

a.skip-main:focus {
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 5% 35% 0 35%;
  padding: 5px;
  font-size: 20px;
  background: white;
  text-align: center;
  color: inherit;
  z-index: 999;
}
</style>
