import router from '@/router';
import _ from 'lodash';
import Vue from 'vue';
import ApiClient from '../../client';
import { replaceTokens } from '../../utils';

export const state = () => {
  return {
    timelineItems: [],
    promotedTimelineItems: [],
    mode: 'timeline',
    promotedPage: {
      page: null,
      id: null
    },
    promise: null
  };
};

export const getters = {
  getTimelineItems: (state) => state.timelineItems,
  getMode: (state) => state.mode,
  getPromotedPage: (state) => state.promotedPage,
  getPromotedTimelineItems: (state) => state.promotedTimelineItems
};

export const mutations = {
  setTimelineItems: (state, payload) => {
    const { items, profile } = payload;
    state.timelineItems = items
      .flatMap((x) =>
        x.isPromo === '1' ? replaceTokens(x, profile, 'Message') : x
      )
      .sort((a, b) => Vue.moment(b.Tid) - Vue.moment(a.Tid));
  },
  setPromotedTimelineItems: (state, payload) => {
    const { items, profile } = payload;
    state.promotedTimelineItems = replaceTokens(
      [...items],
      profile,
      'Message'
    ).sort((a, b) => b.ToDateTicks - a.ToDateTicks);
  },
  setPromise: (state, promise) => {
    state.promise = promise;
  },
  setMode: (state, mode) => {
    state.mode = mode;
  },
  setPromotedPage: (state, payload) => {
    state.promotedPage = payload;
  },
  resetMode: (state) => {
    state.mode = 'timeline';
  },
  setTimelineItemLikeState: (state, { timelineId, liked }) => {
    const clone = _.cloneDeep(state.timelineItems);
    const timelineItem = clone.find((i) => i.TimelineID === timelineId);
    if (timelineItem) {
      timelineItem.Likes = parseInt(timelineItem.Likes) + (liked ? 1 : -1);
      clone.splice(
        clone.findIndex((i) => i.TimelineID == timelineId),
        1,
        timelineItem
      );

      state.timelineItems = clone;
    }
  },
  addTimelineComment: (state, payload) => {
    const clone = _.cloneDeep(state.timelineItems);
    const timelineItem = clone.find((i) => i.TimelineID === payload.parentId);
    if (timelineItem) {
      timelineItem.Comments.unshift(payload.item);
      clone.splice(
        clone.findIndex((i) => i.TimelineID === payload.parentId),
        1,
        timelineItem
      );
      state.timelineItems = clone;
    }
  },
  updateTimelineComment: (state, payload) => {
    const clone = _.cloneDeep(state.timelineItems);
    const itemParent = clone.find(
      (item) => item.TimelineID === payload.inReplyTo
    );
    if (itemParent) {
      itemParent.splice(
        itemParent.findIndex(
          (comment) => comment.TimelineID === payload.TimelineID
        ),
        1,
        payload
      );
    }
    state.timelineItems = clone;
  },
  removeTimelineItem: (state, payload) => {
    state.timelineItems = state.timelineItems.filter(
      ({ TimelineID }) => TimelineID !== payload.itemId
    );
  },
  removeTimelineComment: (state, payload) => {
    const clone = _.cloneDeep(state.timelineItems);
    const timelineItemParent = clone.find(
      (i) => i.TimelineID === payload.parentId
    );
    if (timelineItemParent) {
      timelineItemParent.Comments = timelineItemParent.Comments.filter(
        ({ TimelineID }) => TimelineID !== payload.TimelineId
      );
      state.timelineItems = clone;
    }
  }
};

export const actions = {
  async setPromotedPage({ commit, dispatch }, payload) {
    if (payload?.liveUrl) {
      dispatch('Stream/setStreamFromSession', payload, { root: true });
    } else if (payload?.page === 'sponsor') {
      router.push({
        name: 'sponsor',
        params: { sponsorId: payload?.id }
      });
    } else {
      commit('setMode', 'promo');
      commit('setPromotedPage', payload);
    }
  },

  async resetPromotedPage({ commit }) {
    commit('setMode', 'timeline');
    commit('setPromotedPage', {
      page: null,
      id: null
    });
  },

  async likePost({ state, commit, dispatch }, timelineId) {
    await ApiClient.likeTimelineEntry(timelineId);
  },

  async unlikePost({ state, commit, dispatch }, timelineId) {
    await ApiClient.unlikeTimelineEntry(timelineId);
  },

  async addTimelineEntry({ state, commit, dispatch }, { message, files }) {
    await ApiClient.addTimelineEntry(message, files);
  },

  async addComment(
    { state, commit, dispatch },
    { timelineId, message, files }
  ) {
    await ApiClient.addTimelineComment(timelineId, message, files);
  },

  async deleteItem({ state, commit, dispatch }, timelineId) {
    await ApiClient.deleteTimelineEntry(timelineId);
  },
  async deleteComment({ state, commit, dispatch }, timelineId) {
    await ApiClient.deleteTimelineEntry(timelineId);
  },

  async loadTimelineData({ state, commit, getters, rootGetters }) {
    if (state.promise) return state.promise;

    const promise = Promise.all([
      ApiClient.getTimelineItems().then((response) => {
        commit('setTimelineItems', {
          items: response.data,
          profile: rootGetters['Authentication/getUser']
        });
      }),
      ApiClient.getPromotedTimelineItems().then((response) => {
        commit('setPromotedTimelineItems', {
          items: response.data,
          profile: rootGetters['Authentication/getUser']
        });
      })
    ]).finally(() => {
      commit('setPromise', null);
    });
    commit('setPromise', promise);
  },
  addTimelineItem({ state, commit, rootGetters }, payload) {
    if (
      state.timelineItems?.some((x) => x.TimelineID === payload?.TimelineID)
    ) {
      return;
    }
    const clone = _.cloneDeep(state.timelineItems);
    clone.unshift(payload.item ?? payload);

    commit('setTimelineItems', {
      items: clone,
      profile: rootGetters['Authentication/getUser']
    });
  },
  updateTimelineItem({ state, commit, rootGetters }, payload) {
    const clone = _.cloneDeep(state.timelineItems);
    const itemPredicate = (item) => item.TimelineID === payload.TimelineID;

    const item = clone.find(itemPredicate);

    if (item) {
      clone.splice(clone.findIndex(itemPredicate), 1, payload);
    }

    commit('setTimelineItems', {
      items: clone,
      profile: rootGetters['Authentication/getUser']
    });
  },
  addPromotedMessage({ state, commit, rootGetters }, payload) {
    const clone = _.cloneDeep(state.promotedTimelineItems);
    clone.unshift(payload);
    commit('setPromotedTimelineItems', {
      items: clone,
      profile: rootGetters['Authentication/getUser']
    });
  },
  updatePromotedMessage({ state, commit, rootGetters }, payload) {
    const itemPredicate = (item) => item.TimelineID === payload.TimelineID;
    const promotedMessage = state.promotedTimelineItems.find(itemPredicate);
    const expiredPromotedMessage = state.timelineItems.find(itemPredicate);

    if (promotedMessage) {
      const clone = _.cloneDeep(state.promotedTimelineItems);

      clone.splice(clone.findIndex(itemPredicate), 1, payload);

      commit('setPromotedTimelineItems', {
        items: clone,
        profile: rootGetters['Authentication/getUser']
      });
    }

    if (expiredPromotedMessage) {
      const clone = _.cloneDeep(state.timelineItems);

      clone.splice(clone.findIndex(itemPredicate), 1, payload);

      commit('setTimelineItems', {
        items: clone,
        profile: rootGetters['Authentication/getUser']
      });
    }
  },
  removePromotedMessage({ state, commit, rootGetters }, payload) {
    const itemPredicate = ({ TimelineID }) => TimelineID === payload.itemId;
    const notItemPredicate = ({ TimelineID }) => TimelineID !== payload.itemId;

    if (state.promotedTimelineItems.find(itemPredicate)) {
      commit('setPromotedTimelineItems', {
        items: state.promotedTimelineItems.filter(notItemPredicate),
        profile: rootGetters['Authentication/getUser']
      });
    }

    if (state.timelineItems.find(itemPredicate)) {
      commit('setTimelineItems', {
        items: state.timelineItems.filter(notItemPredicate),
        profile: rootGetters['Authentication/getUser']
      });
    }
  },
  resetAllTimelineItems({ state, commit }) {
    commit('setTimelineItems', { items: [] });
    commit('setPromotedTimelineItems', { items: [] });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
