<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <UsersDataProvider :style="designStyles">
      <ChatDataProvider>
        <div class="absolute top-0 bottom-0 w-full over flex flex-col z-10l">
          <div class="flex flex-col h-screen">
            <div class="flex overflow-auto">
              <div class="flex flex-col w-full md:mb-16">
                <!--Chat Header-->
                <div class="h-20 w-full bg-primary-bg text-primary-text">
                  <div class="name h-full flex flex-wrap items-center">
                    <button
                      class="px-4"
                      aria-label="Close Chat"
                      @click="closeChat"
                    >
                      <i class="far fa-chevron-left" aria-hidden="true" />
                    </button>
                    <div class="icon">
                      <div class="md:pl-4">
                        <div>
                          <span
                            class="icon__available-badge"
                            :aria-label="
                              otherChatUser.Online ? 'online' : 'offline'
                            "
                          />
                        </div>

                        <Avatar
                          :image="otherChatUser.Picture"
                          :circle="true"
                          :size="'medium'"
                          :online-status="status"
                          :alt="$t('Views.Shared.UserPicture')"
                          @error="setAltImg"
                        />
                      </div>
                    </div>
                    <div
                      class="headerName pl-2 md:pl-4 font-bold text-sm md:text-base"
                    >
                      <button
                        class="button w-full hover:opacity-50"
                        aria-label="Users first and last name"
                        @click="showDetails(otherChatUser)"
                      >
                        <span class="name__truncate">
                          {{
                            otherChatUser.Firstname +
                            ' ' +
                            otherChatUser.Lastname
                          }}</span
                        >
                      </button>
                    </div>
                    <div class="text-right whitespace-nowrap flex-1" />
                  </div>
                </div>
                <!--Chat message content-->
                <div
                  v-chat-scroll
                  class="flex-1 relative overflow-y-auto h-screen z-0 mb-20"
                >
                  <div
                    v-for="chatMessage in getCurrentChatMessages"
                    :key="chatMessage.ChatMessageID"
                    class="px-2"
                  >
                    <div
                      v-if="
                        currentDate() == formattedDate(chatMessage.CreatedAt)
                      "
                    >
                      <div>
                        <div
                          class="flex justify-center items-center space-x-4 text-xxs"
                        >
                          <span>{{
                            formattedTime(chatMessage.CreatedAt)
                          }}</span>
                        </div>
                        <div
                          v-if="chatMessage.UserID === getOwnUser.UserID"
                          class="my-1 flex justify-end"
                        >
                          <div class="message flex flex-wrap justify-end">
                            <div
                              class="py-2 px-4 rounded-2xl shadow-inner bg-secondary-bg text-secondary-text"
                            >
                              <div class="text-left">
                                <span
                                  class="break-all text-xs md:text-sm"
                                  :title="
                                    formattedFullDateTime(chatMessage.CreatedAt)
                                  "
                                >
                                  {{ chatMessage.Message }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else class="my-1">
                          <div class="flex flex-wrap items-end">
                            <div class="message flex items-end">
                              <Avatar
                                :image="otherChatUser.Picture"
                                :size="'medium'"
                                :circle="true"
                                class="shrink-0"
                                @error="setAltImg"
                              />
                              <div
                                class="py-2 px-4 rounded-2xl shadow-inner bg-primary-bg text-primary-text ml-2"
                              >
                                <div class="text-left">
                                  <span
                                    class="break-all text-xs md:text-sm"
                                    :title="
                                      formattedFullDateTime(
                                        chatMessage.CreatedAt
                                      )
                                    "
                                  >
                                    {{ chatMessage.Message }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="flex justify-center items-center space-x-4">
                        <!--<hr class="hr float-left" />-->
                        <div class="capitalize text-xxs">
                          <span
                            >{{ formattedDateTime(chatMessage.CreatedAt) }}
                          </span>
                        </div>
                        <!--<hr class="hr float-right" />-->
                      </div>
                      <div
                        v-if="chatMessage.UserID === getOwnUser.UserID"
                        class="my-1 flex justify-end"
                      >
                        <div class="message flex flex-wrap justify-end">
                          <div
                            class="py-2 px-4 rounded-2xl shadow-inner bg-secondary-bg text-secondary-text"
                          >
                            <div class="text-left">
                              <span
                                class="break-all text-xs md:text-sm"
                                :title="
                                  formattedFullDateTime(chatMessage.CreatedAt)
                                "
                              >
                                {{ chatMessage.Message }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="my-1">
                        <div class="flex flex-wrap items-end">
                          <div class="message flex items-end">
                            <Avatar
                              :image="otherChatUser.Picture"
                              :circle="true"
                              :size="'medium'"
                              class="shrink-0"
                              @error="setAltImg"
                            />
                            <div
                              class="py-2 px-4 rounded-2xl shadow-inner bg-primary-bg text-primary-text ml-2"
                            >
                              <div class="text-left">
                                <span
                                  class="break-all text-xs md:text-sm"
                                  :title="
                                    formattedFullDateTime(chatMessage.CreatedAt)
                                  "
                                >
                                  {{ chatMessage.Message }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Chat Footer-->
                <div>
                  <div class="fixed bottom-0 h-16 w-full bg-white">
                    <form
                      class="flex items-center"
                      style="box-shadow: 0 -5px 20px -10px rgba(0, 0, 0, 0.3)"
                      @submit.prevent="sendMessage"
                    >
                      <input
                        v-model="inputMessage"
                        type="text"
                        :placeholder="
                          $t('Components.Chat.ChatMain.writeAMessage')
                        "
                        class="bg-white ml-4 mr-2 px-4 py-2 w-full text-black text-base placeholder-gray-500"
                      />
                      <button
                        :disabled="!inputMessage"
                        type="submit"
                        class="button w-16 h-16 hover:opacity-50"
                        aria-label="Send"
                      >
                        <i class="fal fa-paper-plane" aria-hidden="true" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ChatDataProvider>
    </UsersDataProvider>
  </CurrentEventDataProvider>
</template>
<script>
import ParticipantModal from '@/views/Stage/Participant/ParticipantModal';
import Avatar from '@/components/shared/Avatar';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { formatDate, formatCurrentDate, unknownUser } from '@/utils';
export default {
  name: 'ChatMain',
  components: {
    Avatar
  },
  data() {
    return {
      inputMessage: '',
      date1: '',
      date2: ''
    };
  },
  computed: {
    ...mapGetters('Chat', [
      'getCurrentChatID',
      'getCurrentChat',
      'getCurrentChatMessages'
    ]),
    ...mapGetters('Users', {
      isUserOnline: 'isUserOnline'
    }),
    ...mapGetters('Authentication', { getOwnUser: 'getUser' }),
    ...mapGetters('Authentication', ['getDefaultImage']),
    otherChatUser() {
      return this.otherChatUsers[0] ?? unknownUser;
    },
    otherChatUsers() {
      return (
        this.getCurrentChat?.Users?.filter(
          (item) => item.UserID !== this.getOwnUser.UserID
        ) ?? [{}]
      );
    },
    status() {
      return this.isUserOnline(this.otherChatUser);
    }
  },
  methods: {
    ...mapActions('Chat', ['sendChatMessage']),
    ...mapMutations('Chat', [
      'setCurrentChatID',
      'setIsOutGoingCall',
      'setIsChatList'
    ]),
    setAltImg(event) {
      event.target.src = this.getDefaultImage;
    },
    sendMessage() {
      this.sendChatMessage({
        chatid: this.getCurrentChatID,
        message: this.inputMessage
      });
      this.inputMessage = '';
    },
    sortedAndFiltered(items) {},
    showDetails(participant) {
      this.$modal.show(
        ParticipantModal,
        { participant },
        {
          adaptive: true,
          minWidth: 320,
          maxWidth: 1700,
          width: '45%',
          height: 'auto'
        }
      );
    },
    closeChat() {
      this.setCurrentChatID(false);
      this.setIsChatList(true);
    },
    formattedTime(time) {
      return formatDate(time, 'HH:mm');
    },
    formattedDate(time) {
      return formatDate(time, 'DD-MMMM-YYYY');
    },
    formattedDateTime(time) {
      return formatDate(time, 'DD-MMM HH:mm');
    },
    formattedFullDateTime(time) {
      return formatDate(time, 'DD-MMMM-YYYY, HH:mm');
    },
    currentDate() {
      return formatCurrentDate('DD-MMMM-YYYY');
    }
  }
};
</script>

<style scoped lang="scss">
.name {
  width: 100%;
  &__truncate {
    width: 90%;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
}
.headerName {
  width: 60%;
  @media (max-width: 1024px) {
    width: 70%;
  }
}
.message {
  max-width: 70%;
}
.text-xxs {
  font-size: 10px;
}
.hr {
  width: 20%;
  display: inline-block;
}
.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}
</style>
