<template>
  <Card>
    <div class="flex flex-col">
      <div class="flex w-full items-center gap-x-4">
        <div class="min-w-1/5 w-1/5">
          <Avatar
            :image="user.Picture"
            :circle="true"
            :online-status="isUserOnline(user.UserID)"
            :alt="$t('Views.Shared.UserPicture')"
            class="medium"
            @error="setAltImg"
          />
        </div>
        <div class="w-4/5 flex flex-col justify-center space-y-1">
          <div class="text-xs md:text-sm lg:text-base font-bold">
            {{ user.Firstname }}
            {{ user.Lastname }}
          </div>
          <div class="text-sm">{{ user.Company }}</div>
          <div class="text-xs">{{ user.Jobtitle }}</div>
          <div class="flex flex-row w-full space-x-2">
            <!-- TODO: Opens a profile modal ontop of this modal -->
            <div>
              <TappinButton
                v-if="
                  features.profileEmailVisible ||
                  features.profilePhoneVisible ||
                  user.About2 !== null
                "
                class="button button-primary"
                aria-expanded="true"
                aria-haspopup="true"
                aria-label="view more info"
                @click="toggleMoreDetails"
                :custom_caption="
                  moreDetailsExpanded
                    ? $t('Views.Stage.Speakers.showLess')
                    : $t('Views.Stage.Participants.moreInfo')
                "
              />
            </div>
            <div v-if="isChatVisible">
              <TappinButton
                v-if="!isCurrentUser(user)"
                icon="comments"
                class="button button-secondary"
                aria-label="send message to this participant"
                @click="createChat(user)"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="moreDetailsExpanded"
        id="expanded-pane "
        ref="childElement"
        tabindex="-1"
      >
        <ParticipantsModal :participant="user" />
      </div>
      <div v-show="false" class="pb-8">
        <span class="font-bold text-xs" aria-label="user's title">
          {{ user.Title }}
        </span>
        <div
          class="pt-2 text-xs html-content"
          aria-label="read more about participant"
          v-html="user.About"
        />
      </div>
    </div>
  </Card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ParticipantsModal from '@/views/Stage/Participant/ParticipantsModal';
import Card from '@/components/shared/Card';
import Avatar from '@/components/shared/Avatar';
import TappinButton from '@/components/buttons/TappinButton';
import ChatLobby from '@/components/chat/lobby/ChatLobby';
import Conversations from '@/views/Stage/Conversations';

export default {
  name: 'item-component',
  components: {
    Card,
    Avatar,
    ParticipantsModal,
    TappinButton
  },
  data() {
    return {
      moreDetailsExpanded: false,
      tab: {
        enabled: () => true,
        key: 'conversations',
        icon: 'fa-comments',
        label: () => this.getEvent.captions.profileCaption,
        component: Conversations,
        isModal: false
      }
    };
  },
  props: {
    index: {
      // index of current item
      type: Number
    },
    source: {
      // here is: {uid: 'unique_1', text: 'abc'}
      type: Object,
      default() {
        return {};
      }
    },
    features: {
      type: Object,
      default() {
        return {};
      }
    },
    isChatOpen: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    ...mapGetters('Authentication', ['getDefaultImage', 'getUser']),
    ...mapGetters('Chat', ['getChatWithUser']),
    ...mapGetters('Users', {
      isUserOnline: 'isUserOnline'
    }),
    user() {
      return this.source;
    },
    isChatVisible() {
      return (
        (this.$route.name === 'lobby' && this.features.lobbyChatEnabled) ||
        (this.$route.name === 'stage' && this.features.stageChatEnabled) ||
        (this.$route.name === 'webapp' && this.features.webAppChat)
      );
    }
  },
  methods: {
    ...mapMutations('Chat', [
      'setCurrentChatID',
      'setIsOutGoingCall',
      'setInCall',
      'setCallEnded',
      'setCallNotAvailable',
      'setIsChatList',
      'setIsNewChat'
    ]),
    ...mapMutations('Stage', ['setCurrentTab']),
    ...mapActions('Chat', ['createNewChat', 'activateChat']),
    setAltImg(event) {
      event.target.src = this.getDefaultImage;
    },
    isCurrentUser(user) {
      const currentUser = this.getUser;

      if (!currentUser) {
        return false;
      }

      return user.UserID === currentUser.UserID;
    },
    toggleMoreDetails() {
      this.moreDetailsExpanded = !this.moreDetailsExpanded;
    },
    onClose() {
      document.body.classList.remove('overflow-hidden');
      this.$emit('close', {
        success: false
      });
      // setTimeout(function () {
      //   document.getElementById('participants').focus();
      // }, 0);
    },
    hasChat(userID) {
      return this.getChatWithUser(userID);
    },
    createChat(user) {
      const currentChat = this.hasChat(user.UserID);
      const openChatCallback =
        this.$route.name === 'lobby' ? this.openChatInLobby : this.openChat;
      if (currentChat) {
        openChatCallback(currentChat);
      } else {
        this.createNewChat(user.UserID).then((response) => {
          openChatCallback(response.data);
        });
      }
    },
    openChat(data) {
      this.activateChat(data);
      this.openChatModal(this.tab);
    },
    openChatInLobby(data) {
      this.activateChat(data);
      this.onClose();
      if (!this.isChatOpen) {
        this.showChatModal();
      }
    },
    openChatModal(tab) {
      // Close participant profile
      // Close participant Modal
      // Open chat tab
      this.onClose();
      this.sidebarCollapsed = false;
      this.setCurrentTab(tab);
    },
    showChatModal(participant) {
      document.body.classList.add('overflow-hidden');
      this.$modal.show(
        ChatLobby,
        { participant },
        {
          clickToClose: true,
          adaptive: true,
          minWidth: 320,
          maxWidth: 1700,
          width: '75%',
          height: 'auto'
        },
        { closed: () => document.body.classList.remove('overflow-hidden') }
      );
    }
  }
};
</script>
