import ApiClient from '@/client';

export const state = () => {
  return {
    resultsLoggedIn: false,
    resultsLogingIn: false,
    resultsLoginError: null,
    resultsSuccesful: false
  };
};

export const getters = {
  resultsIsLoggedIn: (state) => {
    return state.resultsLoggedIn;
  },
  resultsIslogingIn: (state) => {
    return state.resultslogingIn;
  }
};

export const mutations = {
  setResultsLoggedIn: (state, payload) => {
    state.resultsLoggedIn = payload;
  },
  resultsLoginStart: (state) => {
    state.resultslogingIn = true;
  },
  resultsLoginStop: (state) => {
    state.resultslogingIn = false;
  },
  resultsLoginSuccess: (state, response) => {
    state.resultsLoggedIn = response;
    state.resultsLoginSuccessful = response;
    state.resultsLoginError = null;
  },
  resultsloginFailure: (state, response) => {
    state.resultsLoggedIn = false;
    state.resultsloginSuccessful = false;
  }
};

export const actions = {
  async doLoginToParticipantResults({ commit }, loginData) {
    await commit('resultsLoginStart');
    const response = await ApiClient.loginParticipantsToResults(
      loginData.password
    ).catch(async () => {
      return { data: false };
    });
    if (response.data === false) {
      commit('resultsloginFailure', response.data);
    } else {
      commit('resultsLoginSuccess', response.data);
      localStorage.setItem(
        `participant_results`,
        JSON.stringify(response.data)
      );
    }

    await commit('resultsLoginStop');
  },

  async doLoginToPollResults({ commit }, loginData) {
    await commit('resultsLoginStart');
    const response = await ApiClient.loginToResults(
      loginData.password,
      loginData.pollAccessKey
    ).catch(async () => {
      return { data: false };
    });
    if (response.data.isLoginSuccess === false) {
      commit('resultsloginFailure', response.data);
    } else {
      commit('resultsLoginSuccess', response.data.isLoginSuccess);
      if (response.data.isGenericLogin) {
        localStorage.setItem(
          'private_results_generic',
          JSON.stringify(response.data.isLoginSuccess)
        );
      } else {
        localStorage.setItem(
          `private_results_${loginData.pollAccessKey}`,
          JSON.stringify(response.data.isLoginSuccess)
        );
      }
    }

    await commit('resultsLoginStop');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
