<template>
  <div class="flex flex-col h-full w-full pt-4 space-y-4 text-xs lg:text-sm">
    <!-- PHONE -->
    <div class="flex flex-row items-center">
      <div class="w-1/6 flex justify-center">
        <i class="fal fa-phone-alt" />
      </div>
      <div class="w-5/6">
        <div
          v-if="participant.Phone && features.profilePhoneVisible"
          v-text="participant.Phone"
        />
        <div v-else :aria-label="$t('Views.Stage.MyProfile.phoneNotAvailable')">
          {{ $t('Views.Stage.MyProfile.phoneNotAvailable') }}
        </div>
      </div>
    </div>
    <!-- EMAIL -->
    <div class="flex flex-row items-center">
      <div class="w-1/6 flex justify-center">
        <i class="fal fa-envelope" />
      </div>
      <div class="w-5/6">
        <div
          v-if="participant.Email && features.profileEmailVisible"
          v-text="participant.Email"
        />
        <div v-else :aria-label="$t('Views.Stage.MyProfile.emailNotAvailable')">
          {{ $t('Views.Stage.MyProfile.emailNotAvailable') }}
        </div>
      </div>
    </div>
    <!-- DESCRIPTION -->
    <div
      aria-label="More information about the user"
      v-text="participant.About2"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ParticipantsModal',
  props: {
    participant: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters('CurrentEvent', ['features'])
  }
};
</script>
