<template>
  <SponsorsDataProvider>
    <tappin-banner ref="bannerWc"></tappin-banner>
  </SponsorsDataProvider>
</template>

<script>
import { mapGetters } from 'vuex';
import IframeModal from '@/components/shared/IframeModal';

export default {
  name: 'Banner',
  data() {
    return {
      translations: {},
      bannerElement: null
    };
  },
  mounted() {
    this.bannerElement = this.$refs.bannerWc;
    if (!this.bannerElement) {
      console.error(`bannerElement was not found`);
      return;
    }

    const banner = this.lobbyElements?.find((x) => x.id === 'banner');
    const topSponsorOptions = banner?.options?.topSponsorOptions;

    this.bannerElement.translations = this.translations;
    this.bannerElement.designStyles = {
      ...this.getDesignStyles,
      '--xl-banner-height': '100vh',
      '--lg-banner-height': '100vh',
      '--md-banner-height': '50vh',
      '--sm-banner-height': '50vh'
    };

    this.bannerElement.text = banner.options?.text;
    this.bannerElement.startDate = this.eventDetails?.startDate;
    this.bannerElement.endDate = this.eventDetails?.endDate;
    this.bannerElement.locale = this.eventDetails?.language;
    this.bannerElement.fileUrl = banner.options.fileUrl;
    this.bannerElement.isDatesEnabled = banner.options.isDatesEnabled;
    this.bannerElement.hideLabels = true;
    this.bannerElement.topSponsorOptions = {
      ...topSponsorOptions,
      sponsors: this.topSponsors(topSponsorOptions, this.sponsors)
    };
    this.bannerElement.addEventListener('sponsorClick', (e) => {
      const sponsorId = e.detail?.sponsor?.id;
      if (!sponsorId) {
        return;
      }

      this.$router.push({
        name: 'sponsor',
        params: { sponsorId }
      });
    });
  },
  watch: {
    '$store.state.Sponsors.items': function (newSponsors) {
      const banner = this.lobbyElements?.find((x) => x.id === 'banner');
      const topSponsorOptions = banner?.options?.topSponsorOptions ?? {};

      this.bannerElement.topSponsorOptions = {
        ...topSponsorOptions,
        sponsors: this.topSponsors(topSponsorOptions, newSponsors)
      };
    }
  },
  computed: {
    ...mapGetters('CurrentEvent', [
      'getDesignStyles',
      'eventDetails',
      'lobbyElements'
    ]),
    ...mapGetters('Sponsors', { sponsors: 'getItems' })
  },
  methods: {
    topSponsors(topSponsorOptions, sponsors) {
      return topSponsorOptions?.elements
        ?.filter((x) => x.sponsorId)
        .map((x) => sponsors.find((sponsor) => sponsor.id === x.sponsorId))
        .filter((x) => x);
    },
    openModal(url) {
      this.$modal.show(
        IframeModal,
        {
          url: url
        },
        {
          width: '90%',
          height: 'auto',
          adaptive: true,
          minWidth: 300,
          maxWidth: 640
        }
      );
    }
  }
};
</script>
