<template>
  <SponsorDataProvider v-slot="{ polls, loading }">
    <div
      v-if="!loading"
      class="absolute top-0 bottom-0 w-full overflow-y-auto flex flex-col z-10l sponsor-polls"
    >
      <div class="p-4 pt-0 text-sm">
        <h2 class="text-md font-bold pb-6">
          {{ $t('Views.Sponsor.labelPolls') }}
        </h2>
        <div class="flex flex-col gap-2">
          <PollButton
            v-for="p in polls"
            :key="p.value"
            class="button-primary cursor-pointer text-dark font-bold p-2 space-x-2 text-sm lg:text-base"
            @click="getPoll(p.value)"
            :custom_caption="p.label"
          />
        </div>
        <component
          v-if="loaded"
          :is="PollComponent"
          :id="poll.id"
          container="Sponsor"
          :close="close"
          :item="poll"
        />
      </div>
    </div>
  </SponsorDataProvider>
</template>
<script>
import ApiClient from '@/client';
import PollButton from '@/components/buttons/PollButton.vue';
import Poll from '@/components/timeline/promoted/Poll.vue';
import { getPusherInstance } from '@/utils';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'Polls',
  components: { PollButton },
  created() {
    getPusherInstance({ isReady: true }).bind(
      `sponsor-polls-change`,
      (topic, data) => {
        this.fetchItems(data.additional_data);
      }
    );
  },
  data() {
    return {
      poll: {},
      loaded: false,
      submitted: undefined,
      PollComponent: Poll
    };
  },
  methods: {
    ...mapActions('Sponsor', ['setPromotedPage']),
    ...mapMutations('Sponsor', ['setPolls']),
    getPoll(e) {
      if (this.poll.id) {
        this.loaded = false;
      }
      ApiClient.getPoll(e, false).then((r) => {
        if (r.data) {
          this.setPromotedPage({
            page: this.handleNamedPolls(r.data.pollType),
            id: r.data.id
          });
          this.poll = r.data;
          this.loaded = true;
        }
      });
    },
    handleNamedPolls(e) {
      switch (e) {
        case 0:
        case 1:
          return 'poll';
        case 2:
          return 'quiz';
        case 3:
          return 'treasure_hunt';
        default:
          return 'poll';
      }
    },
    close(e) {
      this.loaded = e;
    },
    fetchItems(data) {
      ApiClient.getSponsor(data.item).then((r) => this.setPolls(r.data.polls));
    }
  }
};
</script>
<style lang="scss">
.sponsor-polls {
  padding: 0 1rem;
  @media only screen and (min-width: 1024px) {
    padding-bottom: 5rem;
  }
}
</style>
