<template>
  <PublicCategoriesDataProvider v-slot="{ agendaSessionCategories }">
    <PublicAgendasDataProvider
      v-slot="{
        groupItemIndex,
        groupItemIsFirst,
        groupItemIsLast,
        initiallyActiveDate,
        searchAgendasForDateAndTime,
        singleAgendaGroupedByDay,
        lastDateInSingleAgenda,
        isLoading,
        trackById,
        tracks,
        goToStreamFromSession,
        filterSessions,
        data,
        features,
        locale
      }"
    >
      <AgendaSection
        :locale="locale"
        :caption="data.captions.programCaption"
        :initiallyActiveDate="initiallyActiveDate"
        :groupItemIndex="groupItemIndex"
        :groupItemIsFirst="groupItemIsFirst"
        :groupItemIsLast="groupItemIsLast"
        :searchAgendasForDateAndTime="searchAgendasForDateAndTime"
        :singleAgendaGroupedByDay="singleAgendaGroupedByDay"
        :lastDateInSingleAgenda="lastDateInSingleAgenda"
        :isLoading="isLoading"
        :tracks="tracks"
        :trackById="trackById"
        :goToStreamFromSession="goToStreamFromSession"
        :showGoToMainStage="features.agendaSessionGoToMainStage"
        :checkMainStage="
          data.lobbyElements.find((label) => label.options.mainStageLabel)
        "
        :filterSessions="filterSessions"
        :agendaSessionCategories="agendaSessionCategories"
        :isExternalAgenda="true"
      />
    </PublicAgendasDataProvider>
  </PublicCategoriesDataProvider>
</template>

<script>
import AgendaSection from '@/views/Lobby/AgendaSection';
import _ from 'lodash';

export default {
  name: 'PublicAgenda',
  components: {
    AgendaSection
  },
  props: {
    options: Object
  }
};
</script>
