var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Section',{attrs:{"id":"agenda","container-class":"max-w-large anchor"}},[_c('template',{slot:"heading"},[_vm._v(" "+_vm._s(_vm.caption)+" ")]),_c('template',{slot:"content"},[_c('tappin-filters',{ref:"filterWc",attrs:{"placeholder":_vm.translations.search,"showCategoriesFilter":"","categories":JSON.stringify(_vm.agendaSessionCategories),"selectStyles":"background-color: white; color: black;","translations":JSON.stringify(_vm.translations)},on:{"filters-search":_vm.handleSearchChange,"filters-categories":_vm.handleChangeCategory}}),(!_vm.isLoading)?_c('CollapsibleList',_vm._l((_vm.displayedItems.sessions),function(day,date){return _c('CollapsibleItem',{key:date,attrs:{"item-id":date,"agenda":_vm.singleAgendaGroupedByDay},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('div',{staticClass:"py-8",class:{
            'border-b': _vm.lastDateInSingleAgenda !== date && !active
          },staticStyle:{"border-color":"#ccc"}},[_c('button',{staticClass:"focus:outline-none flex justify-between items-center w-full text-md md:text-lg font-sans",staticStyle:{"text-transform":"capitalize"},on:{"click":toggle}},[(_vm.locale === 'en')?_c('Moment',{attrs:{"value":date,"format":"dddd DD MMMM"}}):_vm._e(),(_vm.locale === 'nb')?_c('Moment',{attrs:{"value":date,"format":"dddd DD. MMMM"}}):_vm._e(),_c('i',{staticClass:"font-bold text-md sm:text-lg far",class:{
                'fa-chevron-up': active,
                'fa-chevron-down': !active
              }})],1),(active)?_c('div',[_c('div',{staticClass:"leader-wrapper text-dark"},[_c('CollapsibleList',_vm._l((day),function(session){return _c('CollapsibleItem',{key:session.id,attrs:{"item-id":session.id,"agenda":_vm.singleAgendaGroupedByDay},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('Card',{staticClass:"leader-start space-y-2",class:[
                      {
                        indent: _vm.searchAgendasForDateAndTime(
                          date,
                          session.startDate
                        ).length
                      },
                      {
                        'indent-first':
                          _vm.groupItemIndex(
                            date,
                            session.startDate,
                            session.id
                          ) === 0
                      },
                      {
                        'indent-last': _vm.groupItemIsLast(
                          date,
                          session.startDate,
                          session.id
                        )
                      }
                    ]},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"text-sm md:text-md font-sans flex items-center"},[_c('div',{staticClass:"inline-block whitespace-no-wrap"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(session.startDate,'HH:mm'))+" ")]),_vm._v(" - "),_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(session.endDate,'HH:mm'))+" ")])]),_c('div',{staticClass:"ml-4"},[(
                              _vm.tracks &&
                              _vm.tracks.length > 1 &&
                              session.trackId != '0'
                            )?_c('div',{staticClass:"inline-block text-xs py-1 px-2 text-white",style:({
                              'background-color': _vm.trackById(session.trackId)
                                .headerBackgroundColor
                            })},[_vm._v(" "+_vm._s(_vm.trackById(session.trackId).title)+" ")]):_vm._e()])]),(_vm.isBookSessionLoading)?_c('div',[_c('i',{staticClass:"fas fa-circle-notch fa-spin text-sm"})]):_vm._e(),(_vm.isBookingsEnabled && !_vm.isBookSessionLoading)?_c('div',{staticClass:"ml-4"},[(!_vm.isLimitReached(session))?_c('BookSessionButton',{staticClass:"font-bold text-sm md:text-md fa",class:[
                            _vm.bookSessions.some((ses) => ses.id === session.id)
                              ? 'fa-minus-square'
                              : 'fa-calendar-plus-o'
                          ],on:{"click":function($event){return _vm.addOrDeleteBooksSesions(session)}}}):_c('button',{staticClass:"font-bold text-sm md:text-md fa"},[_c('i',{staticClass:"fa fa-lock"})])],1):_vm._e()]),_c('div',[_c('h3',{staticClass:"font-bold text-md"},[_vm._v(" "+_vm._s(session.title)+" ")]),_c('h4',[_vm._v(" "+_vm._s(session.subtitle)+" ")])]),(session.speakers)?_c('div',{staticClass:"flex flex-row flex-wrap ml-2 md:ml-4"},_vm._l((session.speakers),function(speaker){return _c('div',{key:speaker.id,staticClass:"flex justify-items items-center space-x-2 space-y-2 w-full md:w-1/5"},[_c('Avatar',{attrs:{"circle":true,"size":'small'}}),_c('div',{staticClass:"flex flex-col text-sm"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(speaker.name + ' ' + speaker.lastName))]),(speaker.jobTitle)?_c('span',[_vm._v(" "+_vm._s(speaker.jobTitle))]):_vm._e(),(speaker.company)?_c('span',[_vm._v(" "+_vm._s(speaker.company))]):_vm._e()])],1)}),0):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(active),expression:"active"}],staticClass:"py-1"},[_c('div',{staticClass:"rich-text html-content",domProps:{"innerHTML":_vm._s(session.description)}})]),_c('div',{staticClass:"pt-2"},[(session.description)?_c('button',{staticClass:"button button-secondary text-sm md:text-base mr-4",on:{"click":toggle}},[_vm._v(" "+_vm._s(active ? _vm.$t('Views.Lobby.Sections.Program.showLess') : _vm.$t('Views.Lobby.Sections.Program.moreInfo'))+" ")]):_vm._e(),(session.liveUrl)?_c('button',{staticClass:"button button-primary text-sm md:text-base",on:{"click":function($event){return _vm.goToStreamFromSession(session)}}},[_vm._v(" "+_vm._s(_vm.$t('Views.Lobby.Sections.Program.goToScene'))+" ")]):(
                          _vm.showGoToMainStage &&
                          _vm.checkMainStage.options.mainStageEnabled === true
                        )?_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isExternalAgenda),expression:"!isExternalAgenda"}],staticClass:"button button-primary text-sm md:text-base",attrs:{"tag":"button","to":{ name: 'stage' }}},[_vm._v(" "+_vm._s(_vm.$t('Views.Lobby.Sections.Program.goTo'))+" "+_vm._s(_vm.checkMainStage.options.mainStageLabel)+" ")]):_vm._e()],1)])]}}],null,true)})}),1)],1)]):_vm._e()])]}}],null,true)})}),1):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }