<template>
  <CurrentEventDataProvider v-slot="{ data, logoUrl }">
    <div class="flex flex-wrap overflow-hidden w-full h-full md:space-y-2">
      <!-- Navbar -->
      <div
        class="navbar top-0 z-30 flex justify-between items-center text-center sticky font-bold bg-dark px-4 w-full"
      >
        <div class="flex items-center h-16">
          <img
            :src="'https://jolly-coast-0ed26f703-development.westeurope.3.azurestaticapps.net/ec7c06a311e758b602863004841e8d19.svg'"
            :alt="logoUrl && 'Event\'s logo'"
            class="object-scale-down h-6 md:h-12 w-auto"
          />
        </div>
      </div>
      <!--  -->
      <div class="flex flex-row w-full h-full px-2 md:px-4">
        <!-- Sidebar -->
        <div v-if="showSlider" class="w-full md:w-1/4 flex flex-col">
          <div class="sidebar-container shadow-lg p-4 space-y-4 md:space-y-6">
            <!-- Event detail -->
            <div class="flex w-full space-x-4 items-center text-center">
              <div class="shadow-md p-1 rounded-full">
                <img
                  :src="logoUrl"
                  :alt="logoUrl && 'Event\'s logo'"
                  class="object-scale-down h-8 w-8"
                />
              </div>
              <h2 class="font-bold text-md">
                {{ data.eventName }}
              </h2>
            </div>
            <!-- Filters refresh-->

            <div
              class="flex flex-col md:flex-row justify-between md:items-center w-full"
            >
              <!-- Filters hidden for small screen-->
              <h2
                class="hidden md:flex font-bold text-base pt-2 md:pt-0 items-center"
              >
                Filters
              </h2>
              <div class="flex w-full justify-between md:justify-end">
                <TappinButton
                  :disabled="isLoading"
                  :icon="isLoading ? 'circle-notch' : 'repeat'"
                  @click="refreshItems"
                  class="button button-secondary text-center items-center"
                  :aria-label="isLoading ? 'loading' : 'Refresh'"
                  :custom_caption="
                    isLoading ? '' : $t('Views.PollResults.refresh')
                  "
                />
                <TappinButton
                  v-if="showSlider"
                  :icon="'close'"
                  @click="toggleSlider"
                  class="inline md:hidden whitespace-nowrap button button-secondary"
                  :aria-label="'Close filter'"
                  :custom_caption="'close filter'"
                />
              </div>
              <!-- Filters hidden for large screen-->
              <h2 class="md:hidden font-bold text-base pt-2 md:pt-0">
                Filters
              </h2>
            </div>

            <!-- Date picker -->
            <div class="flex flex-col gap-2">
              <h2 class="font-bold text-base">Date</h2>
              <Filters
                showDates
                @date-filter="handleDates"
                @checkbox-filter="handleCheckBoxFilters"
              />
            </div>
            <!-- Status -->
            <div class="flex flex-col justify-between gap-2">
              <div class="flex justify-between border-b border-grey p-2">
                <h2 class="font-bold text-base">Status</h2>
                <i class="fas fa-chevron-down ml-2" aria-hidden="true" />
              </div>
              <div class="flex flex-col w-full space-y-2 px-4">
                <!-- admitted checkbox -->
                <div class="flex justify-between">
                  <div class="space-x-4">
                    <input
                      type="checkbox"
                      v-model="showAdmittedRows"
                      id="admittedCheckbox"
                    />
                    <label for="admittedCheckbox">Scanned-In</label>
                  </div>
                  <div class="text-gray-500">{{ admittedRowsCount }}</div>
                </div>

                <!-- not admitted checkbox -->
                <div class="flex justify-between">
                  <div class="space-x-4">
                    <input
                      type="checkbox"
                      v-model="showNoAdmittedRows"
                      id="noAdmittedCheckbox"
                    />
                    <label for="noAdmittedCheckbox">Not Scanned-In</label>
                  </div>
                  <div>
                    <span class="text-gray-500">{{ noAdmittedRowsCount }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Group -->
            <div class="flex flex-col justify-between gap-2">
              <div class="flex justify-between border-b border-grey p-2">
                <h2 class="font-bold text-base">
                  {{ $t('Components.Filters.groups') }}
                </h2>
                <i class="fas fa-chevron-down ml-2" aria-hidden="true" />
              </div>
              <Filters
                showCheckbox
                @checkbox-filter="handleCheckBoxFilters"
                :groups="groups"
                tableCheckBoxName="$t('Components.Filters.groups')"
                showGroupModal
              />
            </div>
          </div>
        </div>
        <!-- Content -->
        <div
          :class="
            showSlider
              ? 'w-1/6 md:w-3/4 flex flex-col space-y-4 h-full md:ml-4'
              : 'w-full md:w-3/4 flex flex-col space-y-4 h-full md:ml-4'
          "
        >
          <!--search-->
          <div class="flex justify-between pt-4">
            <div class="w-full md:w-1/2 relative">
              <input
                v-model="searchText"
                type="text"
                :placeholder="$t('Views.Stage.Participants.participants')"
                class="bg-light text-base w-full rounded-3xl"
                ref="childFocus"
              />
              <i class="fas fa-search absolute right-0 m-3" />
            </div>
          </div>
          <!--loader-->
          <div v-if="isLoading" slot="footer" class="loading-spinner">
            <Spinner />
          </div>
          <!--header and export-->
          <div class="flex justify-between w-full">
            <div class="flex flex-col space-y-2">
              <h1 class="text-base md:text-md font-bold">Participant List</h1>
              <h3 class="text-sm" v-if="showAdmittedRows">
                Showing {{ totalRowCount }} of
                {{ totalRowCount }}
              </h3>
              <h3 class="text-sm" v-else>
                Showing {{ totalRowCount }} of
                {{ totalRowCount }}
              </h3>
            </div>
            <div class="flex flex-row items-center">
              <div>
                <TappinButton
                  :icon="loadingExport ? 'circle-notch' : ''"
                  class="button button-primary"
                  :disabled="loadingExport"
                  @click="handleExport"
                  :custom_caption="loadingExport ? '' : 'Export'"
                />
              </div>
              <div class="button flex lg:hidden" @click="toggleSlider">
                <TappinButton
                  v-if="!showSlider"
                  icon="filter"
                  class="button button-primary"
                  :custom_caption="'Filters'"
                />
              </div>
            </div>
          </div>
          <!-- Table -->
          <div class="table-container shadow-lg">
            <table class="mx-auto table-auto">
              <thead class="sticky top-0 bg-white">
                <tr class="text-left shadow-md">
                  <th
                    v-for="(headerColumn, i) in admittedHeader"
                    :key="i"
                    class="w-1/4 p-4 md:p-8 text-xs md:text-base"
                  >
                    {{ headerColumn }}
                  </th>
                </tr>
              </thead>
              <!-- admitted -->
              <tbody
                v-if="
                  showAdmittedRows && admittedRows && admittedRows.length > 0
                "
              >
                <tr
                  v-for="(row, i) in filteredAdmittedRows"
                  :key="i"
                  class="border"
                >
                  <td
                    v-for="(hd, i) in admittedHeader"
                    :key="i"
                    class="p-4 md:p-8 text-xs md:text-base"
                  >
                    {{ row.hasOwnProperty(i) ? row[i] : '' }}
                  </td>
                </tr>
              </tbody>
              <!-- not-admitted -->
              <tbody
                v-if="
                  showNoAdmittedRows &&
                  noAdmittedRows &&
                  noAdmittedRows.length > 0
                "
              >
                <tr
                  v-for="(row, i) in filteredNoAdmittedRows"
                  :key="i"
                  class="border"
                >
                  <td
                    v-for="(hd, i) in noAdmittedHeader"
                    :key="i"
                    class="p-4 md:p-8 text-xs md:text-base"
                  >
                    {{ row.hasOwnProperty(i) ? row[i] : '' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--  -->
          <div
            v-if="
              (!noAdmittedRows || noAdmittedRows.length === 0) &&
              (!admittedRows || admittedRows.length === 0) &&
              !isLoading
            "
          >
            <h2 class="font-bold text-md text-center grow">
              {{ $t('Views.ParticipantsAdmitted.notFound') }}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import ApiClient from '@/client';
import Filters from '@/components/shared/Filters.vue';
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/shared/Spinner';
import TappinButton from '@/components/buttons/TappinButton.vue';

export default {
  components: {
    Spinner,
    Filters,
    TappinButton
  },
  data() {
    return {
      isLoading: false,
      eventId: window.location.host.split('.')[0],
      checkBoxFilter: [],
      admittedHeader: [],
      noAdmittedHeader: [],
      admittedRows: [],
      noAdmittedRows: [],
      groupsFilter: [],
      fromDate: '',
      toDate: '',
      loadingFilter: false,
      defaultHeader: ['name', 'lastName', 'company', 'email', 'phone'],
      showAdmittedRows: true, // Add this
      showNoAdmittedRows: false, // Add this
      debouncedSearchTimeout: null,
      searchText: '',
      showSlider: false,
      loadingExport: false
    };
  },
  created() {
    this.fetchGroups();
    this.loadParticipants();

    this.checkScreenSize();
    // Add an event listener to react to screen resize
    window.addEventListener('resize', this.checkScreenSize);
  },
  computed: {
    ...mapGetters('Groups', { groups: 'getItems' }),
    ...mapGetters('CurrentEvent', [{ eventName: 'getTitle' }]),
    ...mapGetters('CurrentEvent', [
      'getLocale',
      'getEvent',
      'isWebappEnabled',
      'isLobbyEnabled',
      'features'
    ]),
    filteredAdmittedRows() {
      return this.admittedRows.filter((row) =>
        this.doesRowMatchSearch(row, this.searchText)
      );
    },

    // Compute filtered non-admitted rows based on search text
    filteredNoAdmittedRows() {
      return this.noAdmittedRows.filter((row) =>
        this.doesRowMatchSearch(row, this.searchText)
      );
    },
    admittedRowsCount() {
      return this.admittedRows.length;
    },

    // Compute the length of noAdmittedRows
    noAdmittedRowsCount() {
      return this.noAdmittedRows.length;
    },

    totalRowCount() {
      return (
        (this.showAdmittedRows ? this.admittedRowsCount : 0) +
        (this.showNoAdmittedRows ? this.noAdmittedRowsCount : 0)
      );
    }
  },
  methods: {
    ...mapActions('Groups', { fetchGroups: 'fetchItems' }),
    fetchItems() {
      this.loadParticipants();
    },
    toggleSlider() {
      this.showSlider = !this.showSlider;
    },
    handleDates(e) {
      this.fromDate = e.from;
      this.toDate = e.to;

      if (
        this.toDate ||
        (this.fromDate && this.toDate) ||
        (!this.fromDate && !this.toDate)
      ) {
        this.fetchItems({
          eventId: this.eventId,
          fromDate: this.fromDate,
          toDate: this.toDate,
          groups: this.groupsFilter
        });
      }
    },
    handleResetFilter() {
      this.checkBoxFilter = [];
      this.$emit('checkbox-filter', this.checkBoxFilter);
    },
    handleSaveFilter() {
      this.$emit('checkbox-filter', this.checkBoxFilter);
    },
    handleCheckBox(e) {
      const value = e.target.value.trim();
      const isChecked = this.checkBoxFilter.find((v) => v === value);
      if (!isChecked) {
        this.checkBoxFilter.push(value);
      } else {
        this.checkBoxFilter = this.checkBoxFilter.filter(
          (v) => v.trim() !== value
        );
      }
    },
    handleCheckBoxFilters(e) {
      this.groupsFilter = e;
      this.loadingFilter = true;
      this.fetchItems({
        eventId: this.eventId,
        fromDate: this.fromDate,
        toDate: this.toDate,
        groups: this.groupsFilter
      });
    },
    filterRows(rows, searchText) {
      return rows.filter((row) => {
        return Object.values(row).some((value) => {
          return value && value.toString().toLowerCase().includes(searchText);
        });
      });
    },
    filterData() {
      const searchText = this.searchText.toLowerCase();
      this.admittedRows = this.filterRows(this.admittedRows, searchText);
      this.noAdmittedRows = this.filterRows(this.noAdmittedRows, searchText);
    },

    loadParticipants() {
      this.isLoading = true;
      ApiClient.getParticipantsAdmission(
        this.groupsFilter,
        this.fromDate,
        this.toDate
      )
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = false;
            this.admittedHeader = response?.data?.admittedParticipants?.header;
            this.noAdmittedHeader =
              response?.data?.noAdmittedParticipants?.header;
            this.admittedRows = response?.data?.admittedParticipants?.values;
            this.noAdmittedRows =
              response?.data?.noAdmittedParticipants?.values;
            this.filterData();
          }
        })
        .catch((e) => console.error(`Critical failure: ${e.message}`));
    },

    doesRowMatchSearch(row, searchText) {
      return Object.values(row).some((value) => {
        return (
          value &&
          value.toString().toLowerCase().includes(searchText.toLowerCase())
        );
      });
    },

    checkScreenSize() {
      // Use window.innerWidth to get the current viewport width
      // You can adjust the breakpoint value according to your requirements
      if (window.innerWidth >= 1024) {
        this.showSlider = true;
      } else {
        this.showSlider = false;
      }
    },
    async handleExport() {
      this.loadingExport = true;
      await ApiClient.exportParticipantsAdmission(
        this.groupsFilter,
        this.fromDate,
        this.toDatem,
        this.showAdmittedRows,
        this.showNoAdmittedRows
      );
      this.loadingExport = false;
    },
    refreshItems() {
      this.fetchItems();
    }
  },
  destroyed() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('resize', this.checkScreenSize);
  }
};
</script>

<style scoped lang="scss">
:root {
  --sidebar-height: 100%;
}
@media screen and (max-width: 1023px) {
  .slider {
    display: none;
    position: fixed;
    top: -100%; /* Initially hide the slider off-screen */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: top 0.5s; /* Add a smooth transition for opening and closing */
    z-index: 999; /* Ensure the slider is above other content */
  }

  .slider.open {
    top: 0; /* Move the slider to the top of the screen when open */
  }
}
.slider {
  display: flex;
}
.table {
  width: 100%;
}
.table-container {
  overflow-y: auto;
  height: calc(100vh - 14em);
}

.sidebar-container {
  overflow-y: auto;
  height: calc(100vh - 5em);
}
</style>
