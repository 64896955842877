<template>
  <div class="container shadow-md">
    <button class="container__holder" @click="$emit('click')">
      <img class="container__image" :src="image" />
    </button>
  </div>
</template>
<script>
export default {
  name: 'TimelineImage',
  props: {
    image: String
  }
};
</script>
<style scoped lang="scss">
.container {
  position: relative;
  &__holder {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
  }
  &__image {
    border: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    text-align: center;
    text-indent: -10000px;
    top: 0;
    width: 100%;
  }
}
</style>
