<template>
  <CurrentEventDataProvider>
    <div class="text-dark text-xxs md:text-xs lg:text-sm select-none space-y-2">
      <!-- Platform visibility -->
      <div class="space-x-4" v-if="isHybridEvent">
        <div>
          <span>{{ $t('Views.Login.platformToggleLabel') }}</span>
        </div>
        <div class="flex space-x-8">
          <div class="space-x-2">
            <input
              type="radio"
              v-model="isPlatformSelected"
              :value="false"
              id="physicalRadio"
              :aria-label="$t('Views.Login.physical')"
              @change="togglePlatform(false)"
            />
            <label for="physicalRadio">{{ $t('Views.Login.physical') }}</label>
          </div>
          <div class="space-x-2">
            <input
              type="radio"
              v-model="isPlatformSelected"
              :value="true"
              id="onlineRadio "
              :aria-label="$t('Views.Login.online')"
              @change="togglePlatform(true)"
            />
            <label for="onlineRadio">{{ $t('Views.Login.online') }}</label>
          </div>
        </div>
      </div>
      <!-- Profile visibility -->
      <div
        v-if="
          features.stageTabParticipants || features.webAppNavigationParticipants
        "
      >
        <div class="space-x-4" v-if="isProfileEnabled">
          <ToggleButton
            :value="profile.Hidden == '0'"
            :color="{ checked: '#22aaa2', unchecked: '#A6a6a6' }"
            :width="65"
            :labels="{
              checked: $t('Views.Login.visible'),
              unchecked: $t('Views.Login.hidden')
            }"
            @input="toggleProfileVisibility"
          />
          <span>{{ $t('Views.Login.visibilityToggleLabel') }}</span>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>
<script>
import { ToggleButton } from 'vue-js-toggle-button';
import { getApplicablePlatforms } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ProfileSettings',
  components: {
    ToggleButton
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    ...mapGetters('Authentication', {
      profile: 'getUser'
    }),
    ...mapGetters('CurrentEvent', [
      'isLobbyEnabled',
      'isStageEnabled',
      'isWebappEnabled',
      'features'
    ]),
    isPlatformSelected() {
      return !getApplicablePlatforms(this.$route).includes('webApp');
    },
    isProfileEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppProfile
        : this.features.stageTabProfile;
    },
    isHybridEvent() {
      return (
        this.isWebappEnabled && (this.isLobbyEnabled || this.isStageEnabled)
      );
    }
  },
  created() {
    this.syncFormFromProfile();
  },
  methods: {
    ...mapActions('Authentication', {
      refreshProfileFromServer: 'getUser',
      changeAvatar: 'changeAvatar',
      updateUser: 'updateUser',
      toggleProfileVisibility: 'toggleProfileVisibility'
    }),
    syncFormFromProfile() {
      this.visible = !this.profile.Hidden;
    },
    togglePlatform() {
      if (!this.isPlatformSelected) {
        return this.$router.push({
          name: this.isLobbyEnabled ? 'lobby' : 'stage'
        });
      }

      return this.$router.push({ name: 'webapp' });
    }
  }
};
</script>
