<template>
  <div class="flex w-full h-full">
    <img class="rounded object-scale-down border-2" :src="image" />
    <button class="button absolute top-0 right-0 m-3" @click="onModalClose">
      <i class="far fa-times" aria-hidden="true" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'ImageModal',
  props: {
    image: {
      required: true,
      type: String
    }
  },
  methods: {
    onModalClose() {
      this.$emit('close', {
        success: false
      });
    }
  }
};
</script>

<style scoped>
.button {
  transition-duration: 0.4s;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.511);
  color: white;
}
</style>
