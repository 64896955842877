var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4 w-full lg:w-3/5 xl:w-full space-y-6"},[(_vm.isFreeTextPoll)?_c('div',[_c('h3',{staticClass:"font-bold text-md"},[_vm._v(" "+_vm._s(_vm.poll.question)+" ")]),_c('div',{staticClass:"text-sm html-content",domProps:{"innerHTML":_vm._s(_vm.poll.description)}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.freeTextAnswer),expression:"freeTextAnswer"}],staticClass:"w-full p-4 placeholder-dark bg-light text-sm",attrs:{"rows":"8","placeholder":_vm.$t('Components.Timeline.Promoted.Poll.freeTextPlaceholder')},domProps:{"value":(_vm.freeTextAnswer)},on:{"input":function($event){if($event.target.composing)return;_vm.freeTextAnswer=$event.target.value}}})]):_c('div',[(_vm.alreadySubmitted)?_c('div',[(_vm.shouldDisplayResults)?_c('div',[_c('h3',{staticClass:"font-bold text-md"},[_vm._v(" "+_vm._s(_vm.poll.question)+" ")]),_c('div',{staticClass:"text-sm html-content",domProps:{"innerHTML":_vm._s(_vm.poll.description)}}),_vm._l((_vm.pollAnswers),function(alternative){return _c('div',{key:alternative.index,class:{
            'bg-grey text-dark button flex items-center justify-between w-full my-2':
              !_vm.isSelectedAlternative(alternative),
            'bg-primary-bg text-primary-text button flex items-center justify-between w-full my-2':
              _vm.isSelectedAlternative(alternative),
            'cursor-auto': _vm.poll.selectedAnswer
          },attrs:{"aria-label":"alternative poll"},on:{"click":function($event){return _vm.setSelectedAlternative(alternative)}}},[_c('span',{attrs:{"aria-label":"alternative poll texts"}},[_vm._v(_vm._s(alternative.text))]),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(alternative.count)+" ")])])})],2):_c('div',[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('Components.Timeline.Promoted.Poll.notPublic'))+" ")])])]):_c('div',[_c('h3',{staticClass:"font-bold text-md"},[_vm._v(" "+_vm._s(_vm.poll.question)+" ")]),_c('div',{staticClass:"text-sm html-content",domProps:{"innerHTML":_vm._s(_vm.poll.description)}}),_vm._l((_vm.pollAnswers),function(alternative){return _c('div',{key:alternative.index,class:{
          'bg-grey text-dark button flex items-center justify-between w-full my-2':
            !_vm.isSelectedAlternative(alternative),
          'bg-primary-bg text-primary-text button flex items-center justify-between w-full my-2':
            _vm.isSelectedAlternative(alternative),
          'cursor-auto': _vm.poll.selectedAnswer
        },attrs:{"aria-label":"alternative poll"},on:{"click":function($event){return _vm.setSelectedAlternative(alternative)}}},[_c('span',{attrs:{"aria-label":"alternative poll texts"}},[_vm._v(_vm._s(alternative.text))])])})],2)]),_c('PollActions',{attrs:{"hasClose":"","hasPrevious":_vm.hasPrevious,"hasNext":_vm.hasNext,"previous":_vm.handlePrevious,"next":_vm.handleNext,"submit":_vm.handleSubmit,"disabled":_vm.alreadySubmitted},on:{"close":_vm.close}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }