import store from '../store';
import ApiClient from '@/client';

export default async function setAgendaIframes({ next, to }) {
  const eventIdFromRoute = window.location.host.split('.')[0];
  const { commit } = store;
  if (eventIdFromRoute) {
    const agendaResponse = await ApiClient.getIframeAgendas();
    commit('Agendas/setAgenda', agendaResponse.data);

    const categoriesResponse = await ApiClient.getPublicCategories();
    commit('Categories/setCategories', categoriesResponse.data);
  }
  return next();
}
