export const state = () => {
  return {
    items: []
  };
};
export const getters = {
  getItems: (state) => {
    return state.items;
  }
};

export const mutations = {
  setNewSpeakers: (state, payload) => {
    state.items = payload.append
      ? state.items.concat(payload.data)
      : payload.data;
  }
};

export const actions = {
  setSpeakers({ commit }, payload) {
    commit('setNewSpeakers', payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
