import store from '../store';
import ApiClient from '@/client';

export default async function setFullEvent({ next, to }) {
  const eventIdFromRoute = window.location.host.split('.')[0];
  const pollAccessKeyFromRoute = to.params.pollAccessKey;
  const { commit, dispatch } = store;

  if (eventIdFromRoute && pollAccessKeyFromRoute) {
    // Check if we have a "session" cookie in our browser
    const response = await ApiClient.getFullEventInfo(
      eventIdFromRoute,
      pollAccessKeyFromRoute
    );
    commit('CurrentEvent/setEventId', response.data.eventId);
    dispatch('CurrentEvent/changeLanguage', response.data.languageId);
    commit('CurrentEvent/setEventDetails', response.data);
    commit('CurrentEvent/setHasFullEventDetails', false);

    document.title = response.data.eventName;
  }

  return next();
}
