<template>
  <CurrentEventDataProvider v-slot="{ data, features, locale }">
    <BookSessionsDataProvider
      v-slot="{ bookSessions, addOrDeleteBooksSesions, isBookSessionLoading }"
    >
      <CategoriesDataProvider v-slot="{ agendaSessionCategories }">
        <AgendasDataProvider
          v-if="features.lobbyAgenda"
          v-slot="{
            groupItemIndex,
            groupItemIsFirst,
            groupItemIsLast,
            initiallyActiveDate,
            searchAgendasForDateAndTime,
            singleAgendaGroupedByDay,
            lastDateInSingleAgenda,
            isLoading,
            trackById,
            tracks,
            goToStreamFromSession,
            filterSessions
          }"
        >
          <AgendaSection
            :locale="locale"
            :bookSessions="bookSessions"
            :caption="options.label || data.captions.programCaption"
            :initiallyActiveDate="initiallyActiveDate"
            :groupItemIndex="groupItemIndex"
            :groupItemIsFirst="groupItemIsFirst"
            :groupItemIsLast="groupItemIsLast"
            :searchAgendasForDateAndTime="searchAgendasForDateAndTime"
            :singleAgendaGroupedByDay="singleAgendaGroupedByDay"
            :lastDateInSingleAgenda="lastDateInSingleAgenda"
            :isLoading="isLoading"
            :tracks="tracks"
            :trackById="trackById"
            :goToStreamFromSession="goToStreamFromSession"
            :showGoToMainStage="features.agendaSessionGoToMainStage"
            :checkMainStage="
              data.lobbyElements.find((label) => label.options.mainStageLabel)
            "
            :addOrDeleteBooksSesions="addOrDeleteBooksSesions"
            :filterSessions="filterSessions"
            :agendaSessionCategories="agendaSessionCategories"
            :isBookSessionLoading="isBookSessionLoading"
          />
        </AgendasDataProvider>
      </CategoriesDataProvider>
    </BookSessionsDataProvider>
  </CurrentEventDataProvider>
</template>
<script>
import AgendaSection from '@/views/Lobby/AgendaSection';

export default {
  components: {
    AgendaSection
  },
  props: {
    options: Object
  }
};
</script>
