<template>
  <!-- TODO: This document list and the stage document list is the same, we can share this component and pass documents into it -->
  <div
    class="absolute top-0 bottom-0 w-full overflow-y-auto flex flex-col z-10l"
  >
    <SponsorDataProvider v-slot="{ documents }">
      <div class="overflow-y-auto flex flex-col z-10">
        <!-- Documents -->
        <div class="px-4">
          <h2 class="text-md font-bold pb-4">
            {{ $t('Views.Sponsor.documents') }}
          </h2>

          <div
            v-for="document in documents"
            :key="document.DocumentID"
            class="mb-8"
          >
            <div class="mb-2">
              <p class="text-xs">
                <Moment :value="document.uTime" format="DD.MM.YYYY" />
                <br />
              </p>
              <h4 class="font-bold">
                {{ document.Name }}
              </h4>
            </div>
            <div class="mb-4">
              <div class="flex">
                <button
                  v-if="canPreview(document)"
                  class="button button-secondary mr-4"
                  aria-label="View document"
                  @click="openPreview(document)"
                >
                  {{ $t('Views.Sponsor.documentsShow') }}
                </button>

                <a
                  :href="document.Documentfile"
                  target="_blank"
                  class="button button-primary"
                  aria-label="download document"
                  download
                >
                  {{ $t('Views.Sponsor.documentsDownload') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SponsorDataProvider>
  </div>
</template>

<script>
import { documentPreviewMixin } from '@/mixins';

export default {
  name: 'Documents',
  mixins: [documentPreviewMixin]
};
</script>
