import { render, staticRenderFns } from "./TimelinePost.vue?vue&type=template&id=24cdc6ba&scoped=true&"
import script from "./TimelinePost.vue?vue&type=script&lang=js&"
export * from "./TimelinePost.vue?vue&type=script&lang=js&"
import style0 from "./TimelinePost.vue?vue&type=style&index=0&id=24cdc6ba&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24cdc6ba",
  null
  
)

export default component.exports