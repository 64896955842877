<template>
  <CurrentEventDataProvider>
    <div
      v-if="profile"
      class="flex flex-col overflow-y-auto overflow-x-hidden justify-between p-4 md:p-8 space-y-2"
    >
      <div class="flex-1 md:space-y-4">
        <!-- Name -->
        <div class="items-start">
          <div class="inline-block font-bold text-xxs md:text-xs lg:text-sm">
            {{ $t('Views.Stage.MyProfile.name') }}
          </div>
          <div class="md:flex">
            <div class="w-full lg:w-1/2 md:mr-2">
              <input
                v-model="form.firstName"
                :placeholder="$t('Views.Stage.MyProfile.firstName')"
                type="text"
                class="p-1 md:p-2 bg-light text-dark text-base w-full rounded outline-none border-none shadow-md"
              />
              <div v-if="$v.form.firstName.$invalid" class="form-error">
                {{ $t('Views.Stage.MyProfile.firstNameRequired') }}
              </div>
            </div>
            <div class="w-full lg:w-1/2">
              <input
                v-model="form.lastName"
                :placeholder="$t('Views.Stage.MyProfile.lastName')"
                type="text"
                class="p-1 md:p-2 bg-light text-dark text-base w-full rounded outline-none border-none shadow-md"
              />
              <div v-if="$v.form.lastName.$invalid" class="form-error">
                {{ $t('Views.Stage.MyProfile.lastNameRequired') }}
              </div>
            </div>
          </div>
        </div>

        <!-- Company -->
        <div class="w-full md:mr-2">
          <div class="inline-block font-bold text-xxs md:text-xs lg:text-sm">
            {{ $t('Views.Stage.MyProfile.company') }}
          </div>
          <input
            v-model="form.company"
            type="text"
            class="p-1 md:p-2 bg-light text-base text-dark w-full rounded outline-none border-none shadow-md"
            :placeholder="$t('Views.Stage.MyProfile.company')"
          />
        </div>
        <!-- Jobtitle -->
        <div class="w-full">
          <div class="inline-block font-bold text-xxs md:text-xs lg:text-sm">
            {{ $t('Views.Stage.MyProfile.jobTitle') }}
          </div>
          <input
            v-model="form.jobTitle"
            type="text"
            class="p-1 md:p-2 bg-light text-dark text-base w-full rounded outline-none border-none shadow-md"
            :placeholder="$t('Views.Stage.MyProfile.jobTitle')"
          />
        </div>

        <!-- Phone / Email-->
        <div class="w-full md:mr-2">
          <div class="inline-block font-bold text-xxs md:text-xs lg:text-sm">
            {{ $t('Views.Stage.MyProfile.phone') }}
          </div>
          <div>
            <input
              v-model="form.phone"
              type="tel"
              class="p-1 md:p-2 bg-light text-base text-gray-500 w-full rounded outline-none border-none shadow-md"
              :placeholder="$t('Views.Stage.MyProfile.phone')"
              disabled
            />
            <div v-if="$v.form.phone.$invalid" class="form-error">
              {{ $t('Views.Stage.MyProfile.phoneInvalid') }}
            </div>
          </div>
        </div>
        <div class="w-full">
          <div
            class="w-1/2 inline-block font-bold text-xxs md:text-xs lg:text-sm"
          >
            {{ $t('Views.Stage.MyProfile.email') }}
          </div>
          <div>
            <input
              v-model="form.email"
              type="email"
              value="lorem ipsum is
              great text for testing"
              class="p-1 md:p-2 bg-light text-base text-gray-500 w-full rounded outline-none border-none shadow-md"
              :placeholder="$t('Views.Stage.MyProfile.email')"
              disabled
            />

            <div v-if="$v.form.email.$invalid" class="form-error">
              {{ $t('Views.Stage.MyProfile.emailInvalid') }}
            </div>
          </div>
        </div>

        <!-- About / Description -->
        <div class="items-start">
          <div>
            <span class="inline-block font-bold text-xxs md:text-xs lg:text-sm">
              {{ $t('Views.Stage.MyProfile.about') }}
            </span>
          </div>
          <div>
            <textarea
              v-model="form.description"
              rows="2"
              type="text"
              class="p-2 bg-light text-xxs md:text-xs lg:text-sm text-dark w-full rounded"
              :placeholder="$t('Views.Stage.MyProfile.about')"
            />
          </div>
        </div>
      </div>
      <!-- Actions -->
      <div class="text-base flex justify-end items-baseline relative">
        <!-- Save -->
        <div>
          <button
            v-focus
            :disabled="$v.form.$invalid || loading"
            class="button button-secondary text-sm px-4"
            aria-label="save new changes "
            @click="saveUser"
          >
            <span v-if="loading" class="inline-block" aria-label="loading">
              {{ $t('Views.Stage.MyProfile.saving') }}
              <i class="fas ml-2 fa-circle-notch fa-spin" />
            </span>
            <span v-else class="inline-block" aria-label="save">
              <i class="fas mr-2 fa-save" aria-hidden="true" />
              {{ $t('Views.Stage.MyProfile.save') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<style>
.form-error {
  margin-top: 5px;
  color: red;
  font-weight: bold;
  font-size: 12px;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import { email, helpers, minLength, required } from 'vuelidate/lib/validators';

const phone = helpers.regex('phone', /^[0-9 +]*$/);

export default {
  name: 'MyProfile',
  validations: {
    form: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      description: {
        minLength: minLength(4)
      },
      email: {
        email
      },
      phone: {
        phone
      }
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      form: {
        firstName: '',
        lastName: '',
        description: '',
        company: '',
        jobTitle: '',
        phone: '',
        email: '',
        address: ''
      }
    };
  },
  created() {
    this.syncFormFromProfile();
  },
  methods: {
    ...mapActions('Authentication', {
      refreshProfileFromServer: 'getUser',
      changeAvatar: 'changeAvatar',
      updateUser: 'updateUser',
      toggleProfileVisibility: 'toggleProfileVisibility'
    }),
    syncFormFromProfile() {
      this.form.firstName = this.profile.Firstname;
      this.form.lastName = this.profile.Lastname;
      this.form.description = this.profile.About2; // Why 2?, I don't know.
      this.form.phone = this.profile.Cell;
      this.form.company = this.profile.Company;
      this.form.email = this.profile.Email;
      this.form.jobTitle = this.profile.Jobtitle;
      this.form.address = this.profile.Address1;
      this.form.visible = !this.profile.Hidden;
    },
    selectAvatarPicture() {
      this.$refs.fileInput.click();
    },
    onClose() {
      document.body.classList.remove('overflow-hidden');
      this.$emit('close', {
        success: false
      });
      // setTimeout(function () {
      //   document.getElementById('myProfile').focus();
      // }, 0);
    },
    async saveUser() {
      this.loading = true;
      this.updateUser({
        Firstname: this.form.firstName,
        Lastname: this.form.lastName,
        About2: this.form.description,
        Phone: this.form.phone,
        Company: this.form.company,
        Jobtitle: this.form.jobTitle,
        Email: this.form.email,
        Address1: this.form.address
      }).then(() => {
        document.body.classList.remove('overflow-hidden');
        this.loading = false;
        this.onClose();
        this.$notify({
          group: 'default',
          title: this.$t('Views.Stage.MyProfile.saved')
        });
        this.syncFormFromProfile();
      });
    },
    onAvatarFileChanged(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      this.changeAvatar(files[0]);
    }
  },
  computed: {
    ...mapGetters('Authentication', {
      profile: 'getUser'
    })
  }
};
</script>
