const lobbyElementCategory = {
  MODULE: 1,
  WIDGET: 2
};

const moduleType = {
  BANNER: 1,
  INFO_PAGE: 2,
  AGENDA: 3,
  SPONSOR: 4,
  SPEAKER: 5,
  HEADER: 6,
  TOP_SPONSORS: 7
};

export { lobbyElementCategory, moduleType };
