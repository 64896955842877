<template>
  <CurrentEventDataProvider>
    <!-- Tab Buttons -->
    <div class="flex flex-wrap h-full w-full">
      <div
        class="lg:hidden h-full"
        :class="expanded ? ' w-2/5' : 'w-5/6'"
        @click="closeMobileMenu"
      ></div>
      <div
        class="menu-sidebar flex flex-col flex-wrap justify-between h-full"
        :class="expanded ? 'items-start w-3/5' : ' menu-width'"
      >
        <TappinButton
          icon="times-circle"
          class="lg:hidden close-button bg-white rounded-full shadow-md px-2 py-1"
          @click="closeMobileMenu"
        />
        <div
          class="flex flex-col flex-wrap w-full space-y-4"
          :class="expanded ? 'items-start' : 'justify-between'"
        >
          <div class="flex flex-col flex-wrap items-start">
            <TappinButton
              icon="user"
              class="lg:hidden text-white font-bold h-12"
              :class="expanded ? 'space-x-4 caption' : 'justify-between'"
              :custom_caption="
                expanded ? $t('Components.shared.Navbar.myProfile') : ''
              "
              @click="showProfileModal"
            />
          </div>
          <Tabs
            class="flex flex-col flex-wrap cursor-pointer"
            :className="expanded ? ' caption' : 'justify-between'"
          >
            <Tab
              v-for="tab in enabledTabs"
              :icon="tab.icon"
              :label="tab.label"
              :active="
                getCurrentTab && getCurrentTab.component === tab.component
              "
              :key="tab.key"
              :custom_caption="expanded ? tab.label() : ''"
              @click="openTab(tab, true)"
              :class="expanded ? 'space-x-4 caption__truncate' : ''"
            />
          </Tabs>
        </div>
        <TappinButton
          icon="sign-out"
          class="lg:hidden text-white font-bold"
          :class="expanded ? 'space-x-4' : ''"
          :custom_caption="
            expanded ? $t('Components.shared.Navbar.logout') : ''
          "
          @click="doLogout"
        />
        <TappinButton
          :icon="expanded ? 'chevron-right' : 'chevron-left'"
          class="lg:hidden expand-button bg-white rounded-full shadow-md px-3 py-1"
          @click="showExpandableSideBar"
        />
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import UserProfile from '@/views/Stage/UserProfile';
import Tabs from '@/components/shared/Tabs';
import Tab from '@/components/shared/Tab';
import TappinButton from '@/components/buttons/TappinButton';

export default {
  name: 'SideBarTabs',
  components: {
    Tabs,
    Tab,
    TappinButton
  },
  props: {
    closeMobileMenu: {
      default: true
    },
    tabs: {
      type: Array,
      required: true
    },
    chatTab: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      expanded: false,
      enabled: {}
    };
  },
  created() {
    this.setCurrentTab(this.enabledTabs[0]);
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
    this.sidebarHeight();
    window.addEventListener('resize', this.sidebarHeight);
  },
  computed: {
    ...mapGetters('CurrentEvent', ['features', 'getEvent', 'lobbyElements']),
    ...mapGetters('Stage', ['getCurrentTab']),

    enabledTabs() {
      return this.tabs.filter((tab) => tab.enabled());
    }
  },
  methods: {
    ...mapActions('Authentication', ['doLogout']),
    ...mapMutations('Stage', ['setCurrentTab']),
    ...mapMutations('Chat', ['setCurrentChatID', 'setIsChatList']),
    sidebarHeight() {
      const doc = document.documentElement;
      doc.style.setProperty('--sidebar-height', `${window.innerHeight}px`);
    },
    onResize() {
      this.expanded = window.innerWidth < 1024;
    },
    showProfileModal(profile) {
      document.body.classList.add('overflow-hidden');

      this.$modal.show(
        UserProfile,
        { profile },
        {
          clickToClose: true,
          adaptive: true,
          minWidth: 250,
          maxWidth: 1000,
          width: '90%',
          height: '80%'
        },
        {
          opened: () => this.$root.$emit('focusChild'),
          closed: () => document.body.classList.remove('overflow-hidden')
        }
      );
      this.closeMobileMenu();
    },
    openTab(tab, isDirectClick) {
      if (tab.onClick) {
        tab.onClick(tab);
        this.closeMobileMenu();
      }

      if (isDirectClick) {
        this.setCurrentChatID(false);
      }

      if (tab === this.chatTab && isDirectClick) {
        this.setIsChatList(true);
      }
    },
    showExpandableSideBar() {
      this.expanded = !this.expanded;
    }
  }
};
</script>

<style lang="scss" scoped>
:root {
  --sidebar-height: 100%;
}
.menu-width {
  width: 100% !important;
}
@media only screen and (max-width: 1023px) {
  .menu-sidebar {
    background-color: rgba(0, 0, 0, 0.758);
    position: relative;
    z-index: 9999 !important;
    height: 100vh;
    height: var(--sidebar-height);
    padding: 1em 1.5em 2em 1.5em;
  }
  @supports (height: 100dvh) {
    .menu-sidebar {
      background-color: rgba(0, 0, 0, 0.758);
      position: relative;
      z-index: 9999 !important;
      height: 100dvh;
      padding: 1em 1.5em 2em 1.5em;
    }
  }
  .menu-width {
    width: 16.666667% !important;
  }
  .close-button {
    position: absolute;
    left: -1em;
    top: 2em;
  }
  .expand-button {
    position: absolute;
    left: -1em;
    bottom: 7em;
  }
  .caption {
    width: 100%;
    text-align: left !important;
  }
  .caption__truncate {
    width: 97%;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
}
</style>
