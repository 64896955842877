import store from '../store';
import ApiClient from '@/client';

export default async function setPublicFullEvent({ next, to }) {
  const eventIdFromRoute = window.location.host.split('.')[0];
  const { commit, dispatch } = store;

  if (eventIdFromRoute) {
    const response = await ApiClient.getPublicFullEventInfo(eventIdFromRoute);
    commit('CurrentEvent/setEventId', response.data.eventId);
    dispatch('CurrentEvent/changeLanguage', response.data.languageId);
    commit('CurrentEvent/setEventDetails', response.data);
    commit('CurrentEvent/setHasFullEventDetails', true);

    document.title = response.data.eventName;
  }

  return next();
}
