<template>
  <SponsorDataProvider v-slot="{ sponsor, loading }">
    <div
      v-if="!loading"
      class="absolute top-0 bottom-0 w-full overflow-y-auto flex flex-col z-10l sponsor-contact"
    >
      <div class="p-4 pt-0 text-sm">
        <h2 class="text-md font-bold pb-6">
          {{ $t('Views.Sponsor.labelContactInformation') }}
        </h2>
        <div v-if="sponsor.name" v-text="sponsor.name" />
        <div>
          <span v-if="sponsor.address">{{ sponsor.address }}</span>
        </div>
        <div v-if="sponsor.phoneNumber">
          <a :href="'tel:' + sponsor.phoneNumber" target="_blank">
            {{ sponsor.phoneNumber }}
          </a>
        </div>
        <div v-if="sponsor.email">
          <a :href="'mailto:' + sponsor.email" target="_blank">
            {{ sponsor.email }}
          </a>
        </div>
        <div v-if="sponsor.website">
          <a :href="sponsor.website" target="_blank">
            {{ sponsor.website }}
          </a>
        </div>

        <ContactDetails :links="sponsor.links"></ContactDetails>
        <!-- <hr class="extended" />
        <h2 class="text-md leading-5 font-bold pt-5 pb-6">
          {{ $t('Views.Sponsor.labelSendMessage') }}
        </h2>
        <p>{{ $t('Views.Sponsor.sendMessageText') }}</p>
        <label>
          <textarea
            v-model="message"
            class="mt-5 bg-light text-dark text-sm p-4 pr-12 w-full rounded-lg resize-y"
            :placeholder="$t('Views.Sponsor.messagePlaceholder')"
            rows="6"
            @input="checkMessage"
          />
          <span
            v-if="sent"
            class="text-xs mb-4 font-extralight"
            v-text="$t('Views.Sponsor.messageSent')"
          />
        </label>
        <div class="flex justify-end">
          <button
            type="button"
            class="button button-primary mt-3"
            aria-label="Send"
            @click="sendMessage"
          >
            {{ $t('Views.Sponsor.send') }}
          </button>
        </div>-->
      </div>
      <div
        class="hidden lg:flex items-center justify-center h-16 bottom-0 fixed max-h-max w-full bg-white"
      >
        <TappinButton
          icon="share-square"
          :class="
            sponsor && sponsor.email === '' ? 'hidden' : 'button button-primary'
          "
          :custom_caption="$t('Views.Sponsor.sendInfo')"
          @click="sendContactInfo()"
        />
      </div>
    </div>
  </SponsorDataProvider>
</template>

<script>
import ApiClient from '@/client';
import TappinButton from '@/components/buttons/TappinButton';
import ContactDetails from '@/components/shared/ContactDetails';

export default {
  name: 'ContactInfo',
  components: { TappinButton, ContactDetails },
  data() {
    return {
      message: '',
      error: true,
      sent: false
    };
  },
  methods: {
    checkMessage() {
      this.error = this.message < 1;
    },
    sendContactInfo() {
      ApiClient.sendContactInfoToSponsor(this.$route.params.sponsorId).then(
        () => {
          this.$notify({
            group: 'default',
            title: this.$t('Views.Sponsor.successMessage')
          });
        }
      );
    },
    sendMessage() {
      if (!this.message) {
        this.error = true;
        return;
      }

      return ApiClient.sendContactMessageToSponsor(
        this.$route.params.sponsorId,
        this.message
      )
        .then((response) => {
          this.error = false;
          this.sent = true;
          this.message = '';
          setTimeout(() => (this.sent = false), 4500);
        })
        .catch(() => (this.error = true));
    }
  }
};
</script>

<style lang="scss" scoped>
.sponsor-contact {
  padding: 0 1rem;
  @media only screen and (min-width: 1024px) {
    padding-bottom: 5rem;
  }
}
.extended {
  width: calc(100% + 32px);
  margin-left: -16px;
  color: #ededed;
}
a {
  font-weight: bolder;
}
</style>
