<template>
  <div
    class="w-full h-full flex flex-col justify-center items-center text-center p-16"
  >
    <img src="/assets/tappin-logo.png" class="h-10 w-auto" />
    <h1 class="text-lg font-bold mt-6">
      {{ $t('Views.Fallback.title') }}
    </h1>
    <div class="w-full md:w-1/3 mt-5">
      <p>{{ $t('Views.Fallback.description') }}</p>
    </div>
    <a
      href="https://tappin.no/"
      class="bg-tappin py-2 px-3 rounded text-base mt-8"
      aria-label="Go to tappin.no"
    >
      {{ $t('Views.Fallback.button') }}
    </a>

    <button
      class="bg-tappin py-2 px-3 rounded text-base mt-4"
      aria-label="help"
      @click="toggleLiveChatWindow"
    >
      {{ $t('Views.Fallback.help') }}
    </button>
  </div>
</template>

<script>
import { togglesSupportChat } from '@/mixins';

export default {
  name: 'Fallback',
  mixins: [togglesSupportChat]
};
</script>

<style scoped>
.bg-tappin {
  background-color: #94f7f5;
}
</style>
