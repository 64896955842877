<template>
  <CurrentEventDataProvider v-slot="{ data, logoUrl }">
    <div class="flex flex-wrap overflow-hidden w-full h-full md:space-y-2">
      <!-- Navbar -->
      <div
        class="navbar top-0 z-30 flex justify-between items-center text-center sticky font-bold bg-dark px-4 w-full"
      >
        <div class="flex items-center h-16">
          <img
            :src="'https://jolly-coast-0ed26f703-development.westeurope.3.azurestaticapps.net/ec7c06a311e758b602863004841e8d19.svg'"
            :alt="logoUrl && 'Event\'s logo'"
            class="object-scale-down h-6 md:h-12 w-auto"
          />
        </div>
      </div>
      <!--  -->
      <div class="flex flex-row w-full h-full px-2 md:px-4">
        <!-- Sidebar -->
        <div v-if="showSlider" class="w-full md:w-1/4 flex flex-col">
          <div class="sidebar-container shadow-lg p-4 space-y-4 md:space-y-6">
            <!-- Event detail -->
            <div class="flex justify-between items-center w-full">
              <div class="shadow-md p-1 rounded-full">
                <img
                  :src="logoUrl"
                  :alt="logoUrl && 'Event\'s logo'"
                  class="object-scale-down h-8 w-8"
                />
              </div>
              <h2 class="font-bold text-md">
                {{ data.eventName }}
              </h2>
              <i class="fas fa-chevron-down ml-2" aria-hidden="true" />
            </div>
            <!-- Filters refresh-->
            <div
              class="flex flex-col md:flex-row justify-between md:items-center w-full"
            >
              <!-- Filters hidden for small screen-->
              <h2
                class="hidden md:flex font-bold text-base pt-2 md:pt-0 items-center"
              >
                Filters
              </h2>
              <div class="flex w-full justify-between md:justify-end">
                <TappinButton
                  :disabled="loading"
                  :icon="loading ? 'circle-notch' : 'repeat'"
                  @click="refreshItems"
                  class="button button-secondary text-center items-center"
                  :aria-label="loading ? 'loading' : 'Refresh'"
                  :custom_caption="
                    loading ? '' : $t('Views.PollResults.refresh')
                  "
                />
                <TappinButton
                  v-if="showSlider"
                  :icon="'close'"
                  @click="toggleSlider"
                  class="inline md:hidden whitespace-nowrap button button-secondary"
                  :aria-label="'Close filter'"
                  :custom_caption="'close filter'"
                />
              </div>
              <!-- Filters hidden for large screen-->
              <h2 class="md:hidden font-bold text-base pt-2 md:pt-0">
                Filters
              </h2>
            </div>
            <!-- Date picker -->
            <div class="flex flex-col gap-2">
              <h2 class="font-bold text-base">Date</h2>
              <Filters
                showDates
                @date-filter="handleDates"
                @checkbox-filter="handleCheckBoxFilters"
              />
            </div>
            <!--Select template-->
            <div
              v-if="!showStream && !pollAccessKey"
              class="flex flex-col gap-2"
            >
              <label for="generic-template">{{
                $t('Views.PollResults.selectQuizGroup')
              }}</label>
              <div class="relative h-8 flex flex-wrap my-2">
                <input
                  type="text"
                  placeholder="Search here..."
                  class="border border-black rounded-lg w-full"
                  @click="showSelectTemplate = true"
                  v-model="searchTemplateQuery"
                  @input="handleTemplateInput"
                />
                <div
                  class="absolute bottom-0 right-0 pr-4"
                  v-if="searchTemplateQuery !== ''"
                  @click="clearSearchInput"
                >
                  <i class="far fa-times" />
                </div>
              </div>
              <select
                v-show="showSelectTemplate"
                id="generic-template"
                v-model="selectedTemplate"
                @change="handleTemplateChange"
                class="flex flex-col border border-black rounded-lg p-2 gap-2 w-full"
                size="5"
              >
                <option disabled value="">
                  {{ $t('Views.PollResults.selectQuizGroup') }}
                </option>
                <!-- Filtered options based on searchQuery -->
                <option
                  v-for="template in filteredTemplates"
                  :key="template.id"
                  :value="template"
                >
                  {{ template.title }}
                </option>
              </select>
            </div>
            <!--Quiz list-->
            <div
              v-if="!showStream && !pollAccessKey"
              class="flex flex-col gap-2"
            >
              <label for="generic-input">{{
                $t('Views.PollResults.selectQuiz')
              }}</label>
              <!-- Search input field -->
              <div class="relative h-8 flex flex-wrap my-2">
                <input
                  type="text"
                  v-model="searchQuery"
                  placeholder="Search here..."
                  class="border border-black rounded-lg w-full"
                  @click="showSelect = true"
                  @input="handleInput"
                />
                <div
                  class="absolute bottom-0 right-0 pr-4"
                  v-if="searchQuery !== ''"
                  @click="clearQuizSearchInput"
                >
                  <i class="far fa-times" />
                </div>
              </div>
              <select
                v-show="showSelect"
                id="generic-input"
                v-model="selectedGenericPoll"
                @change="handleChangeQuiz"
                class="flex flex-col border border-black rounded-lg p-2 gap-2 w-full"
                size="5"
              >
                <option disabled value="">Select a quiz</option>
                <!-- Filtered options based on searchQuery -->
                <option
                  v-for="poll in filteredPolls"
                  :key="poll.id"
                  :value="poll"
                >
                  {{ poll.title }}
                </option>
              </select>
            </div>
            <!-- Group -->
            <div class="flex flex-col justify-between gap-2">
              <div class="flex justify-between border-b border-grey p-2">
                <h2 class="font-bold text-base">
                  {{ $t('Components.Filters.groups') }}
                </h2>
                <i class="fas fa-chevron-down ml-2" aria-hidden="true" />
              </div>
              <Filters
                showCheckbox
                @checkbox-filter="handleCheckBoxFilters"
                :groups="groups"
                :tableCheckBoxName="$t('Components.Filters.groups')"
                showGroupModal
              />
            </div>
          </div>
        </div>
        <!-- Content -->
        <div
          :class="
            showSlider
              ? 'w-1/6 md:w-3/4 flex flex-col space-y-4 h-full md:ml-4'
              : 'w-full md:w-3/4 flex flex-col space-y-4 h-full md:ml-4'
          "
        >
          <!--loader-->
          <div v-if="loading" slot="footer" class="loading-spinner">
            <Spinner />
          </div>
          <div
            class="poll-results shadow-lg py-2 md:p-2 space-y-0"
            style="
              color: var(--login-color-text);
              background-color: var(--login-background-color);
            "
          >
            <!--poll content-->
            <div
              :class="[
                'custom-card-parent',
                'flex',
                'flex-col',
                'h-full',
                'bg-white',
                'text-dark',
                'justify-between',
                'py-2'
              ]"
            >
              <div class="flex flex-wrap justify-between sticky top-0 bg-white">
                <div v-if="isSingelPoll" class="flex gap-2 justify-center p-4">
                  <button
                    v-if="poll.pollType === 0 || poll.pollType === 10"
                    class="button button-primary"
                    @click="handleShowStream"
                  >
                    {{
                      !showStream
                        ? $t('Views.PollResults.showPollStream')
                        : $t('Views.PollResults.goToResults')
                    }}
                  </button>
                  <button
                    v-if="poll.pollType === 1"
                    class="button button-primary"
                    @click="handleShowQuizManagement"
                  >
                    {{
                      !showStream
                        ? $t('Views.PollResults.showOpenTextManagement')
                        : $t('Views.PollResults.goToResults')
                    }}
                  </button>
                  <TappinButton
                    v-if="!showStream"
                    :disabled="loading"
                    @click="refreshItems"
                    class="whitespace-nowrap button button-secondary mt-2 md:mt-0"
                    :aria-label="loading ? 'loading' : 'Refresh'"
                    :icon="loading ? 'circle-notch' : ''"
                    :custom_caption="
                      loading ? '' : $t('Views.PollResults.refresh')
                    "
                  />
                </div>
                <div v-else class="flex gap-2 justify-center pb-2">
                  <button
                    class="button button-primary"
                    @click="handleShowStream"
                  >
                    {{
                      showStream && showMultipleStream
                        ? $t('Views.PollResults.goToResults')
                        : $t('Views.PollResults.showQuizStream')
                    }}
                  </button>
                  <button
                    class="button button-primary"
                    @click="handleShowQuizManagement"
                  >
                    {{
                      showStream && showOpenTextManagement
                        ? $t('Views.PollResults.goToResults')
                        : $t('Views.PollResults.showOpenTextManagement')
                    }}
                  </button>
                  <button
                    class="button button-primary"
                    @click="handleShowQuizList"
                  >
                    {{
                      showStream && showQuizList
                        ? $t('Views.PollResults.goToResults')
                        : 'Show quiz list'
                    }}
                  </button>
                </div>
                <!--header and export-->
                <div class="flex space-x-2">
                  <div class="hidden md:flex" v-if="selectedPage !== null">
                    <TappinButton
                      :icon="loadingExport ? 'circle-notch' : ''"
                      class="button button-primary"
                      :disabled="loadingExport"
                      @click="handleExport"
                      :custom_caption="loadingExport ? '' : 'Export'"
                    />
                  </div>
                  <div class="button flex lg:hidden" @click="toggleSlider">
                    <TappinButton
                      v-if="!showSlider"
                      icon="filter"
                      class="button button-primary"
                      :custom_caption="'Filters'"
                    />
                  </div>
                </div>
              </div>
              <div v-if="!showStream" class="flex flex-col w-full flex-wrap">
                <h1 class="text-center font-bold pb-2 text-xl">
                  {{ poll.question }}
                </h1>
                <div
                  v-if="isQuizOrTreasure"
                  class="flex flex-row justify-between gap-2 font-bold mb-6 w-full"
                >
                  <select
                    v-model="selectedPoll"
                    @change="handleChange"
                    class="w-1/2 md:w-1/3"
                  >
                    <option
                      v-for="poll in poll.items"
                      :key="poll.id"
                      :value="poll"
                    >
                      {{ poll.poll.question }}
                    </option>
                  </select>
                  <div class="flex justify-between gap-2">
                    <PollActions
                      :hasPrevious="hasPrevious"
                      :hasNext="hasNext"
                      :previous="previousPoll"
                      :next="nextPoll"
                    />
                  </div>
                </div>
                <ResultsTable :data="dataTable" />
              </div>
              <div v-else-if="showMultipleStream && isQuizOrTreasure">
                <QuizStream
                  :showPartial="false"
                  resultsPageType="quiz"
                  :pollAccessKey="selectedAccessKey || pollAccessKey"
                  :resultsFromDate="fromDate"
                  :resultsToDate="toDate"
                  :resultsGroups="groupsFilter"
                />
              </div>
              <div v-else-if="showOpenTextManagement && isQuizOrTreasure">
                <QuizManagement
                  :showPartial="false"
                  :pollAccessKey="selectedAccessKey || pollAccessKey"
                  :resultsFromDate="fromDate"
                  :resultsToDate="toDate"
                  :resultsGroups="groupsFilter"
                />
              </div>
              <div v-else-if="showQuizList && isQuizOrTreasure">
                <QuizList :polls="allPolls" />
              </div>
              <div v-else-if="isSingelPoll && showMultipleStream">
                <PollStream :pollAccessKey="pollAccessKey" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import ApiClient from '@/client';
import { mapActions, mapGetters } from 'vuex';
import Filters from '@/components/shared/Filters.vue';
import Spinner from '@/components/shared/Spinner';
import TappinButton from '@/components/buttons/TappinButton.vue';
import PollActions from '@/components/shared/PollActions.vue';
import { pollType, resultsPageType } from '@/constants/poll-type-constants';
import QuizStream from './QuizStream.vue';
import ResultsTable from '@/components/shared/ResultsTable.vue';
import QuizManagement from './QuizManagement.vue';
import QuizList from './QuizList.vue';
import PollStream from './PollStream.vue';
import fileDownload from 'js-file-download';

export default {
  props: {
    pollAccessKey: String
  },
  components: {
    Spinner,
    Filters,
    TappinButton,
    PollActions,
    QuizStream,
    ResultsTable,
    QuizList,
    QuizManagement,
    PollStream
  },
  data() {
    return {
      loading: false,
      checkBoxFilter: [],
      groupsFilter: [],
      fromDate: '',
      toDate: '',
      loadingFilter: false,
      showSlider: false,
      dataTable: [],
      currentPoll: 0,
      registrations: [],
      poll: {},
      item: {},
      pollItem: {},
      selectedPoll: null,
      searchQuery: '',
      searchTemplateQuery: '',
      selectedTemplate: null,
      selectedGenericPoll: null,
      showStream: false,
      showMultipleStream: false,
      showQuizList: false,
      showOpenTextManagement: false,
      templates: [],
      showSelect: false,
      showSelectTemplate: false,
      pollStream: {},
      allPolls: [],
      quizes: [],
      selectedAccessKey: null,
      selectedPage: resultsPageType.RESULTS,
      loadingExport: false
    };
  },
  created() {
    this.fetchItems();
    this.fetchGroups();

    this.checkScreenSize();
    // Add an event listener to react to screen resize
    window.addEventListener('resize', this.checkScreenSize);
  },
  computed: {
    ...mapGetters('Groups', { groups: 'getItems' }),
    ...mapGetters('CurrentEvent', [{ eventName: 'getTitle' }]),
    ...mapGetters('CurrentEvent', [
      'getLocale',
      'getEvent',
      'isWebappEnabled',
      'isLobbyEnabled',
      'features'
    ]),
    filteredPolls() {
      // Filter polls based on the search query
      return this.quizes.filter((poll) => {
        return poll.title
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    },
    filteredTemplates() {
      return this.templates.filter((template) => {
        return template.title
          .toLowerCase()
          .includes(this.searchTemplateQuery.toLowerCase());
      });
    },
    hasNext() {
      return this.poll?.items?.length !== this.currentPoll + 1;
    },
    hasPrevious() {
      return this.currentPoll !== 0;
    },
    isQuizOrTreasure() {
      return (
        this.poll.pollType === pollType.QUIZ ||
        this.poll.pollType === pollType.TREASURE_HUNT
      );
    },
    isSingelPoll() {
      return (
        this.poll.pollType === pollType.MULTIPLE_ANSWERS ||
        this.poll.pollType === pollType.OPEN_TEXT ||
        this.poll.pollType === pollType.RATING
      );
    }
  },
  methods: {
    ...mapActions('Polls', ['getPollResults']),
    ...mapActions('Groups', { fetchGroups: 'fetchItems' }),
    toggleSlider() {
      this.showSlider = !this.showSlider;
    },
    handleInput() {
      // Update filteredPolls based on searchQuery
      // Show the select element only if there's input in the search field
      this.showSelect = this.searchQuery.length > 0;
    },
    handleTemplateInput() {
      // Update filteredTemplates based on searchTempalteQuery
      // Show the select element only if there's input in the search field
      this.showSelectTemplate = this.searchTemplateQuery.length > 0;
    },
    handleDates(e) {
      this.fromDate = e.from;
      this.toDate = e.to;

      if (
        this.toDate ||
        (this.fromDate && this.toDate) ||
        (!this.fromDate && !this.toDate)
      ) {
        this.fetchPollResults();
      }
    },
    handleCheckBox(e) {
      const value = e.target.value.trim();
      const isChecked = this.checkBoxFilter.find((v) => v === value);
      if (!isChecked) {
        this.checkBoxFilter.push(value);
      } else {
        this.checkBoxFilter = this.checkBoxFilter.filter(
          (v) => v.trim() !== value
        );
      }
    },
    handleCheckBoxFilters(e) {
      this.groupsFilter = e;
      this.loadingFilter = true;
      this.fetchPollResults();
    },
    handleChange() {
      const index = this.poll.items.findIndex(
        (x) => x.poll.id === this.selectedPoll.pollId
      );
      this.currentPoll = index;
      this.handleData(this.registrations, this.selectedPoll);
    },
    handleTemplateChange() {
      if (this.selectedTemplate) {
        this.searchTemplateQuery = this.selectedTemplate.title;
        this.selectedAccessKey = this.selectedTemplate?.accessKey;

        this.quizes = this.allPolls.filter(
          (x) => x.templateId == this.selectedTemplate.id
        );
        this.currentPoll = 0;
        this.selectedGenericPoll = null;
        this.showSelectTemplate = false;
        this.fetchPollResults();
      } else {
        this.searchTemplateQuery = '';
      }
    },
    clearSearchInput() {
      this.searchTemplateQuery = '';
      this.showSelectTemplate = true;
    },

    handleChangeQuiz() {
      if (this.selectedGenericPoll) {
        this.currentPoll = 0;
        this.searchQuery = this.selectedGenericPoll.title;
        this.selectedAccessKey = this.selectedGenericPoll?.accessKey;
        this.selectedTemplate = null;
        this.fetchPollResults();
      } else {
        this.searchQuery = '';
      }
    },
    clearQuizSearchInput() {
      this.searchQuery = '';
      this.showSelect = true; // Optional: hide select dropdown after clearing input
    },
    async fetchItems() {
      this.loading = true;
      if (!this.pollAccessKey) {
        const res = await ApiClient.getGenericPollResults();
        const templates = res.data.filter((x) => x.isTemplate);
        const quizes = res.data.filter((x) => !x.isTemplate);
        this.templates = templates;
        this.quizes = quizes;
        this.allPolls = res.data;
        this.selectedGenericPoll = quizes[0];
        this.selectedAccessKey = quizes[0].accessKey;
      }
      this.fetchPollResults();
    },
    async fetchPollResults() {
      this.loading = true;
      const {
        data: { liveEventPollResults, poll },
        status
      } = this.selectedGenericPoll?.templateId
        ? await ApiClient.getPollUsingTemplateResults(
            this.selectedGenericPoll.id,
            this.fromDate,
            this.toDate,
            this.groupsFilter
          )
        : this.selectedTemplate
        ? await ApiClient.getTemplatePollResults(
            this.selectedTemplate.id,
            this.fromDate,
            this.toDate,
            this.groupsFilter
          )
        : await ApiClient.getPollResults(
            this.selectedGenericPoll?.accessKey || this.pollAccessKey,
            this.fromDate,
            this.toDate,
            this.groupsFilter
          );
      this.registrations = liveEventPollResults.votesRegistrations;
      this.poll = poll;
      if (status === 200) {
        this.loading = false;
      }
      if (this.isSingelPoll) {
        this.item = this.selectedPoll = poll;
        this.handleData(liveEventPollResults.votesRegistrations, this.item);
      } else if (this.isQuizOrTreasure) {
        this.item = this.poll.items[0];
        this.pollItem = this.item.poll;
        this.selectedPoll = this.poll.items[0];
        this.handleData(liveEventPollResults.votesRegistrations, this.item);
      }
    },
    changePoll(direction) {
      this.currentPoll += direction;
      this.item = this.poll.items[this.currentPoll];
      this.handleData(this.registrations, this.item);
      this.selectedPoll = this.item;
    },
    nextPoll() {
      this.changePoll(1);
    },
    previousPoll() {
      this.changePoll(-1);
    },
    handleData(registrations, item) {
      this.dataTable = registrations.flatMap((registration) => {
        const matchingVotes = item.votes.filter(
          (vote) => vote.answeredBy === registration.id
        );
        if (matchingVotes.length > 0) {
          return matchingVotes.map((matchingVote) => ({
            label: matchingVote.label,
            name: registration.name,
            lastName: registration.lastName,
            email: registration.email
          }));
        }
        return [];
      });
    },

    handleShowStream() {
      this.selectedPage = null;
      if (this.selectedGenericPoll) {
        this.selectedAccessKey = this.selectedGenericPoll.accessKey;
      }

      if (this.selectedTemplate) {
        this.selectedAccessKey = this.selectedTemplate.accessKey;
      }
      this.showStream = true;
      this.showOpenTextManagement = false;
      this.showQuizList = false;
      this.showMultipleStream = !this.showMultipleStream;
      if (!this.showMultipleStream) {
        this.handleShowResults();
      }
    },
    handleShowQuizManagement() {
      this.selectedPage = resultsPageType.OPEN_TEXT;
      if (this.selectedGenericPoll) {
        this.selectedAccessKey = this.selectedGenericPoll.accessKey;
      }

      if (this.selectedTemplate) {
        this.selectedAccessKey = this.selectedTemplate.accessKey;
      }
      this.showStream = true;
      this.showMultipleStream = false;
      this.showQuizList = false;
      this.showOpenTextManagement = !this.showOpenTextManagement;
      if (!this.showOpenTextManagement) {
        this.handleShowResults();
      }
    },
    handleShowQuizList() {
      this.selectedPage = resultsPageType.QUIZ_LIST;
      this.showStream = true;
      this.showOpenTextManagement = false;
      this.showMultipleStream = false;
      this.showQuizList = !this.showQuizList;
      if (!this.showQuizList) {
        this.handleShowResults();
      }
    },
    handleShowResults() {
      this.selectedPage = resultsPageType.RESULTS;
      this.showStream = false;
    },
    checkScreenSize() {
      // Use window.innerWidth to get the current viewport width
      // You can adjust the breakpoint value according to your requirements
      if (window.innerWidth >= 1024) {
        this.showSlider = true;
      } else {
        this.showSlider = false;
      }
    },
    refreshItems() {
      this.selectedTemplate = null;
      this.fetchItems();
    },
    async handleExport() {
      this.loadingExport = true;
      if (this.selectedPage === resultsPageType.QUIZ_LIST) {
        await ApiClient.exportQuizList();
        this.loadingExport = false;
        return;
      }
      const res = this.selectedGenericPoll?.templateId
        ? await ApiClient.exportPollUsingTemplateResults(
            this.selectedGenericPoll.id,
            this.selectedPage,
            this.fromDate,
            this.toDate,
            this.groupsFilter
          )
        : this.selectedTemplate
        ? await ApiClient.exportTemplatePollResults(
            this.selectedTemplate.id,
            this.selectedPage,
            this.fromDate,
            this.toDate,
            this.groupsFilter
          )
        : await ApiClient.exportPollResults(
            this.selectedGenericPoll?.accessKey || this.pollAccessKey,
            this.selectedPage,
            this.fromDate,
            this.toDate,
            this.groupsFilter
          );
      this.loadingExport = false;
    }
  },
  destroyed() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('resize', this.checkScreenSize);
  }
};
</script>

<style scoped lang="scss">
:root {
  --sidebar-height: 100%;
}
@media screen and (max-width: 1023px) {
  .slider {
    display: none;
    position: fixed;
    top: -100%; /* Initially hide the slider off-screen */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: top 0.5s; /* Add a smooth transition for opening and closing */
    z-index: 999; /* Ensure the slider is above other content */
  }

  .slider.open {
    top: 0; /* Move the slider to the top of the screen when open */
  }
}
.slider {
  display: flex;
}
.custom-card-parent .card {
  box-shadow: none;
}
.poll-results {
  height: calc(100vh - 5em);
  .card {
    .toggle-type {
      color: var(--color-secondary-bg);
      opacity: 0.75;
    }
  }
}
.sidebar-container {
  overflow-y: auto;
  height: calc(100vh - 5em);
}
</style>
