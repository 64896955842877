import store from '../store';
import ApiClient from '@/client';

export default async function setSpeakersIframe({ next, to }) {
  const eventIdFromRoute = window.location.host.split('.')[0];
  const { commit } = store;
  if (eventIdFromRoute) {
    const speakersResponse = await ApiClient.getIframeSpeakers();
    commit('Speakers/setItems', speakersResponse.data);
  }
  return next();
}
