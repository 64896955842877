<template>
  <button
    class="delete text-xs md:text-sm"
    aria-label="remove the item"
    @click="$emit('click')"
  >
    <i class="fal fa-trash mr-2" aria-hidden="true" />
    <span class="text-xs md:text-sm">
      {{ custom_caption }}
    </span>
  </button>
</template>
<script>
export default {
  name: 'DeleteButton',
  props: {
    custom_caption: {}
  }
};
</script>
<style scoped>
.delete {
  width: 80%;
  padding: 0.5em;
  border-width: 1px;
  border-color: #d10202;
  border-radius: 9999px;
  color: #d10202;
}
</style>
