<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <div class="flex flex-wrap flex-col h-screen" :style="designStyles">
      <div class="m-auto">
        <div class="flex flex-col items-center text-center">
          <Avatar
            :image="getUser(getChatOtherUser()[0]).Picture"
            :circle="true"
            :size="'xlarge'"
            @error="setAltImg"
          />
          <div class="pb-32">
            <h2 class="text-md mb-8">
              {{
                getUser(getChatOtherUser()[0]).Firstname +
                ' ' +
                getUser(getChatOtherUser()[0]).Lastname
              }}
            </h2>
            <p>Calling...</p>
          </div>
        </div>
        <div class="flex justify-center">
          <button class="button mr-1 pl-6 pr-8 py-4" @click="joinCall()">
            JOIN!
          </button>
        </div>
        <div class="flex flex-wrap justify-center">
          <div class="bg-gray-900 text-white">
            <button class="button mr-1 px-6 py-4">
              <i class="fal fa-video fa-lg" />
            </button>
            <button class="button mr-1 pl-6 pr-8 py-4">
              <i class="fal fa-microphone fa-lg" />
            </button>
            <button class="button bg-red-600 px-6 py-4" @click="endCall()">
              <i class="fal fa-phone-alt fa-lg" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Avatar from '@/components/shared/Avatar';
export default {
  name: 'OutgoingCall',
  components: {
    Avatar
  },
  computed: {
    ...mapGetters('Chat', ['getCurrentChat']),
    ...mapGetters('Users', { getUser: 'getById' }),
    ...mapGetters('Authentication', { getOwnUser: 'getUser' })
  },
  methods: {
    ...mapMutations('Chat', ['setIsOutGoingCall', 'setCallEnded', 'setInCall']),
    getChatOtherUser() {
      return this.getCurrentChat.Users.filter(
        (item) => item !== this.getOwnUser.UserID
      );
    },
    endCall() {
      this.setIsOutGoingCall(false);
      this.setCallEnded(true);
    },
    joinCall() {
      this.setIsOutGoingCall(false);
      this.setInCall(true);
    }
  }
};
</script>
