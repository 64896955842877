<template>
  <button @click="$emit('click')" :type="type" class="flex items-center">
    <div>
      <span class="text-sm md:text-base">
        {{ custom_caption }}
      </span>
    </div>
  </button>
</template>
<script>
export default {
  name: 'PollButton',
  props: {
    custom_caption: {},
    type: { type: String, default: 'button' }
  }
};
</script>
