<template>
  <div class="flex flex-col flex-wrap">
    <div class="flex justify-between mb-8 shrink-0 grow-0 flex-auto">
      <h1 class="font-bold text-lg">
        {{ quizTitle ? quizTitle : '' }}
      </h1>
    </div>
    <div class="p-0 md:p-4 flex flex-col h-full">
      <div class="mb-8 shrink-0 grow-0 flex-auto">
        <h2 class="font-bold text-md">
          {{ poll.title }}
        </h2>
      </div>
      <div class="flex flex-col overflow-y-auto item-container">
        <Card
          v-for="item in filteredVotes"
          :key="item.createdOn"
          class="item p-2 flex justify-between"
        >
          <div
            class="text flex flex-col flex-grow-1 shrink-1"
            :class="{ 'opacity-25': item.isHidden }"
          >
            <div class="time text-xs mb-2 font-bold text-dark">
              <Moment
                :value="item.createdOn"
                format="YYYY-MM-DD HH:mm:ss"
                :is-offset="false"
              />
            </div>
            <div class="question">
              <span>{{ item.label }}</span>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from '../shared/Card.vue';
export default {
  props: {
    poll: Object,
    quizTitle: String
  },
  components: {
    Card
  },
  data() {
    return {
      showPieChart: false
    };
  },
  computed: {
    filteredVotes() {
      return this.poll.votes.filter((item) => item.label !== '');
    }
  }
};
</script>

<style scoped lang="scss">
.item-container {
  height: 45vh;
  overflow-y: auto;
}
.item {
  .time {
    color: #888;
  }
  .button-primary {
    background: #52cdda;
    border-color: #52cdda;
    color: #fff;
  }
  .button-secondary {
    background: #720600;
    border-color: #720600;
    color: #fff;
  }
}
</style>
