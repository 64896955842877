// Functions to interact with Google Analytics

function createAnalyticsEvent(gtag, action, category, label) {
  gtag.event(action, {
    event_category: category,
    event_label: label
  });
}

function createAnalyticsPageView(gtag, path, title) {
  gtag.pageview({
    page_path: path,
    page_title: title
  });
}

export { createAnalyticsEvent, createAnalyticsPageView };
