var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ContentWithSidebarLayout',{attrs:{"sidebar-collapsed":_vm.sidebarCollapsed},on:{"closed":function($event){_vm.sidebarCollapsed = false},"opened":function($event){_vm.sidebarCollapsed = true}}},[_c('template',{slot:"navbar"},[_c('Navbar',{attrs:{"show-nav":true,"tabs":_vm.tabs}})],1),_c('template',{slot:"mainContent"},[_c('SponsorDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ sponsor, loading }){return [(!loading)?_c('StreamDataProvider',{attrs:{"set-from-sponsor":sponsor},scopedSlots:_vm._u([{key:"default",fn:function({
          isReady,
          isImage,
          hasStream,
          embedUrl,
          embedType,
          isMainEventStream,
          switchToMainEventStream,
          iframeAllowAttributes
        }){return [_c('div',{staticClass:"relative text-white flex flex-col flex-1 h-full"},[(_vm.showStream)?_c('StageStream',{staticClass:"md:p-4 md:pt-0",attrs:{"isReady":isReady,"isImage":isImage,"hasStream":hasStream,"embedUrl":embedUrl,"embedType":embedType,"iframeAllowAttributes":iframeAllowAttributes}}):_vm._e(),_c('div',{staticClass:"flex lg:hidden flex-col text-dark"},[(!isMainEventStream && _vm.$route.query.fromStage)?_c('TappinButton',{staticClass:"button-primary cursor-pointer font-bold p-2 space-x-2",attrs:{"icon":"chevron-left","aria-label":"back to main stream","custom_caption":_vm.screenWidth > 1024
                  ? _vm.$t('Views.Sponsor.backToMainStream')
                  : _vm.$t('Views.Sponsor.backToMainStage')},on:{"click":switchToMainEventStream}}):_vm._e(),_c('div',{staticClass:"flex justify-between text-center"},[_c('TappinButton',{staticClass:"button-primary cursor-pointer font-bold p-2 space-x-2 text-sm lg:text-base",attrs:{"icon":"chevron-left","custom_caption":_vm.computedBackFromLabel},on:{"click":function($event){return _vm.goBack()}}}),_c('TappinButton',{directives:[{name:"show",rawName:"v-show",value:(hasStream),expression:"hasStream"}],staticClass:"button cursor-pointer text-dark p-2 flex flex-row items-center justify-center space-x-2 text-sm lg:text-base",attrs:{"icon":"tv","custom_caption":_vm.isStreamOff
                    ? _vm.$t('Views.Stage.showStream')
                    : _vm.$t('Views.Stage.hideStream')},on:{"click":function($event){return _vm.hideStream()}}})],1)],1)],1)]}}],null,true)}):_vm._e()]}}])})],1),_c('template',{slot:"belowMainContent"},[_c('CurrentEventDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('div',{staticClass:"hidden justify-between lg:flex h-16"},[_c('TappinButton',{staticClass:"button-primary cursor-pointer text-dark font-bold p-2 hover:opacity-50 transition-all duration-200 space-x-2",attrs:{"icon":"chevron-left","custom_caption":_vm.computedBackFromLabel},on:{"click":function($event){return _vm.goBack()}}}),_c('TappinButton',{staticClass:"button-secondary cursor-pointer font-bold p-2 space-x-2",attrs:{"id":"allSponsors","icon":"list","aria-label":"See all sponsors","custom_caption":_vm.$t('Views.Sponsor.seeAll')},on:{"click":_vm.showSponsorOverviewModal}})],1)]}}])})],1),_c('template',{slot:"sidebarContent"},[_c('div',{staticClass:"h-full relative"},_vm._l((_vm.tabs),function(tab){return _c(tab.component,{directives:[{name:"show",rawName:"v-show",value:(tab === _vm.currentTab),expression:"tab === currentTab"}],key:tab.key,tag:"component"})}),1)]),_c('template',{slot:"sidebar"},[_c('div',{staticClass:"bg-navbar hidden lg:flex flex-col flex-1 justify-between"},[_c('SideBarTabs',{attrs:{"tabs":_vm.tabs}})],1)]),_c('template',{slot:"bottom"},[_c('SponsorDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ sponsor }){return [_c('div',{staticClass:"lg:hidden flex justify-between p-4"},[_c('TappinButton',{class:sponsor && sponsor.email === ''
              ? 'hidden'
              : 'button button-primary',attrs:{"icon":"share-square","custom_caption":_vm.$t('Views.Sponsor.sendInfo')},on:{"click":function($event){return _vm.sendContactInfo()}}}),_c('TappinButton',{staticClass:"button button-secondary",attrs:{"icon":"list","id":"allSponsors","aria-label":"See all sponsors","custom_caption":_vm.$t('Views.Sponsor.seeAll')},on:{"click":_vm.showSponsorOverviewModal}})],1)]}}])})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }