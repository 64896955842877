<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SpeakersIframeDataProvider',
  computed: {
    ...mapGetters('Speakers', ['getItems', 'isLoading', 'failed']),

    ...mapGetters('CurrentEvent', ['features'])
  },
  render() {
    return this.$scopedSlots.default({
      speakers: this.getItems,
      loading: this.isLoading,
      failed: this.failed,
      refresh: this.refresh,
      features: this.features
    });
  }
};
</script>
