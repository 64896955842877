<template>
  <ContentWithSidebarLayout
    :sidebar-collapsed="sidebarCollapsed"
    @closed="sidebarCollapsed = false"
    @opened="sidebarCollapsed = true"
  >
    <template slot="navbar">
      <Navbar :show-nav="true" :tabs="tabs" />
    </template>
    <template slot="mainContent">
      <!-- Main stream -->
      <SponsorDataProvider v-slot="{ sponsor, loading }">
        <StreamDataProvider
          v-if="!loading"
          v-slot="{
            isReady,
            isImage,
            hasStream,
            embedUrl,
            embedType,
            isMainEventStream,
            switchToMainEventStream,
            iframeAllowAttributes
          }"
          :set-from-sponsor="sponsor"
        >
          <div class="relative text-white flex flex-col flex-1 h-full">
            <StageStream
              v-if="showStream"
              :isReady="isReady"
              :isImage="isImage"
              :hasStream="hasStream"
              :embedUrl="embedUrl"
              :embedType="embedType"
              :iframeAllowAttributes="iframeAllowAttributes"
              class="md:p-4 md:pt-0"
            />
            <div class="flex lg:hidden flex-col text-dark">
              <TappinButton
                v-if="!isMainEventStream && $route.query.fromStage"
                icon="chevron-left"
                class="button-primary cursor-pointer font-bold p-2 space-x-2"
                aria-label="back to main stream"
                @click="switchToMainEventStream"
                :custom_caption="
                  screenWidth > 1024
                    ? $t('Views.Sponsor.backToMainStream')
                    : $t('Views.Sponsor.backToMainStage')
                "
              />
              <div class="flex justify-between text-center">
                <TappinButton
                  icon="chevron-left"
                  class="button-primary cursor-pointer font-bold p-2 space-x-2 text-sm lg:text-base"
                  @click="goBack()"
                  :custom_caption="computedBackFromLabel"
                />
                <TappinButton
                  v-show="hasStream"
                  icon="tv"
                  class="button cursor-pointer text-dark p-2 flex flex-row items-center justify-center space-x-2 text-sm lg:text-base"
                  :custom_caption="
                    isStreamOff
                      ? $t('Views.Stage.showStream')
                      : $t('Views.Stage.hideStream')
                  "
                  @click="hideStream()"
                />
              </div>
            </div>
          </div>
        </StreamDataProvider>
      </SponsorDataProvider>
    </template>

    <template slot="belowMainContent">
      <CurrentEventDataProvider v-slot="{}">
        <div class="hidden justify-between lg:flex h-16">
          <TappinButton
            icon="chevron-left"
            class="button-primary cursor-pointer text-dark font-bold p-2 hover:opacity-50 transition-all duration-200 space-x-2"
            @click="goBack()"
            :custom_caption="computedBackFromLabel"
          />
          <TappinButton
            id="allSponsors"
            icon="list"
            class="button-secondary cursor-pointer font-bold p-2 space-x-2"
            aria-label="See all sponsors"
            @click="showSponsorOverviewModal"
            :custom_caption="$t('Views.Sponsor.seeAll')"
          />
        </div>
      </CurrentEventDataProvider>
    </template>

    <template slot="sidebarContent">
      <!-- Sidebar active tab -->
      <div class="h-full relative">
        <component
          :is="tab.component"
          v-for="tab in tabs"
          v-show="tab === currentTab"
          :key="tab.key"
        />
      </div>
    </template>

    <template slot="sidebar">
      <!-- Tab Buttons -->
      <div class="bg-navbar hidden lg:flex flex-col flex-1 justify-between">
        <SideBarTabs :tabs="tabs" />
      </div>
    </template>

    <template slot="bottom">
      <SponsorDataProvider v-slot="{ sponsor }">
        <div class="lg:hidden flex justify-between p-4">
          <TappinButton
            icon="share-square"
            :class="
              sponsor && sponsor.email === ''
                ? 'hidden'
                : 'button button-primary'
            "
            :custom_caption="$t('Views.Sponsor.sendInfo')"
            @click="sendContactInfo()"
          />
          <TappinButton
            icon="list"
            id="allSponsors"
            aria-label="See all sponsors"
            class="button button-secondary"
            :custom_caption="$t('Views.Sponsor.seeAll')"
            @click="showSponsorOverviewModal"
          />
        </div>
      </SponsorDataProvider>
    </template>
  </ContentWithSidebarLayout>
</template>

<script>
import ApiClient from '@/client';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ContentWithSidebarLayout from '@/components/shared/ContentWithSidebarLayout';
import Navbar from '@/components/shared/Navbar';
import Contact from '@/views/Sponsor/Contact';
import MeetingRooms from '@/views/Sponsor/MeetingRooms';
import Documents from '@/views/Sponsor/Documents';
import Information from '@/views/Sponsor/Information';
import Contacts from '@/views/Sponsor/Contacts';
import Agenda from '@/views/Sponsor/Agenda';
import SideBarTabs from '@/components/shared/SideBarTabs';
import StageStream from '@/components/shared/StageStream';
import SponsorOverviewModal from '@/components/SponsorOverviewModal';
import TappinButton from '@/components/buttons/TappinButton';
import Polls from './Sponsor/Polls.vue';
import { getPusherInstance } from '@/utils';

export default {
  name: 'Sponsor',
  components: {
    Navbar,
    ContentWithSidebarLayout,
    SideBarTabs,
    StageStream,
    TappinButton
  },
  data() {
    return {
      previousPage: null,
      sidebarCollapsed: false,
      screenWidth: window.innerWidth,
      isStreamOff: true,
      showStream: false,
      currentTab: null,
      tabs: [
        {
          enabled: () => this.features.sponsorInfo,
          key: 'info',
          icon: 'fa-info-circle',
          label: () => this.$t('Views.Sponsor.labelInformation'),
          component: Information,
          isModal: false,
          onClick: this.openTab
        },
        {
          enabled: () =>
            this.features.sponsorAgendaTab &&
            this.singleAgendaBySpeaker(this.$route.params.sponsorId).length,
          key: 'agenda',
          icon: 'fa-calendar-alt',
          label: () => this.getEvent.captions.programCaption,
          component: Agenda,
          isModal: false,
          onClick: this.openTab
        },
        {
          enabled: () => this.features.sponsorContact,
          key: 'contact',
          icon: 'fa-comment-alt',
          label: () => this.$t('Views.Sponsor.labelContact'),
          component: Contact,
          isModal: false,
          onClick: this.openTab
        },
        {
          enabled: () => this.features.sponsorPolls && this.getPolls.length,
          key: 'polls',
          icon: 'fa-poll',
          label: () => this.$t('Views.Sponsor.polls'),
          component: Polls,
          isModal: false,
          onClick: this.openTab
        },
        {
          enabled: () => this.features.sponsorUsers && this.getUsers.length,
          key: 'contacts',
          icon: 'fa-address-card',
          label: () => this.$t('Views.Sponsor.labelContactParticipants'),
          component: Contacts,
          isModal: false,
          onClick: this.openTab
        },
        {
          enabled: () =>
            this.features.sponsorWherebyRooms && this.getMeetingRooms.length,
          key: 'rooms',
          icon: 'fa-video',
          label: () => this.$t('Views.Sponsor.videoRooms'),
          component: MeetingRooms,
          isModal: false,
          onClick: this.openTab
        },
        {
          enabled: () =>
            this.features.sponsorDocumentsTab && this.getDocuments.length,
          key: 'documents',
          icon: 'fa-file',
          label: () => this.$t('Views.Sponsor.documents'),
          component: Documents,
          isModal: false,
          onClick: this.openTab
        }
      ]
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.showStream = window.innerWidth > 1024;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  created() {
    this.currentTab = this.enabledTabs[0];
  },
  computed: {
    ...mapGetters('CurrentEvent', ['features', 'getEvent', 'lobbyElements']),
    ...mapGetters('Sponsor', [
      'getPolls',
      'getDocuments',
      'getMeetingRooms',
      'getUsers'
    ]),
    ...mapGetters('Agendas', ['singleAgendaBySpeaker']),
    enabledTabs() {
      return this.tabs.filter((tab) => tab.enabled());
    },
    programCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.programCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'sponsors'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },
    computedBackFromLabel() {
      if (this.previousPage === null || this.previousPage.name === null) {
        return this.$t('Views.Sponsor.back');
      }

      if (this.previousPage.fullPath.includes('webapp')) {
        return this.$t('Views.Sponsor.backToHome');
      }

      if (this.previousPage.name === 'lobby') {
        return this.$t('Views.Sponsor.backToLobby');
      }

      if (this.previousPage.fullPath.includes('stage')) {
        return (
          this.$t('Views.Sponsor.backToStage') +
          this.getEvent.captions.programCaption
        );
      }

      return '';
    }
  },
  methods: {
    ...mapActions('Authentication', ['doLogout']),
    ...mapMutations('Stage', ['setCurrentTab']),
    goBack() {
      if (this.previousPage !== null && this.previousPage.name !== null) {
        this.$router.push({ name: this.previousPage.name });
      } else {
        this.$router.go(-1);
      }
    },
    openTab(tab) {
      // If you click on a tab button, we automatically open the sidebar for you.
      this.$root.$emit('focusChildInput');
      this.sidebarCollapsed = false;
      this.currentTab = tab;
      this.setCurrentTab(tab);
    },
    sendContactInfo() {
      ApiClient.sendContactInfoToSponsor(this.$route.params.sponsorId).then(
        () => {
          this.$notify({
            group: 'default',
            title: this.$t('Views.Sponsor.successMessage')
          });
        }
      );
    },
    showSponsorOverviewModal() {
      this.$modal.show(
        SponsorOverviewModal,
        {
          options:
            this.lobbyElements.find((element) => element.id === 'sponsors')
              ?.options ?? {}
        },
        {
          width: '90%',
          height: '90%',
          adaptive: true,
          minWidth: 300,
          maxWidth: 1700
        }
      );
    },
    onResize() {
      this.showStream = !this.isStreamOff || window.innerWidth > 1024;
    },
    hideStream() {
      this.isStreamOff = !this.isStreamOff;
      this.showStream = !this.isStreamOff;
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousPage = from;
    });
  }
};
</script>

<style lang="scss" scoped>
.video-container {
  position: relative;
  margin-bottom: -7px;
  overflow: hidden;
  transition: padding-bottom 0ms ease 200ms, width, height 200ms ease;
  height: 100%;
  width: 100%;

  & iframe,
  & object,
  & embed {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
