var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AgendasDataProvider',{scopedSlots:_vm._u([{key:"default",fn:function({
    singleAgendaBySpeakerId,
    trackById,
    setStreamFromSession,
    isLoading
  }){return [(!isLoading)?_c('div',{staticClass:"absolute top-0 bottom-0 w-full overflow-y-auto flex flex-col z-10l"},[(singleAgendaBySpeakerId(_vm.$route.params.sponsorId).length)?_c('div',{staticClass:"p-4 pt-0"},[_c('h2',{staticClass:"text-md font-bold pb-6"},[_vm._v(" "+_vm._s(_vm.$t('Views.Sponsor.labelAgenda'))+" ")]),_c('div',{staticClass:"pb-2 mt-4 pt-0 border-b border-grey"},[_c('div',{staticClass:"hover:outline-none focus:outline-none w-full flex justify-between items-baseline"},[_c('div',{staticClass:"text-md font-bold"},[_c('Moment',{attrs:{"value":singleAgendaBySpeakerId(_vm.$route.params.sponsorId)[0].startDate,"format":"dddd DD. MMMM"}})],1)])]),_c('CollapsibleList',_vm._l((singleAgendaBySpeakerId(
            _vm.$route.params.sponsorId
          )),function(session,i){return _c('CollapsibleItem',{key:i,attrs:{"item-id":session.id},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('div',{staticClass:"mt-6 mb-2"},[_c('div',[_c('div',{staticClass:"flex items-center mb-1"},[_c('span',{staticClass:"font-bold text-xs mr-4"},[_c('Moment',{attrs:{"value":session.startDate,"format":"HH:mm"}}),_vm._v(" - "),_c('Moment',{attrs:{"value":session.endDate,"format":"HH:mm"}})],1),(session.liveUrl)?_c('div',[(session.trackId != '0')?_c('span',{staticClass:"inline-block font-bold text-xs py-1 px-2 text-white",style:({
                      'background-color': trackById(session.trackId)
                        .headerBackgroundColor
                    })},[_vm._v(" "+_vm._s(trackById(session.trackId).title)+" ")]):_c('span',{staticClass:"inline-block bg-grey font-bold text-xs py-1 px-2"},[_vm._v(" "+_vm._s(_vm.$t('Views.Stage.Agenda.breakoutsession'))+" ")])]):_vm._e()]),_c('div',{staticClass:"mb-2"},[_c('h3',{staticClass:"font-bold text-base"},[_vm._v(" "+_vm._s(session.title)+" ")]),_c('h4',[_vm._v(" "+_vm._s(session.subtitle)+" ")])]),(
                  session.description ||
                  session.speakers.length ||
                  session.liveUrl
                )?_c('div',{staticClass:"flex"},[(session.Description || session.speakers.length)?_c('button',{staticClass:"button button-secondary mr-4",attrs:{"aria-label":"agenda description"},on:{"click":toggle}},[(active)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Views.Stage.Agenda.showLess'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Views.Stage.Agenda.moreInfo'))+" ")])]):_vm._e(),(session.liveUrl)?_c('button',{staticClass:"button button-primary mr-4",attrs:{"aria-label":"go to stage"},on:{"click":function($event){return setStreamFromSession(session)}}},[_vm._v(" "+_vm._s(_vm.$t('Views.Stage.Agenda.goToScene'))+" ")]):_vm._e()]):_vm._e()]),(active)?_c('div',{staticClass:"mt-6"},[_c('div',{staticClass:"mb-4 text-xs html-content",domProps:{"innerHTML":_vm._s(session.description)}}),(session.speakers.length)?_c('div',{staticClass:"flex flex-row flex-wrap p-2"},_vm._l((session.speakers),function(speaker){return _c('div',{key:speaker.id,staticClass:"flex items-center space-x-2 space-y-2 w-1/2"},[_c('Avatar',{attrs:{"image":speaker.profileImageUrl,"circle":true,"size":'small'}}),_c('div',{staticClass:"flex flex-col text-xs"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.getFullName(speaker)))]),_c('span',[_vm._v(" "+_vm._s(speaker.jobTitle))])])],1)}),0):_vm._e()]):_vm._e()])]}}],null,true)})}),1)],1):_vm._e()]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }