<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <div class="p-10 flex flex-col h-full overflow-show" :style="designStyles">
      <div class="h-full flex flex-col space-y-6">
        <!-- Heading -->
        <div ref="childFocus" tabindex="0">
          <h3 class="text-md font-bold">
            {{ $t('Components.RecoveryPasswordModal.forgotPassword') }}
          </h3>
          <!-- <p class="text-base mt-2 ">
            {{ $t("Components.RecoveryPasswordModal.chooseSmsOrEmail") }}
          </p> -->
        </div>

        <!-- Form-->
        <!--div class="text-left">
          <button
            @click="useEmail"
            class="button"
            :class="{ 'font-bold': isEmail }"
            aria-label="Email"
          >
            Epost
          </button>
          <div
            style="height: 10px ;width: 2px"
            class="bg-dark inline-block mx-4"
          ></div>
          <button
            class="button"
            @click="useSms"
            :class="{ 'font-bold': isSms }"
            aria-label="Sms"
          >
            SMS
          </button>
        </div> -->
        <div class="flex-1">
          <!-- Email -->
          <div v-if="isEmail">
            <input
              v-model="email"
              type="email"
              name="email"
              :placeholder="
                $t('Components.RecoveryPasswordModal.emailPlaceholder')
              "
              class="w-full p-4 bg-light text-dark rounded"
              @keyup.enter="onResetPassword"
            />
            <p class="mt-4 text-base">
              {{ $t('Components.RecoveryPasswordModal.emailHelp') }}
            </p>
          </div>

          <!-- SMS -->
          <!-- <div v-if="isSms">
            <div class="flex">
              <vue-country-code
                @onSelect="changeCountryPrefix"
                :enabledCountryCode="true"
                :preferredCountries="['no', 'se', 'dk', 'fi', 'us', 'gb']"
              />
              <input
                type="tel"
                name="phone"
                @keyup.enter="onResetPassword"
                v-model.trim="phoneNumber"
                @keypress="canEnterChar($event)"
                :placeholder="
                  $t('Components.RecoveryPasswordModal.phonePlaceholder')
                "
                class="p-4 bg-light text-dark w-full rounded"
              />
            </div>

            <p class="text-base mt-4">
              {{ $t("Components.RecoveryPasswordModal.smsCountryCode") }}
            </p>
          </div> -->

          <div v-if="error" class="form-error">
            <span class="form-error text-sm font-bold">
              <i class="fas fa-exclamation-circle mr-1" />
              {{ $t('Components.RecoveryPasswordModal.somethingWentWrong') }}
            </span>
          </div>
        </div>

        <!-- Close -->
        <div class="text-base flex justify-between items-center">
          <button
            class="button button-secondary"
            :aria-label="$t('Components.RecoveryPasswordModal.close')"
            @click="$emit('close')"
          >
            {{ $t('Components.RecoveryPasswordModal.close') }}
          </button>

          <button
            :disabled="
              !canResetPassword ||
              onetimeEmailPasswordReset ||
              onetimeSmsPasswordReset
            "
            class="button button-primary"
            aria-label="reset password"
            @click="onResetPassword"
          >
            <span
              v-if="loading"
              class="inline-block"
              aria-label="sending password"
            >
              {{ $t('Components.RecoveryPasswordModal.sendingPassword') }}
              <i class="fas ml-2 fa-circle-notch fa-spin" />
            </span>
            <span v-else class="inline-block" aria-label="try again">
              {{ $t('Components.RecoveryPasswordModal.resetPassword') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import ApiClient from '@/client';
import { mapGetters } from 'vuex';
import email from 'vuelidate/lib/validators/email';

export default {
  name: 'RecoverPasswordModal',
  data() {
    return {
      loading: false,
      onetimeEmailPasswordReset: false,
      onetimeSmsPasswordReset: false,
      recoveryMethod: 'email',
      countryPrefix: null,
      phoneNumber: '',
      email: '',
      error: ''
    };
  },
  computed: {
    ...mapGetters('CurrentEvent', ['getId']),
    trimmedPhoneNumber() {
      // Strip out all whitespace
      return this.phoneNumber.replace(/\s/g, '');
    },
    isSms() {
      return this.recoveryMethod === 'sms';
    },
    isEmail() {
      return this.recoveryMethod === 'email';
    },
    canResetPassword() {
      if (this.isSms) {
        return this.trimmedPhoneNumber.length > 5;
      }

      if (this.isEmail) {
        return this.email.length && email(this.email);
      }

      return false;
    }
  },
  mounted() {
    this.$root.$on('focusChild', () => this.$refs.childFocus.focus());
  },
  methods: {
    useSms() {
      this.email = '';
      this.recoveryMethod = 'sms';
    },
    useEmail() {
      this.phoneNumber = '';
      this.recoveryMethod = 'email';
    },
    changeCountryPrefix({ dialCode }) {
      this.countryPrefix = dialCode;
    },
    onResetPassword() {
      this.error = false;
      this.isSms ? this.resetViaSms() : this.resetViaEmail();
    },
    resetViaSms() {
      if (!this.canResetPassword) return;
      this.loading = true;
      this.onetimeSmsPasswordReset = true;
      ApiClient.recoverPasswordBySms(
        this.countryPrefix,
        this.trimmedPhoneNumber,
        this.getId
      )
        .then((response) => {
          if (response.data.smsSent) {
            if (!this.onetimeSmsPasswordReset) return;
            this.$notify({
              group: 'default',
              title: this.$t(
                'Components.RecoveryPasswordModal.resetSmsPasswordMessage'
              )
            });
          } else {
            this.error = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetViaEmail() {
      this.loading = true;
      this.onetimeEmailPasswordReset = true;
      ApiClient.recoverPasswordByEmail(this.email, this.getId)
        .then((response) => {
          if (response.data.emailSent) {
            if (!this.onetimeEmailPasswordReset) return;
            this.$notify({
              group: 'default',
              title: this.$t(
                'Components.RecoveryPasswordModal.resetEmailPasswordMessage'
              )
            });
          } else {
            this.error = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    canEnterChar(evt) {
      // Is this a number or a space.
      if (evt.key === ' ' || !isNaN(parseInt(evt.key))) {
        return true;
      }

      evt.preventDefault();
    }
  }
};
</script>

<style lang="scss">
.vue-country-select {
  border: none !important;
  background: var(--color-light);
  padding: 5px;
  .current {
    .country-code {
      margin: 0 10px;
      font-size: 16px !important;
    }
  }
}
</style>
