import ApiClient from '../../client';
import Vue from 'vue';
import i18n from '@/i18n';

export const state = () => {
  return {
    eventId: null,
    eventDetails: null,
    locale: null,

    // When this is false, we only have the publicly available event info,
    // when it is true, we are most likely logged in and has populated eventDetails with the result from "loadFullEventInfo"
    hasFullEventDetails: false
  };
};

export const getters = {
  getEventSlug: (state) => state.eventDetails?.eventSlug,
  getTitle: (state) => state.eventDetails.eventName,
  getTwoFact: (state) => state.eventDetails.twoFactor,
  getEvent: (state) => state.eventDetails,
  getId: (state) => state.eventId,
  getIdFromEventDetails: (state) => state.eventDetails.eventId,
  getFaqCategoryId: (state) => state.eventDetails.liveFAQCategory,
  isLobbyEnabled: (state) => !!parseInt(state.eventDetails?.liveLobby),
  isStageEnabled: (state) => !!parseInt(state.eventDetails?.liveStage),
  isWebappEnabled: (state) => !!parseInt(state.eventDetails?.liveWebApp),
  lobbyElements: (state) => state.eventDetails.lobbyElements,
  hasFullEventDetails: (state) => state.hasFullEventDetails,
  eventDetails: (state) => state.eventDetails,
  features: (state) => {
    // Defaults whenever the full event details have not loaded, and alsop if we are
    // running in devmode with the flag "VUE_APP_ENABLE_ALL_FEATURES" on
    if (process.env.VUE_APP_ENABLE_ALL_FEATURES) {
      return {
        lobbyEnabled: true,
        lobbyChat: true,
        lobbyParticipantList: true,
        lobbyParticipantListShowOnline: true,
        lobbyProfilePage: true,
        lobbyFAQPages: true,
        lobbyAgenda: true,
        lobbySpeakers: true,
        lobbyDates: true,
        lobbySponsors: true,
        lobbySponsorToplist: true,
        sponsorInfo: true,
        sponsorContact: true,
        sponsorUsers: true,
        sponsorPolls: true,
        sponsorWherebyRooms: true,
        stageTabTimeline: true,
        stageTabAgenda: true,
        stageTabSpeakers: true,
        stageTabInfoPages: true,
        stageTabDocuments: true,
        stageTabCustom: true,
        stageTabParticipants: true,
        stageTabParticipantsShowOnline: true,
        stageTabProfile: true,
        stageTabSelfie: true,
        stageFuncTimelinePosting: true,
        stageFuncTimelineCommenting: true,
        stageFuncChatPosting: true,
        sponsorAgendaTab: true,
        sponsorDocumentsTab: true,
        profileEmailVisible: true,
        profilePhoneVisible: true,
        bookingsEnabled: true,
        sponsorFilter: true,
        speakerFilter: true
      };
    }

    return {
      ...state.eventDetails.features,
      lobbyEnabled: !!parseInt(state.eventDetails.liveLobby)
    };
  },
  isSingleDayEvent: (state) => {
    if (!state.eventDetails) return false;

    const start = Vue.moment(state.eventDetails.startDate);
    const end = Vue.moment(state.eventDetails.endDate);

    return start.isSame(end, 'day');
  },
  isSameMonthEvent: (state) => {
    if (!state.eventDetails) return false;

    const start = Vue.moment(state.eventDetails.startDate);
    const end = Vue.moment(state.eventDetails.endDate);

    return start.isSame(end, 'month');
  },
  isSameYearEvent: (state) => {
    if (!state.eventDetails) return false;

    const start = Vue.moment(state.eventDetails.startDate);
    const end = Vue.moment(state.eventDetails.endDate);

    return start.isSame(end, 'year');
  },
  isWithoutEndDate: (state) => {
    if (!state.eventDetails) return false;

    return !state.eventDetails.endDate;
  },
  getLocale: (state) => state.locale,
  getDesignStyles: (state) => {
    if (!state.eventDetails || !state.eventDetails.branding) return {};

    const colors = state.eventDetails.branding;

    // Explanation
    // liveBackgroundColor - Bakgrunnsfarge
    // liveTextColorDark - Mørk tekstfarge
    // liveTextColorLight - Lys tekstfarge
    // liveSectionColorOdd - Seksjonsfarge ulik
    // liveSectionColorEven - Seksjonsfarge lik
    // livePrimaryButtonColor - Primær knappefarge
    // liveSecondaryButtonColor - Sekundær knappefarge
    // livePrimaryButtonTextColor - Tekstfarge primærknapp
    // liveSecondaryButtonTextColor - Tekstfarge sekundærknapp
    // liveNavbarBackgroundColor - Navigering bakrunnsfarge

    return {
      '--background-image': `url("${colors.liveBackgroundImage}")`,
      '--background-color': colors.liveBackgroundColor,
      '--navbar-background-color': colors.liveNavbarBackgroundColor,

      '--section-odd': colors.liveSectionColorOdd,
      '--section-even': colors.liveSectionColorEven,

      '--color-text': colors.liveTextColorDark,

      // Primary
      '--color-primary-bg': `${colors.livePrimaryButtonColor}`,
      '--color-primary-text': `${colors.livePrimaryButtonTextColor}`,

      // Secondary
      '--color-secondary-bg': `${colors.liveSecondaryButtonColor}`,
      '--color-secondary-text': `${colors.liveSecondaryButtonTextColor}`,

      // Login
      '--login-background-color': colors.loginPageBackgroundColor,
      '--lobby-color-text': colors.liveLobbyTextColor,

      '--login-color-text': colors.loginPageTextColor
    };
  }
};

export const mutations = {
  setEventId: (state, eventId) => {
    state.eventId = eventId;
  },
  setEventDetails: (state, eventDetails) => {
    state.eventDetails = eventDetails;
  },
  clearEventDetails: (state) => {
    state.eventDetails = null;
  },
  setHasFullEventDetails: (state, hasFullInfo) => {
    state.hasFullEventDetails = hasFullInfo;
  },
  setLocale: (state, payload) => {
    state.locale = payload;
  }
};

export const actions = {
  async loadAndCacheEventDetails({ state, commit, dispatch }, eventId) {
    const response = await ApiClient.getPublicEventInfo(eventId);
    if (response.data) {
      commit('setEventId', response.data.eventId);
      dispatch('changeFavicon', response.data.faviconUrl);
      dispatch('changeTitle', response.data.eventName);
      dispatch('changeLanguage', response.data.languageId);
      commit('setEventDetails', response.data);
      commit('setHasFullEventDetails', false);
    }
  },
  async loadFullEventInfo({ state, commit, dispatch }) {
    const eventId = state.eventId;
    commit('setEventId', eventId);
    const response =
      await ApiClient.getFullEventInfoForEventCurrentlyLoggedInTo();
    if (response.data) {
      dispatch('changeLanguage', response.data.languageId);
      commit('setEventDetails', response.data);
      commit('setHasFullEventDetails', true);
    }
  },

  updateEventInfo({ state, commit, dispatch }, data) {
    commit('setEventDetails', data);
  },

  async changeFavicon({ state }, icon) {
    if (icon) {
      console.log('Setting favicon: ', icon);
      const favicons = document.getElementsByClassName('favicon');

      for (var i = 0; i < favicons.length; i++) {
        favicons[i].href = `${icon}?size=${icon.sizes ?? '256x256'}`;
      }
    }
  },

  async changeTitle({ state }, title) {
    if (title) {
      document.title = title;
    }
  },

  async changeLanguage({ state, commit }, language) {
    // English
    if (language === '1') {
      commit('setLocale', 'en');
    }

    // Norwegian
    if (language === '2') {
      commit('setLocale', 'nb');
    }

    i18n.locale = state.locale;
    Vue.moment.locale(state.locale);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
