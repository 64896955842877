<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'StreamDataProvider',
  props: {
    setFromCurrentEvent: {
      type: Boolean,
      required: false,
      default: false
    },
    setFromSponsor: {
      type: Object,
      required: false,
      default: null
    }
  },
  created() {
    const sessionId = this.$route.query.session;
    if (sessionId) {
      // If we are going directly to a session, that will override any other "initial streams" logic
      this.loadAgenda().then(() => {
        this.setStreamFromSessionId({
          sessionId,
          fallback: this.setStreamFromCurrentEvent
        });
      });
    } else {
      // If we are being redirected from another page, we want the state to stay as it was before we were redirected.
      if (this.isBeingRedirectedFromOtherPage === false) {
        // Set the initial stream from the current event
        if (this.setFromCurrentEvent) {
          this.setStreamFromCurrentEvent();
        }

        // Set the initial stream from sponsor
        if (this.setFromSponsor) {
          this.setStreamFromSponsor(this.setFromSponsor);
        }
      }
    }

    this.setBeingRedirectedFromOtherPage(false);
  },
  methods: {
    ...mapMutations('Stream', [
      'setBeingRedirectedFromOtherPage',
      'setStreamTitle',
      'setStreamUrl'
    ]),
    ...mapActions('Stream', [
      'setStreamFromCurrentEvent',
      'setStreamFromSessionId',
      'setStreamFromMeetingRoom',
      'setStreamFromSponsor',
      'setstreamUrl',
      'changeStreamUrl'
    ]),
    ...mapActions('Agendas', {
      loadAgenda: 'fetchItems'
    })
  },
  computed: {
    ...mapGetters('CurrentEvent', ['hasFullEventDetails', 'getEvent']),
    ...mapGetters('Stream', [
      'isWhereby',
      'getStreamTitle',
      'getEmbedUrl',
      'getHasStream',
      'getStreamUrl',
      'getEmbedType',
      'getIframeAllowAttributes',
      'isBeingRedirectedFromOtherPage'
    ])
  },
  render() {
    return this.$scopedSlots.default({
      streamUrl: this.getStreamUrl,
      isWhereby: this.isWhereby,
      isReady: this.hasFullEventDetails,
      isImage: /\.(jpg|jpeg|png|webp|avif|gif|svg)$/i.test(
        this.hasFullEventDetails ? this.getEmbedUrl : ''
      ),
      hasStream: this.getHasStream,
      embedUrl: this.hasFullEventDetails ? this.getEmbedUrl : '',
      embedType: this.getEmbedType,
      iframeAllowAttributes: this.getIframeAllowAttributes,
      streamTitle: this.getStreamTitle,
      changeStreamUrl: this.changeStreamUrl,
      setStreamFromMeetingRoom: this.setStreamFromMeetingRoom,
      isMainEventStream: this.hasFullEventDetails
        ? this.getEvent.streamUrl === this.getStreamUrl
        : false,
      isMainSponsorStream: this.setFromSponsor
        ? this.setFromSponsor.liveURL === this.getStreamUrl
        : false,
      switchToMainSponsorStream: () => {
        this.setStreamFromSponsor(this.setFromSponsor);
      },
      switchToMainEventStream: () => {
        this.setStreamFromCurrentEvent();
      }
    });
  }
};
</script>
