var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CurrentEventDataProvider',[_c('ContentWithSidebarLayout',{attrs:{"sidebar-collapsed":_vm.sidebarCollapsed},on:{"closed":function($event){_vm.sidebarCollapsed = false},"opened":function($event){_vm.sidebarCollapsed = true}}},[_c('template',{slot:"navbar"},[_c('Navbar',{attrs:{"show-nav":true,"tabs":_vm.tabs}})],1),_c('template',{slot:"mainContent"},[_c('StreamDataProvider',{attrs:{"set-from-current-event":true},scopedSlots:_vm._u([{key:"default",fn:function({
          isReady,
          isImage,
          hasStream,
          embedUrl,
          embedType,
          isMainEventStream,
          switchToMainEventStream,
          iframeAllowAttributes
        }){return [_c('div',{staticClass:"relative text-white flex flex-col flex-1 h-full"},[(_vm.showStream)?_c('StageStream',{staticClass:"md:p-4 md:pt-0",attrs:{"isReady":isReady,"isImage":isImage,"hasStream":hasStream,"embedUrl":embedUrl,"embedType":embedType,"iframeAllowAttributes":iframeAllowAttributes}}):_vm._e(),(_vm.isWebapp || hasStream)?_c('div',{staticClass:"flex flex-wrap lg:hidden justify-between my-1 h-12 md:h-16"},[(!isMainEventStream)?_c('TappinButton',{staticClass:"button-primary cursor-pointer text-dark font-bold p-2 space-x-2 text-sm lg:text-base",attrs:{"icon":"chevron-left","custom_caption":_vm.screenWidth > 1024
                  ? _vm.$t('Views.Stage.backToMainStream')
                  : _vm.$t('Views.Stage.backToMainStage')},on:{"click":switchToMainEventStream}}):(_vm.isWebapp)?_c('TappinButton',{staticClass:"button-primary cursor-pointer text-dark font-bold p-2 space-x-2 text-sm lg:text-base",attrs:{"icon":"chevron-left","custom_caption":_vm.$t('Components.shared.ContentWithSidebarLayout.backToLobby')},on:{"click":_vm.backToLobby}}):_vm._e(),_c('TappinButton',{directives:[{name:"show",rawName:"v-show",value:(hasStream),expression:"hasStream"}],staticClass:"button cursor-pointer text-dark p-2 flex flex-row items-center justify-center space-x-2 text-sm lg:text-base",attrs:{"icon":"tv","custom_caption":_vm.isStreamOff
                  ? _vm.$t('Views.Stage.showStream')
                  : _vm.$t('Views.Stage.hideStream')},on:{"click":function($event){return _vm.hideStream()}}})],1):_vm._e()],1)]}}])})],1),_c('template',{slot:"belowMainContent"},[_c('StreamDataProvider',{attrs:{"set-from-current-event":true},scopedSlots:_vm._u([{key:"default",fn:function({ isMainEventStream, switchToMainEventStream }){return [_c('div',{staticClass:"hidden lg:flex h-16 text-sm"},[(!isMainEventStream)?_c('TappinButton',{staticClass:"the-other-one button-primary cursor-pointer text-dark font-bold p-2 space-x-2",attrs:{"icon":"chevron-left","custom_caption":_vm.screenWidth > 1023
                ? _vm.$t('Views.Stage.backToMainStream')
                : _vm.$t('Views.Stage.backToMainStage')},on:{"click":switchToMainEventStream}}):(_vm.isWebapp)?_c('TappinButton',{staticClass:"the-other-two button-primary cursor-pointer text-dark font-bold p-2 space-x-2",attrs:{"icon":"chevron-left","custom_caption":_vm.$t('Components.shared.ContentWithSidebarLayout.backToLobby')},on:{"click":_vm.backToLobby}}):_vm._e()],1)]}}])})],1),_c('template',{slot:"sidebarContent"},[_c('SponsorsDataProvider',[_c('transition-group',{staticClass:"h-full relative sidebar-content",attrs:{"name":"fade","tag":"div"}},_vm._l((_vm.components),function(component){return _c(component.component,{directives:[{name:"show",rawName:"v-show",value:(
              _vm.getCurrentTab &&
              component.component === _vm.getCurrentTab.component &&
              !!component.enabled()
            ),expression:"\n              getCurrentTab &&\n              component.component === getCurrentTab.component &&\n              !!component.enabled()\n            "}],key:component.key,tag:"component",attrs:{"active":_vm.getCurrentTab &&
              component.component === _vm.getCurrentTab.component &&
              !!component.enabled()}})}),1)],1)],1),_c('template',{slot:"sidebar"},[_c('div',{staticClass:"bg-navbar hidden lg:flex flex-col flex-1 justify-between"},[_c('SideBarTabs',{attrs:{"tabs":_vm.tabs,"chatTab":_vm.chatTab}}),(_vm.isChatTabEnabled)?_c('div',{staticClass:"icon-notification"},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              placement: 'right',
              theme: 'light-border',
              arrow: false,
              flipBehavior: ['left']
            }),expression:"{\n              placement: 'right',\n              theme: 'light-border',\n              arrow: false,\n              flipBehavior: ['left']\n            }"}],staticClass:"button hidden lg:block hover:opacity-50 w-16 h-20",attrs:{"content":_vm.$t('Components.Chat.chatHere'),"aria-label":"open chat"},on:{"click":function($event){return _vm.openTab(_vm.ChatTab, true)}}},[_c('i',{staticClass:"fal fa-comments",attrs:{"aria-hidden":"true"}}),(_vm.unreadChatsCount >= 1)?_c('div',{staticClass:"icon-notification__badge"},[_c('span',{staticClass:"font-bold text-xxs md:text-xs",attrs:{"aria-label":"unread message available"}},[_vm._v(" "+_vm._s(_vm.unreadChatsCount)+" ")])]):_vm._e()])]):_vm._e()],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }