<template>
  <div>
    <div class="mx-0 md:mx-12 lg:mx-0">
      <div v-if="item.Message !== ''">
        <div
          class="text-sm mt-4 mb-2 html-content"
          v-html="parsedMessage"
        ></div>
      </div>

      <button
        v-if="isButtonClickable && item.Promolinktext.length"
        class="button button-primary mt-2"
        aria-label="promoted messages text"
        @click="onButtonClick"
      >
        {{ item.Promolinktext }}
      </button>
    </div>
  </div>
</template>
<script>
import { getApplicablePlatforms, parsePromoLink } from '@/utils';
import { mapActions } from 'vuex';
import router from '@/router';
import { createAnalyticsEvent } from '@/helpers/ga-helper';

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      parsedMessage: ''
    };
  },
  watch: {
    '$store.state.Timeline.promotedTimelineItems'() {
      this.parseAndModifyMessage();
    }
  },
  created() {
    createAnalyticsEvent(
      this.$gtag,
      'View',
      'PromotedMessage',
      this.$props.item?.TimelineID
    );
  },
  mounted() {
    this.parseAndModifyMessage();
  },
  computed: {
    isImageClickable() {
      return ['PICTURE_ONLY_HIDDEN_BUTTON', 'CONTAINER'].includes(
        this.item.LinkMode
      );
    },
    isButtonClickable() {
      return ['BUTTON_LINK_ONLY', 'CONTAINER'].includes(this.item.LinkMode);
    },
    isWebapp() {
      return getApplicablePlatforms(this.$route).includes('webApp');
    }
  },
  methods: {
    ...mapActions('Timeline', ['setPromotedPage']),
    onImageClick() {
      if (['PICTURE_ONLY_HIDDEN_BUTTON'].includes(this.item.LinkMode)) {
        return this.onPromoClick();
      }

      return null;
    },
    onButtonClick() {
      if (['BUTTON_LINK_ONLY', 'CONTAINER'].includes(this.item.LinkMode)) {
        return this.onPromoClick();
      }

      return null;
    },
    onPromoClick() {
      const promoted = parsePromoLink(this.item.Promolink);
      if (promoted.page === 'sponsor_detail') {
        return router.push({
          name: 'sponsor',
          params: { sponsorId: promoted.id },
          query: { fromStage: true }
        });
      }

      this.setPromotedPage(promoted);
    },
    parseAndModifyMessage() {
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(this.item.Message, 'text/html');

      parsedHtml.querySelectorAll('a.button').forEach((button) => {
        if (button.dataset.tappinPlatforms) {
          if (
            (!this.isWebapp && button.dataset.tappinPlatforms === '0') ||
            (this.isWebapp && button.dataset.tappinPlatforms === '2')
          ) {
            button.style.display = 'inline-block';
          } else {
            button.parentNode.removeChild(button);
          }
        }
      });

      parsedHtml.querySelectorAll('a[href]').forEach((link) => {
        const href = link.href;
        const parts = href.split('/');
        const platform = parts[parts.length - 2];
        if (platform === '0' || platform === '2') {
          if (
            (!this.isWebapp && platform === '0') ||
            (this.isWebapp && platform === '2')
          ) {
            link.style.display = 'inline-block';
          } else {
            link.parentNode.removeChild(link);
          }
        }
      });

      this.parsedMessage = parsedHtml.documentElement.innerHTML;
    }
  }
};
</script>
