<template>
  <SponsorDataProvider v-slot="{ meetingRooms, roomIsAvailable }">
    <StreamDataProvider v-slot="{ setStreamFromMeetingRoom }">
      <div
        class="absolute top-0 bottom-0 w-full overflow-y-auto flex flex-col z-10l"
      >
        <div class="p-4 pt-0">
          <h2 class="text-md font-bold pb-6">
            {{ $t('Views.Sponsor.videoRooms') }}
          </h2>

          <div v-for="room in meetingRooms" :key="room.id" class="mb-6">
            <div class="text-xs font-bold mb-1">
              <!-- TODO: Add the time here as well  -->
              <Moment
                :value="room.wherebyStartDate"
                format="dddd DD. MMMM - HH:mm"
              />
              -
              <Moment
                :value="room.wherebyEndDate"
                format="dddd DD. MMMM - HH:mm"
              />
            </div>
            <div class="font-bold mb-1">
              {{ room.wherebyMeetingName }}
            </div>

            <!-- Can't use :disabled because that will not trigger v-tippy -->
            <button
              v-tippy="{ placement: 'right', arrow: true }"
              :class="{ disabled: !roomIsAvailable(room, now) }"
              :content="
                !roomIsAvailable(room)
                  ? $t('Views.Sponsor.unavailableMeeting')
                  : null
              "
              type="button"
              class="button button-secondary mt-3"
              aria-label="Go to meeting room"
              @click="roomIsAvailable(room) && setStreamFromMeetingRoom(room)"
              v-text="$t('Views.Sponsor.goToRoom')"
            />
          </div>
        </div>
      </div>
    </StreamDataProvider>
  </SponsorDataProvider>
</template>

<script>
export default {
  name: 'MeetingRooms',
  data() {
    return {
      now: null,
      interval: null
    };
  },
  created() {
    this.now = this.$moment();
    this.interval = setInterval(() => {
      this.now = this.$moment();
    }, 60000); // Once a minute
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>
