<template>
  <CurrentEventDataProvider v-slot="{ designStyles, data }">
    <div class="p-10 flex flex-col h-full" :style="designStyles">
      <div class="h-full flex flex-col">
        <!-- Heading -->
        <div class="mb-4">
          <h3 class="text-md font-bold">
            {{ $t('Components.RegistrationModal.title') }}
          </h3>
        </div>

        <!-- Registration Iframe text-->
        <iframe
          width="100%"
          height="800px"
          :src="
            data.regEventCode
              ? registrationBaseUrl + '/register/' + data.eventSlug
              : registrationBaseUrl + '/register/' + data.eventId
          "
        />

        <!-- Close -->
        <div class="text-base flex justify-between items-center mt-8">
          <button
            class="button button-secondary"
            aria-label="Close"
            @click="$emit('close')"
          >
            {{ $t('Components.RecoveryPasswordModal.close') }}
          </button>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
export default {
  name: 'RegistrationModal',
  data() {
    return {
      registrationBaseUrl: process.env.VUE_APP_REGISTRATION_BASE_URL
    };
  }
};
</script>
