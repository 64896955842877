<template>
  <div class="flex justify-center lg:hidden">
    <TappinButton
      @click="goToMainStage"
      class="button-secondary transition-all duration-200 font-bold px-10 py-2 rounded-full"
      aria-label="go to top of lobby"
      :custom_caption="options.mainStageCaption"
    />
  </div>
</template>

<script>
import TappinButton from '@/components/buttons/TappinButton';
export default {
  name: 'MainStageButton',
  components: {
    TappinButton
  },
  props: {
    options: Object
  },
  methods: {
    goToMainStage() {
      location.href = '/stage';
    }
  }
};
</script>
