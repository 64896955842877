<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'SponsorsDataProvider',
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('Sponsors', ['fetchItems', 'refresh']),
    getById(id) {
      return this.getItems.find((e) => e.Id === id);
    }
  },
  computed: {
    ...mapGetters('Sponsors', ['getItems', 'isLoading', 'failed'])
  },
  render() {
    return this.$scopedSlots.default({
      sponsors: this.getItems,
      sponsorsSortedByOrder: _.sortBy(this.getItems, [
        (sponsor) => parseInt(sponsor.order)
      ]),
      featuredSponsors: _.sortBy(this.getItems, [
        (sponsor) => parseInt(sponsor.order)
      ])
        .filter((e) => e.logoUrl)
        .slice(0, 5),
      loading: this.isLoading,
      failed: this.failed,
      refresh: this.refresh,
      getById: this.getById
    });
  }
};
</script>
