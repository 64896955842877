<template>
  <CurrentEventDataProvider v-slot="{ data, hasFullEventDetails, features }">
    <PagesDataProvider
      v-if="showFaqs"
      v-slot="{ pages, promotedPage, promotedPageComponent }"
      :applicablePlatforms="['lobby']"
    >
      <FaqSection
        :caption="options.label || data.captions.informationCaption"
        :aboutOnlineEvent="data.aboutOnlineEvent"
        :lobbyFaqEnabled="features.lobbyFAQPages"
        :hasFullEventDetails="hasFullEventDetails"
        :faqs="pages"
        :promotedPage="promotedPage"
        :promotedPageComponent="promotedPageComponent"
      />
    </PagesDataProvider>
  </CurrentEventDataProvider>
</template>
<script>
import { mapGetters } from 'vuex';
import FaqSection from '@/views/Lobby/FaqSection';

export default {
  components: {
    FaqSection
  },
  props: {
    options: Object
  },
  computed: {
    ...mapGetters('CurrentEvent', ['getEvent']),
    showFaqs() {
      const event = this.getEvent;

      if (!event.lobbyElements) {
        return event.aboutOnlineEvent;
      }

      return event.lobbyElements.some((x) => x.id === 'information');
    }
  }
};
</script>
