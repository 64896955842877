<template v-if="poll">
  <apexchart :options="chartOptions" :series="series" height="100%" />
</template>

<script>
import { chartColor } from '@/constants/chart-color-constants';

export default {
  props: {
    poll: Array,
    allVoteCount: Number,
    participantsCount: Number
  },
  data: function () {
    return {
      noData: {
        text: 'Loading...'
      },
      pollList: []
    };
  },
  mounted() {},
  methods: {
    addRow: function (item) {
      this.pollList.push(item);
    }
  },
  watch: {
    poll: {
      handler(val) {
        this.pollList = Object.assign([], val);
      },
      immediate: true
    }
  },
  computed: {
    totalRows: function () {
      return Math.sqrt(
        this.$props?.participantsCount ?? this.$props?.allVoteCount
      );
    },
    getCells: function () {
      const allParticipants =
        this.$props.participantsCount ?? this.$props.allVoteCount;
      const rowsNumber = Math.ceil(Math.sqrt(allParticipants));
      let arr = [];
      let row = 0;
      let col = 0;

      // add no answer poll serie
      this.addRow({
        label: 'Unanswered',
        count: this.participantsCount - this.allVoteCount
      });

      this.pollList?.forEach((el, i) => {
        let serieData = {
          name: el.label,
          data: [],
          color:
            el.color ??
            (el.label === 'Unanswered' ? '#F1E4E8' : chartColor[i % 5])
        };
        for (let i = 0; i < el?.count; i++) {
          serieData.data.push([row, col]);
          row = (row + 1) % rowsNumber;
          col = row === 0 ? (col + 1) % rowsNumber : col;
        }
        arr.push(serieData);
      });

      return arr;
    },
    series: function () {
      return this.getCells;
    },
    chartOptions: function () {
      return {
        chart: {
          type: 'scatter',
          toolbar: {
            show: false
          },
          height: '100%',
          zoom: {
            enabled: false
          }
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        grid: {
          show: false
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div class="arrow_box" style="background-color: ' +
              w.globals.colors[seriesIndex] +
              '; color: white; padding: 5px">' +
              '<span>' +
              w.globals.seriesNames[seriesIndex] +
              ': <strong>' +
              series[seriesIndex].length +
              '</strong></span>' +
              '</div>'
            );
          }
        },
        colors: [
          function ({ seriesIndex, w }) {
            return w.globals.seriesNames[seriesIndex] === 'Unanswered'
              ? '#F1E4E8'
              : chartColor[seriesIndex % 5];
          }
        ],
        markers: {
          size: this.$props.participantsCount > 500 ? 4 : 10
        },
        legend: {
          position: 'bottom',
          fontSize: '16px',
          fontFamily: 'Arial',
          fontWeight: 'bold',
          itemMargin: {
            horizontal: 20,
            vertical: 10
          },
          markers: {
            width: 16,
            height: 16,
            fillColors: undefined
          },
          horizontalAlign: 'center'
        },
        xaxis: {
          min: 0,
          max: Math.ceil(this.totalRows),
          tickAmount: 10,
          labels: {
            show: false,
            formatter: function (val) {
              return parseInt(val).toFixed(1);
            }
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          min: 0,
          max: Math.ceil(this.totalRows),
          tickAmount: 7,
          labels: {
            show: false,
            formatter: function (val) {
              return parseInt(val).toFixed(1);
            }
          },
          axisBorder: {
            show: false
          }
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                width: '100%'
              },
              legend: {
                fontSize: '12px',
                fontWeight: 'normal',
                itemMargin: {
                  horizontal: 10,
                  vertical: 5
                },
                markers: {
                  width: 12,
                  height: 12
                },
                horizontalAlign: 'center'
              }
            }
          }
        ]
      };
    }
  }
};
</script>
