import _ from 'lodash';

// Sets up a boilerplate vuex store to be used for data providers.
export const createNamespacedDataProviderStore = (fetchLogic) => {
  return {
    namespaced: true,
    state: {
      items: [],
      loading: false,
      failed: false,
      promise: null
    },
    getters: {
      getItems: (state) => state.items,
      isLoading: (state) => state.loading,
      failed: (state) => state.failed
    },
    mutations: {
      setItems(state, items) {
        state.items = items;
      },
      removeItem(state, payload) {
        state.items = state.items.filter(({ id }) => id !== payload.id);
      },
      setFailed(state, failed) {
        state.failed = failed;
      },
      setLoading(state, loading) {
        state.loading = loading;
      },
      setPromise(state, promise) {
        state.promise = promise;
      }
    },
    actions: {
      async refresh({ commit, dispatch }) {
        // Trigger a forced update that will ignore if we already have
        // data or if anyone else is already refreshing the state.
        commit('setItems', []);
        commit('setPromise', null);
        dispatch('fetchItems');
      },
      async fetchItems({ commit, state, rootGetters }) {
        // We already have the data, we don't need to fetch it again.
        if (state.items.length) return;

        // This is basically a thread lock on, checking if this promise is
        // set to anything other than null let's us make sure that if this action
        // is dispatched multiple times (eg: 3 data providers are loaded at once on the same page,
        // which triggers the created() method in all of them, the first one will acquire a lock by
        // setting the promise, subsequent calls will not run since the lock is acquired,
        // however all consumers of this store acts upon the same data so they will get the data from the first call.
        // TL;DR: If multiple components asks this store to fetch its data multiple times, only the first call is executed,
        if (state.promise) return state.promise;

        // Set loading/failed flags
        commit('setFailed', false);
        commit('setLoading', true);

        // The fetch logic callback will get this function as its only
        // argument, it should be called with data to set as state.items
        const setItemCallback = (data) => {
          commit('setPromise', null);
          if (data) {
            commit('setItems', data);
            commit('setFailed', false);
          } else {
            commit('setItems', []);
            commit('setFailed', true);
          }
          commit('setLoading', false);
        };

        // Run fetch logic and acquire a thread lock, fetchLogic should return a promise
        commit(
          'setPromise',
          fetchLogic(setItemCallback, rootGetters['Authentication/getUser'])
        );
      }
    }
  };
};

// Performs a deep merge of an existing store object with a new store object,
// this is a convenient wrapper around lodash _.merge()
export const extendStore = (original, merge) => {
  return _.merge(original, merge);
};
