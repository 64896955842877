<template>
  <div class="relative overflow-y-auto flex flex-col z-10 flex-auto h-full">
    <div class="p-4 pt-0 h-full">
      <CurrentEventDataProvider v-slot="{ data, hasFullEventDetails }">
        <div class="h-full">
          <div v-if="hasFullEventDetails" class="h-full">
            <iframe
              :src="data.gamificationUrl"
              frameborder="0"
              class="h-full w-full"
              tabindex="0"
            />
          </div>
          <div v-else class="flex items-center justify-center h-full">
            <i class="fas fa-circle-notch fa-spin text-xl" />
          </div>
        </div>
      </CurrentEventDataProvider>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Browser'
};
</script>
