import ApiClient from '../../client';

export const state = () => {
  return {
    voucherBalance: null,
    isVoucherBalanceLoading: true
  };
};

export const getters = {
  getVoucherBalance: (state) => {
    return state.voucherBalance;
  },
  getIsVoucherBalanceLoading: (state) => {
    return state.isVoucherBalanceLoading;
  }
};

export const mutations = {
  setVoucherBalance: (state, payload) => {
    state.voucherBalance = payload;
  },
  setIsVoucherBalanceLoading: (state, payload) => {
    state.isVoucherBalanceLoading = payload;
  }
};

export const actions = {
  async getVouchers({ commit, dispatch, rootGetters }) {
    let response;
    try {
      const userId = rootGetters['Authentication/getUser'].UserID;
      response = await ApiClient.getVoucherBalance(userId);
      commit('setVoucherBalance', response.data?.voucherBalance);
      commit('setIsVoucherBalanceLoading', false);
    } catch (e) {
      console.error('Voucher balance threw exception', e);
      return;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
