import ApiClient from '../../client';

export const state = () => {
  return {
    sponsor: null,
    documents: [],
    meetingRooms: [],
    users: [],
    polls: [],
    promotedPage: {
      page: 'null',
      id: null
    },
    promise: null,
    loading: true,
    failed: false,
    defaultCompanyLogoUrl:
      'https://tap2api.tappin.no/api/asset/assets/default-company-logo.png'
  };
};

export const getters = {
  getFailed: (state) => state.failed,
  getLoading: (state) => state.loading,
  getSponsor: (state) => state.sponsor,
  getDocuments: (state) => state.documents,
  getUsers: (state) => state.users,
  getPolls: (state) => state.polls,
  getMeetingRooms: (state) => state.meetingRooms,
  getDefaultCompanyLogoUrl: (state) => state.defaultCompanyLogoUrl,
  getPromotedPage: (state) => state.promotedPage
};

export const mutations = {
  getDefaultCompanyLogoUrl: (state, payload) => {
    state.defaultCompanyLogoUrl = payload;
  },
  setSponsor: (state, payload) => {
    state.sponsor = payload;
  },
  setDocuments: (state, payload) => {
    state.documents = payload;
  },
  setUsers: (state, payload) => {
    state.users = payload;
  },
  setMeetingRooms: (state, payload) => {
    state.meetingRooms = payload;
  },
  setPolls: (state, payload) => {
    state.polls = payload;
  },
  setPromise: (state, promise) => {
    state.promise = promise;
  },
  setLoading: (state, payload) => {
    state.loading = payload;
  },
  setFailed: (state, payload) => {
    state.failed = payload;
  },
  setPromotedPage: (state, payload) => {
    state.promotedPage = payload;
  }
};

export const actions = {
  async refresh({ commit, dispatch }) {
    commit('setSponsor', null);
    commit('setDocuments', []);
    commit('setCategories', []);
    commit('setUsers', []);
    commit('setMeetingRooms', []);
    commit('setQuizzes', []);
    commit('setPolls', []);
    commit('setPromise', null);

    dispatch('loadSponsorData');
  },

  async requestSponsorUserMeeting({ state, commit, dispatch }, userId) {
    return await ApiClient.requestSponsorUserMeeting(userId);
  },

  async loadSponsorData({ state, commit }, sponsorId) {
    if (state.promise) return state.promise;

    commit('setLoading', true);
    commit('setFailed', false);
    const promise = Promise.all([
      ApiClient.getSponsorUsers(sponsorId).then((response) => {
        commit('setUsers', response.data);
      }),
      ApiClient.getSponsorWherebyRooms(sponsorId).then((response) => {
        if (response.data) {
          commit('setMeetingRooms', response.data);
        } else {
          commit('setMeetingRooms', []);
        }
      }),
      ApiClient.getSponsorDocuments(sponsorId).then((response) => {
        commit('setDocuments', response.data);
      }),
      ApiClient.getSponsor(sponsorId).then((response) => {
        commit('setPolls', response.data.polls);
        commit('setSponsor', response.data);
      })
      // No data is ever returned, might be unused or broken
      // ApiClient.getSponsorQuizzes(sponsorId).then(response => {
      //   commit("setQuizzes", response.data);
      // }),
    ])
      .catch(() => {
        commit('setLoading', false);
        commit('setFailed', true);
      })
      .finally(() => {
        commit('setLoading', false);
        commit('setPromise', null);
        commit('setFailed', false);
      });

    commit('setPromise', promise);
  },
  async setPromotedPage({ commit }, payload) {
    commit('setPromotedPage', payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
