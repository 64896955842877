import VueI18n from 'vue-i18n';
import Vue from 'vue';
import en from '@/translations/en.json';
import nb from '@/translations/nb.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  messages: {
    en,
    nb
  },
  locale: 'en',
  localeDir: '@/translations',
  enableInSFC: true
});

export default i18n;
