<template>
  <Section
    id="information"
    ref="container"
    class="flex flex-col justify-center items-start anchor"
    container-class="max-w-large w-full"
  >
    <template slot="heading">
      {{ caption }}
    </template>
    <template slot="content">
      <div class="max-w-base py-2">
        <div v-if="hasFullEventDetails">
          <div v-html="aboutOnlineEvent" class="rich-text html-content"></div>
        </div>
      </div>
      <div
        class="max-w-large"
        id="faq"
        v-if="lobbyFaqEnabled && faqsConverted.length"
      >
        <CollapsibleList>
          <CollapsibleItem
            v-for="(faq, i) in faqsConverted"
            v-slot="{ active, toggle, toggleInfoPage }"
            :key="i"
            :item-id="i"
          >
            <Card>
              <div
                class="cursor-pointer flex items-center text-dark"
                @click="toggle(), toggleInfoPage()"
              >
                <i
                  class="far mr-5"
                  :class="[active ? 'fa-minus' : 'fa-plus']"
                ></i>
                <h4 class="text-sm sm:text-md font-sans">
                  {{ faq.Heading }}
                </h4>
              </div>
              <div class="html-content">
                <div
                  v-if="!promotedPage.page"
                  v-show="active"
                  v-html="faq.Tekst"
                  class="mt-5 text-base text-dark html-content"
                ></div>
                <component
                  v-if="promotedPage.page && active"
                  :is="promotedPageComponent"
                  :id="promotedPage.id"
                  container="Info"
                  class="mt-5 text-base text-dark ml-8"
                />
              </div>
            </Card>
          </CollapsibleItem>
        </CollapsibleList>
      </div>
    </template>
  </Section>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import Section from '@/components/shared/Section';
import CollapsibleList from '@/components/shared/CollapsibleList';
import CollapsibleItem from '@/components/shared/CollapsibleItem';
import Card from '@/components/shared/Card';
import Poll from '@/components/timeline/promoted/Poll';
import {
  listenClickInsideContainer,
  removeClickInsideContainerListener
} from '@/helpers/content-event-helpers';
import { getPusherInstance, replaceTokens } from '@/utils';
import ApiClient from '@/client';

export default {
  components: {
    Section,
    CollapsibleList,
    CollapsibleItem,
    Card,
    Poll
  },
  created() {
    getPusherInstance({ isReady: true }).bind(`info-page-change`, () => {
      this.fetchItems();
    });
  },
  props: {
    faqs: [Array, String],
    aboutOnlineEvent: String,
    hasFullEventDetails: [Boolean, String],
    lobbyFaqEnabled: [Boolean, String],
    caption: { type: String, default: 'Information' },
    promotedPage: { id: String, page: String },
    promotedPageComponent: Object
  },
  data() {
    return {
      pollSelected: null,
      containerClickEvent: null
    };
  },
  mounted() {
    this.containerClickEvent = listenClickInsideContainer(
      this.$refs.container?.$el,
      this.setPromotedPage
    );
  },
  beforeDestroy() {
    removeClickInsideContainerListener(
      this.$refs.container?.$el,
      this.containerClickEvent
    );
  },
  computed: {
    ...mapGetters('Authentication', ['getUser']),
    faqsConverted() {
      if (this.faqs instanceof Array) {
        return this.faqs;
      }

      return JSON.parse(this.faqs);
    }
  },
  methods: {
    ...mapActions('Info', ['setPromotedPage']),
    ...mapMutations('Pages', ['setItems']),
    fetchItems() {
      ApiClient.getLivePages().then((r) => {
        if (r.data) {
          r.data = replaceTokens(r.data, this.getUser, 'Tekst');
        }
        this.setItems(r.data);
      });
    }
  }
};
</script>

<style scoped>
::v-deep .html-content > p:has(iframe) {
  position: relative;
  padding-top: 56.25%;
}
::v-deep .html-content > p > iframe {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
}
</style>
