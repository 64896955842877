<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <div class="px-8 flex flex-col h-full" :style="designStyles">
      <div class="flex items-center justify-center h-screen">
        <div class="text-center">
          <i class="fal fa-exclamation-circle fa-4x mb-2" />
          <h2 class="text-lg mb-2">Call not sent</h2>
          <p class="text-md">The person is not available at the moment.</p>
        </div>
      </div>
      <!-- Close -->
      <div class="absolute bottom-0 pb-12">
        <button class="button button-secondary mt-6" @click="$emit('close')">
          {{ $t('Views.Stage.ParticipantsModal.close') }}
        </button>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
export default {
  name: 'NotAvailableModal'
};
</script>
<style scoped>
.fa-exclamation-circle {
  color: rgb(231, 42, 42);
}
</style>
