var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PollDataProvider',{attrs:{"container":_vm.container},scopedSlots:_vm._u([{key:"default",fn:function({ promotedPage }){return [_c('div',{staticClass:"p-4"},[(_vm.loading)?_c('div',[_c('i',{staticClass:"fal fa-spinner spin fa-2xl",attrs:{"aria-hidden":"true"}})]):_vm._e(),(!_vm.loading && (_vm.submitted === true || _vm.hasAlreadyAnsweredPoll))?_c('div',[(
          _vm.isQuiz || _vm.isTreasureHunt || _vm.isTemplateQuiz || _vm.isTemplateTreasureHunt
        )?_c('div',[_c('h3',{staticClass:"font-bold text-md mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Components.Timeline.Promoted.Poll.thanksForAnswer'))+" ")])]):_vm._e(),(_vm.isFreeTextPoll)?_c('div',[_c('h3',{staticClass:"font-bold text-md mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Components.Timeline.Promoted.Poll.thanksForAnswer'))+" ")]),_c('div',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.$t('Components.Timeline.Promoted.Poll.questionSendIn'))+" ")]),_c('div',{staticClass:"text-sm font-bold my-2",domProps:{"textContent":_vm._s(_vm.freeTextAnswer || _vm.freeText.Label)}})]):_vm._e(),(_vm.isMultiChoicePoll || _vm.isRatingPoll)?_c('div',[_c('h3',{staticClass:"font-bold text-md mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Components.Timeline.Promoted.Poll.thanksForAnswer'))+" ")]),_c('div',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.$t('Components.Timeline.Promoted.Poll.answerSendIn'))+" ")])]):_vm._e(),_c('PollActions',{attrs:{"hasClose":"","disabled":_vm.submitted},on:{"close":_vm.close}})],1):_vm._e(),(!_vm.loading && _vm.submitted === false)?_c('div',[(promotedPage.page === 'poll')?_c('SinglePoll',{attrs:{"id":_vm.id,"poll":_vm.poll,"submit":_vm.submitPollAnswer,"close":_vm.close}}):_vm._e(),(
          promotedPage.page === 'quiz' ||
          promotedPage.page === 'treasure_hunt'
        )?_c('QuizPoll',{attrs:{"quiz":_vm.poll,"submit":_vm.submitPollAnswer,"close":_vm.close}}):_vm._e(),(
          promotedPage.page === 'coded_quizzes' ||
          promotedPage.page === 'coded_polls'
        )?_c('CodedPoll',{attrs:{"submit":_vm.submitPollAnswer,"poll":_vm.poll,"showQuiz":_vm.showQuiz,"showPoll":_vm.showPoll,"errorInputCode":_vm.errorInputCode,"pollType":_vm.getPollType(promotedPage),"pollName":_vm.pollName,"showInputsCodedPoll":_vm.showInputsCodedPoll},on:{"codePoll":_vm.sendPollCode}}):_vm._e()],1):_vm._e()])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }