import DocumentPreview from '@/components/shared/DocumentPreview';

export const documentPreviewMixin = {
  methods: {
    canPreview(document) {
      // We only support PDFs for now
      return (
        (document.Documentfile && document.docType === 'pdf') ||
        document.docType === 'fa fa-file-pdf-o' ||
        document.docType === 'fa fa-file-photo-o'
      );
    },
    openPreview(document) {
      this.$modal.show(
        DocumentPreview,
        {
          document
        },
        {
          draggable: true,
          resizable: true,
          adaptive: true,
          width: '45%',
          height: '90%',
          minWidth: 300,
          maxWidth: 1920,
          minHeight: 480,
          maxHeight: 900
        }
      );
    }
  }
};

export const togglesSupportChat = {
  data() {
    return {
      chatWidgetLoaded: false
    };
  },
  created() {
    window.LiveChatWidget.on('ready', () => {
      console.log('LiveChatInc widget has loaded');
      this.chatWidgetLoaded = true;
    });
  },
  methods: {
    toggleLiveChatWindow() {
      // If we call this method before the chat widget has loaded, it throws an exception
      if (!this.chatWidgetLoaded) return;

      return window.LiveChatWidget.get('state').visibility === 'maximized'
        ? window.LiveChatWidget.call('minimize')
        : window.LiveChatWidget.call('maximize');
    }
  }
};
