import Vue from 'vue';
import { createNamespacedDataProviderStore, extendStore } from './dataprovider';
import ApiClient from '../../client';
import _ from 'lodash';

const store = createNamespacedDataProviderStore((setItems) => {
  return ApiClient.getAgendas().then((response) => {
    setItems(response.data);
  });
});

export default extendStore(store, {
  state: {
    filteredItems: []
  },
  getters: {
    getSessions: (state) => {
      return state.items?.flatMap((agenda) => agenda?.sessions || []) || [];
    },
    getSessionById: (state) => (id) => {
      return state.items
        ?.flatMap((x) => x.sessions)
        .find((session) => session.id == id);
    },
    getTracks: (state) => {
      if (!state.items) {
        return [];
      }
      return state.items.map((agenda) => agenda.tracks).flat();
    },
    getAgendaCount: (state) => state.items.length,
    agendasWithSessionsGroupedByDay: (state) => {
      return state.items.map((agenda) => {
        return {
          ...agenda,
          sessions: _.groupBy(agenda.sessions, (session) =>
            Vue.moment(session.startDate).format('YYYY-MM-DD')
          )
        };
      });
    },
    filteredAgendasWithSessionsGroupedByDay: (state) => {
      return state.filteredItems?.map((agenda) => {
        return {
          ...agenda,
          sessions: _.groupBy(agenda.sessions, (session) =>
            Vue.moment(session.startDate).format('YYYY-MM-DD')
          )
        };
      });
    },
    singleAgendaBySpeaker: (state) => (id) => {
      if (!state.items.length) return [];

      return state.items[0].sessions.filter(({ speakers }) =>
        speakers.some((speaker) => speaker.id == id)
      );
    },
    singleAgendaGroupedByDay: (state, getters) => {
      return getters.agendasWithSessionsGroupedByDay[0] ?? { sessions: {} };
    },
    filteredSingleAgendaGroupedByDay: (state, getters) => {
      return (
        getters.filteredAgendasWithSessionsGroupedByDay[0] ?? { sessions: {} }
      );
    },
    agendasWithSessionsGroupedByTime: (state, getters) => {
      return getters.agendasWithSessionsGroupedByDay.map((agenda) => {
        return _.mapValues(agenda.sessions, (value) => {
          const times = _.groupBy(value, (day) =>
            Vue.moment(day.startDate).format('HH:mm')
          );

          return _.flatten(_.filter(times, (time) => time.length > 1));
        });
      });
    },
    singleAgendaGroupedByTime: (state, getters) => {
      return getters.agendasWithSessionsGroupedByTime[0];
    },
    searchAgendasForDateAndTime: (state, getters) => (date, time) => {
      const items = getters.singleAgendaGroupedByTime;
      const found = _.find(items, (value, key) => key === date);

      return found
        ? _.filter(found, (value) => value.startDate == time)
        : false;
    },
    groupItemIndex: (state, getters) => (date, time, sessionId) => {
      const group = getters.searchAgendasForDateAndTime(date, time);
      return _.findIndex(group, { id: sessionId });
    },
    groupItemIsFirst: (state, getters) => (date, time, sessionId) => {
      const group = getters.groupItemIndex(date, time, sessionId);
      return _.findIndex(group, { id: sessionId }) === 0;
    },
    groupItemIsLast: (state, getters) => (date, time, sessionId) => {
      const group = getters.searchAgendasForDateAndTime(date, time);
      return group.length > 0
        ? _.findLastIndex(group) ===
            getters.groupItemIndex(date, time, sessionId)
        : false;
    },
    isSuccessfullyLoaded: (state) => {
      return state.loading === false && state.failed === false;
    }
  },
  mutations: {
    setFilteredAgenda: (state, payload) => {
      const { searchText, category } = payload;
      const filteredSessions = state.items[0].sessions.filter((session) => {
        const matchesSearchText =
          session.keywords.some((keyword) =>
            keyword.value?.toLowerCase().includes(searchText.toLowerCase())
          ) ||
          session.title?.toLowerCase().includes(searchText.toLowerCase()) ||
          session.subtitle?.toLowerCase().includes(searchText.toLowerCase()) ||
          session.speakers.some((speaker) =>
            speaker.name?.toLowerCase().includes(searchText.toLowerCase())
          );
        const matchesCategory =
          !category ||
          session.categories.some(
            (c) => c.value?.toLowerCase() === category.toLowerCase()
          );

        return matchesSearchText && matchesCategory;
      });
      state.filteredItems = [
        {
          sessions: filteredSessions,
          tracks: state.items[0].tracks
        }
      ];
    },

    setAgenda: (state, payload) => {
      state.items = payload;
    }
  },

  actions: {
    filterSessions({ commit }, payload) {
      commit('setFilteredAgenda', payload);
    }
  }
});
