<template>
  <div class="mx-auto">
    <div class="p-4">
      <h2 class="text-center text-lg mb-8">
        {{ $t('Views.QrTicket.qrTicket') }}
      </h2>
      <h3 class="text-center mb-1 font-bold">{{ this.eventName }}</h3>
      <h4 class="text-center mb-8">
        {{ this.name }}
      </h4>
      <div v-if="loading"><Spinner /></div>
      <div
        class="flex flex-col items-center justify-center"
        v-else-if="!loading && name"
      >
        <qrcode-vue
          :value="registrationId"
          :size="qrCodeSize"
          render-as="svg"
          level="H"
        />
      </div>
      <div v-else-if="error">
        <p class="p-4 font-bold text-sm md:text-base text-center">
          {{ $t('Views.QrTicket.qrTicket_not_available') }}
        </p>
      </div>
    </div>
    <QrTicketFooter />
  </div>
</template>

<script>
import ApiClient from '@/client';
import { mapGetters } from 'vuex';
import QrcodeVue from 'qrcode.vue';
import Spinner from '@/components/shared/Spinner';
import QrTicketFooter from '@/views/QrTicket/QrTicketFooter';
export default {
  data() {
    return {
      loading: false,
      name: '',
      windowWidth: window.innerWidth,
      error: false
    };
  },
  created() {
    this.checkRegistrationId();
  },
  props: {
    registrationId: String
  },
  components: {
    QrcodeVue,
    Spinner,
    QrTicketFooter
  },
  computed: {
    ...mapGetters('CurrentEvent', { eventName: 'getTitle' }),
    qrCodeSize() {
      return Math.min(Math.max(parseInt(this.windowWidth / 2), 50), 200);
    }
  },
  methods: {
    checkRegistrationId() {
      this.loading = true;
      ApiClient.getRegistrationId(this.registrationId)
        .then((response) => {
          if (response.data?.userID) {
            this.name = `${response.data?.firstname} ${response.data?.lastname}`;
          } else {
            this.error = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
