<template>
  <div class="absolute top-0 bottom-0 w-full">
    <DocumentsDataProvider
      v-slot="{ documents, designStyles }"
      :applicablePlatforms="applicablePlatform"
    >
      <div class="flex flex-grow h-full" :style="designStyles">
        <div class="flex flex-col w-full h-full">
          <!--header-->
          <div class="space-y-2 p-4">
            <div class="text-md font-bold">
              {{ $t('Views.Stage.Documents.documents') }}
            </div>
          </div>
          <!-- Documents -->
          <div
            class="flex-grow h-auto overflow-y-auto overflow-x-hidden p-2 space-y-6"
          >
            <TappinButton
              icon="share"
              class="button button-secondary"
              @click="sendDocumentsByEmail()"
              :custom_caption="$t('Views.Stage.Documents.sendAll')"
            />
            <div
              v-for="document in documents"
              :key="document.DocumentID"
              class="w-full px-2"
            >
              <div class="mb-2">
                <p class="text-xs">
                  <Moment :value="document.uTime" format="DD.MM.YYYY" />
                  <br />
                </p>
                <h4 class="font-bold">
                  {{ document.Name }}
                </h4>
              </div>
              <div class="mb-4">
                <div class="flex">
                  <button
                    v-if="canPreview(document) && isDesktop"
                    class="button button-secondary mr-4"
                    aria-expanded="true"
                    aria-haspopup="true"
                    :aria-label="$t('Views.Stage.Documents.show')"
                    @click="onClickPreview(document)"
                  >
                    {{ $t('Views.Stage.Documents.show') }}
                  </button>
                  <a
                    :href="document.Documentfile"
                    target="_blank"
                    class="button button-primary"
                    :aria-label="$t('Views.Stage.Documents.download')"
                    @click="onClickDownload(document)"
                    download
                  >
                    {{ $t('Views.Stage.Documents.download') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentsDataProvider>
  </div>
</template>

<script>
import ApiClient from '@/client';
import { documentPreviewMixin } from '@/mixins';
import TappinButton from '@/components/buttons/TappinButton';
import { createAnalyticsEvent } from '@/helpers/ga-helper';
import { getPusherInstance } from '@/utils';
import { mapMutations } from 'vuex';

export default {
  name: 'Documents',
  components: {
    TappinButton
  },
  data() {
    return {
      screenWidth: window.screen.width
    };
  },
  created() {
    window.addEventListener(
      'resize',
      () => {
        this.screenWidth = window.screen.width;
      },
      getPusherInstance({ isReady: true }).bind(`document-change`, () => {
        this.fetchItems();
      })
    );
  },
  mixins: [documentPreviewMixin],
  computed: {
    isDesktop() {
      return this.screenWidth > 1024;
    },
    applicablePlatform() {
      return this.$route.fullPath.includes('webapp') ? ['webApp'] : ['digital'];
    }
  },
  methods: {
    ...mapMutations('Documents', ['setItems']),
    sendDocumentsByEmail() {
      const eventId = this.$store.getters['CurrentEvent/getId'];
      createAnalyticsEvent(this.$gtag, 'SendByEmail', 'Documents', eventId);
      ApiClient.sendAllDocuments().then(() => {
        this.$notify({
          group: 'default',
          title:
            this.$t('Views.Stage.Documents.successMessage1') +
            ' ' +
            this.$t('Views.Stage.Documents.successMessage2')
        });
      });
    },
    onClickDownload(doc) {
      createAnalyticsEvent(this.$gtag, 'Download', 'Documents', doc.DocumentID);
    },
    onClickPreview(doc) {
      createAnalyticsEvent(this.$gtag, 'Preview', 'Documents', doc.DocumentID);
      this.openPreview(doc);
    },
    fetchItems() {
      ApiClient.getDocuments().then((r) => this.setItems(r.data.documents));
    }
  }
};
</script>
