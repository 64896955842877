import Vue from 'vue';
import ApiClient from '../../client';
import { createNamespacedDataProviderStore, extendStore } from './dataprovider';
import _ from 'lodash';

const store = createNamespacedDataProviderStore((setItems) => {
  return ApiClient.getChats().then((response) => setItems(response));
});

export default extendStore(store, {
  state: {
    chats: [],
    hasAllChats: false,
    currentChatID: null,
    mode: 'messages',
    chatLoading: false,
    isOutGoingCall: false,
    isInCall: false,
    isNewChat: false,
    isCallEnded: false,
    isCallNotAvailable: false,
    isChatNew: false,
    isChatList: false,
    promise: null
  },
  getters: {
    getChats: (state) => state.chats,
    getCurrentChatID: (state) => state.currentChatID,
    getCurrentChat: (state, getters) => {
      return state.chats.find(
        (chat) => chat.ChatID === getters.getCurrentChatID
      );
    },
    getChatWithUser: (state) => (id) => {
      return state.chats.find((chat) =>
        chat.Users.some((user) => user.UserID === id)
      );
    },
    getChatLastMessage: (state) => (id) => {
      const messages = state.chats.find(
        (element) => element.ChatID === id
      )?.Messages;
      return messages?.[messages?.length - 1];
    },
    getCurrentChatMessages: (state, getters) => {
      return state.chats.find(
        (element) => element.ChatID === getters.getCurrentChatID
      )?.Messages;
    },
    getUnreadChatMessages: (state, getters, rootState, rootGetters) => (id) => {
      const ownUser = rootGetters['Authentication/getUser'];
      const ownUserID = ownUser.UserID;

      return state.chats
        .filter((chat) => chat.ChatID === id)
        .flatMap((chat) => chat.Messages)
        .filter(
          (message) =>
            message.UserID !== ownUserID && !message.readby.includes(ownUserID)
        );
    },
    getUnreadChatsCount: (state, getters, rootState, rootGetters) => {
      const ownUser = rootGetters['Authentication/getUser'];

      if (!ownUser) {
        return 0;
      }

      const ownUserID = ownUser.UserID;

      return state.chats.filter((chat) =>
        chat.Messages.some(
          (message) =>
            message.UserID !== ownUserID && !message.readby.includes(ownUserID)
        )
      ).length;
    },
    chatMessagesGroupedByDay: (state) => {
      return state.chats.map((group) => {
        return {
          ...group,
          chats: _.groupBy(group.chats, (session) =>
            Vue.moment(session.startDate).format('DD-MM-YYYY')
          )
        };
      });
    },
    chatMessagesGroupedByTime: (state, getters) => {
      return getters.chatMessagesGroupedByDay.map((group) => {
        return _.mapValues(group.chats, (value) => {
          const times = _.groupBy(value, (day) =>
            Vue.moment(day.startDate).format('HH:mm')
          );
        });
      });
    },
    singleChatGroupedByTime: (state, getters) => {
      return getters.chatMessagesGroupedByTime[0];
    },
    getUserId: (state) => state.ChatUserID,
    getIsOutGoingCall: (state) => state.isOutGoingCall,
    getIsChatNew: (state) => state.isChatNew,
    getInCall: (state) => state.isInCall,
    getIsChatList: (state) => state.isChatList,
    getCallEnded: (state) => state.isCallEnded,
    getIsNewChat: (state) => state.isNewChat,
    getCallNotAvailable: (state) => state.isCallNotAvailable,
    getMode: (state) => state.mode
  },
  mutations: {
    setChats: (state, payload) => {
      state.chats = payload;
    },
    setCurrentChatID: (state, payload) => {
      state.currentChatID = payload;
    },
    setHasAllChats: (state, payload) => {
      state.hasAllChats = payload;
    },
    setPromise: (state, promise) => {
      state.promise = promise;
    },
    setMode: (state, mode) => {
      state.mode = mode;
    },
    setIsOutGoingCall: (state, payload) => {
      state.isOutGoingCall = payload;
    },
    setInCall: (state, payload) => {
      state.isInCall = payload;
    },
    setCallEnded: (state, payload) => {
      state.isCallEnded = payload;
    },
    setIsNewChat: (state, payload) => {
      state.isNewChat = payload;
    },
    setCallNotAvailable: (state, payload) => {
      state.isCallNotAvailable = payload;
    },

    setIsChatList: (state, payload) => {
      state.isChatList = payload;
    },
    resetMode: (state) => {
      state.mode = 'messages';
    },

    addChat: (state, payload) => {
      const clone = _.cloneDeep(state.chats);
      clone.unshift(payload.item);
      state.chats = clone;
    },
    updateChat: (state, payload) => {},
    deleteChat: (state, payload) => {},

    addChatUser: (state, payload) => {},
    updateChatUser: (state, payload) => {},
    deleteChatUser: (state, payload) => {},
    addChatMessage: (state, payload) => {
      const message = payload.item;
      const chatid = message.ChatID;
      const chatsClone = _.cloneDeep(state.chats);
      const chat = chatsClone.find((i) => i.ChatID === chatid);
      if (chat) {
        chat.Messages = [...chat.Messages, message];
      }

      state.chats = chatsClone;
    },
    updateChatMessage: (state, payload) => {},
    deleteChatMessage: (state, payload) => {},
    markChatAsRead: (state, payload) => {
      const chatId = payload.chatid;
      const userId = payload.ownUserID;
      const chat = state.chats.find((i) => i.ChatID === chatId);
      const chatsClone = _.cloneDeep(state.chats);
      if (!chat) {
        return;
      }

      const chatMessagesClone = _.cloneDeep(chat.Messages);

      for (const message of chatMessagesClone) {
        if (message.UserID !== userId && !message.readby.includes(userId)) {
          message.readby = [...message.readby, userId];
        }
      }

      chat.Messages = chatMessagesClone;

      chatsClone.splice(
        chatsClone.findIndex((i) => i.ChatID === chatId),
        1,
        chat
      );

      state.chats = chatsClone;
    }
  },
  actions: {
    async addMessage({ dispatch, commit, getters, rootGetters }, payload) {
      const message = payload.item;
      const chatid = message.ChatID;
      if (chatid === getters.getCurrentChatID) {
        const ownUserID = rootGetters['Authentication/getUser'].UserID;
        message.readby = [...message.readby, ownUserID];
      }
      commit('addChatMessage', payload);
    },
    async loadChatData({ commit, getters, dispatch }) {
      return await ApiClient.getChats().then((response) => {
        if (response) {
          commit('setChats', response.data);
          commit('setHasAllChats', true);
          /*          const selectedChatId = getters.getCurrentChatID;
          if (selectedChatId) {
            dispatch("markChatRead", { chatid: selectedChatId });
          } */
        }
      });
    },
    async sendChatMessage({ commit }, payload) {
      await ApiClient.sendMessage(payload);
    },
    async createNewChat({ commit }, payload) {
      return await ApiClient.createChat(payload);
    },
    async markChatRead({ dispatch, commit, getters, rootGetters }, payload) {
      const response = await ApiClient.MarkChatAsRead(payload);

      if (!response) {
        return null;
      }

      payload.ownUserID = rootGetters['Authentication/getUser'].UserID;
      commit('markChatAsRead', payload);
    },
    activateChat({ commit }, payload) {
      commit('setCurrentChatID', payload.ChatID);
      commit('setInCall', false);
      commit('setIsNewChat', false);
      commit('setIsOutGoingCall', false);
      commit('setCallEnded', false);
      commit('setCallNotAvailable', false);
      commit('setIsChatList', false);
    }
  }
});
