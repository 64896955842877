<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <div class="p-12 flex flex-col h-full" :style="designStyles">
      <div>
        <!-- Content -->
        <div class="flex flex-row items-start pb-2">
          <div class="pr-2 px-0 md:px-2 lg:px-8 row-3">
            <div
              class="relative icon h-12 w-12 md:h-16 md:w-16 lg:h-24 lg:w-24"
              ref="childFocus"
              tabindex="0"
            >
              <Avatar
                :image="participant.Picture"
                :circle="true"
                :online-status="isUserOnline(participant.UserID)"
                @error="setAltImg"
                alt="User picture"
                class="h-12 w-12 md:h-16 md:w-16 lg:h-24 lg:w-24"
              />
            </div>
          </div>
          <div class="pl-2 md:pl-0 place-content-center space-y-1">
            <h2 class="text-xs md:text-base lg:text-md">
              {{ participant.Firstname }} {{ participant.Lastname }}
            </h2>
            <h3 class="text-xxs md:text-sm lg:text-base">
              {{ participant.Jobtitle }}
            </h3>
            <h3 class="text-xxs md:text-sm lg:text-base">
              {{ participant.Company }}
            </h3>
            <div class="pb-2 pt-0 md:pt-2">
              <div v-if="$route.name == 'stage' && features.stageChatEnabled">
                <button
                  v-if="!isCurrentUser(participant)"
                  class="button button-primary text-xxxs md:text-xs lg:text-sm"
                  aria-label="send private message"
                  @click="createChat(participant)"
                >
                  {{ $t('Views.Stage.ParticipantsModal.sendMessage') }}
                </button>
              </div>
              <div
                v-else-if="$route.name == 'lobby' && features.lobbyChatEnabled"
              >
                <button
                  v-if="!isCurrentUser(participant)"
                  class="button button-primary text-xxxs md:text-xs lg:text-sm"
                  aria-label="send private message"
                  @click="createChatInLobby(participant)"
                >
                  {{ $t('Views.Stage.ParticipantsModal.sendMessage') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Participant details-->
        <div
          class="flex flex-col px-4 py-6 md:px-8 md:py-8 border border-2 space-y-2 md:space-y-4 lg:space-y-6"
        >
          <!-- PHONE -->
          <div class="flex flex-row items-center">
            <div class="w-1/4">
              <i class="fal fa-phone-alt fa-sm md:fa-lg lg:fa-2x" />
            </div>
            <div>
              <span
                class="inline-block font-bold text-xxs md:text-sm lg:text-base"
                aria-label="users personal contact number"
              >
                {{ $t('Views.Stage.MyProfile.phone') }}
              </span>

              <div v-if="participant.Phone && features.profilePhoneVisible">
                <span
                  class="text-xxs md:text-xs lg:text-sm"
                  v-text="participant.Phone"
                />
              </div>
              <div v-else>
                <span
                  class="text-xxs md:text-xs lg:text-sm"
                  :aria-label="$t('Views.Stage.MyProfile.phoneNotAvailable')"
                  >{{ $t('Views.Stage.MyProfile.phoneNotAvailable') }}</span
                >
              </div>
            </div>
          </div>
          <!-- EMAIL -->
          <div class="flex flex-row items-center">
            <div class="w-1/4">
              <i class="fal fa-envelope fa-sm md:fa-lg lg:fa-2x" />
            </div>
            <div>
              <span
                class="font-bold text-xxs md:text-xs lg:text-base"
                aria-label="user's email address"
                >{{ $t('Views.Stage.MyProfile.email') }}</span
              >
              <div v-if="participant.Email && features.profileEmailVisible">
                <span
                  class="text-xxs md:text-xs lg:text-sm"
                  v-text="participant.Email"
                />
              </div>
              <div v-else>
                <span
                  class="text-xxs md:text-xs lg:text-sm"
                  :aria-label="$t('Views.Stage.MyProfile.emailNotAvailable')"
                  >{{ $t('Views.Stage.MyProfile.emailNotAvailable') }}</span
                >
              </div>
            </div>
          </div>
          <!-- DESCRIPTION -->
          <div
            class="text-xxs md:text-xs lg:text-sm"
            aria-label="More information about the user"
          >
            <p v-text="participant.About2" />
          </div>
        </div>
        <!-- Actions -->
        <div>
          <button
            class="button button-secondary button-xs text-xxxs md:button-sm md:text-sm mt-4"
            aria-label="close modal"
            @click="onClose()"
          >
            {{ $t('Views.Stage.ParticipantsModal.close') }}
          </button>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Avatar from '@/components/shared/Avatar';
import Conversations from '@/views/Stage/Conversations';
import ChatLobby from '@/components/chat/lobby/ChatLobby';
export default {
  name: 'ParticipantModal',
  components: {
    Avatar
  },
  props: {
    participant: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      tab: {
        enabled: () => true,
        key: 'conversations',
        icon: 'fa-comments',
        label: () => this.getEvent.captions.profileCaption,
        component: Conversations,
        isModal: false,
        onClick: this.openTab
      }
    };
  },
  computed: {
    ...mapGetters('Users', {
      isUserOnline: 'isUserOnline'
    }),
    ...mapGetters('CurrentEvent', ['features']),
    ...mapGetters('Chat', ['getChatWithUser']),
    ...mapGetters('Stage', ['getCurrentTab']),
    ...mapGetters('Authentication', ['getDefaultImage'])
  },
  mounted() {
    // this.$root.$on('focusChild', () => this.$refs.childFocus.focus());
  },
  methods: {
    ...mapMutations('Chat', [
      'setCurrentChatID',
      'setIsOutGoingCall',
      'setInCall',
      'setCallEnded',
      'setCallNotAvailable',
      'setIsChatList'
    ]),
    ...mapMutations('Stage', ['setCurrentTab']),
    ...mapActions('Chat', ['createNewChat']),
    setAltImg(event) {
      event.target.src = this.getDefaultImage;
    },
    isCurrentUser(user) {
      const currentUser = this.$store.getters['Authentication/getUser'];

      if (!currentUser) {
        return false;
      }

      return user.UserID === currentUser.UserID;
    },
    hasChat(userID) {
      return this.getChatWithUser(userID);
    },
    createChat() {
      const currentChat = this.hasChat(this.participant.UserID);
      if (currentChat) {
        this.setCurrentChatID(currentChat.ChatID);
        this.setInCall(false);
        this.setIsOutGoingCall(false);
        this.setCallEnded(false);
        this.setCallNotAvailable(false);
        this.setIsChatList(false);
        this.openChatModal(this.tab);
      } else {
        this.createNewChat(this.participant.UserID).then((response) => {
          this.setCurrentChatID(response.data);
          this.setInCall(false);
          this.setIsOutGoingCall(false);
          this.setCallEnded(false);
          this.setCallNotAvailable(false);
          this.setIsChatList(false);
          this.openChatModal(this.tab);
        });
      }
    },
    openChatModal(tab) {
      // Close participant profile
      // Close participant Modal
      // Open chat tab
      this.onClose();
      this.sidebarCollapsed = false;
      this.setCurrentTab(tab);
    },
    createChatInLobby() {
      const currentChat = this.hasChat(this.participant.UserID);
      if (currentChat) {
        this.setCurrentChatID(currentChat.ChatID);
        this.onClose();
        this.showChatModal();
      } else {
        this.createNewChat(this.participant.UserID).then((response) => {
          this.setCurrentChatID(response.data);
          this.onClose();
          this.showChatModal();
        });
      }
    },
    showChatModal(participant) {
      document.body.classList.add('overflow-hidden');
      this.$modal.show(
        ChatLobby,
        { participant },
        {
          clickToClose: false,
          adaptive: true,
          minWidth: 320,
          maxWidth: 1700,
          width: '75%',
          height: 'auto'
        }
      );
    },
    onClose() {
      document.body.classList.remove('overflow-hidden');
      this.$emit('close', {
        success: false
      });
    }
  }
};
</script>
<style scoped>
.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}
</style>
