<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <div
      class="flex flex-col h-full overflow-y-auto overflow-x-hidden space-y-6"
      :class="!isPromotedPage ? 'p-6' : ''"
      :style="designStyles"
    >
      <div class="flex-1">
        <table class="w-full">
          <thead class="text-md" :class="!isPromotedPage ? 'md:text-lg' : ''">
            <tr>
              <th class="text-left">
                <span ref="childFocus" tabindex="0">{{
                  $t('Components.Speaker.SpeakerSessions.time')
                }}</span>
              </th>
              <th class="text-left">
                <span>{{
                  $t('Components.Speaker.SpeakerSessions.session')
                }}</span>
              </th>
            </tr>
          </thead>
          <tbody :class="!isPromotedPage ? '' : 'md:text-sm'">
            <tr v-for="session in speaker.sessions" :key="session.id">
              <td class="w-2/5">
                <div class="text-left mt-6">
                  <span>
                    <Moment :value="session.startDate" format="HH:mm" />
                  </span>
                  -
                  <span>
                    <Moment :value="session.endDate" format="HH:mm" />
                  </span>
                </div>
              </td>
              <td class="w-3/5">
                <div class="text-left mt-6">
                  <div>
                    <Moment :value="session.startDate" format="dddd DD. MMMM" />
                  </div>
                  <div>
                    <span>
                      {{ session.title }}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="!isPromotedPage"
        class="text-base flex justify-between items-baseline"
      >
        <div>
          <button
            @click="onModalClose"
            class="button button-primary text-sm md:text-base"
            aria-label="close"
          >
            {{ $t('Components.Speaker.SpeakerSessions.close') }}
          </button>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>
<script>
export default {
  name: 'SpeakerSessions',
  props: {
    speaker: {
      type: Object,
      required: true
    },
    isPromotedPage: Boolean
  },
  mounted() {
    this.$root.$on('focusChild', () => this.$refs.childFocus.focus());
  },
  methods: {
    onModalClose() {
      this.$emit('close', {
        success: false
      });
      setTimeout(function () {
        // document.getElementById('sSession').focus();
      }, 0);
    }
  }
};
</script>
