<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <div
      class="relative flex flex-wrap flex-col h-screen"
      :style="designStyles"
    >
      <!-- Close -->
      <div class="absolute top-0 right-0">
        <button class="button" @click="closeCallEnded()">
          <i class="far fa-times fa-lg" />
        </button>
      </div>
      <!-- content-->
      <div class="m-auto">
        <div class="pb-32 flex justify-center">
          <div class="text-center">
            <h2 class="text-md">Call Ended</h2>
            <h3>03:24</h3>
          </div>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'CallEnded',
  methods: {
    ...mapMutations('Chat', ['setCallEnded']),
    closeCallEnded() {
      this.setCallEnded(false);
    }
  }
};
</script>
