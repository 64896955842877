<template>
  <div class="card p-4 mx-0 md:mx-12 lg:mx-0 mb-4 rounded-md bg-white">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card'
};
</script>

<style scoped lang="scss">
.card {
  transition: all 200ms;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
</style>
