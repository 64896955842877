<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { getPusherInstance } from '@/utils';

export default {
  name: 'FavSessionsDataProvider',
  created() {
    this.fetchItems();
    this.loadSortedBooksSesions();
  },
  methods: {
    ...mapActions('BookSessions', [
      'fetchItems',
      'refresh',
      'loadSortedBooksSesions',
      'addOrDeleteBooksSesions'
    ])
  },
  computed: {
    ...mapGetters('BookSessions', [
      'getItems',
      'singleMyAgendaGroupedByDay',
      'getLoadingState'
    ])
  },
  render() {
    // TODO(23 jul. 2020) ~ Helge: We should not render until we have our data, make this a bit more clever and abstract it away
    if (this.isLoading) {
      return this.$scopedSlots.default({
        isLoading: this.isLoading
      });
    }
    return this.$scopedSlots.default({
      bookSessions: this.getItems,
      singleMyAgendaGroupedByDay: this.singleMyAgendaGroupedByDay,
      addOrDeleteBooksSesions: this.addOrDeleteBooksSesions,
      isBookSessionLoading: this.getLoadingState
    });
  }
};
</script>
