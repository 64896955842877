<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'PublicCategoriesDataProvider',
  computed: {
    ...mapGetters('Categories', [
      'getItems',
      'isLoading',
      'failed',
      'getSponsorsCategories',
      'getAgendaSessionsCategories'
    ])
  },
  render() {
    return this.$scopedSlots.default({
      categories: this.getItems,
      sponsorsCategories: this.getSponsorsCategories,
      agendaSessionCategories: this.getAgendaSessionsCategories
    });
  }
};
</script>
