<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CollapsibleList',
  props: {
    initialActiveItem: {
      required: false,
      type: String,
      default: null
    }
  },
  data() {
    return {
      sharedState: {
        activeItem: null
      }
    };
  },
  created() {
    this.sharedState.activeItem = this.initialActiveItem;
  },
  provide() {
    return {
      collapsibleListState: this.sharedState
    };
  }
};
</script>

<style scoped></style>
