<script>
import { mapActions, mapGetters } from 'vuex';
import { getPusherInstance } from '@/utils';

export default {
  name: 'PublicGroupsDataProvider',
  created() {
    this.fetchItems();
    getPusherInstance().bind('publicGroups-change', () => this.refresh());
  },
  methods: {
    ...mapActions('PublicGroups', ['fetchItems', 'refresh'])
  },
  computed: {
    ...mapGetters('PublicGroups', ['getItems', 'isLoading', 'failed'])
  },
  render() {
    return this.$scopedSlots.default({
      PublicGroups: this.getItems,
      loading: this.isLoading,
      failed: this.failed,
      refresh: this.refresh
    });
  }
};
</script>
