<template>
  <PagesDataProvider
    v-slot="{ pages, mode, promotedPage, promotedPageComponent }"
    :applicablePlatforms="applicablePlatform"
  >
    <div class="absolute top-0 bottom-0 w-full info-content">
      <div class="flex flex-grow h-full">
        <div class="flex flex-col w-full h-full">
          <!--header-->
          <div class="space-y-2 p-4">
            <div class="text-md font-bold">
              {{ $t('Views.Stage.Labels.information') }}
            </div>
          </div>
          <!-- Information -->
          <div class="flex-grow h-auto overflow-y-auto overflow-x-hidden p-2">
            <div v-if="mode === 'info'" class="p-4">
              <CollapsibleList>
                <CollapsibleItem
                  v-for="(page, i) in pages"
                  v-slot="{ active, toggle }"
                  :key="page.PageID"
                  :item-id="i"
                >
                  <Card>
                    <div
                      class="button cursor-pointer flex items-center justify-start text-dark"
                      :aria-expanded="[active ? 'true' : 'false']"
                      aria-controls="expanded-panel"
                      tabindex="0"
                      @click="toggle"
                    >
                      <i
                        class="far mr-5"
                        :class="[active ? 'fa-minus' : 'fa-plus']"
                      />
                      <h4 class="text-base text-dark font-bold">
                        {{ page.Heading }}
                      </h4>
                    </div>
                    <div
                      v-show="active"
                      id="expanded-panel"
                      class="mt-5 text-base text-dark rich-text html-content"
                      v-html="page.Tekst"
                    />
                  </Card>
                </CollapsibleItem>
              </CollapsibleList>
            </div>
            <div v-if="mode === 'promo'">
              <component
                v-if="promotedPage.page && active"
                :is="promotedPageComponent"
                :id="promotedPage.id"
                container="Info"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </PagesDataProvider>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Card from '@/components/shared/Card';
import CollapsibleItem from '@/components/shared/CollapsibleItem';
import CollapsibleList from '@/components/shared/CollapsibleList';
import {
  listenClickInsideContainer,
  removeClickInsideContainerListener
} from '@/helpers/content-event-helpers';
import ApiClient from '@/client';
import { getPusherInstance, replaceTokens } from '@/utils';

export default {
  name: 'Information',
  components: {
    Card,
    CollapsibleItem,
    CollapsibleList
  },
  props: {
    active: {
      type: Boolean,
      required: false
    }
  },
  created() {
    getPusherInstance({ isReady: true }).bind(`info-page-change`, () => {
      this.fetchItems();
    });
  },
  mounted() {
    this.addListener();
  },
  beforeDestroy() {
    this.removeListener();
  },
  computed: {
    ...mapGetters('Authentication', ['getUser']),
    applicablePlatform() {
      return this.$route.fullPath.includes('webapp') ? ['webApp'] : ['digital'];
    }
  },
  methods: {
    ...mapActions('Info', ['setPromotedPage']),
    ...mapMutations('Pages', ['setItems']),
    addListener() {
      this.containerClickEvent = listenClickInsideContainer(
        document.getElementsByClassName('sidebar-content')[0],
        this.setPromotedPage
      );
    },
    removeListener() {
      removeClickInsideContainerListener(
        document.getElementsByClassName('sidebar-content')[0],
        this.containerClickEvent
      );
    },
    fetchItems() {
      ApiClient.getLivePages().then((r) => {
        if (r.data) {
          r.data = replaceTokens(r.data, this.getUser, 'Tekst');
        }
        this.setItems(r.data);
      });
    }
  },
  watch: {
    active(newActive, oldActive) {
      if (newActive && !oldActive) {
        this.addListener();
      }

      if (oldActive && !newActive) {
        this.removeListener();
      }
    }
  }
};
</script>

<style scoped>
::v-deep .html-content > p:has(iframe) {
  position: relative;
  padding-top: 56.25%;
}
::v-deep .html-content > p > iframe {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
}
</style>
