<template>
  <div>
    <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'PwaLogin',
  computed: {
    ...mapGetters('CurrentEvent', ['getId'])
  },
  methods: {
    ...mapActions('Authentication', ['doPasswordlessLogin'])
  },
  created() {
    if (this.$route.query.otp) {
      this.doPasswordlessLogin({
        payload: this.$route.query.otp,
        eventId: this.getId,
        visible: this.$route.query.public
      }).then(() => {
        this.$router.push({ name: 'webapp' });
      });
    }
  }
};
</script>
