<template>
  <div class="m-2 rounded flex flex-row relative">
    <img class="max-h-16 max-w-16 object-contain rounded" :src="image" />
    <button class="absolute right-0" @click="$emit('click')">
      <i class="absolute right-0 fad fa-times-circle"></i>
    </button>
  </div>
</template>
<script>
export default {
  name: 'ImagePreview',
  props: {
    image: String
  }
};
</script>

<style scoped></style>
