import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import Authentication from './store/modules/authentication';
import CurrentEvent from './store/modules/currentEvent';
import Users from './store/modules/users';
import Speakers from './store/modules/speakers';
import Sponsors from './store/modules/sponsors';
import Documents from './store/modules/documents';
import Pages from './store/modules/pages';
import Info from './store/modules/info';
import Agendas from './store/modules/agendas';
import Timeline from './store/modules/timeline';
import Stream from './store/modules/stream';
import Sponsor from './store/modules/sponsor';
import Chat from '@/store/modules/chat';
import Stage from '@/store/modules/stage';
import BookSessions from '@/store/modules/bookSessions';
import Vouchers from '@/store/modules/vouchers';
import PublicGroups from '@/store/modules/publicGroups';
import Categories from '@/store/modules/categories';
import Terms from '@/store/modules/terms';
import Groups from '@/store/modules/groups';
import ResultsAuthentication from '@/store/modules/resultsAuthentication';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['Terms']
});

export default new Vuex.Store({
  strict: true,
  modules: {
    Authentication,
    Chat,
    CurrentEvent,
    Documents,
    Sponsors,
    Users,
    Speakers,
    Pages,
    Info,
    Agendas,
    Timeline,
    Stage,
    Stream,
    Sponsor,
    BookSessions,
    Categories,
    Vouchers,
    PublicGroups,
    Groups,
    Terms,
    ResultsAuthentication
  },
  plugins: [vuexLocal.plugin]
});
