<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <div class="px-6 md:px-12 flex flex-col" :style="designStyles">
      <div
        class="flex flex-col items-center h-full mb-1 md:mb-2"
        v-if="profile"
      >
        <div class="text-base md:text-md font-bold pb-6">
          {{ $t('Components.shared.Navbar.myTicket') }}
        </div>
        <div class="flex items-center justify-center pb-1 md:pb-6 lg:pb-0">
          <qrcode-vue
            :value="profile.TicketQrCode"
            :size="qrCodeSize"
            render-as="svg"
            level="H"
          />
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>
<script>
import { mapGetters } from 'vuex';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'TicketModal',
  components: { QrcodeVue },
  data() {
    return { windowWidth: window.innerWidth };
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    ...mapGetters('Authentication', {
      profile: 'getUser'
    }),
    qrCodeSize() {
      return Math.min(Math.max(parseInt(this.windowWidth / 2), 100), 300);
    }
  }
};
</script>
