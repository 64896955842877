<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'VouchersDataProvider',
  created() {
    this.getVouchers();
  },
  methods: {
    ...mapActions('Vouchers', ['getVouchers'])
  },
  computed: {
    ...mapGetters('Vouchers', [
      'getVoucherBalance',
      'getIsVoucherBalanceLoading'
    ])
  },
  render() {
    return this.$scopedSlots.default({
      voucherBalance: this.getVoucherBalance,
      isVoucherBalanceLoading: this.getIsVoucherBalanceLoading
    });
  }
};
</script>
