<template>
  <CurrentEventDataProvider>
    <div class="flex flex-col h-screen">
      <div class="flex overflow-auto">
        <div class="flex flex-col w-full pb-4 md:mb-20">
          <!--Header-->
          <div class="w-full">
            <div class="h-16 bg-primary-bg text-primary-text">
              <div class="flex flex-wrap items-center">
                <button class="px-4" aria-label="go back" @click="backToList()">
                  <i class="far fa-chevron-left" aria-hidden="true" />
                </button>
                <p class="p-5 font-bold text-sm md:text-base">
                  {{ $t('Components.Chat.NewChat.newChatCreation') }}
                </p>
              </div>
            </div>
          </div>
          <ParticipantList :active="true" :listHeight="83.5" />
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'NewChat',
  components: {
    ParticipantList: () => import('@/components/participants/ParticipantList')
  },
  data() {
    return {
      query: ''
    };
  },
  computed: {
    ...mapGetters('Chat', [
      'getChats',
      'getChatWithUser',
      'getChatLastMessage'
    ]),
    ...mapGetters('Users', {
      isUserOnline: 'isUserOnline'
    }),
    ...mapGetters('Authentication', { getOwnUser: 'getUser' }),
    ...mapGetters('Authentication', ['getDefaultImage'])
  },
  methods: {
    ...mapActions('Chat', ['createNewChat']),
    ...mapMutations('Chat', [
      'setCurrentChatID',
      'setInCall',
      'setIsOutGoingCall',
      'setCallEnded',
      'setCallNotAvailable',
      'setIsNewChat',
      'setIsChatList'
    ]),
    setAltImg(event) {
      event.target.src = this.getDefaultImage;
    },
    getChatOtherUser(users) {
      return this.getChatOtherUsers(users)[0];
    },
    getChatOtherUsers(users) {
      return users.filter((item) => item !== this.getOwnUser.UserID) ?? [{}];
    },
    getSuggestionValue(suggestion) {
      return this.userName(suggestion.item);
    },
    userName(user) {
      return user.Firstname + ' ' + user.Lastname;
    },
    userPicture(user) {
      return user.Picture
        ? user.Picture
        : 'https://cencup.com/wp-content/uploads/2019/07/avatar-placeholder.png';
    },
    userStatus(user) {
      return this.isUserOnline(user.UserID);
    },
    hasChat(userID) {
      return this.getChatWithUser(userID);
    },
    createChat(user) {
      const currentChat = this.hasChat(user.UserID);
      if (currentChat) {
        this.setCurrentChatID(currentChat.ChatID);
        this.setIsNewChat(false);
        this.setInCall(false);
        this.setIsOutGoingCall(false);
        this.setCallEnded(false);
        this.setCallNotAvailable(false);
      } else {
        this.createNewChat(user.UserID).then((response) => {
          this.setCurrentChatID(response.data);
          this.setIsNewChat(false);
          this.setInCall(false);
          this.setIsOutGoingCall(false);
          this.setCallEnded(false);
          this.setCallNotAvailable(false);
        });
      }
    },
    backToList() {
      this.setIsNewChat(false);
      this.setIsChatList(true);
    },
    isOwnUser(userid) {
      return this.getOwnUser.UserID === userid;
    }
  }
};
</script>
<style scoped>
.icon {
  position: relative;
  display: flex;
  border: none;
  outline: none;
}
</style>
