<template>
  <CurrentEventDataProvider v-slot="{ features }">
    <Card id="card">
      <div>
        <!-- Post header -->
        <div class="flex items-center w-full">
          <a
            href="javascript:void(0)"
            class="no-underline hover:underline flex flex-wrap"
            @mouseover="mouseEnter"
            @mouseleave="mouseLeave"
          >
            <div class="flex items-center">
              <Avatar
                :image="user.Picture"
                :circle="true"
                :online-status="isUserOnline(user.UserID)"
                class="w-6 h-6 md:w-8 md:h-8"
                @error="setAltImg"
              />
              <div
                :class="[
                  (user.Firstname + user.Lastname).length < 20
                    ? 'font-bold px-2 text-xs md:text-sm'
                    : 'font-bold px-2 text-xxs md:text-xs'
                ]"
              >
                {{ user.Firstname }}
                {{ user.Lastname }}
              </div>
            </div>
          </a>
          <div class="text-xxs md:text-xs text-right whitespace-nowrap flex-1">
            <from-now :value="item.CreatedOn" tag="span" class="red" />
          </div>
        </div>
        <!-- Post content -->
        <div class="my-4 relative">
          <!-- Plain text -->
          <div v-if="item.Message" class="pr-8">
            <div
              class="text-xs md:text-sm html-content"
              v-html="item.Message"
            />
          </div>
          <!-- Images -->
          <div
            :class="[
              item.imageUrls.length > 1 ? 'grid grid-cols-2 gap-2' : 'block '
            ]"
          >
            <div v-for="imageUrl in item.imageUrls" :key="imageUrl">
              <TimelineImage
                :image="baseApiAssetUrl + imageUrl"
                @click="showImageModal(baseApiAssetUrl + imageUrl)"
              />
            </div>
          </div>
        </div>

        <div v-if="isItemPostedByCurrentUser(item)" class="flex justify-center">
          <DeleteButton
            @click="deleteItem(item.TimelineID)"
            :custom_caption="
              $t('Components.Timeline.TimelineUserItem.removePost')
            "
          />
        </div>
      </div>

      <!-- Likes & Comments -->
      <div class="flex justify-end items-center">
        <div class="flex justify-between items-center">
          <!-- Likes -->
          <div
            id="TimelineLikes"
            class="button"
            :disabled="islikeDisabled"
            aria-label="like this post"
            @click="toggleLike"
          >
            <span class="text-xs">{{ item.Likes }}</span>
            <i
              class="fa-thumbs-up ml-2"
              :class="{
                fal: !isLikedByCurrentUser,
                fas: isLikedByCurrentUser
              }"
              aria-hidden="true"
            />
          </div>
          <!-- Comments -->
          <div
            id="TimelineComments"
            v-if="isTimelineCommentingEnabled"
            class="button"
            aria-label="comment on this post"
            @click="showComments = !showComments"
          >
            <span class="text-xs">{{ item.Comments.length }}</span>
            <i class="fal fa-comment-alt ml-2" aria-hidden="true" />
          </div>
        </div>
      </div>

      <!-- Comments to this post -->
      <div v-show="showComments && isTimelineCommentingEnabled">
        <TimelineCommentPost :item="item" />

        <!-- Comments -->
        <div
          v-for="(comment, index) in item.Comments"
          :key="comment.TimelineID"
          class="text-xs flex flex-col justify-between m-2 p-4 bg-light rounded"
          :class="{
            'border-b':
              index !== item.Comments.length - 1 && item.Comments.length > 1
          }"
        >
          <div class="flex w-full items-center">
            <Avatar
              :image="getAuthor(comment).Picture"
              :circle="true"
              :online-status="isUserOnline(getAuthor(comment).UserID)"
              :alt="$t('Views.Shared.UserPicture')"
              class="w-6 h-6 md:w-8 md:h-8"
              @error="setAltImg"
            />
            <div class="font-bold px-1 md:px-2 text-xs md:text-sm">
              {{ getAuthor(comment).Firstname }}
              {{ getAuthor(comment).Lastname }}
            </div>
            <div
              class="text-xxs md:text-xs text-right h-8 flex flex-1 items-center justify-end"
            >
              <from-now :value="comment.CreatedOn" tag="span" />
            </div>
          </div>
          <div class="my-4 relative">
            <div
              class="m-2 text-xs md:text-sm pr-8 html-content"
              v-html="comment.Message"
            />
            <!-- Images -->
            <div
              :class="[
                comment.imageUrls.length > 1
                  ? 'grid grid-cols-2 gap-2'
                  : 'block'
              ]"
            >
              <div v-for="imageUrl in comment.imageUrls" :key="imageUrl">
                <TimelineImage
                  :image="baseApiAssetUrl + imageUrl"
                  @click="showImageModal(baseApiAssetUrl + imageUrl)"
                />
              </div>
            </div>
          </div>
          <div
            v-if="isItemPostedByCurrentUser(comment)"
            class="flex justify-center"
          >
            <DeleteButton
              @click="deleteComment(comment.TimelineID)"
              :custom_caption="
                $t('Components.Timeline.TimelineUserItem.removeComment')
              "
            />
          </div>
        </div>
      </div>
      <!--User profile Hovercard-->
      <div v-show="toShowOnHover" @mouseover="cardOver" @mouseleave="cardLeave">
        <Card id="hovercard">
          <div class="flex items-center">
            <div class="w-1/4">
              <Avatar
                :image="user.Picture"
                :circle="true"
                :online-status="isUserOnline(user.UserID)"
                :alt="$t('Views.Shared.UserPicture')"
                class="h-10 w-10 md:h-16 md:w-16"
                @error="setAltImg"
              />
            </div>
            <div class="flex flex-col space-y-1 md:space-y-2 sm:text-left">
              <h3 class="text-xs md:text-sm font-bold">
                {{ user.Firstname }}
                {{ user.Lastname }}
              </h3>
              <h2 class="text-xs md:text-sm">
                {{ user.Company }}
              </h2>
              <div class="flex flex-row">
                <button
                  class="button button-primary py-1 px-2 rounded text-xxxs md:text-xs mr-2"
                  aria-label="View profile"
                  @click="showDetails(user)"
                >
                  {{ $t('Views.Stage.ParticipantsModal.viewProfile') }}
                </button>
                <button
                  v-if="
                    !isItemPostedByCurrentUser(item) &&
                    features.stageChatEnabled
                  "
                  class="button button-secondary py-1 px-2 rounded text-xxxs md:text-xs"
                  aria-label="send message"
                  @click="createChat(user)"
                >
                  {{ $t('Views.Stage.ParticipantsModal.sendMessage') }}
                </button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Card>
  </CurrentEventDataProvider>
</template>
<script>
import { getApplicablePlatforms, unknownUser } from '@/utils';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Card from '@/components/shared/webapp/Card';
import Avatar from '@/components/shared/Avatar';
import Conversations from '@/views/Stage/Conversations';
import ParticipantModal from '@/views/Stage/Participant/ParticipantModal';
import FromNow from '@/components/shared/FromNow';
import ImageModal from '../shared/ImageModal';
import TimelineImage from '@/components/timeline/TimelineImage';
import TimelineCommentPost from '@/components/timeline/TimelineCommentPost';
import DeleteButton from '@/components/buttons/DeleteButton';
import { createAnalyticsEvent } from '@/helpers/ga-helper';

export default {
  components: {
    Card,
    Avatar,
    FromNow,
    TimelineImage,
    TimelineCommentPost,
    DeleteButton
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      baseApiAssetUrl: process.env.VUE_APP_API_BASE_URL + '/api/asset/',
      comment: null,
      showComments: false,
      timeout: null,
      isLoaded: false,
      toShowOnHover: false,
      tab: {
        enabled: () => true,
        key: 'conversations',
        icon: 'fa-comments',
        label: () => this.getEvent.captions.profileCaption,
        component: Conversations,
        isModal: false,
        onClick: this.openTab
      },
      likeDisabled: false
    };
  },
  computed: {
    ...mapGetters('CurrentEvent', ['features']),
    ...mapGetters('Users', {
      isUserOnline: 'isUserOnline'
    }),
    ...mapGetters('Chat', ['getChatWithUser']),
    ...mapGetters('Stage', ['getCurrentTab']),
    ...mapGetters('Authentication', ['getDefaultImage']),
    isLikedByCurrentUser() {
      const currentUser = this.$store.getters['Authentication/getUser'];
      return this.item.LikedBy.filter((e) => e === currentUser.UserID).length;
    },
    user() {
      return this.getAuthor(this.item);
    },
    islikeDisabled: function () {
      return this.likeDisabled;
    },
    isTimelineCommentingEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppTimelineCommenting
        : this.features.stageFuncTimelineCommenting;
    }
  },
  methods: {
    ...mapMutations('Chat', [
      'setCurrentChatID',
      'setIsOutGoingCall',
      'setInCall',
      'setCallEnded',
      'setCallNotAvailable',
      'setIsChatList'
    ]),
    ...mapMutations('Stage', ['setCurrentTab']),
    ...mapActions('Chat', ['createNewChat']),
    ...mapActions('Timeline', [
      'deleteItem',
      'deleteComment',
      'likePost',
      'unlikePost'
    ]),
    getAuthor(item) {
      return item?.Author ?? unknownUser;
    },
    showImageModal(image) {
      document.body.classList.add('overflow-hidden');
      this.$modal.show(
        ImageModal,
        { image },
        {
          adaptive: true,
          width: '50%',
          height: 'auto',
          minWidth: 300,
          maxWidth: 1500
        },
        { closed: () => document.body.classList.remove('overflow-hidden') }
      );
    },
    setAltImg(event) {
      event.target.src = this.getDefaultImage;
    },
    isItemPostedByCurrentUser(item) {
      const currentUser = this.$store.getters['Authentication/getUser'];

      if (!currentUser) {
        return false;
      }

      return item.Author?.UserID === currentUser.UserID;
    },
    toggleLike() {
      if (this.likeDisabled) {
        return;
      }
      createAnalyticsEvent(
        this.$gtag,
        this.isLikedByCurrentUser ? 'Unlike' : 'Like',
        'Timeline',
        this.item.TimelineID
      );
      this.likeDisabled = true;
      if (this.isLikedByCurrentUser) {
        this.unlikePost(this.item.TimelineID).then((value) => {
          this.likeDisabled = false;
        });
      } else {
        this.likePost(this.item.TimelineID).then((value) => {
          this.likeDisabled = false;
        });
      }
    },
    mouseEnter: function () {
      this.toShowOnHover = !this.toShowOnHover;
    },
    mouseLeave: function () {
      this.toShowOnHover = false;
    },
    cardOver: function () {
      clearTimeout(this.timeout);
      this.toShowOnHover = true;
    },
    cardLeave: function () {
      const self = this;
      this.timeout = setTimeout(function () {
        self.toShowOnHover = false;
        self.isLoaded = false;
      }, 500);
    },
    sortedAndFiltered(items) {
      return items;
    },
    showDetails(participant) {
      this.$modal.show(
        ParticipantModal,
        { participant },
        {
          adaptive: true,
          minWidth: 320,
          maxWidth: 1700,
          width: '45%',
          height: 'auto'
        }
      );
    },
    onClose() {
      this.$emit('close', {
        success: false
      });
    },
    hasChat(userID) {
      return this.getChatWithUser(userID);
    },
    createChat() {
      const currentChat = this.hasChat(this.item.Author.UserID);
      if (currentChat) {
        this.setCurrentChatID(currentChat.ChatID);
        this.setInCall(false);
        this.setIsOutGoingCall(false);
        this.setCallEnded(false);
        this.setCallNotAvailable(false);
        this.setIsChatList(false);
        this.openChatTab(this.tab);
      } else {
        this.createNewChat(this.item.Author.UserID).then((response) => {
          this.setCurrentChatID(response.data);
          this.setInCall(false);
          this.setIsOutGoingCall(false);
          this.setCallEnded(false);
          this.setCallNotAvailable(false);
          this.setIsChatList(false);
          this.openChatTab(this.tab);
        });
      }
    },
    openChatTab(tab) {
      this.onClose();
      this.sidebarCollapsed = false;
      this.setCurrentTab(tab);
    }
  }
};
</script>
<style scoped lang="scss">
#card {
  position: relative;
}
#hovercard {
  position: absolute;
  top: 3rem;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 10;
}
.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}
</style>
