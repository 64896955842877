import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '@/views/Login';
import PwaLogin from '@/views/PwaLogin';
import PasswordlessLogin from '@/views/PasswordlessLogin';
import PasswordlessLoginRequest from '@/views/PasswordlessLoginRequest';

import Lobby from '@/views/Lobby';
import Stage from '@/views/Stage';
import Sponsor from '@/views/Sponsor';

import AgendaIframe from '@/views/Iframes/AgendaIframe';
import SpeakersIframe from '@/views/Iframes/SpeakersIframe';

import Fallback from '@/views/Fallback';

import setCurrentEvent from './middleware/setCurrentEvent';
import setFullEvent from './middleware/setFullEvent';
import checkAuthentication from './middleware/checkAuthentication';

import SponsorContactForm from '@/views/SponsorContactForm';
import PollStream from '@/views/PollStream';
import PollManagement from '@/views/PollManagement';
import QuizStream from '@/views/QuizStream';
import QuizManagement from '@/views/QuizManagement';
import LoginResults from '@/views/LoginResults';
import PollResults from '@/views/PollResults';
import checkPollResultsAuthentication from './middleware/checkPollResultsAuthentication';
import ParticipantLink from '@/views/ParticipantLink';
import setPublicFullEvent from './middleware/setPublicFullEvent';
import ParticipantsAdmitted from '@/views/ParticipantsAdmitted';
import checkParticipantResultsAuthentication from './middleware/checkParticipantResultsAuthentication';
import QrTicket from '@/views/QrTicket/QrTicket';
import setAgendaIframe from './middleware/setAgendaIframe';
import setSpeakersIframe from './middleware/setSpeakersIframe';

Vue.use(VueRouter);

// Route definition
const routes = [
  { path: '/login', name: 'login', component: Login },
  {
    path: '/login/otp/:payload',
    name: 'login/otp',
    component: PasswordlessLogin
  },
  {
    path: '/login/otp/request/:payload',
    name: 'login/otp/request',
    component: PasswordlessLoginRequest
  },
  {
    path: '/login/pwa',
    name: 'login/pwa',
    component: PwaLogin
  },
  { path: '/', name: 'lobby', component: Lobby },
  { path: '/stage', name: 'stage', component: Stage },
  { path: '/webapp', name: 'webapp', component: Stage },
  { path: '/sponsor/:sponsorId', name: 'sponsor', component: Sponsor }
].map((r) => {
  return {
    ...r,
    meta: {
      middleware: [setCurrentEvent, checkAuthentication]
    }
  };
});

routes.push({
  path: '/polls/:pollAccessKey',
  name: 'pollStream',
  component: PollStream,
  props: true,
  meta: { middleware: [setFullEvent] }
});

routes.push({
  path: '/poll-management/:pollAccessKey',
  name: 'pollManagement',
  component: PollManagement,
  props: true,
  meta: { middleware: [setFullEvent] }
});

routes.push({
  path: '/quiz-management/:pollAccessKey',
  name: 'quiz-management',
  component: QuizManagement,
  props: true,
  meta: { middleware: [setFullEvent] }
});

routes.push({
  path: '/quiz/:pollAccessKey',
  name: 'quiz-stream',
  component: QuizStream,
  props: true,
  meta: { middleware: [setFullEvent] }
});

routes.push({
  path: '/quiz-open-text/:pollAccessKey',
  name: 'quiz-open-text',
  component: QuizStream,
  props: true,
  meta: { middleware: [setFullEvent] }
});

routes.push({
  path: '/treasure-hunt-management/:pollAccessKey',
  name: 'treasure-hunt-management',
  component: QuizManagement,
  props: true,
  meta: { middleware: [setFullEvent] }
});

routes.push({
  path: '/treasure-hunt-open-text/:pollAccessKey',
  name: 'treasure-hunt-open-text',
  component: QuizStream,
  props: true,
  meta: { middleware: [setFullEvent] }
});

routes.push({
  path: '/agendaIframe',
  name: 'agenda-iframe',
  component: AgendaIframe,
  props: false,
  meta: { middleware: [setPublicFullEvent, setAgendaIframe] }
});

routes.push({
  path: '/speakersIframe',
  name: 'speakers-iframe',
  component: SpeakersIframe,
  props: false,
  meta: { middleware: [setPublicFullEvent, setSpeakersIframe] }
});

routes.push({
  path: '/treasure-hunt/:pollAccessKey',
  name: 'treasure-hunt-stream',
  component: QuizStream,
  props: true,
  meta: { middleware: [setFullEvent] }
});

routes.push({
  path: '/login-poll-results/:pollAccessKey',
  name: 'login-poll-results',
  component: LoginResults,
  props: true,
  meta: { middleware: [setFullEvent] }
});

routes.push({
  path: '/login-poll-results',
  name: 'login-generic-poll-results',
  component: LoginResults,
  props: true,
  meta: { middleware: [setPublicFullEvent] }
});

routes.push({
  path: '/pollResults',
  name: 'generic-poll-results',
  component: PollResults,
  meta: { middleware: [setPublicFullEvent, checkPollResultsAuthentication] }
});

routes.push({
  path: '/pollResults/:pollAccessKey',
  name: 'poll-results',
  component: PollResults,
  props: true,
  meta: { middleware: [setFullEvent, checkPollResultsAuthentication] }
});

routes.push({
  path: '/participants/:pinCode',
  name: 'participants-link',
  component: ParticipantLink,
  props: true,
  meta: { middleware: [setFullEvent] }
});

routes.push({
  path: '/login-participants-results',
  name: 'login-participants-results',
  component: LoginResults,
  props: true,
  meta: { middleware: [setPublicFullEvent] }
});

routes.push({
  path: '/participantsAdmitted',
  name: 'participants-admitted',
  component: ParticipantsAdmitted,
  props: true,
  meta: {
    middleware: [setPublicFullEvent, checkParticipantResultsAuthentication]
  }
});

routes.push({
  path: '/qrTicket/:registrationId',
  name: 'qr-ticket',
  component: QrTicket,
  props: true,
  meta: { middleware: [setCurrentEvent] }
});

routes.push({
  path: '/sponsor/:sponsorId/contactForm',
  name: 'sponsorContactForm',
  component: SponsorContactForm,
  meta: { middleware: [setPublicFullEvent] }
});

routes.push({ path: '*', name: 'fallback', component: Fallback });

// Middleware factory
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);

    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

// Router instance
const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkExactActiveClass: 'active',
  linkActiveClass: 'active',
  routes: routes
});

// Make sure all middlewares run
router.beforeEach((to, from, next) => {
  // short circuit anchors on the same page with hashes
  if (from.name !== null && to.path === from.path && to.hash !== from.hash) {
    return next();
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = { from, next, router, to };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
