import { categoryType } from '@/constants/category-type-constants';
import ApiClient from '../../client';
const {
  createNamespacedDataProviderStore,
  extendStore
} = require('./dataprovider');

const store = createNamespacedDataProviderStore((setItems) => {
  return ApiClient.getCategories().then((response) => {
    setItems(response.data.categories);
  });
});

export default extendStore(store, {
  getters: {
    getAgendaSessionsCategories: (state) => {
      return state.items.filter(
        (category) => category.categoryType === categoryType.AGENDA
      );
    },
    getSponsorsCategories: (state) => {
      return state.items.filter(
        (category) =>
          category.categoryType === categoryType.SPONSOR ||
          category.categoryType === null ||
          category.categoryType === undefined
      );
    }
  },
  mutations: {
    setCategories: (state, payload) => {
      state.items = payload.categories;
    }
  }
});
