<template>
  <SponsorDataProvider v-slot="{ sponsor, loading }">
    <div
      v-if="!loading"
      class="absolute top-0 bottom-0 w-full overflow-y-auto flex flex-col z-10l sponsor-contact"
    >
      <div class="rich-text html-content" v-html="sponsor.description" />
      <div
        class="hidden lg:flex items-center justify-center h-16 bottom-0 fixed max-h-max w-full bg-white"
      >
        <TappinButton
          icon="share-square"
          :class="
            sponsor && sponsor.email === '' ? 'hidden' : 'button button-primary'
          "
          :custom_caption="$t('Views.Sponsor.sendInfo')"
          @click="sendContactInfo()"
        />
      </div>
    </div>
  </SponsorDataProvider>
</template>
<script>
import ApiClient from '@/client';
import TappinButton from '@/components/buttons/TappinButton';
import { createAnalyticsEvent } from '@/helpers/ga-helper';
export default {
  name: 'Information',
  components: {
    TappinButton
  },
  methods: {
    sendContactInfo() {
      const sponsorId = this.$route.params.sponsorId;
      createAnalyticsEvent(
        this.$gtag,
        'ClickOnSendContactInfo',
        'Sponsors',
        sponsorId
      );
      ApiClient.sendContactInfoToSponsor(sponsorId).then(() => {
        this.$notify({
          group: 'default',
          title: this.$t('Views.Sponsor.successMessage')
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.sponsor-contact {
  padding: 0 1rem;
  @media only screen and (min-width: 1024px) {
    padding-bottom: 5rem;
  }
}
</style>
