<template>
  <AgendasDataProvider
    v-slot="{
      singleAgendaBySpeakerId,
      trackById,
      setStreamFromSession,
      isLoading
    }"
  >
    <div
      v-if="!isLoading"
      class="absolute top-0 bottom-0 w-full overflow-y-auto flex flex-col z-10l"
    >
      <div
        v-if="singleAgendaBySpeakerId($route.params.sponsorId).length"
        class="p-4 pt-0"
      >
        <h2 class="text-md font-bold pb-6">
          {{ $t('Views.Sponsor.labelAgenda') }}
        </h2>

        <div class="pb-2 mt-4 pt-0 border-b border-grey">
          <!-- Agenda day header -->
          <div
            class="hover:outline-none focus:outline-none w-full flex justify-between items-baseline"
          >
            <div class="text-md font-bold">
              <Moment
                :value="
                  singleAgendaBySpeakerId($route.params.sponsorId)[0].startDate
                "
                format="dddd DD. MMMM"
              />
            </div>
          </div>
        </div>

        <CollapsibleList>
          <CollapsibleItem
            v-for="(session, i) in singleAgendaBySpeakerId(
              $route.params.sponsorId
            )"
            v-slot="{ active, toggle }"
            :key="i"
            :item-id="session.id"
          >
            <div class="mt-6 mb-2">
              <!-- Session content -->
              <div>
                <div class="flex items-center mb-1">
                  <span class="font-bold text-xs mr-4">
                    <Moment :value="session.startDate" format="HH:mm" />
                    -
                    <Moment :value="session.endDate" format="HH:mm" />
                  </span>

                  <div v-if="session.liveUrl">
                    <span
                      v-if="session.trackId != '0'"
                      class="inline-block font-bold text-xs py-1 px-2 text-white"
                      :style="{
                        'background-color': trackById(session.trackId)
                          .headerBackgroundColor
                      }"
                    >
                      {{ trackById(session.trackId).title }}
                    </span>
                    <span
                      v-else
                      class="inline-block bg-grey font-bold text-xs py-1 px-2"
                    >
                      {{ $t('Views.Stage.Agenda.breakoutsession') }}
                    </span>
                  </div>
                </div>
                <div class="mb-2">
                  <h3 class="font-bold text-base">
                    {{ session.title }}
                  </h3>
                  <h4>
                    {{ session.subtitle }}
                  </h4>
                </div>

                <!-- Session Actions -->
                <div
                  v-if="
                    session.description ||
                    session.speakers.length ||
                    session.liveUrl
                  "
                  class="flex"
                >
                  <button
                    v-if="session.Description || session.speakers.length"
                    class="button button-secondary mr-4"
                    aria-label="agenda description"
                    @click="toggle"
                  >
                    <span v-if="active">
                      {{ $t('Views.Stage.Agenda.showLess') }}
                    </span>
                    <span v-else>
                      {{ $t('Views.Stage.Agenda.moreInfo') }}
                    </span>
                  </button>

                  <button
                    v-if="session.liveUrl"
                    class="button button-primary mr-4"
                    aria-label="go to stage"
                    @click="setStreamFromSession(session)"
                  >
                    {{ $t('Views.Stage.Agenda.goToScene') }}
                  </button>
                </div>
              </div>

              <div v-if="active" class="mt-6">
                <div
                  class="mb-4 text-xs html-content"
                  v-html="session.description"
                />

                <div
                  v-if="session.speakers.length"
                  class="flex flex-row flex-wrap p-2"
                >
                  <div
                    v-for="speaker in session.speakers"
                    :key="speaker.id"
                    class="flex items-center space-x-2 space-y-2 w-1/2"
                  >
                    <Avatar
                      :image="speaker.profileImageUrl"
                      :circle="true"
                      :size="'small'"
                    />
                    <div class="flex flex-col text-xs">
                      <span class="font-bold">{{ getFullName(speaker) }}</span>
                      <span> {{ speaker.jobTitle }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CollapsibleItem>
        </CollapsibleList>
      </div>
    </div>
  </AgendasDataProvider>
</template>
<script>
import CollapsibleList from '@/components/shared/CollapsibleList';
import CollapsibleItem from '@/components/shared/CollapsibleItem';
import Avatar from '@/components/shared/Avatar';

export default {
  name: 'Agenda',
  components: {
    CollapsibleList,
    CollapsibleItem,
    Avatar
  },
  methods: {
    getFullName(speaker) {
      return speaker && speaker.name + ' ' + speaker.lastName;
    }
  }
};
</script>
