import router from '../router';

export default async function checkPollResultsAuthentication({ next, to }) {
  const eventIdFromRoute = window.location.host.split('.')[0];
  const pollResultsFromRoute = window.location.pathname.split('/')[2];
  const genericPollResultsFromRoute = window.location.pathname.split('/')[1];
  const results_access = JSON.parse(
    localStorage.getItem(`private_results_${pollResultsFromRoute}`)
  );

  const generic_results_access = JSON.parse(
    localStorage.getItem('private_results_generic')
  );

  if (eventIdFromRoute) {
    if (pollResultsFromRoute && to.name.startsWith('poll-results')) {
      if (!results_access) {
        return await router.replace({ name: 'login-poll-results' });
      }
    }

    if (
      genericPollResultsFromRoute &&
      to.name.startsWith('generic-poll-results')
    ) {
      if (!generic_results_access) {
        return await router.replace({ name: 'login-poll-results' });
      }
    }
  }

  return next();
}
