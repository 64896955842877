<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <UsersDataProvider :style="designStyles">
      <ChatDataProvider>
        <div class="h-full">
          <div class="relative flex h-full">
            <!--list section-->
            <div
              class="relative w-full md:w-2/5 lg:w-1/3 overflow-x-hidden overflow-y-scroll"
            >
              <div class="grid grid-cols-2">
                <!--List Header-->
                <div class="absolute inset-x-0 top-0 h-32 w-full">
                  <div class="py-2">
                    <div class="flex items-center py-2 px-4">
                      <h2 class="text-md lg:text-lg">
                        {{ $t('Components.Chat.chat') }}
                      </h2>
                      <div class="text-right flex-1">
                        <button
                          v-show="!isNewChat"
                          v-focus
                          class="button button-primary text-xs md:text-sm lg:text-base"
                          aria-label="open new chat"
                          @click="openNewChat()"
                        >
                          <i class="far fa-plus" aria-hidden="true" />
                          {{ $t('Components.Chat.ChatMessages.newChatButton') }}
                        </button>
                        <button
                          @click="onModalClose"
                          class="button ml-4 md:ml-0 md:hidden"
                          aria-label="close"
                        >
                          <i class="far fa-times fa-lg" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <!--search-->
                    <div class="relative px-2 py-1">
                      <vue-autosuggest
                        v-model="query"
                        :suggestions="filteredOptions"
                        :input-props="{
                          id: 'autosuggest__input',
                          class:
                            'bg-light text-dark text-xs lg:text-sm p-4 pr-12 w-full rounded-lg',
                          placeholder: $t('Components.Chat.search')
                        }"
                        :get-suggestion-value="getSuggestionValue"
                      >
                        <div style="display: flex; align-items: center"></div>
                      </vue-autosuggest>
                    </div>
                  </div>
                </div>
                <!--List Body-->
                <div
                  class="chatlist_body absolute bottom-0 w-full p-2 md:p-4 overflow-x-hidden overflow-y-auto"
                >
                  <div
                    v-for="chat in filteredOptions[0].data"
                    :key="chat.ChatID"
                  >
                    <button
                      type="submit"
                      class="button w-full h-16 hover:opacity-50"
                      aria-label="open message"
                      @click="openCurrentChat(chat)"
                    >
                      <div class="icon w-full flex flex-wrap items-center">
                        <div class="pr-4 md:pr-2 lg:pr-4">
                          <div class="notify">
                            <Avatar
                              :image="chatPicture(chat)"
                              :circle="true"
                              :size="'medium'"
                              :alt="$t('Views.Shared.UserPicture')"
                              :online-status="status(chat)"
                              @error="setAltImg"
                            />
                          </div>
                        </div>
                        <div
                          class="message flex flex-col text-left"
                          :class="{ 'font-bold': hasUnreadMessages(chat) }"
                          :aria-label="
                            hasUnreadMessages(chat)
                              ? 'has unread message'
                              : 'open message'
                          "
                        >
                          <div class="name">
                            <span class="name__truncate text-sm lg:text-base">
                              {{ chatName(chat) }}
                            </span>
                          </div>
                          <div>
                            <span class="message__truncate text-xs">
                              {{
                                getChatLastMessage(chat.ChatID).Message
                              }}</span
                            >
                          </div>
                          <div
                            v-if="hasUnreadMessages(chat)"
                            class="icon__unread"
                          />
                        </div>
                        <div
                          class="text-xxs md:text-xs text-right whitespace-nowrap flex-1"
                          aria-label="last message time"
                        >
                          <!--<span class="icon__notification-badge">{{
                            chat.Messages.length
                          }}</span>-->
                          <span
                            :title="
                              formattedDateTime(
                                getChatLastMessage(chat.ChatID).CreatedAt
                              )
                            "
                            >{{
                              formattedTime(
                                getChatLastMessage(chat.ChatID).CreatedAt
                              )
                            }}</span
                          >
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--Default section-->
            <div v-if="isDefault" class="hidden md:inline-block md:w-2/3">
              <div class="hidden md:block md:w-full">
                <div class="flex flex-wrap flex-col h-screen">
                  <div class="flex flex-wrap items-center">
                    <div class="text-right whitespace-nowrap flex-1">
                      <button
                        @click="onModalClose"
                        class="button w-16 h-16"
                        aria-label="close"
                      >
                        <i class="far fa-times fa-lg" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="m-auto px-16 text-center">
                    <h2 class="text-sm md:text-base" style="opacity: 0.6">
                      {{ $t('Components.Chat.startConversation') }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <!--Conversation section-->
            <div
              v-if="isConversation"
              class="w-full md:w-3/5 lg:w-2/3 absolute float-none top-0 bottom-0 right-0 bg-white"
            >
              <div
                v-chat-scroll="{ smooth: true }"
                class="grid grid-rows-3 grid-flow-col h-full overflow-x-hidden overflow-y-auto"
                style="grid-template-rows: 10% 78% 10%"
              >
                <!--Conversation header-->
                <div class="h-20 md:h-24 lg:h-20">
                  <div
                    class="absolute top-0 left-0 right-0 h-20 md:h-24 lg:h-20 bg-primary-bg text-primary-text z-10"
                  >
                    <div
                      ref="chatHeader"
                      class="name flex flex-wrap items-center"
                      tabindex="0"
                    >
                      <button
                        class="button px-4"
                        aria-label="go to previous page"
                        @click="setCurrentChatID(false)"
                      >
                        <i class="far fa-chevron-left" aria-hidden="true" />
                      </button>
                      <div class="icon">
                        <div class="md:pl-4">
                          <Avatar
                            :image="getChatOtherUser().Picture"
                            :circle="true"
                            :size="'medium'"
                            :online-status="isUserOnline(getChatOtherUser())"
                            :alt="$t('Views.Shared.UserPicture')"
                            @error="setAltImg"
                          />
                        </div>
                      </div>
                      <div
                        class="headerName pl-2 md:pl-4 font-bold text-sm md:text-base"
                      >
                        <button
                          refs="individualChatProfile"
                          class="button w-full hover:opacity-50"
                          aria-label="user first and last name"
                          @click="showDetails(getChatOtherUser())"
                        >
                          <span class="name__truncate">
                            {{ userName(getChatOtherUser()) }}</span
                          >
                        </button>
                      </div>
                      <div
                        class="h-20 md:h-28 lg:h-20 text-right whitespace-nowrap flex-1"
                      >
                        <div class="inline-block">
                          <button
                            @click="onModalClose"
                            class="button w-12 md:w-16 h-20 md:h-28 lg:h-20 hover:opacity-50"
                            aria-label="close"
                          >
                            <i class="far fa-times fa-lg" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Conversation body-->
                <div class="overflow-y-auto w-full">
                  <div class="p-2 space-y-2">
                    <div id="ChatMessages">
                      <div
                        v-for="chatMessage in getCurrentChatMessages"
                        :key="chatMessage.ChatMessageID"
                        class="px-2"
                      >
                        <div>
                          <div
                            class="flex justify-center items-center space-x-4"
                          >
                            <div class="capitalize text-xxs">
                              <span
                                >{{
                                  formattedMessageTime(chatMessage.CreatedAt)
                                }}
                              </span>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              class="flex flex-wrap items-center gap-2"
                              :class="{
                                'items-end pt-2': !isOwnUser(
                                  chatMessage.UserID
                                ),
                                'justify-end': isOwnUser(chatMessage.UserID)
                              }"
                            >
                              <Avatar
                                v-if="!isOwnUser(chatMessage.UserID)"
                                :image="getChatOtherUser().Picture"
                                :circle="true"
                                :size="'medium'"
                                :alt="$t('Views.Shared.UserPicture')"
                                @error="setAltImg"
                              />
                              <div
                                class="messageWidth py-2 px-4 rounded-2xl shadow-inner"
                                :class="{
                                  'bg-primary-bg text-primary-text': !isOwnUser(
                                    chatMessage.UserID
                                  ),
                                  'bg-secondary-bg text-secondary-text':
                                    isOwnUser(chatMessage.UserID)
                                }"
                              >
                                <div class="text-left">
                                  <span
                                    class="break-all text-xs md:text-sm"
                                    :title="
                                      formattedFullDateTime(
                                        chatMessage.CreatedAt
                                      )
                                    "
                                    >{{ chatMessage.Message }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Conversation footer -->
                <div class="h-16 md:h-24 lg:h-20">
                  <div
                    class="absolute bottom-0 left-0 right-0 h-16 md:h-24 lg:h-20 bg-white"
                  >
                    <form
                      class="flex items-center rounded-lg"
                      style="box-shadow: 0 -5px 20px -10px rgba(0, 0, 0, 0.3)"
                      @submit.prevent="sendMessage"
                    >
                      <input
                        v-model="inputMessage"
                        type="text"
                        :placeholder="
                          $t('Components.Chat.ChatMain.writeAMessage')
                        "
                        class="bg-white ml-4 mr-2 px-4 py-2 w-full text-black text-base placeholder-gray-500"
                      />
                      <button
                        :disabled="!inputMessage"
                        type="submit"
                        class="button w-16 h-16 md:h-24 lg:h-20"
                        aria-label="send message"
                      >
                        <i class="fal fa-paper-plane" aria-hidden="true" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!--New chat section section-->
            <div
              v-if="isNewChat"
              class="w-full md:w-3/5 lg:w-2/3 absolute float-none top-0 bottom-0 right-0 bg-white"
            >
              <div
                class="grid grid-rows-2 grid-flow-col h-full overflow-auto"
                style="grid-template-rows: 15% 80%"
              >
                <!--Header-->
                <div class="h-32">
                  <div class="absolute top-0 left-0 right-0 z-10">
                    <div class="h-20 bg-primary-bg text-primary-text">
                      <div class="flex flex-wrap items-center">
                        <button
                          class="button px-4"
                          aria-label="go to previous page"
                          @click="setIsNewChat(false)"
                        >
                          <i class="far fa-chevron-left" aria-hidden="true" />
                        </button>
                        <h3 class="p-5 font-bold text-sm md:text-base">
                          {{ $t('Components.Chat.NewChat.newChatCreation') }}
                        </h3>
                        <div class="h-20 text-right whitespace-nowrap flex-1">
                          <div class="hidden md:inline-block">
                            <button
                              @click="onModalClose"
                              class="button w-12 md:w-16 h-16 hover:opacity-50"
                              aria-label="close"
                            >
                              <i
                                class="far fa-times fa-lg"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ParticipantList
                  :active="true"
                  :isChatOpen="true"
                  :listHeight="83.5"
                />
              </div>
            </div>
          </div>
        </div>
      </ChatDataProvider>
    </UsersDataProvider>
  </CurrentEventDataProvider>
</template>
<script>
import ParticipantsModal from '@/views/Stage/Participant/ParticipantsModal';
import Avatar from '@/components/shared/Avatar';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { VueAutosuggest } from 'vue-autosuggest';
import { formatDate, unknownUser } from '@/utils';
import _ from 'lodash';
export default {
  name: 'ChatLobby',
  components: {
    VueAutosuggest,
    Avatar,
    ParticipantList: () => import('@/components/participants/ParticipantList')
  },
  data() {
    return {
      query: '',
      selected: '',
      isDefault: true,
      inputMessage: ''
    };
  },
  computed: {
    ...mapGetters('Users', {
      getUser: 'getById',
      getUsers: 'getItems',
      isUserOnline: 'isUserOnline'
    }),
    ...mapGetters('Chat', [
      'getChatLastMessage',
      'getChats',
      'getChatWithUser',
      'getChatMessages',
      'getIsNewChat',
      'getCurrentChatID',
      'getCurrentChat',
      'getUnreadChatMessages',
      'getCurrentChatMessages'
    ]),
    ...mapGetters('Authentication', { getOwnUser: 'getUser' }),
    ...mapGetters('Authentication', ['getDefaultImage']),
    filteredOptions() {
      return [
        {
          data: this.suggestions[0].data.filter((option) => {
            return (
              this.chatName(option)
                .toLowerCase()
                .indexOf(this.query.toLowerCase()) > -1
            );
          })
        }
      ];
    },
    suggestions() {
      return [
        {
          data: this.sortedChats
        }
      ];
    },
    sortedChats() {
      const clone = _.cloneDeep(this.getChats).filter(
        (option) => option.Messages.length >= 1
      );
      return _.orderBy(
        clone,
        [
          (item) => {
            const nestedObj = _.get(item, 'Messages');
            return nestedObj[nestedObj.length - 1]?.CreatedAt;
          }
        ],
        'desc'
      );
    },

    isNewChat() {
      return this.getIsNewChat;
    },

    isConversation() {
      return this.getCurrentChatID;
    }
  },
  mounted() {
    // this.$root.$on('focusHeader', () => this.$refs.chatHeader.focus());
  },
  methods: {
    ...mapActions('Chat', [
      'addChatEntry',
      'markChatRead',
      'createNewChat',
      'sendChatMessage'
    ]),
    ...mapMutations('Chat', [
      'setCurrentChatID',
      'setInCall',
      'setIsOutGoingCall',
      'setCallEnded',
      'setCallNotAvailable',
      'setIsNewChat'
    ]),
    setAltImg(event) {
      event.target.src = this.getDefaultImage;
    },
    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue(suggestion) {
      return this.chatName(suggestion.item);
    },
    getChatOtherUser(users) {
      return this.getChatOtherUsers(users)[0] ?? unknownUser;
    },
    getChatOtherUsers(users) {
      return (
        (users ?? this.getCurrentChat?.Users)?.filter(
          (item) => item.UserID !== this.getOwnUser.UserID
        ) ?? [{}]
      );
    },
    status(chat) {
      return this.isUserOnline(this.getChatOtherUser(chat.Users));
    },
    userStatus() {
      return this.isUserOnline(
        this.getChatOtherUser(this.getCurrentChat?.Users)
      );
    },
    userName(user) {
      return user.Firstname + ' ' + user.Lastname;
    },
    chatName(chat) {
      const otherUser = this.getChatOtherUser(chat.Users);
      return `${otherUser.Firstname} ${otherUser.Lastname}`;
    },
    userPicture(user) {
      return user?.Picture
        ? user.Picture
        : 'https://ideffector-prod.s3.amazonaws.com/images/avatar.png';
    },
    chatPicture(chat) {
      return this.userPicture(this.getChatOtherUser(chat.Users));
    },
    currentDate() {
      return this.$moment().format('DD-MMMM-YYYY');
    },
    formattedMessageTime(date) {
      return this.currentDate() == this.formattedDate(date)
        ? this.formattedTime(date)
        : this.formattedDateTime(date);
    },
    formattedTime(date) {
      return formatDate(date, 'HH:mm');
    },
    formattedDate(date) {
      return formatDate(date, 'DD-MMMM-YYYY');
    },
    formattedDateTime(date) {
      return formatDate(date, 'DD-MMM HH:mm');
    },
    formattedFullDateTime(date) {
      return formatDate(date, 'DD-MMMM-YYYY, HH:mm');
    },
    hasUnreadMessages(chat) {
      return this.getUnreadChatMessages(chat.ChatID).length > 0;
    },
    isOwnUser(userid) {
      return this.getOwnUser.UserID === userid;
    },
    sendMessage() {
      this.sendChatMessage({
        chatid: this.getCurrentChatID,
        message: this.inputMessage
      });
      this.inputMessage = '';
    },
    openNewChat() {
      this.setIsNewChat(true);
      this.setCurrentChatID(null);
    },
    openDefault() {
      this.isDefault = true;
    },
    openCurrentChat(chat) {
      this.markChatRead({
        chatid: chat.ChatID
      });
      this.$root.$emit('focusHeader');
      this.setIsNewChat(false);
      this.setCurrentChatID(chat.ChatID);
    },
    showDetails(participant) {
      this.$modal.show(
        ParticipantsModal,
        { participant },
        {
          adaptive: true,
          minWidth: 320,
          maxWidth: 1700,
          width: '50%',
          height: 'auto'
        }
      );
    },
    hasChat(userID) {
      return this.getChatWithUser(userID);
    },
    createChat(user) {
      const currentChat = this.hasChat(user.UserID);
      if (currentChat) {
        this.setCurrentChatID(currentChat.ChatID);
        this.setIsNewChat(false);
        this.setInCall(false);
        this.setIsOutGoingCall(false);
        this.setCallEnded(false);
        this.setCallNotAvailable(false);
      } else {
        this.createNewChat(user.UserID).then((response) => {
          this.setCurrentChatID(response.data);
          this.setIsNewChat(false);
          this.setInCall(false);
          this.setIsOutGoingCall(false);
          this.setCallEnded(false);
          this.setCallNotAvailable(false);
        });
      }
    },
    onModalClose() {
      this.$emit('close', {
        success: false
      });
      this.setCurrentChatID(null);
      setTimeout(function () {
        document.getElementById('chatModal').focus();
      }, 0);
    }
  }
};
</script>
<style lang="scss">
.chatlist_body {
  top: 120px;
}
.notify {
  position: relative;
  display: flex;
  border: none;
  outline: none;
}
.notify__available {
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 15px;
  height: 15px;
  background: rgb(58, 235, 58);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1875em solid;
  border-radius: 100%;
  z-index: 10;
}
.notify__unavailable {
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 15px;
  height: 15px;
  background: rgb(228, 36, 36);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1875em solid;
  border-radius: 100%;
  z-index: 10;
}
.icon {
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
}
.icon__available {
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 15px;
  height: 15px;
  background: rgb(58, 235, 58);
  color: var(--color-primary-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1875em solid;
  border-radius: 50%;
  z-index: 10;
}
.icon__unavailable {
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 15px;
  height: 15px;
  background: rgb(255, 18, 18);
  color: var(--color-primary-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1875em solid;
  border-radius: 50%;
  z-index: 10;
}
.icon__unread {
  position: absolute;
  top: 40%;
  right: 15%;
  width: 10px;
  height: 10px;
  background: var(--color-primary-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.chatMaxWidth {
  max-width: 60%;
}
.stuck {
  width: calc(100% - 75%);
}
.headerName {
  width: 70%;
  @media (min-width: 993px) and (max-width: 1024px) {
    width: 60%;
  }
  @media (max-width: 992px) {
    width: 50%;
  }
}
.message {
  width: 70%;
  @media (min-width: 760px) and (max-width: 1024px) {
    width: 60%;
  }
}
.name {
  width: 100%;
}
.message__truncate {
  width: 90%;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.name__truncate {
  width: 90%;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.messageWidth {
  max-width: 60%;
}
</style>
