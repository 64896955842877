<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <PublicGroupsDataProvider
      ><div
        class="relative flex flex-col h-full gap-2 px-0 md:px-8 py-4 space-y-2"
        :style="designStyles"
      >
        <div class="mb-8 z-0 overflow-x-hidden overflow-y-scroll h-full">
          <div class="relative flex flex-col md:flex-row h-full space-y-2">
            <!--list section-->
            <div class="relative w-full md:w-1/3 space-y-2 md:space-y-4">
              <!-- Header-->
              <div class="flex items-center justify-center">
                <!-- Avatar -->
                <div class="w-2/5">
                  <button class="button" @click="selectAvatarPicture">
                    <div ref="childFocus" class="relative" tabindex="-1">
                      <Avatar
                        :image="profile.Picture"
                        :circle="true"
                        :alt="$t('Views.Shared.UserPicture')"
                        class="h-16 w-16 lg:h-24 lg:w-24"
                        @error="setAltImg"
                      />
                      <div
                        v-if="isProfileEnabled"
                        class="rounded-full bg-dark h-8 w-8 flex items-center justify-center absolute bottom-0 right-0"
                      >
                        <i
                          class="fal fa-camera fa-sm text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        ref="fileInput"
                        type="file"
                        class="hidden"
                        accept=".png,.jpg,.jpeg,.webp,.gif"
                        aria-haspopup="true"
                        aria-label="upload your picture "
                        @change="onAvatarFileChanged"
                      />
                    </div>
                  </button>
                </div>
                <div class="w-3/5 space-y-2">
                  <div class="text-base lg:text-md">
                    {{ profile.Firstname }} {{ profile.Lastname }}
                  </div>
                  <div class="flex flex-wrap">
                    <TappinButton
                      icon="sign-out"
                      class="button-secondary rounded-3xl px-4"
                      :custom_caption="$t('Components.shared.Navbar.logout')"
                      @click="doLogout"
                    />
                  </div>
                </div>
              </div>
              <!--Toggle settings-->
              <ProfileSettings class="px-6" />
              <!--Content menu-->
              <div v-if="isDefault" class="space-y-2 md:space-y-4">
                <!--User settings Tabs-->
                <div
                  class="flex flex-col shadow rounded-xl p-4 md:p-6 space-y-6"
                >
                  <TappinButton
                    icon="info"
                    directionIcon="angle-right"
                    class="flex justify-between px-4 space-x-4"
                    v-tippy="{
                      placement: 'right',
                      theme: 'light-border',
                      arrow: false,
                      flipBehavior: ['left']
                    }"
                    :custom_caption="$t('Views.Stage.MyProfile.info')"
                    :content="$t('Views.Stage.MyProfile.info')"
                    @click="loadInfo"
                  />
                  <TappinButton
                    v-if="isProfileEnabled"
                    icon="address-card"
                    directionIcon="angle-right"
                    class="flex justify-between px-4"
                    v-tippy="{
                      placement: 'right',
                      theme: 'light-border',
                      arrow: false,
                      flipBehavior: ['left']
                    }"
                    :custom_caption="$t('Views.Stage.MyProfile.editProfile')"
                    :content="$t('Views.Stage.MyProfile.editProfile')"
                    @click="loadEditProfile"
                  />
                </div>
                <!--Settings Tabs-->
                <div
                  v-if="hasContent"
                  class="flex flex-col shadow rounded-xl p-4 md:p-6 space-y-6"
                >
                  <TappinButton
                    icon="users"
                    directionIcon="angle-right"
                    :class="
                      groups && groups.length
                        ? 'flex justify-between px-4'
                        : 'hidden'
                    "
                    v-tippy="{
                      placement: 'right',
                      theme: 'light-border',
                      arrow: false,
                      flipBehavior: ['left']
                    }"
                    :custom_caption="$t('Views.Stage.MyProfile.groups')"
                    :content="$t('Views.Stage.MyProfile.groups')"
                    @click="loadPublicGroups"
                  />
                  <TappinButton
                    v-if="isBookingsEnabled && isAgendaEnabled"
                    icon="calendar-alt"
                    directionIcon="angle-right"
                    class="flex justify-between px-4"
                    v-tippy="{
                      placement: 'right',
                      theme: 'light-border',
                      arrow: false,
                      flipBehavior: ['left']
                    }"
                    :custom_caption="$t('Components.shared.Navbar.myAgenda')"
                    :content="$t('Components.shared.Navbar.myAgenda')"
                    @click="loadBookSessionsModal"
                  />
                  <TappinButton
                    v-if="isQrEnabled"
                    icon="qrcode"
                    directionIcon="angle-right"
                    class="flex justify-between px-4"
                    v-tippy="{
                      placement: 'right',
                      theme: 'light-border',
                      arrow: false,
                      flipBehavior: ['left']
                    }"
                    :custom_caption="$t('Components.shared.Navbar.myTicket')"
                    :content="$t('Components.shared.Navbar.myTicket')"
                    @click="loadTicketModal"
                  />
                  <TappinButton
                    v-if="isVoucherBalanceEnabled"
                    icon="ticket"
                    directionIcon="angle-right"
                    class="flex justify-between px-4"
                    v-tippy="{
                      placement: 'right',
                      theme: 'light-border',
                      arrow: false,
                      flipBehavior: ['left']
                    }"
                    :custom_caption="$t('Components.shared.Navbar.myVouchers')"
                    :content="$t('Components.shared.Navbar.myVouchers')"
                    @click="loadVoucherBalance"
                  />
                </div>
              </div>
            </div>
            <!--For large screen sizes-->
            <div
              v-if="isDefault"
              class="hidden overflow-x-hidden overflow-y-scroll md:w-2/3 md:inline-block p-4"
            >
              <div class="flex flex-col flex-wrap w-full h-full">
                <div class="h-full">
                  <button
                    class="button px-4 md:hidden"
                    aria-label="go to previous page"
                    @click="setCurrentChatID(false)"
                  >
                    <i class="far fa-chevron-left" aria-hidden="true" />
                  </button>
                  <ProfileInfo v-if="info" />
                  <EditProfile v-if="editProfile" />
                  <TicketModal v-if="ticket" />
                  <BookSessions v-if="bookSessions" :isAgendaStage="false" />
                  <VoucherBalance v-if="voucherBalance" />
                  <PublicGroups v-if="publicGroups" />
                </div>
              </div>
            </div>
            <!--For small screen sizes-->
            <div v-if="!isDefault" class="w-full md:hidden">
              <div class="flex flex-col h-full">
                <div class="h-full">
                  <TappinButton
                    icon="chevron-left"
                    class="p-2 md:hidden"
                    aria-label="go to previous page"
                    :custom_caption="$t('Views.Stage.MyProfile.back')"
                    @click="setDefault()"
                  />

                  <ProfileInfo v-if="info" />
                  <EditProfile v-if="editProfile" />
                  <TicketModal v-if="ticket" />
                  <BookSessions v-if="bookSessions" :isAgendaStage="false" />
                  <VoucherBalance v-if="voucherBalance" />
                  <PublicGroups v-if="publicGroups" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="absolute bottom-0 bg-white w-full p-2 md:p-4 z-10">
          <TappinButton
            class="button button-secondary text-xs"
            :custom_caption="$t('Views.Stage.MyProfile.close')"
            @click="onClose"
          />
        </div></div></PublicGroupsDataProvider
  ></CurrentEventDataProvider>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { getApplicablePlatforms } from '@/utils';

import Avatar from '@/components/shared/Avatar';
import TappinButton from '@/components/buttons/TappinButton';

import ProfileInfo from '@/components/profile/ProfileInfo';
import EditProfile from '@/components/profile/EditProfile';
import BookSessions from '@/components/profile/BookSessions';
import TicketModal from '@/components/shared/TicketModal';
import ProfileSettings from '@/components/profile/ProfileSettings';
import VoucherBalance from '@/components/profile/VoucherBalance';
import PublicGroups from '@/components/profile/PublicGroups';

export default {
  name: 'UserProfile',
  components: {
    Avatar,
    TappinButton,
    ProfileInfo,
    EditProfile,
    TicketModal,
    BookSessions,
    ProfileSettings,
    VoucherBalance,
    PublicGroups
  },
  data() {
    return {
      isDefault: true,
      info: true,
      editProfile: false,
      ticket: false,
      bookSessions: false,
      voucherBalance: false,
      publicGroups: false
    };
  },
  computed: {
    ...mapGetters('CurrentEvent', ['getEvent', 'features']),
    ...mapGetters('Authentication', {
      profile: 'getUser'
    }),
    ...mapGetters('Authentication', ['getDefaultImage']),
    ...mapGetters('PublicGroups', {
      groups: 'getItems'
    }),
    isProfileEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppProfile
        : this.features.stageTabProfile;
    },
    isQrEnabled() {
      const event = this.getEvent;

      return (
        this.profile &&
        this.profile.TicketQrCode &&
        event.features.stageQrTicketsEnabled
      );
    },
    isAgendaEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppNavigationAgenda || this.features.lobbyAgenda
        : this.features.stageTabAgenda || this.features.lobbyAgenda;
    },
    isVoucherBalanceEnabled() {
      const event = this.getEvent;
      return this.profile && event.features.stageVoucherBalanceEnabled;
    },
    isBookingsEnabled() {
      const event = this.getEvent;
      return this.profile && event.features.bookingsEnabled;
    },
    hasContent() {
      return (
        (this.groups && this.groups.length) ||
        (this.isBookingsEnabled && this.isAgendaEnabled) ||
        this.isQrEnabled ||
        this.isVoucherBalanceEnabled
      );
    }
  },
  methods: {
    ...mapActions('Authentication', ['doLogout']),
    ...mapActions('Authentication', {
      changeAvatar: 'changeAvatar'
    }),
    setAltImg(event) {
      event.target.src = this.getDefaultImage;
    },
    selectAvatarPicture() {
      this.$refs.fileInput.click();
    },
    onAvatarFileChanged(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      this.changeAvatar(files[0]);
    },
    setDefault() {
      this.isDefault = true;
    },
    loadInfo() {
      window.innerWidth < 768
        ? (this.isDefault = false)
        : (this.isDefault = true);
      this.editProfile = false;
      this.ticket = false;
      this.bookSessions = false;
      this.voucherBalance = false;
      this.publicGroups = false;
      return (this.info = true);
    },
    loadEditProfile() {
      window.innerWidth < 768
        ? (this.isDefault = false)
        : (this.isDefault = true);
      this.info = false;
      this.ticket = false;
      this.bookSessions = false;
      this.voucherBalance = false;
      this.publicGroups = false;
      return (this.editProfile = true);
    },
    loadTicketModal() {
      window.innerWidth < 768
        ? (this.isDefault = false)
        : (this.isDefault = true);
      this.info = false;
      this.editProfile = false;
      this.bookSessions = false;
      this.voucherBalance = false;
      this.publicGroups = false;
      return (this.ticket = true);
    },
    loadBookSessionsModal() {
      window.innerWidth < 768
        ? (this.isDefault = false)
        : (this.isDefault = true);
      this.info = false;
      this.editProfile = false;
      this.ticket = false;
      this.voucherBalance = false;
      this.publicGroups = false;
      return (this.bookSessions = true);
    },
    loadVoucherBalance() {
      window.innerWidth < 768
        ? (this.isDefault = false)
        : (this.isDefault = true);
      this.info = false;
      this.editProfile = false;
      this.ticket = false;
      this.bookSessions = false;
      this.publicGroups = false;
      return (this.voucherBalance = true);
    },
    loadPublicGroups() {
      window.innerWidth < 768
        ? (this.isDefault = false)
        : (this.isDefault = true);
      this.info = false;
      this.editProfile = false;
      this.ticket = false;
      this.bookSessions = false;
      this.voucherBalance = false;
      return (this.publicGroups = true);
    },
    onClose() {
      document.body.classList.remove('overflow-hidden');
      this.$emit('close', {
        success: false
      });
      // setTimeout(function () {
      //   document.getElementById('myProfile').focus();
      // }, 0);
    }
  }
};
</script>
