<template>
  <CurrentEventDataProvider v-slot="{ designStyles, locale, data }">
    <VouchersDataProvider v-slot="{ voucherBalance, isVoucherBalanceLoading }">
      <div v-if="isVoucherBalanceLoading"><Spinner /></div>
      <div
        v-else-if="
          voucherBalance &&
          (voucherBalance.usedVouchers ||
            voucherBalance.pendingVouchers ||
            (voucherBalance.transactions &&
              voucherBalance.transactions.length > 0))
        "
        class="flex flex-col overflow-y-auto overflow-x-hidden items-center space-y-4 py-4"
        :style="designStyles"
      >
        <div class="text-base md:text-md font-bold">
          {{ $t('Components.shared.Navbar.myVouchers') }}
        </div>
        <!-- QR Code -->
        <div class="flex flex-col items-center justify-center" v-if="profile">
          <qrcode-vue
            :value="profile.TicketQrCode"
            :size="qrCodeSize"
            render-as="svg"
            level="H"
          />
        </div>
        <!--QR code Info-->
        <div class="flex flex-row items-center w-full h-full px-6 space-x-4">
          <!--voucher used-->
          <div
            class="flex w-1/2 items-center justify-between shadow rounded-lg p-2 md:p-4"
          >
            <i class="fa fa-ticket fa-lg" aria-hidden="true"></i>
            <div
              class="flex flex-col items-center justify-center md:justify-between"
            >
              <div>
                <span
                  class="inline-block font-bold text-xxs md:text-xs xl:text-sm"
                >
                  {{ $t('Views.Stage.MyProfile.vouchersUsed') }}
                </span>
              </div>
              <div>
                <div v-if="voucherBalance.usedVouchers">
                  <span
                    class="text-xxs md:text-xs xl:text-sm"
                    v-text="voucherBalance.usedVouchers"
                  />
                </div>
                <div v-else>
                  <span class="text-xxs md:text-xs xl:text-sm">{{
                    $t('Views.Stage.MyProfile.vouchersUsedPendingNotAvailable')
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <!--voucher pending-->
          <div
            class="flex w-1/2 items-center justify-between shadow rounded-lg p-2 md:p-4"
          >
            <i class="fa fa-ticket fa-lg" aria-hidden="true"></i>
            <div
              class="flex flex-col items-center justify-center md:justify-between"
            >
              <div>
                <span
                  class="inline-block font-bold text-xxs md:text-xs xl:text-sm"
                >
                  {{ $t('Views.Stage.MyProfile.vouchersPending') }}
                </span>
              </div>
              <div>
                <div v-if="voucherBalance.pendingVouchers">
                  <span
                    class="text-xxs md:text-xs xl:text-sm"
                    v-text="voucherBalance.pendingVouchers"
                  />
                </div>
                <div v-else>
                  <span class="text-xxs md:text-xs xl:text-sm">{{
                    $t('Views.Stage.MyProfile.vouchersUsedPendingNotAvailable')
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Transactions -->
        <!--<div class="items-center h-full">
          <span class="inline-block font-bold text-xxs md:text-xs xl:text-sm">
            {{ $t('Views.Stage.MyProfile.transactions') }}
          </span>
          <div
            class="flex items-center justify-center h-full"
            v-if="
              voucherBalance.transactions == null ||
              voucherBalance.transactions.length == 0
            "
          >
            <p class="p-4 font-bold text-sm md:text-base">
              {{ $t('Views.Stage.MyProfile.vouchersUsedPendingNotAvailable') }}
            </p>
          </div>
          <div
            v-else
            class="overflow-y-auto h-32 mx-auto bg-white dark:bg-slate-800 dark:highlight-white/5 shadow-lg ring-1 ring-black/5 rounded-xl flex flex-col divide-y dark:divide-slate-200/5"
          >
            <div
              class="flex items-center gap-2 p-4"
              :key="i"
              v-for="(voucher, i) in voucherBalance.transactions"
            >
              <i class="fas fa-ticket-alt mr-4" />
              <div class="flex flex-col">
                <strong
                  class="text-slate-900 text-sm font-medium dark:text-slate-200"
                  >{{ voucher.amount }}</strong
                >
                <span
                  class="text-slate-500 text-sm font-medium dark:text-slate-400"
                >
                  <Moment
                    v-if="locale === 'en'"
                    :value="voucher.registerOn"
                    format="dddd DD MMMM - HH:mm"
                  />
                  <Moment
                    v-if="locale === 'nb'"
                    :value="voucher.registerOn"
                    format="dddd DD. MMMM - HH:mm"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>-->
      </div>
      <div v-else class="flex justify-center items-center">
        <i class="fas fa-exclamation-circle" />
        <p class="p-4 font-bold text-sm md:text-base">
          {{ $t('Views.Stage.MyProfile.vouchersNotAvailable') }}
        </p>
      </div>
    </VouchersDataProvider>
  </CurrentEventDataProvider>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/shared/Spinner';
import QrcodeVue from 'qrcode.vue';
import { getPusherInstance } from '@/utils';
export default {
  name: 'ProfileInfo',
  components: {
    Spinner,
    QrcodeVue
  },
  data() {
    return { windowWidth: window.innerWidth };
  },
  created() {
    getPusherInstance({ isReady: true }).bind(
      `voucher-change-${this.profile.TicketQrCode}`,
      () => {
        this.getVouchers();
      }
    );
  },
  computed: {
    ...mapGetters('CurrentEvent', ['getEvent', 'features']),
    ...mapGetters('Authentication', {
      profile: 'getUser'
    }),
    qrCodeSize() {
      return Math.min(Math.max(parseInt(this.windowWidth / 2), 50), 200);
    },
    isQrEnabled() {
      const event = this.getEvent;
      return (
        this.profile &&
        this.profile.TicketQrCode &&
        event.features.stageQrTicketsEnabled
      );
    }
  },
  methods: {
    ...mapActions('Vouchers', ['getVouchers'])
  }
};
</script>
