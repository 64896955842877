<template>
  <ul class="flex flex-wrap">
    <li
      v-for="link in linksWithIcons"
      :key="link.key"
      class="inline-block text-base w-8"
    >
      <a
        :href="link.value"
        target="_blank"
        :title="link.label || link.key"
        class="p-2 text-dark hover:opacity-50"
      >
        <i :class="link.icon" />
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ContactDetails',
  props: {
    links: {
      type: Array,
      default: () => []
    },
    phoneNumber: {
      type: String,
      default: undefined
    },
    email: {
      type: String,
      default: undefined
    }
  },
  computed: {
    linkTypes() {
      return {
        [linkKeys.WEB]: {
          key: linkKeys.WEB,
          label: this.$t('Components.Speaker.SpeakerInformation.websiteLabel'),
          icon: 'fal fa-globe'
        },
        [linkKeys.EMAIL]: {
          key: linkKeys.EMAIL,
          label: this.$t('Components.Speaker.SpeakerInformation.emailLabel'),
          icon: 'fal fa-envelope',
          prefix: 'mailto:'
        },
        [linkKeys.PHONE]: {
          key: linkKeys.PHONE,
          label: this.$t('Components.Speaker.SpeakerInformation.phoneLabel'),
          icon: 'fal fa-phone',
          prefix: 'tel:'
        },
        [linkKeys.SKYPE]: {
          key: linkKeys.SKYPE,
          icon: 'fab fa-skype',
          prefix: 'skype:',
          postfix: '?call'
        },
        [linkKeys.FACEBOOK]: {
          key: linkKeys.FACEBOOK,
          icon: 'fab fa-facebook'
        },
        [linkKeys.TWITTER]: {
          key: linkKeys.TWITTER,
          icon: 'fab fa-twitter'
        },
        [linkKeys.LINKEDIN]: {
          key: linkKeys.LINKEDIN,
          icon: 'fab fa-linkedin'
        },
        [linkKeys.SNAPCHAT]: {
          key: linkKeys.SNAPCHAT,
          icon: 'fab fa-snapchat'
        },
        [linkKeys.INSTAGRAM]: {
          key: linkKeys.INSTAGRAM,
          icon: 'fab fa-instagram'
        },
        [linkKeys.YOUTUBE]: { key: linkKeys.YOUTUBE, icon: 'fab fa-youtube' },
        [linkKeys.VIMEO]: { key: linkKeys.VIMEO, icon: 'fab fa-vimeo' },
        [linkKeys.SLIDESHARE]: {
          key: linkKeys.SLIDESHARE,
          icon: 'fab fa-slideshare'
        }
      };
    },
    linksWithIcons() {
      const additionalLinks = [];

      if (this.phoneNumber) {
        additionalLinks.push({ type: linkKeys.PHONE, url: this.phoneNumber });
      }

      if (this.email) {
        additionalLinks.push({ type: linkKeys.EMAIL, url: this.email });
      }

      return [...additionalLinks, ...this.links]
        .map((link) =>
          getFullLink(
            this.linkTypes[link.type ?? getLinkType(link.url)],
            link.url
          )
        )
        .filter((item) => item?.value?.trim());
    }
  }
};

const linkKeys = {
  WEB: 'Web',
  EMAIL: 'Email',
  PHONE: 'Phone',
  SKYPE: 'Skype',
  FACEBOOK: 'Facebook',
  TWITTER: 'Twitter',
  LINKEDIN: 'Linkedin',
  SNAPCHAT: 'Snapchat',
  INSTAGRAM: 'Instagram',
  YOUTUBE: 'Youtube',
  VIMEO: 'Vimeo',
  SLIDESHARE: 'Slideshare'
};

function getFullLink(item, value) {
  if (!value) {
    return null;
  }

  return {
    ...item,
    key: item.key + Math.random() * 100000,
    value: `${item.prefix ?? ''}${value}${item.postfix ?? ''}`
  };
}

const urlSocialLinkMap = {
  'fb.me': linkKeys.FACEBOOK,
  'fb.com': linkKeys.FACEBOOK,
  'facebook.com': linkKeys.FACEBOOK,
  'twitter.com': linkKeys.TWITTER,
  'linkedin.com': linkKeys.LINKEDIN,
  'snapchat.com': linkKeys.SNAPCHAT,
  'instagram.com': linkKeys.INSTAGRAM,
  'youtube.com': linkKeys.YOUTUBE,
  'youtu.be': linkKeys.YOUTUBE,
  'vimeo.com': linkKeys.VIMEO,
  'slideshare.net': linkKeys.SLIDESHARE
};

function getLinkType(url) {
  const type = Object.keys(urlSocialLinkMap).find((x) =>
    url.match(`(http(s)?://)?${x}`)
  );

  if (!type) {
    return linkKeys.WEB;
  }

  return urlSocialLinkMap[type];
}
</script>
