<template>
  <CurrentEventDataProvider v-slot="{ data }">
    <Section id="speakers" container-class="max-w-large">
      <template slot="heading">{{ data.captions.speakersCaption }}</template>
      <template slot="content">
        <SpeakerList
          :active="true"
          :showHeader="false"
          :isStage="false"
          :listHeight="70.5"
        />
      </template>
    </Section>
  </CurrentEventDataProvider>
</template>
<script>
import Section from '@/components/shared/Section';
import SpeakerList from '@/components/speaker/SpeakerList.vue';

export default {
  components: {
    Section,
    SpeakerList
  }
};
</script>
