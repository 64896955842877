<template>
  <apexchart :options="chartOptions" :series="series" height="100%" />
</template>

<script>
import { chartColor } from '@/constants/chart-color-constants';

export default {
  props: {
    poll: Array
  },
  methods: {},
  computed: {
    series: function () {
      return this.$props.poll.map((vote) => vote.count);
    },
    chartOptions: function () {
      return {
        labels: this.$props.poll.map((vote) => vote.label),
        chart: {
          type: 'pie'
        },
        plotOptions: {
          pie: {
            expandOnClick: false
          }
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        dataLabels: {
          style: {
            fontSize: '16px',
            fontFamily: 'Arial',
            fontWeight: 'bold'
          }
        },
        legend: {
          position: 'bottom',
          fontSize: '16px',
          fontFamily: 'Arial',
          fontWeight: 'bold',
          itemMargin: {
            horizontal: 20,
            vertical: 10
          },
          markers: {
            width: 16,
            height: 16
          },
          horizontalAlign: 'center'
        },
        colors: this.$props.poll.map(
          (vote, i) => vote.color ?? chartColor[i % 5]
        ),
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                width: '100%'
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                fontSize: '12px',
                fontWeight: 'normal',
                itemMargin: {
                  horizontal: 10,
                  vertical: 5
                },
                markers: {
                  width: 12,
                  height: 12
                },
                horizontalAlign: 'center'
              }
            }
          }
        ]
      };
    }
  }
};
</script>
