<template>
  <CurrentEventDataProvider v-slot="{ designStyles }">
    <div
      class="flex flex-col items-center w-full h-full overflow-y-auto overflow-x-hidden space-y-6"
      :style="designStyles"
    >
      <div class="text-base md:text-md font-bold">
        {{ $t('Views.Stage.MyProfile.info') }}
      </div>
      <div class="flex flex-col w-full space-y-6 text-center">
        <!--Info-->
        <div class="flex flex-row items-center w-full">
          <div class="w-2/5">
            <i class="fal fa-phone-alt fa-sm md:fa-lg lg:fa-2x" />
          </div>
          <div class="w-3/5">
            <div v-if="profile.Cell">
              <span
                class="text-xxs md:text-xs lg:text-sm"
                v-text="profile.Cell"
              />
            </div>
            <div v-else>
              <span class="text-xxs md:text-xs lg:text-sm">{{
                $t('Views.Stage.MyProfile.phoneNotAvailable')
              }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-row items-center w-full">
          <div class="w-2/5">
            <i class="fal fa-envelope fa-sm md:fa-lg lg:fa-2x" />
          </div>
          <div class="w-3/5">
            <div v-if="profile.Email">
              <span
                class="text-xxs md:text-xs lg:text-sm"
                v-text="profile.Email"
              />
            </div>
            <div v-else>
              <span class="text-xxs md:text-xs lg:text-sm">{{
                $t('Views.Stage.MyProfile.emailNotAvailable')
              }}</span>
            </div>
          </div>
        </div>
        <!-- ADDRESS -->
        <!-- <div class="flex flex-row items-center ">
        <div class="w-1/4">
          <i class="fal fa-map-marker-alt fa-sm md:fa-md"></i>
        </div>
        <div>
          <span class="font-bold text-xxs md:text-xs lg:text-sm">{{
            $t("Views.Stage.MyProfile.address")
          }}</span>
          <div v-if="profile.Address1">
            <span
              class="text-xxs md:text-xs lg:text-sm"
              v-text="profile.Address1"
            />
          </div>
          <div v-else>
            <span class="text-xxs md:text-xs lg:text-sm">{{
              $t("Views.Stage.MyProfile.addressNotAvailable")
            }}</span>
          </div>
        </div>
      </div>-->
        <div>
          <span
            class="text-xxs md:text-xs lg:text-sm"
            v-text="profile.About2"
          />
        </div>
        <div>
          <ul class="flex">
            <li v-if="profile.Linkedin">
              <a
                :href="`https://www.linkedin.com/${profile.Linkedin}`"
                target="_blank"
              >
                <i class="fab fa-linkedin fa-sm md:fa-lg lg:fa-2x mr-3" />
              </a>
            </li>
            <li v-if="profile.Facebook">
              <a
                :href="`https://www.facebook.com/${profile.Facebook}`"
                target="_blank"
              >
                <i
                  class="fab fa-facebook-square fa-sm md:fa-lg lg:fa-2x mr-3"
                />
              </a>
            </li>
            <li v-if="profile.Instagram">
              <a
                :href="`https://www.instagram.com/${profile.Instagram}`"
                target="_blank"
              >
                <i class="fab fa-instagram fa-sm md:fa-lg lg:fa-2x mr-3" />
              </a>
            </li>
            <li v-if="profile.Twitter">
              <a
                :href="`https://www.twitter.com/${profile.Twitter}`"
                target="_blank"
              >
                <i class="fab fa-twitter fa-sm md:fa-lg lg:fa-2x mr-3" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ProfileInfo',
  computed: {
    ...mapGetters('Authentication', {
      profile: 'getUser'
    })
  }
};
</script>
