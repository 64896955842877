import store from '@/store';
import router from '@/router';

export default async function setCurrentEvent({ next, to }) {
  const loadedEventId = store.getters['CurrentEvent/getId'];
  const loadedEventSlug = store.getters['CurrentEvent/getEventSlug'];
  const paramsEventId = window.location.host.split('.')[0];
  if (
    paramsEventId &&
    paramsEventId !== loadedEventId &&
    paramsEventId !== loadedEventSlug
  ) {
    await store.dispatch(
      'CurrentEvent/loadAndCacheEventDetails',
      paramsEventId
    );

    const id = store.getters['CurrentEvent/getId'];
    const idFromDetails = store.getters['CurrentEvent/getIdFromEventDetails'];
    const title = store.getters['CurrentEvent/getTitle'];

    if (idFromDetails == null || idFromDetails === '0') {
      return router.push({
        name: 'fallback'
      });
    }
    console.log(`CurrentEvent: id: ${id}, title: '${title}'`);
  }

  return next();
}
