<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'DocumentsDataProvider',
  props: {
    applicablePlatforms: {
      type: Array,
      default: () => ['digital']
    }
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('Documents', ['fetchItems', 'refresh']),
    getById(id) {
      return this.getSponsors.find((e) => e.DocumentID === id);
    }
  },
  computed: {
    ...mapGetters('Documents', ['getItems', 'isLoading', 'failed'])
  },
  render() {
    return this.$scopedSlots.default({
      documents: this.getItems?.filter(
        (i) =>
          _.intersection(this.applicablePlatforms, i.PlatformDisplayOptions)
            .length > 0
      ),
      loading: this.isLoading,
      failed: this.failed,
      refresh: this.refresh
    });
  }
};
</script>
