<template>
  <CurrentEventDataProvider v-slot="{ title, data, features }">
    <div class="h-full w-full flex flex-col bg-white text-dark px-4">
      <!-- Navbar -->
      <div
        class="navbar pt-4 flex justify-between items-center font-bold bg-white sticky top-0 z-30"
      >
        <!-- Logo & Event name -->
        <div class="logo flex items-center">
          <img
            :src="data.logoUrl"
            :alt="data.logoUrl && 'Event\'s logo'"
            class="h-5 lg:h-10 w-auto"
          />
        </div>

        <!-- Support & Logout -->
        <div>
          <button
            v-show="chatWidgetLoaded"
            v-if="features.stageSupport"
            class="button button-primary button-xs text-xxs md:text-xs sm:inline-block"
            aria-label="Support"
            @click="toggleLiveChatWindow"
          >
            {{ $t('Views.Login.support') }}
          </button>
        </div>
      </div>

      <!-- Content -->
      <div
        class="flex flex-col flex-1 bg-light mt-4 md:mb-8 md:ml-8 md:mr-8 items-center justify-center"
        style="
          color: var(--login-color-text);
          background-color: var(--login-background-color);
        "
      >
        <div class="text-center mb-2 md:mb-8">
          <h1 class="font-bold text-md md:text-lg mb-4">
            {{ title }}
          </h1>
          <div
            class="text-xs md:text-base px-6 text-center html-content"
            v-html="data.loginCaption"
          />
          <h2 class="font-bold text-base md:text-md">
            {{ $t('Views.Login.logInToEvent') }}
          </h2>
        </div>

        <Card v-if="data.active === '1'" class="py-8 px-12 md:py-10 md:w-1/4">
          <form
            method="post"
            autocapitalize="none"
            @submit.prevent="submitForm"
          >
            <div class="space-y-4">
              <!--Error on request of new link-->
              <div class="flex-1 mb-6">
                <div v-if="requestFailed">
                  <transition name="fade">
                    <span class="form-error text-sm font-bold">
                      <i class="fas fa-exclamation-circle mr-1" />
                      {{ $t('Views.Login.Otp.errorRequestNewLink') }}
                    </span>
                  </transition>
                </div>
                <!--on request success of new link-->
                <div v-if="requestSucceeded">
                  <transition name="fade">
                    <span class="form-success text-sm font-bold">
                      <i class="fas fa-exclamation-circle mr-1" />
                      {{ $t('Views.Login.Otp.newLinkSent') }}
                    </span>
                  </transition>
                </div>
              </div>
              <!-- options to request new link -->
              <div class="flex flex-col justify-center space-y-4">
                <div class="flex items-center space-x-4">
                  <input type="radio" value="email" v-model="form.delivery" />
                  <label :aria-label="$t('Views.Login.Otp.sendToEmail')">
                    {{ $t('Views.Login.Otp.sendToEmail') }}
                  </label>
                </div>
                <div class="flex items-center space-x-4">
                  <input type="radio" value="sms" v-model="form.delivery" />
                  <label :aria-label="$t('Views.Login.Otp.sendToPhone')">
                    {{ $t('Views.Login.Otp.sendToPhone') }}
                  </label>
                </div>
              </div>
              <!--Button-->
              <div class="flex items-baseline justify-between flex-col">
                <div
                  class="w-full flex flex-col-reverse md:flex-row justify-center"
                >
                  <button
                    type="submit"
                    :disabled="loading || requestSucceeded"
                    class="whitespace-nowrap button button-primary mt-2 md:mt-0"
                    aria-label="request"
                  >
                    <span
                      v-if="loading"
                      class="inline-block"
                      aria-label="loading"
                    >
                      {{ $t('Views.Login.Otp.sendingLink') }}
                      <i class="fal ml-2 fa-circle-notch fa-spin" />
                    </span>
                    <span v-else class="inline-block" aria-label="request">
                      {{ $t('Views.Login.Otp.requestNewLink') }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Card>
        <Card v-else class="p-10 lg:w-2/5">
          <span class="text-center" aria-label="This event has closed">
            <i class="fas fa-exclamation-circle mr-1" aria-label="hidden" />
            {{ $t('Views.Login.eventClosed') }}
          </span>
        </Card>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Card from '@/components/shared/Card';
import ApiClient from '@/client';
import { togglesSupportChat } from '@/mixins';

export default {
  name: 'PasswordlessLoginRequest',
  components: {
    Card
  },
  mixins: [togglesSupportChat],
  created() {},
  data() {
    return {
      loading: false,
      requestFailed: false,
      requestSucceeded: false,
      form: {
        delivery: 'email'
      },
      tosText: null
    };
  },
  computed: {
    ...mapGetters('CurrentEvent', [
      'isLobbyEnabled',
      'isStageEnabled',
      'isWebappEnabled',
      'getId',
      'getTwoFact',
      'features'
    ]),
    ...mapGetters('Authentication', ['isLoggedIn', 'isLoggingIn'])
  },
  methods: {
    ...mapActions('Authentication', ['doPasswordlessLogin', 'doLogout']),
    async submitForm(e) {
      this.loading = true;
      ApiClient.requestNewPasswordlessLoginLink(
        this.getId,
        this.$route.params.payload,
        this.form.delivery
      )
        .then(() => {
          this.requestFailed = false;
          this.requestSucceeded = true;
        })
        .catch(() => {
          this.requestFailed = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped>
.form-success {
  color: #34b60e;
}
</style>
