import ApiClient from '../../client';
import { createNamespacedDataProviderStore, extendStore } from './dataprovider';

const store = createNamespacedDataProviderStore((setItems) => {
  return ApiClient.getPublicGroups().then((response) =>
    setItems(response.data)
  );
});

export default extendStore(store, {});
