<template>
  <CurrentEventDataProvider>
    <ContentWithSidebarLayout
      :sidebar-collapsed="sidebarCollapsed"
      @closed="sidebarCollapsed = false"
      @opened="sidebarCollapsed = true"
    >
      <template slot="navbar">
        <Navbar :show-nav="true" :tabs="tabs" />
      </template>
      <template slot="mainContent">
        <!-- Main stream -->
        <StreamDataProvider
          :set-from-current-event="true"
          v-slot="{
            isReady,
            isImage,
            hasStream,
            embedUrl,
            embedType,
            isMainEventStream,
            switchToMainEventStream,
            iframeAllowAttributes
          }"
        >
          <div class="relative text-white flex flex-col flex-1 h-full">
            <StageStream
              v-if="showStream"
              :isReady="isReady"
              :isImage="isImage"
              :hasStream="hasStream"
              :embedUrl="embedUrl"
              :embedType="embedType"
              :iframeAllowAttributes="iframeAllowAttributes"
              class="md:p-4 md:pt-0"
            />
            <div
              v-if="isWebapp || hasStream"
              class="flex flex-wrap lg:hidden justify-between my-1 h-12 md:h-16"
            >
              <TappinButton
                v-if="!isMainEventStream"
                icon="chevron-left"
                class="button-primary cursor-pointer text-dark font-bold p-2 space-x-2 text-sm lg:text-base"
                @click="switchToMainEventStream"
                :custom_caption="
                  screenWidth > 1024
                    ? $t('Views.Stage.backToMainStream')
                    : $t('Views.Stage.backToMainStage')
                "
              />
              <TappinButton
                v-else-if="isWebapp"
                icon="chevron-left"
                class="button-primary cursor-pointer text-dark font-bold p-2 space-x-2 text-sm lg:text-base"
                @click="backToLobby"
                :custom_caption="
                  $t('Components.shared.ContentWithSidebarLayout.backToLobby')
                "
              />
              <TappinButton
                v-show="hasStream"
                icon="tv"
                class="button cursor-pointer text-dark p-2 flex flex-row items-center justify-center space-x-2 text-sm lg:text-base"
                :custom_caption="
                  isStreamOff
                    ? $t('Views.Stage.showStream')
                    : $t('Views.Stage.hideStream')
                "
                @click="hideStream()"
              />
            </div>
          </div>
        </StreamDataProvider>
      </template>

      <template slot="belowMainContent">
        <StreamDataProvider
          :set-from-current-event="true"
          v-slot="{ isMainEventStream, switchToMainEventStream }"
        >
          <div class="hidden lg:flex h-16 text-sm">
            <TappinButton
              v-if="!isMainEventStream"
              icon="chevron-left"
              class="the-other-one button-primary cursor-pointer text-dark font-bold p-2 space-x-2"
              @click="switchToMainEventStream"
              :custom_caption="
                screenWidth > 1023
                  ? $t('Views.Stage.backToMainStream')
                  : $t('Views.Stage.backToMainStage')
              "
            />
            <TappinButton
              v-else-if="isWebapp"
              icon="chevron-left"
              class="the-other-two button-primary cursor-pointer text-dark font-bold p-2 space-x-2"
              @click="backToLobby"
              :custom_caption="
                $t('Components.shared.ContentWithSidebarLayout.backToLobby')
              "
            />
          </div>
        </StreamDataProvider>
      </template>

      <template slot="sidebarContent">
        <!-- load content that is served in modals -->
        <SponsorsDataProvider>
          <!-- Sidebar active tab -->
          <transition-group
            name="fade"
            tag="div"
            class="h-full relative sidebar-content"
          >
            <component
              v-for="component in components"
              :key="component.key"
              :is="component.component"
              :active="
                getCurrentTab &&
                component.component === getCurrentTab.component &&
                !!component.enabled()
              "
              v-show="
                getCurrentTab &&
                component.component === getCurrentTab.component &&
                !!component.enabled()
              "
            />
          </transition-group>
        </SponsorsDataProvider>
      </template>

      <template slot="sidebar">
        <!-- Tab Buttons -->
        <div class="bg-navbar hidden lg:flex flex-col flex-1 justify-between">
          <SideBarTabs :tabs="tabs" :chatTab="chatTab" />

          <!-- Chat -->
          <div class="icon-notification" v-if="isChatTabEnabled">
            <button
              class="button hidden lg:block hover:opacity-50 w-16 h-20"
              v-tippy="{
                placement: 'right',
                theme: 'light-border',
                arrow: false,
                flipBehavior: ['left']
              }"
              :content="$t('Components.Chat.chatHere')"
              @click="openTab(ChatTab, true)"
              aria-label="open chat"
            >
              <i class="fal fa-comments" aria-hidden="true"></i>
              <div
                v-if="unreadChatsCount >= 1"
                class="icon-notification__badge"
              >
                <span
                  class="font-bold text-xxs md:text-xs"
                  aria-label="unread message available"
                >
                  {{ unreadChatsCount }}
                </span>
              </div>
            </button>
          </div>
        </div>
      </template>
    </ContentWithSidebarLayout>
  </CurrentEventDataProvider>
</template>

<script>
import { getApplicablePlatforms } from '@/utils';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Navbar from '@/components/shared/Navbar';
import Timeline from '@/views/Stage/Timeline';
import Agenda from '@/views/Stage/Agenda/Agenda';
import Speakers from '@/views/Stage/Speakers';
import Information from '@/views/Stage/Information';
import Documents from '@/views/Stage/Documents';
import Browser from '@/views/Stage/Browser';
import Participants from '@/views/Stage/Participant/Participants';
import SideBarTabs from '@/components/shared/SideBarTabs';
import StageStream from '@/components/shared/StageStream';
import Conversations from '@/views/Stage/Conversations';
import ContentWithSidebarLayout from '@/components/shared/ContentWithSidebarLayout';
import SponsorOverviewModal from '@/components/SponsorOverviewModal';
import StreamDataProvider from '@/components/providers/StreamDataProvider';
import UsersDataProvider from '@/components/providers/UsersDataProvider';
import TappinButton from '@/components/buttons/TappinButton';
import {
  createAnalyticsEvent,
  createAnalyticsPageView
} from '@/helpers/ga-helper';
import UserProfile from './Stage/UserProfile.vue';

export default {
  name: 'Stage',
  components: {
    Navbar,
    ContentWithSidebarLayout,
    SideBarTabs,
    StageStream,
    SponsorOverviewModal,
    StreamDataProvider,
    UsersDataProvider,
    TappinButton
  },
  data() {
    return {
      sidebarCollapsed: false,
      screenWidth: window.innerWidth,
      isStreamOff: true,
      showStream: false,
      chatTab: {
        enabled: () => true,
        key: 'conversations',
        icon: 'fa-comments',
        label: () => this.$t('Views.Stage.Labels.chats'),
        component: Conversations,
        isModal: false,
        onClick: (tab) => {
          createAnalyticsPageView(
            this.$gtag,
            `/event/${this.getEvent.eventId}/stage/chat`,
            'chat'
          );
          this.setTab(tab);
        }
      },
      tabs: [
        {
          enabled: this.isTimelineEnabled,
          key: 'timeline',
          icon: window.innerWidth < 1023 ? 'fa-home' : 'fa-comment-alt',
          label: () =>
            window.innerWidth < 1023
              ? this.$t('Components.shared.Navbar.home')
              : this.getEvent.captions.timelineCaption ||
                this.$t('Views.Stage.Labels.timeline'),
          component: Timeline,
          isModal: false,
          onClick: (tab) => {
            createAnalyticsPageView(
              this.$gtag,
              `/event/${this.getEvent.eventId}/stage/timeline`,
              'timeline'
            );
            this.setTab(tab);
          }
        },
        {
          enabled: this.isAgendaEnabled,
          key: 'agenda',
          icon: 'fa-calendar-alt',
          label: () => this.programCaption,
          component: Agenda,
          isModal: false,
          onClick: (tab) => {
            createAnalyticsPageView(
              this.$gtag,
              `/event/${this.getEvent.eventId}/stage/program`,
              'program'
            );
            this.setTab(tab);
          }
        },
        {
          enabled: this.isSponsorsEnabled,
          key: 'sponsors',
          icon: 'fa-star',
          label: () => this.sponsorsCaption,
          component: SponsorOverviewModal,
          isModal: true,
          onClick: (tab) => {
            createAnalyticsPageView(
              this.$gtag,
              `/event/${this.getEvent.eventId}/stage/sponsors`,
              'sponsors'
            );
            this.showSponsorsModal();
          }
        },
        {
          enabled: this.isSpeakersEnabled,
          key: 'speakers',
          icon: 'fa-microphone',
          label: () => this.speakersCaption,
          component: Speakers,
          isModal: false,
          onClick: (tab) => {
            createAnalyticsPageView(
              this.$gtag,
              `/event/${this.getEvent.eventId}/stage/speakers`,
              'speakers'
            );
            this.setTab(tab);
          }
        },
        {
          enabled: this.isInfosEnabled,
          key: 'info',
          icon: 'fa-info-circle',
          label: () => this.informationCaption,
          component: Information,
          isModal: false,
          onClick: (tab) => {
            createAnalyticsPageView(
              this.$gtag,
              `/event/${this.getEvent.eventId}/stage/information`,
              'information'
            );
            this.setTab(tab);
          }
        },
        {
          enabled: this.isDocumentsEnabled,
          key: 'documents',
          icon: 'fa-file',
          label: () =>
            this.getEvent.captions.documentsCaption ||
            this.$t('Views.Stage.Labels.documents'),
          component: Documents,
          isModal: false,
          onClick: (tab) => {
            createAnalyticsPageView(
              this.$gtag,
              `/event/${this.getEvent.eventId}/stage/documents`,
              'documents'
            );
            this.setTab(tab);
          }
        },
        {
          enabled: () =>
            this.features.stageTabCustom && this.getEvent.gamificationUrl,
          key: 'iframe',
          icon: 'fa-browser',
          label: () =>
            this.getEvent.captions.gamificationCaption ||
            this.$t('Views.Stage.Labels.gamification'),
          component: Browser,
          isModal: false,
          onClick: this.setTab
        },
        {
          enabled: this.isParticipantsEnabled,
          key: 'participants',
          icon: 'fa-users',
          label: () =>
            this.getEvent.captions.usersCaption ||
            this.$t('Views.Stage.Labels.users'),
          component: Participants,
          isModal: false,
          onClick: (tab) => {
            createAnalyticsPageView(
              this.$gtag,
              `/event/${this.getEvent.eventId}/stage/participants`,
              'participants'
            );
            this.setTab(tab);
          }
        }
      ]
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.showStream = window.innerWidth > 1023;
    this.track();
    createAnalyticsPageView(
      this.$gtag,
      `/event/${this.getEvent.eventId}/stage`,
      'stage'
    );
    this.$root.$on('focus-tab', this.focusTab);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    this.$root.$off('focus-tab', this.focusTab);
  },
  computed: {
    ...mapGetters('CurrentEvent', ['features', 'getEvent', 'lobbyElements']),
    ...mapGetters('Agendas', { getAgendas: 'getSessions' }),
    ...mapGetters('Sponsors', { getSponsors: 'getItems' }),
    ...mapGetters('Documents', { getRawDocuments: 'getItems' }),
    ...mapGetters('Speakers', { getSpeakers: 'getItems' }),
    ...mapGetters('Pages', { getRawPages: 'getItems' }),
    ...mapGetters('Stage', ['getCurrentTab']),
    ...mapGetters('Chat', [
      'getChats',
      'getUnreadChatMessages',
      'getUnreadChatsCount'
    ]),
    ...mapGetters('Stream', ['getStreamUrl', 'getEmbedType']),
    enabledTabs() {
      return this.tabs.filter((tab) => tab.enabled());
    },
    isWebapp() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? false
        : this.features.lobbyEnabled;
    },
    ChatTab() {
      return this.chatTab;
    },
    components() {
      const tabs = this.tabs.filter((tab) => !tab.isModal);
      tabs.push(this.chatTab);
      return tabs;
    },
    unreadChatsCount() {
      return this.getUnreadChatsCount;
    },
    getWebappInfoPages() {
      return this.getRawPages?.filter((x) =>
        x.PlatformDisplayOptions?.includes('webApp')
      );
    },
    getInfoPages() {
      return this.getRawPages?.filter((x) =>
        x.PlatformDisplayOptions?.includes('digital')
      );
    },

    getWebappDocuments() {
      return this.getRawDocuments?.filter((x) =>
        x.PlatformDisplayOptions?.includes('webApp')
      );
    },
    getDocuments() {
      return this.getRawDocuments?.filter((x) =>
        x.PlatformDisplayOptions?.includes('digital')
      );
    },
    programCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.programCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'agenda'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },

    sponsorsCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.sponsorsCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'sponsors'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },
    speakersCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.speakersCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'speakers'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },
    informationCaption() {
      const event = this.getEvent;

      const defaultCaption = event.captions.informationCaption;

      if (!event.lobbyElements) {
        return defaultCaption;
      }

      const mainStageCaption = event.lobbyElements.find(
        (x) => x.id === 'information'
      )?.options?.label;

      return mainStageCaption || defaultCaption;
    },
    isChatTabEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppChat
        : this.features.stageChatEnabled;
    }
  },
  methods: {
    ...mapActions('Authentication', ['doLogout']),
    ...mapActions('Timeline', {
      timelineResetPromotedPage: 'resetPromotedPage',
      setPromotedPage: 'setPromotedPage'
    }),
    ...mapActions('Stream', ['setStreamFromSession']),
    ...mapActions('Info', {
      pagesResetPromotedPage: 'resetPromotedPage'
    }),
    ...mapActions('Stage', ['goToSponsor', 'goToStageSession']),
    ...mapMutations('Stage', ['setCurrentTab']),
    ...mapMutations('Chat', ['setCurrentChatID', 'setIsChatList']),
    resetPromotedPage() {
      this.timelineResetPromotedPage();
      this.pagesResetPromotedPage();
    },
    backToLobby() {
      this.$router.push({
        name: 'lobby'
      });
    },
    openTab(tab, isDirectClick) {
      // If you click on a tab button, we automatically open the sidebar for you.
      this.sidebarCollapsed = false;
      this.resetPromotedPage();

      if (tab.onClick) {
        tab.onClick(tab);
      }

      if (isDirectClick) {
        this.setCurrentChatID(false);
      }

      if (tab === this.chatTab && isDirectClick) {
        this.setIsChatList(true);
      }
    },
    setTab(tab) {
      this.resetPromotedPage();
      this.$root.$emit('focusHere');
      this.setCurrentTab(tab);
    },

    focusTab(e) {
      const tabMapping = {
        infoPages: 'info',
        agenda: 'agenda',
        session: 'agenda',
        documents: 'documents',
        sponsor: 'sponsors',
        speakers: 'speakers',
        participants: 'participants',
        poll: 'poll',
        quiz: 'quiz',
        codedPoll: 'coded_polls',
        codedQuiz: 'coded_quizzes',
        allSponsors: 'sponsors',
        stageSession: 'stageSession',
        myAgenda: 'agenda',
        userProfile: 'userProfile'
      };

      const tabKey = tabMapping[e.type];
      const actions = {
        sponsors: () => {
          if (e.type === 'allSponsors') {
            this.showSponsorsModal();
          } else {
            this.goToSponsor(e.id);
          }
        },
        poll: () => this.setPromotedPage({ page: tabKey, id: e.id }),
        quiz: () => this.setPromotedPage({ page: tabKey, id: e.id }),
        coded_polls: () =>
          this.setPromotedPage({ page: tabKey, id: 'undefined' }),
        coded_quizzes: () =>
          this.setPromotedPage({ page: tabKey, id: 'undefined' }),
        userProfile: () => this.showProfileModal(),
        default: () => this.handleTab(tabKey, e)
      };
      (actions[tabKey] || actions.default)();
    },

    showSponsorsModal() {
      const tab = this.tabs.find((x) => x.key === 'sponsors');
      this.$modal.show(
        tab.component,
        {
          options:
            this.lobbyElements.find((element) => element.id === 'sponsors')
              ?.options ?? {}
        },
        {
          width: '90%',
          height: '90%',
          adaptive: true,
          minWidth: 300,
          maxWidth: 1700
        }
      );
    },

    showProfileModal(profile) {
      document.body.classList.add('overflow-hidden');

      this.$modal.show(
        UserProfile,
        { profile },
        {
          clickToClose: true,
          adaptive: true,
          minWidth: 250,
          maxWidth: 1000,
          width: '90%',
          height: '80%'
        },
        {
          opened: () => this.$root.$emit('focusChild'),
          closed: () => document.body.classList.remove('overflow-hidden')
        }
      );
      this.closeMobileMenu();
    },

    handleTab(tabKey, data) {
      const tab = this.tabs.find((x) => x.key === tabKey);
      this.setTab(tab);
      switch (data.type) {
        case 'session':
          this.handleSession(data.id, data.date, data.liveUrl);
          break;
        case 'myAgenda':
          this.handleMyAgenda();
          break;
      }
    },

    handleSession(id, date, liveUrl) {
      if (liveUrl != 'null') {
        this.setStreamFromSession({ id: id, liveUrl: liveUrl });
      }
      if (id) {
        this.$root.$emit('set-active', {
          data: id,
          type: 'agenda',
          date: date
        });
      }
    },

    handleMyAgenda() {
      this.$root.$emit('set-active-myAgenda');
    },

    isTimelineEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppTimeline
        : this.features.stageTabTimeline;
    },
    isAgendaEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppNavigationAgenda && this.getAgendas.length > 0
        : this.features.stageTabAgenda && this.getAgendas.length > 0;
    },
    isSponsorsEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppNavigationSponsors && this.getSponsors.length > 0
        : this.features.stageTabSponsors && this.getSponsors.length > 0;
    },
    isSpeakersEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppNavigationSpeakers
        : this.features.stageTabSpeakers;
    },
    isInfosEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppNavigationInfoPages &&
            this.getWebappInfoPages.length > 0
        : this.features.stageTabInfoPages && this.getInfoPages.length > 0;
    },
    isDocumentsEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppNavigationDocuments &&
            this.getWebappDocuments.length > 0
        : this.features.stageTabDocuments && this.getDocuments.length > 0;
    },
    isParticipantsEnabled() {
      return getApplicablePlatforms(this.$route).includes('webApp')
        ? this.features.webAppNavigationParticipants
        : this.features.stageTabParticipants;
    },
    onResize() {
      this.showStream = !this.isStreamOff || window.innerWidth > 1023;
    },
    hideStream() {
      this.isStreamOff = !this.isStreamOff;
      this.showStream = !this.isStreamOff;
    },
    track() {
      this.$gtag.config({
        send_page_view: false
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-notification {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}
.icon-notification__badge {
  position: absolute;
  bottom: 50%;
  left: 40%;
  width: 20px;
  height: 20px;
  background: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
</style>
