<template>
  <ParticipantList
    :active="true"
    :listHeight="85.4"
    :showTitle="true"
    :showHeader="true"
  />
</template>

<script>
import ParticipantList from '@/components/participants/ParticipantList';

export default {
  name: 'Participants',
  components: {
    ParticipantList
  }
};
</script>
