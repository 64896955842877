import ApiClient from '../../client';
import _ from 'lodash';
import { unknownUser } from '@/utils';

export default {
  namespaced: true,
  state: {
    onlineUserIds: [],
    isLoading: true,
    items: [],
    totalCount: null,
    failed: null
  },
  getters: {
    getById: (state) => (id) => {
      const user = state.items[id] || unknownUser;

      const clonedUser = _.cloneDeep(user);

      if (clonedUser) {
        clonedUser.Online = !!state.onlineUserIds.find((x) => x === id);
      }

      return clonedUser;
    },
    getUsers: (state) => {
      return state.items;
    },
    getUserTotalCount: (state) => {
      return state.totalCount;
    },
    getFailed: (state) => {
      return state.failed;
    },
    isLoading: (state) => {
      return state.isLoading;
    },
    isUserOnline: (state) => (id) => {
      return !!state.onlineUserIds.find((x) => x === id);
    }
  },
  actions: {
    async fetchUsers({ commit }, payload) {
      commit('setIsLoading', true);
      try {
        const response = await ApiClient.getUsers(
          payload.page,
          payload.pageSize,
          payload.search,
          payload.groupId
        );
        commit('setUsers', { data: response.data, append: !!payload.append });
      } catch (e) {
        commit('setFailed', e);
      }
      commit('setIsLoading', false);
    },
    async fetchUserTotalCount({ commit }) {
      const response = await ApiClient.getUserCount();
      commit('setUserTotalCount', response.data);
    },
    updateUser({ state, commit }, payload) {
      if (state.items.indexOf((x) => x.UserID === payload.item?.UserID)) {
        commit('updateUser', payload);
      }
    },
    deleteUser({ commit }, payload) {
      commit('deleteUser', payload);
    }
  },
  mutations: {
    updateOnlineUsers: (state, payload) => {
      state.onlineUserIds = payload?.onlineUserIds || [];
    },
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setUsers: (state, payload) => {
      state.items = payload.append
        ? state.items.concat(payload.data)
        : payload.data;
      state.isLoading = false;
      state.failed = null;
    },
    setUserTotalCount: (state, payload) => {
      state.totalCount = payload;
    },
    setFailed: (state, payload) => {
      state.items = [];
      state.isLoading = false;
      state.failed = payload;
    },
    updateUser: (state, payload) => {
      const itemIndex = state.items.indexOf(
        (x) => x.UserID === payload.item.UserID
      );
      if (itemIndex > -1) {
        state.items[itemIndex] = payload.item;
      }
    },
    deleteUser: (state, payload) => {
      state.items = [...state.items.filter((x) => x.UserID !== payload.item)];
    }
  }
};
